import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';

import {
  BoxBotaoCentro,
  BoxTopo,
  BtnConcluir,
  BtnRepeat,
  Container,
  ItemBox1,
  ItemBox2,
  Tela1Box2,
  Tela1Fig0,
  Text,
  customModal,
  TelaModal,
  BoxClose,
  BtnEsc,
} from './styles/stylesClicarImitar';

let video = '';

export default function ClicarImitar({ config }) {
  const qtdRodadas = config.rodadas ? config.rodadas.length : 0;
  const [rodadaAtual, setRodadaAtual] = useState(undefined);
  const [numRodadaAtual, setNumRodadaAtual] = useState(0);

  const [respostaOk, setRespostaOk] = useState(undefined);
  const [modalIsOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (config.audioInicio) {
      const audio = new Audio(config.audioInicio);

      audio.play();
    }

    iniciarRodada(1);
  }, []);

  const iniciarRodada = (numero) => {
    const rodada = config.rodadas[numero - 1];

    if (!rodada) return;

    if (rodada.audioEnunciado) {
      const audio = new Audio(rodada.audioEnunciado);

      audio.play();
    }

    setNumRodadaAtual(numero);
    setRodadaAtual(rodada);

    setRespostaOk(false);
  };

  const avancar = () => {
    if (numRodadaAtual === qtdRodadas) {
      setRespostaOk(true);
      config.avancar ? config.avancar() : alert('acabou!');
    } else {
      iniciarRodada(numRodadaAtual + 1);
    }
  };

  const openModal = (videoID) => {
    video = videoID;
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  if (!rodadaAtual) return null;

  return (
    <>
      <BoxTopo>
        <ItemBox1>
          <Text>{rodadaAtual.enunciado}</Text>
        </ItemBox1>
        <ItemBox2>
          <BtnRepeat onClick={() => iniciarRodada(1)}>
            <i className="fas fa-undo-alt"></i> REPETIR
          </BtnRepeat>
        </ItemBox2>
      </BoxTopo>

      <Container>
        <Tela1Box2>
          {rodadaAtual.opcoes.map((opcao, key) => (
            <Tela1Fig0 key={key} onClick={() => openModal(opcao.video)}>
              <video loop={true} src={opcao.video} muted></video>
            </Tela1Fig0>
          ))}
        </Tela1Box2>
      </Container>

      <div onClick={closeModal}>
        <Modal style={customModal} isOpen={modalIsOpen}>
          <BoxClose>
            <BtnEsc>X</BtnEsc>
          </BoxClose>
          <TelaModal>
            <video autoPlay={true} loop={true} src={video}></video>
          </TelaModal>
        </Modal>
      </div>

      <BoxBotaoCentro>
        <BtnConcluir onClick={avancar}>
          CONTINUAR <i className="fas fa-chevron-right"></i>{' '}
        </BtnConcluir>
      </BoxBotaoCentro>
    </>
  );
}
