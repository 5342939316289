/* eslint-disable react-hooks/exhaustive-deps */
import React, {Fragment, useState, useEffect} from 'react';
import {click, win, wrong} from '../libs/soundEffects';

let audio = undefined;

let rodadaAlternarItens = 0;
let fim = false;

const Genius = ({config}) => {
  const qtdRodadas = config.rodadas ? config.rodadas.length : 0;

  const [rodadaAtual, setRodadaAtual] = useState(undefined);
  const [numRodadaAtual, setNumRodadaAtual] = useState(0);
  const [respostaOk, setRespostaOk] = useState(undefined);
  const [itemAtual, setItemAtual] = useState(0);
  const [contador, setContador] = useState(0);
  const [selecionadas, setSelecionadas] = useState([]);
  const [opcaoSelecionada, setOpcaoSelecionada] = useState({});
  const [respostaId, setrespostaId] = useState(1);
  const [enunciado, setEnunciado] = useState(true);
  const [timer, setTimer] = useState(1);
  const [comecar, setComecar] = useState(false);

    useEffect(() => {
      if(!comecar) {
        if(config.audioInicio){
          const audio = new Audio(config.audioInicio);
  
          audio.play();        
        }
      }

      iniciarRodada(1);

      if(comecar) {
        if(!config.itens) {
          setTimeout(() => alteracoes(), config.tempoGenius ? config.tempoGenius * 1000 : 5000);
        }
      }

    }, [alteracoes, comecar, config.audioInicio, config.itens, config.tempoGenius, iniciarRodada]);

    const alteracoes = () => {

      alternarItens(0);

      setContador(contador + 1)
    }

    const alternarItens = (numero) => {
      const itensAlternar = config.rodadas[rodadaAlternarItens].itens;
      const qtd1 = config.rodadas[rodadaAlternarItens].itens.length;

      const novoItem = itensAlternar[numero + 1] ? numero + 1 : 0;

      setItemAtual(novoItem);

      if(timer === qtd1) {
        setEnunciado(false);
      } else {
        if(!fim) setTimeout(() => alternarItens(novoItem), config.tempoGenius ? config.tempoGenius * 1000 : 5000);
      }
    }

    const iniciarRodada = (numero) => {
      const rodada = config.rodadas[numero - 1];

      if(!rodada) return;

      if(rodada.audioEnunciado){
        const audio = new Audio(rodada.audioEnunciado);

        audio.play();        
      }

      setNumRodadaAtual(numero);
      setRodadaAtual(rodada);
      setRespostaOk(false);
      setSelecionadas([]);
      setrespostaId(1);
      setEnunciado(true);

      rodadaAlternarItens = numero - 1;        
    }

    const avancar = () => {
      if(numRodadaAtual === qtdRodadas){
        setRespostaOk(true);
      } else {
        iniciarRodada(numRodadaAtual + 1);
      }
    }

    const responder = (numOpc, opcao) => {

      if(opcao.id === respostaId) {
        setrespostaId(respostaId + 1)

        const opcSelecionadas = [...selecionadas];

        if(!selecionadas.includes(numOpc)) opcSelecionadas.push(numOpc);

        setSelecionadas(opcSelecionadas);

        if(audio) audio.pause();

        if(opcao.audio){
          const audioOpc = new Audio(opcao.audio);
          audioOpc.play();
        }
  
        if(opcao.audio){
          setTimeout(() => win(), opcao.duracaoAudio ? opcao.duracaoAudio*1000 : 2200);
        } else {
          win();
        }

        if (respostaId === qtdItens) {
          setRespostaOk(true)
        }

      } else {
        if(opcao.audio){
          const audioOpc = new Audio(opcao.audio);
          audioOpc.play();
        } else {
          wrong();
        }
      }
    }

    const selecionar = (numOpc, opcao) => {
      if(opcao.audio){
          const audioOpc = new Audio(opcao.audio);
          audioOpc.play();
      }

      setSelecionadas([numOpc]);
      setOpcaoSelecionada(opcao);
  }

    const encerrar = () => 
    {
      fim = true;
      config.avancar ? config.avancar() : alert('acabou!');
    }

    if(!rodadaAtual) return null;

    const qtdItens = rodadaAtual.itens.length

    return(
      <>
        {comecar ?

        (
          <div className="content">
          <div className="container">
              <div className="box-topo">
                  <div className="item-box-topo-1">
                      <p className="p-text">{rodadaAtual.enunciado}</p>
                  </div>
                  <div className="item-box-topo-2">
                      <a className="btn-repetir" onClick={() => iniciarRodada(1)}><i className="fas fa-undo-alt"></i> REPETIR</a>
                  </div>
              </div>

              {!respostaOk &&
                enunciado &&
                  <div style={{textAlign: 'center'}}>
                    <img src={rodadaAtual.itens[itemAtual].imagem} style={{height: 240}}/>
                  </div>
              }
              
              {respostaOk ?
                <div className="tl8-box-2">
                  <div className="tl7-box-confirmacao">
                    <p className="p-text">PARABÉNS</p>
                    <p className="p-text">VOCÊ ACERTOU!</p>
                    <a className="btn-concluir" onClick={encerrar}>CONTINUAR <i className="fas fa-chevron-right"></i> </a>
                  </div>
                </div>
              :
                <div className="tl1-box-2">
                  {rodadaAtual.opcoes.map((opcao, key) => 
                      <div 
                          key = {key}
                          className={
                            (selecionadas.includes(key + 1)) ? 
                              ((opcao.correta || rodadaAtual.qualquerOpcao) ? "tl1-figura-correta" : "tl1-figura-correta") 
                                : 
                              "tl1-figura-0"
                          }
                          onClick={() => responder(key + 1, opcao)}
                          style={{background:  opcao.cor ? opcao.cor : undefined}}
                      >
                        <img style={{height: 180}} src={opcao.imagem}/>
                      </div>
                  )}                                
                </div> 
              }
            </div>
          </div>
        ) : (

          <div className="content">
            <div className="container">

              <div className="box-topo">
                  <div className="item-box-topo-1">
                      <p className="p-text">{rodadaAtual.enunciado}</p>
                  </div>
                  <div className="item-box-topo-2">
                      <a className="btn-repetir" onClick={() => iniciarRodada(1)}><i className="fas fa-undo-alt"></i> REPETIR</a>
                  </div>
              </div>

              <div className="tl8-box-2">
                <div style={{height: 425, alignItems: 'center', display: 'flex', justifyContent: 'center'}}>
                  <a className="btn-concluir" onClick={() => setComecar(true)}>COMEÇAR <i className="fas fa-chevron-right"></i> </a>
                </div>
              </div>
              
            </div>
          </div>
        )}
      </>
    )
}

export default Genius;