import React, { useState, useEffect } from "react";
import Unity from "../components/Unity";
import AtividadeHtml from "../components/AtividadeHtml";
import { getQueryParams } from "../helpers";

const TesteAtividade = (props) => {
  const [identificador, setIdentificador] = useState("");
  const [play, setPlay] = useState(false);
  const [id, setId] = useState(true);
  const [json, setJson] = useState("");
  const [larguraTela, setLarguraTela] = useState(window.innerWidth);

  useEffect(() => {
    const queryParams = getQueryParams(props.location.search);

    if (queryParams["id"]) {
      setIdentificador(queryParams["id"]);
    }

    // const json = localStorage.getItem("jsonAtividadeTeste");
    // if (json && json !== "") {
    //   setJson(json);
    //   setId(false);
    // }

    const handleResize = () => setLarguraTela(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [props.location.search]);

  const executar = () => {
    if (json === "ERRO") {
      let a = 2;
      let b = 0;
      console.log(a / b);
    }

    if (json !== "") {
      localStorage.setItem("jsonAtividadeTeste", json);
    }

    if (play) {
      setIdentificador("");
      setPlay(false);
    } else {
      setPlay(true);
    }
  };

  const mobileSize = larguraTela < 600;

  return (
    <>
      {mobileSize ? (
        <div
          style={{
            padding: 15,
            backgroundColor: "white",
            borderRadius: 25,
            marginTop: 30,
            marginLeft: 10,
            marginRight: 10,
          }}
        >
          <h3>
            Para uma melhor experiência, utilize com o dispositivo no modo
            paisagem (deitado)!
          </h3>
        </div>
      ) : (
        <>
          <div className="">
            <div className="">
              <form
                // className="form-login"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "white",
                  padding: "15px",
                  marginTop: "30px",
                  marginLeft: "30px",
                  marginRight: "30px",
                  marginBottom: "-60px",
                }}
                onSubmit={(e) => {
                  e.preventDefault();
                  executar();
                }}
              >
                {id ? (
                  <>
                    <input
                      type="submit"
                      style={{ marginBottom: 15 }}
                      className="btn-padrao-lp"
                      value="JSON"
                      onClick={() => setId(false)}
                    />
                    <input
                      style={{
                        display: "block",
                        margin: "0 auto 15px",
                        padding: "12px",
                        width: "600px",
                        color: "#010100",
                        border: "1px solid darkgray",
                        borderRadius: "6px",
                      }}
                      type="text"
                      placeholder="Identificador"
                      id="nome"
                      onChange={(e) => setIdentificador(e.target.value)}
                      value={identificador}
                      disabled={play}
                    />
                    <input
                      type="submit"
                      className="btn-padrao-lp"
                      value={play ? "Alterar" : "Executar"}
                    />
                  </>
                ) : (
                  <>
                    <div style={{ textAlign: "center" }}>
                      <textarea
                        style={{
                          width: "100%",
                          height: window.innerHeight - 200,
                        }}
                        value={json}
                        onChange={(e) => setJson(e.target.value)}
                      />
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <input
                        type="submit"
                        style={{ marginTop: 10, marginRight: 10 }}
                        className="btn-padrao-lp"
                        value="Identificador"
                        onClick={() => setId(!id)}
                      />
                      <input
                        type="submit"
                        style={{ marginTop: 10 }}
                        className="btn-padrao-lp"
                        value="Confirmar"
                      />
                    </div>
                  </>
                )}
              </form>
            </div>
          </div>
          <div
            // className="container-atividades"
            style={{
              display: "flex",
              flexDirection: "column",
              paddingBottom: "80px",
              backgroundPosition: "left top, right bottom",
              backgroundRepeat: "no-repeat, no-repeat",
              backgroundAttachment: "fixed, fixed",
              overflow: "auto",
            }}
          >
            {play && (
              <div
                // className="atividades"
                style={{
                  display: "block",
                  margin: "75px auto",
                  width: "90%",
                  maxWidth: "1140px",
                  background: "#fff",
                  border: "20px solid #fff",
                  borderRadius: "15px",
                }}
              >
                {identificador.substring(0, 1) === "U" ? (
                  <Unity
                    identificador={identificador}
                    avancar={() => alert("ok")}
                  />
                ) : (
                  <AtividadeHtml
                    identificador={identificador}
                    toggleScreen={props.toggleScreen}
                    avancar={() => alert("ok")}
                    json={id ? undefined : json}
                  />
                )}
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default TesteAtividade;
