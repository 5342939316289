import styled from 'styled-components';

export const Card = styled.div`
  display: flex;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 10px solid #fff;
  border-radius: 15px;
  background: #ffffff;
  text-align: center;
  cursor: pointer;
  max-width: 450px;
  overflow: hidden;

  height: 120px;
  background: #75dfeb;

  p {
    font-size: 26px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
  }

  input {
    font-size: 20px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    padding: 10px;
    border-style: none;
  }
`;
