import React, { useState, useEffect, useRef } from 'react';
import Canvas from './Canvas';
import { click } from "../../../libs/soundEffects";
import { CircularProgress, TextField } from "@mui/material";

const Desenho = ({ config }) => {
  const qtdRodadas = config.rodadas ? config.rodadas.length : 1;

  const [carregando, setCarregando] = useState(true);
  const [ferramentaSelecionada, setFerramentaSelecionada] = useState("pen");
  const [corSelecionada, setCorSelecionada] = useState("#000000");
  const [tentativa, setTentativa] = useState(0);
  const [rodadaAtual, setRodadaAtual] = useState(undefined);
  const [numRodadaAtual, setNumRodadaAtual] = useState(0);

  const [moldura, setMoldura] = useState(false);
  const componentRef = useRef();

  useEffect(() => {
    if (config.audioEnunciado) {
      const audio = new Audio(config.audioEnunciado);
      audio.play();
    }
    if (config.audioInicio) {
      const audio = new Audio(config.audioInicio);
      audio.play();
    }

    iniciarRodada(1);
  }, []);

  const iniciarRodada = (numero) => {
    const rodada =
      config.rodadas && config.rodadas.length > 0
        ? config.rodadas[numero - 1]
        : config;

    if (!rodada) return;

    if (rodada.audioEnunciado) {
      const audio = new Audio(rodada.audioEnunciado);

      if (rodada.atrasoAudio) {
        setTimeout(() => audio.play(), rodada.atrasoAudio * 1000);
      } else {
        audio.play();
      }
    }

    setNumRodadaAtual(numero);
    setRodadaAtual(rodada);
    setTentativa(tentativa + 1);
    setMoldura(false);
  };

  const avancar = () => {
    click(); //efeito sonoro

    if (numRodadaAtual === qtdRodadas) {
      return config.avancar ? config.avancar() : alert("Fim!");
    } else {      
      iniciarRodada(numRodadaAtual + 1);
    }
  };

  const reiniciarCanvas = () => {
    
    setTentativa(tentativa + 1);
  };

  const repetir = () => {
    iniciarRodada(1);
    reiniciarCanvas();
  }

  const selecionarCor = cor => {
    setCorSelecionada(cor)
  }

  if (!rodadaAtual) return <CircularProgress />;

  return(
    <div style={styles.container}>
      <div style={styles.cabecalho}>
          <p>{rodadaAtual.enunciado}</p>
          <button style={styles.btnRepetir} onClick={repetir}>
              <i class="fas fa-undo-alt" style={styles.btnRepetirIcon}></i> REPETIR
          </button>
      </div>
      <div style={styles.toolbar}>
          <div 
            onClick={() => selecionarCor('#000000')} 
            style={{
              width: 20, 
              height: 20, 
              backgroundColor: '#000000', 
              margin: 5, 
              border: corSelecionada === '#000000' ? '2px solid yellow': '2px solid rgb(250, 151, 142)'
            }}
          >            
          </div>
          <div 
            onClick={() => selecionarCor('#ffffff')} 
            style={{
              width: 20, 
              height: 20, 
              backgroundColor: '#ffffff', 
              margin: 5, 
              border: corSelecionada === '#ffffff' ? '2px solid yellow': '2px solid rgb(250, 151, 142)'
            }}
          >
          </div>
          <div 
            onClick={() => selecionarCor('#ff0000')} 
            style={{
              width: 20, 
              height: 20, 
              backgroundColor: '#ff0000', 
              margin: 5, 
              border: corSelecionada === '#ff0000' ? '2px solid yellow': '2px solid rgb(250, 151, 142)'
            }}
          >
          </div>
          <div 
            onClick={() => selecionarCor('#ffd900')} 
            style={{
              width: 20, 
              height: 20, 
              backgroundColor: '#ffd900', 
              margin: 5, 
              border: corSelecionada === '#ffd900' ? '2px solid yellow': '2px solid rgb(250, 151, 142)'
            }}
          >
          </div>
          <div 
            onClick={() => selecionarCor('#0051ff')} 
            style={{
              width: 20, 
              height: 20, 
              backgroundColor: '#0051ff', 
              margin: 5, 
              border: corSelecionada === '#0051ff' ? '2px solid yellow': '2px solid rgb(250, 151, 142)'
            }}
          >
          </div>
          <div 
            onClick={() => selecionarCor('#0ba300')} 
            style={{
              width: 20, 
              height: 20, 
              backgroundColor: '#0ba300', 
              margin: 5, 
              border: corSelecionada === '#0ba300' ? '2px solid yellow': '2px solid rgb(250, 151, 142)'
            }}
          >
          </div>
          <div 
            onClick={() => selecionarCor('#ff7300')} 
            style={{
              width: 20, 
              height: 20, 
              backgroundColor: '#ff7300', 
              margin: 5, 
              border: corSelecionada === '#ff7300' ? '2px solid yellow': '2px solid rgb(250, 151, 142)'
            }}
          >
          </div>
          <div 
            onClick={() => selecionarCor('#949494')} 
            style={{
              width: 20, 
              height: 20, 
              backgroundColor: '#949494', 
              margin: 5, 
              border: corSelecionada === '#949494' ? '2px solid yellow': '2px solid rgb(250, 151, 142)'
            }}
          >
          </div>
          <div 
            onClick={() => selecionarCor('#9135f2')} 
            style={{
              width: 20, 
              height: 20, 
              backgroundColor: '#9135f2', 
              margin: 5, 
              border: corSelecionada === '#9135f2' ? '2px solid yellow': '2px solid rgb(250, 151, 142)'
            }}
          >
          </div>
          <div 
            onClick={() => selecionarCor('#00fae5')} 
            style={{
              width: 20, 
              height: 20, 
              backgroundColor: '#00fae5', 
              margin: 5, 
              border: corSelecionada === '#00fae5' ? '2px solid yellow': '2px solid rgb(250, 151, 142)'
            }}
          >
          </div>
          <div 
            onClick={() => selecionarCor('#82583c')} 
            style={{
              width: 20, 
              height: 20, 
              backgroundColor: '#82583c', 
              margin: 5, 
              border: corSelecionada === '#82583c' ? '2px solid yellow': '2px solid rgb(250, 151, 142)'
            }}
          >
          </div>
      </div>
      <div style={styleConteudo(rodadaAtual.imagem && rodadaAtual.imagemAltura ? rodadaAtual.imagemAltura : 0)}>
        <div
          style={{ 
            backgroundImage: `url(${rodadaAtual.imagem})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            display: carregando ? 'none' : '',
            position: 'absolute',
            width: rodadaAtual.imagemLargura ? rodadaAtual.imagemLargura : 300,
            height: rodadaAtual.imagemAltura ? rodadaAtual.imagemAltura : 300,
            pointerEvents: 'none'
          }}
        ></div>
        {carregando && <div style={{padding: 15, position: 'absolute'}}><CircularProgress /></div>}
        <Canvas tentativa={tentativa} corSelecionada={corSelecionada} notificarCarregamento={() => setCarregando(false)}/>
      </div>
      <div style={styles.conteudoFinal}>
        <button style={styles.btnContinuar} onClick={avancar}>
          CONCLUIR <i class="fas fa-chevron-right" style={styles.btnContinuarIcon}></i>
        </button>
      </div>    
    </div>    
  )
}

export default Desenho;

const styles = {
  container: {
      backgroundColor: 'white', 
      margin: 5, 
      borderRadius: 15, 
      padding: 5
  },
  cabecalho: {
      backgroundColor: 'RGB(0,177,181)', 
      margin: 5, 
      borderRadius: 15, 
      padding: 5,
      color: 'white',
      fontWeight: 'bolder',
      display: 'flex',
      justifyContent: 'space-between'
  },
  toolbar: {
    backgroundColor: 'RGB(250, 151, 142)', 
    margin: 5, 
    borderRadius: 15, 
    padding: 5,
    color: 'white',
    fontWeight: 'bolder',
    display: 'flex',
    justifyContent: 'start'
},
  conteudoFinal: {
      flexDirection: 'column',
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
      alignItens: 'center',
      flexWrap: 'wrap',
      borderRadius: 15,
      backgroundColor: 'RGB(193,239,226)',
      margin: 5,
      marginTop: 10,
      padding: 10
  },
  conteudoItem: {
      margin: 5,
      borderRadius: 15,
      backgroundColor: 'RGB(255,206,0)',
      padding: 5
  },
  conteudoItemCorreto: {
      margin: 5,
      borderRadius: 15,
      backgroundColor: 'RGB(107, 255, 107)',
      padding: 5
  },
  conteudoItemIncorreto: {
      margin: 5,
      borderRadius: 15,
      backgroundColor: 'RGB(255, 107, 107)',
      padding: 5
  },
  btnRepetir: {
      backgroundColor: 'RGB(71, 226, 230)',
      border: 'none',
      color: 'white',
      borderRadius: 15
  },
  btnRepetirIcon: {
      color: 'RGB(71, 226, 230)',
      marginBottom: 5
  },
  btnContinuar: {
      backgroundColor: 'RGB(0, 144, 245)',
      border: 'none',
      color: 'white',
      borderRadius: 15,
      maxHeight: 60,
      padding: 10
  },
  btnContinuarIcon: {
      color: 'RGB(0, 144, 245)',
      marginBottom: 5
  }
}

const styleConteudo = altura => {
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItens: 'center',
    flexWrap: 'wrap',
    borderRadius: 15,
    backgroundColor: 'RGB(193,239,226)',
    margin: 5,
    marginTop: 10,
    height: altura ? altura : 300
  }
}