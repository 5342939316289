import React, { useEffect, useState } from 'react';
import { click, win, wrong } from '../libs/soundEffects';
import {
  BoxTopo,
  BtnConcluir,
  ButtonRepetir,
  ItemBoxTopo1,
  ItemBoxTopo2,
  TelaBox2,
  TelaBox3,
  TelaFigCorretaBtn,
  TelaFiguraConfirm,
  Text,
  Tla3Confirm,
  Tla3Box2,
  Tla3Fig0,
  TextFig,
} from './styles/stylesOrdem';

let audio = undefined;

export default function Ordem({ config }) {
  const qtdRodadas = config.rodadas ? config.rodadas.length : 0;

  const [rodadaAtual, setRodadaAtual] = useState(undefined);
  const [numRodadaAtual, setNumRodadaAtual] = useState(0);
  const [respostaOk, setRespostaOk] = useState(undefined);
  const [selecionadas, setSelecionadas] = useState([]);
  const [corretasSelecionadas, setCorretasSelecionadas] = useState([]);
  const [textoConfirmacao, setTextoConfirmacao] = useState('');
  const [time, setTime] = useState(false);
  const [cont, setCont] = useState(undefined);

  useEffect(() => {
    if (config.audioInicio) {
      const audio = new Audio(config.audioInicio);

      audio.play();
    }

    iniciarRodada(1);
  }, []);

  const iniciarRodada = (numero) => {
    const rodada = config.rodadas[numero - 1];

    if (!rodada) return;

    if (rodada.audioEnunciado) {
      audio = new Audio(rodada.audioEnunciado);

      if (rodada.atrasoAudio) {
        setTimeout(() => audio.play(), rodada.atrasoAudio * 1000);
      } else {
        audio.play();
      }
    }

    setNumRodadaAtual(numero);
    setRodadaAtual(rodada);
    setRespostaOk(false);
    setSelecionadas([]);
    setCorretasSelecionadas([]);
    setTime(false);
    setCont(1);
  };

  const responder = (numOpc, opcao) => {
    const opcSelecionadas = [...selecionadas];

    if (!selecionadas.includes(numOpc)) opcSelecionadas.push(numOpc);

    setSelecionadas(opcSelecionadas);

    if (opcao.ordem === cont) {
      const opcCorretasSelecionadas = [...corretasSelecionadas];

      if (!corretasSelecionadas.includes(numOpc))
        opcCorretasSelecionadas.push(numOpc);

      if (
        opcCorretasSelecionadas.length <
        rodadaAtual.opcoes.filter((o) => o.correta).length
      ) {
        win();
        opcao.efeito = true;
        setCont(cont + 1);
        return setCorretasSelecionadas(opcCorretasSelecionadas);
      }

      if (opcao.audio) {
        setTimeout(
          () => win(),
          opcao.duracaoAudio ? opcao.duracaoAudio * 1000 : 2200
        );
      } else {
        win();
      }

      switch (config.midia) {
        case 'imagem':
          return setRespostaOk(true);

        case 'texto':
          return setRespostaOk(true);

        default:
          return setRespostaOk(opcao.imagem);
      }
    } else {
      wrong();
      opcao.efeito = false;
    }
  };

  const avancar = () => {
    click();

    if (numRodadaAtual === qtdRodadas) {
      return config.avancar ? config.avancar() : alert('Fim!');
    } else {
      iniciarRodada(numRodadaAtual + 1);
    }
  };

  let classeColunas = '';
  let alturaImagem = 0;

  if (!rodadaAtual) return null;

  if (config.midia === 'texto') {
    return (
      <Texto
        responder={responder}
        avancar={avancar}
        rodadaAtual={rodadaAtual}
        confirmacao={respostaOk}
        selecionadas={selecionadas}
        iniciarRodada={iniciarRodada}
      />
    );
  }

  switch (rodadaAtual.opcoes.length) {
    case 2:
      classeColunas = 'tl2-box-2-2colunas';
      alturaImagem = 360;
      break;

    case 3:
      classeColunas = 'tl2-box-2-3colunas';
      alturaImagem = 270;
      break;

    case 4:
      classeColunas = 'tl2-box-2-2colunas';
      alturaImagem = 180;
      break;

    default:
      classeColunas = 'tl2-box-2-3colunas';
      alturaImagem = 180;
      break;
  }

  let opcoesExibir = [];

  if (rodadaAtual.livre) {
    opcoesExibir = rodadaAtual.opcoes.filter((opcao, i) =>
      selecionadas.includes(i + 1)
    );
  } else {
    opcoesExibir = rodadaAtual.opcoes.filter((opcao) => opcao.correta);
  }

  console.log('cont:', cont);

  return (
    <div>
      <div>
        {rodadaAtual ? (
          <>
            <BoxTopo>
              <ItemBoxTopo1>
                <Text>{rodadaAtual.enunciado}</Text>
              </ItemBoxTopo1>
              <ItemBoxTopo2>
                <ButtonRepetir onClick={() => iniciarRodada(1)}>
                  <i className="fas fa-undo-alt"></i> REPETIR
                </ButtonRepetir>
              </ItemBoxTopo2>
            </BoxTopo>

            {respostaOk ? (
              <TelaBox2>
                <TelaFiguraConfirm>
                  <TelaFigCorretaBtn>
                    {textoConfirmacao !== '' ? (
                      <Text>{textoConfirmacao}</Text>
                    ) : (
                      <Text style={{ marginRight: 5 }}>
                        {config.textoRespostaOk
                          ? config.textoRespostaOk
                          : `CERTA RESPOSTA!`}
                      </Text>
                    )}
                    <a onClick={() => setTime(false)}>
                      <BtnConcluir onClick={avancar}>
                        CONTINUAR <i className="fas fa-chevron-right"></i>{' '}
                      </BtnConcluir>
                    </a>
                  </TelaFigCorretaBtn>
                </TelaFiguraConfirm>
              </TelaBox2>
            ) : (
              <>
                <TelaBox3 className={classeColunas}>
                  {rodadaAtual.opcoes.map((opcao, key) => (
                    <div
                      key={key}
                      className={
                        selecionadas.includes(key + 1)
                          ? opcao.efeito || rodadaAtual.qualquerOpcao
                            ? 'tl1-figura-correta'
                            : 'tl1-figura-0'
                          : 'tl1-figura-0'
                      }
                      onClick={() => responder(key + 1, opcao)}
                      style={{ background: opcao.cor ? opcao.cor : undefined }}
                    >
                      {config.midia === 'imagem' && <img src={opcao.imagem} />}

                      {opcao.texto && <p>{opcao.texto}</p>}
                    </div>
                  ))}
                </TelaBox3>

                {rodadaAtual.livre && (
                  <div className="caixa-texto-btn-confirmar">
                    <a
                      className="btn-concluir"
                      onClick={() => setRespostaOk(true)}
                    >
                      CONFIRMAR <i className="fas fa-chevron-right"></i>
                    </a>
                  </div>
                )}
              </>
            )}
          </>
        ) : (
          <h1>Carregando</h1>
        )}
      </div>
    </div>
  );
}

const Texto = ({
  rodadaAtual,
  responder,
  avancar,
  confirmacao,
  selecionadas,
  iniciarRodada,
}) => {
  const [time, setTime] = useState(false);

  return (
    <>
      <BoxTopo>
        <ItemBoxTopo1>
          <Text>{rodadaAtual.enunciado}</Text>
        </ItemBoxTopo1>
        <ItemBoxTopo2>
          <ButtonRepetir onClick={() => iniciarRodada(1)}>
            <i className="fas fa-undo-alt"></i> REPETIR
          </ButtonRepetir>
        </ItemBoxTopo2>
      </BoxTopo>

      <Tla3Box2>
        {confirmacao ? (
          <>
            {rodadaAtual.opcoes
              .filter((opcao) => opcao.correta)
              .map((opcao, key) => (
                <Tla3Fig0 key={key}>
                  <TextFig>{opcao.texto}</TextFig>
                </Tla3Fig0>
              ))}

            <Tla3Confirm>
              <div>
                <Text>CERTA RESPOSTA!</Text>
                <a onClick={() => setTime(false)}>
                  <BtnConcluir onClick={avancar}>
                    CONTINUAR <i className="fas fa-chevron-right"></i>{' '}
                  </BtnConcluir>
                </a>
              </div>
            </Tla3Confirm>
          </>
        ) : (
          rodadaAtual.opcoes.map((opcao, key) => (
            <div
              key={key}
              className={
                selecionadas.includes(key + 1)
                  ? opcao.efeito || rodadaAtual.qualquerOpcao
                    ? 'tl2-figura-correta'
                    : 'tl1-figura-1'
                  : 'tl1-figura-1'
              }
              onClick={() => responder(key + 1, opcao)}
            >
              <TextFig>{opcao.texto}</TextFig>
            </div>
          ))
        )}
      </Tla3Box2>
    </>
  );
};
