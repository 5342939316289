import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import api from "../services/api";
import ijws from "../services/ijws";
import { login } from "../services/auth";
import "../styles/out.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import "./styesLogin.css";

const SignIn = (props) => {
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [exibirSenha, setExibirSenha] = useState(false);

  useEffect(() => {
    const param = props.location.search.replace("?m=", "");

    if (param && param.includes("confirm")) {
      setSuccess(
        "Para concluir seu cadastro, acesse o link de confirmação enviado para seu email."
      );
    }
  }, [props.location]);

  const handleSignIn = (e) => {
    e.preventDefault();

    if (!email || !senha) {
      setError("Preencha e-mail e senha para continuar!");
      setSuccess("");
    } else {
      ijws
        .post("usuarios/autenticar", { email, senha })
        .then((res) => {
          if (res.token) {
            login(res.token);
            return props.history.push("/app");
          }
          
        })
        .catch((e) => {
          const { status } = e;

          if (status === 400) {
            return setError("É necessário informar usuário e senha.");
          }
          if (status === 401) {
            return setError("Credenciais inválidas. Verifique os dados e tente novamente.");
          }
          
          setError("Erro ao tentar fazer login");
        });
    }
  };

  return (
    <>
      {window.innerWidth > 800 ? (
        <div className="pagina-login">
          <div className="container-login">
            <img
              className="logo-login"
              alt="Logo da inglês juntos"
              src={require("../assets/imagens/logo.png")}
            />
            {error && (
              <h6 style={{ color: "red", marginLeft: 30, marginRight: 30 }}>
                {error}
              </h6>
            )}
            {success && <h6 style={{ color: "green" }}>{success}</h6>}
            <form className="form-login" onSubmit={handleSignIn}>
              <input
                type="text"
                placeholder="Email"
                id="email"
                onChange={(e) => setEmail(e.target.value)}
              />
              <div style={{ width: "100%" }}>
                <div className="box-password">
                  <input
                    type={exibirSenha ? "text" : "password"}
                    placeholder="Senha"
                    id="senha"
                    onChange={(e) => setSenha(e.target.value)}
                  />
                  <span
                    id="btn-olho"
                    onClick={() => setExibirSenha(!exibirSenha)}
                  >
                    <i className="material-icons">
                      {exibirSenha ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </i>
                  </span>
                </div>
              </div>

              <input type="submit" className="btn-padrao-lp" value="Entrar" />
            </form>
            <div>
              <div>
                <Link className="btn-padrao-lp ali-right" to={"/signup"}>
                  Cadastrar
                </Link>
              </div>
              <div style={{ marginTop: 20 }}>
                <Link className="btn-link ali-right" to={"/passowrd-forgot"}>
                  Esqueci a senha
                </Link>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ height: "100%" }}>
          <div id="logo" style={{ textAlign: "center", paddingTop: "30%" }}>
            <img
              style={{ width: 300 }}
              alt="Logo da inglês juntos"
              src={require("../assets/imagens/logo.png")}
            />

            {error && (
              <h6 style={{ color: "red", marginLeft: 30, marginRight: 30 }}>
                {error}
              </h6>
            )}
          </div>

          <form
            className="login"
            style={{ margin: 10 }}
            onSubmit={handleSignIn}
          >
            <input
              type="text"
              placeholder="Email"
              id="email"
              onChange={(e) => setEmail(e.target.value)}
            />
            <div style={{ width: "100%" }}>
              <div className="box-password">
                <input
                  type={exibirSenha ? "text" : "password"}
                  placeholder="Senha"
                  id="senha"
                  onChange={(e) => setSenha(e.target.value)}
                />
                <span
                  id="btn-olho"
                  onClick={() => setExibirSenha(!exibirSenha)}
                >
                  <i
                  // className="material-icons"
                  >
                    {exibirSenha ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </i>
                </span>
              </div>
            </div>

            <input type="submit" className="btn-entrar" value="Entrar" />
          </form>

          <div>
            <div style={{ textAlign: "center" }}>
              <Link
                // className="btn-padrao-lp ali-right"
                className="btn-cadastrar"
                to={"/signup"}
              >
                Cadastrar
              </Link>
            </div>
            <div style={{ marginTop: 20, textAlign: "center" }}>
              <Link className="btn-link ali-right" to={"/passowrd-forgot"}>
                Esqueci a senha
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SignIn;
