import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import MultiplaEscola from "../atividades/templates/MultiplaEscolha";
import Desenho from '../atividades/mobile/desenho';

const Index = ({ match }) => (
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/multipla-escolha`} />
      <Route path={`${match.url}/multipla-escolha`} component={MultiplaEscola} />
      <Route path={`${match.url}/desenho`} component={Desenho} />
    </Switch>
);

export default Index;
