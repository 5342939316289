import React, { useState, useEffect } from "react";
import KeyboardEventHandler from "react-keyboard-event-handler";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { win, wrong } from "../libs/soundEffects";

import {
  BoxConfirmation,
  BoxPontuacao,
  BoxTopo,
  BtnConcluir,
  Button,
  Container,
  Content,
  ItemBox1,
  ItemBox2,
  Text,
  Text2,
  Tl8Box2,
} from "./styles/stylesEscolher";

let rodadaAlternarItens = 0;
let fim = false;

export default function EscolherLado({ config }) {
  const qtdRodadas = config.rodadas ? config.rodadas.length : 0;

  const [rodadaAtual, setRodadaAtual] = useState(undefined);
  const [numRodadaAtual, setNumRodadaAtual] = useState(0);
  const [itemAtual, setItemAtual] = useState(0);
  const [pontos, setPontos] = useState(0);
  const [respostaOk, setRespostaOk] = useState(undefined);

  useEffect(() => {
    if (config.audioInicio) {
      const audio = new Audio(config.audioInicio);

      audio.play();
    }

    iniciarRodada(1);
    if (!config.itens) {
      setTimeout(
        () => alternarItens(0),
        config.tempoAbertura ? config.tempoAbertura * 1000 : 5000
      );
    }
  }, []);

  const alternarItens = (numero) => {
    const itensAlternar = config.rodadas[rodadaAlternarItens].itens;

    const novoItem = itensAlternar[numero + 1] ? numero + 1 : 0;

    if (!fim) setTimeout(() => alternarItens(novoItem), 3000);
    setItemAtual(novoItem);
  };

  const iniciarRodada = (numero) => {
    const rodada = config.rodadas[numero - 1];

    if (!rodada) return;

    if (rodada.audioEnunciado) {
      const audio = new Audio(rodada.audioEnunciado);

      audio.play();
    }

    setNumRodadaAtual(numero);
    setRodadaAtual(rodada);
    fim = false;
    setPontos(0);
    rodadaAlternarItens = numero - 1;
    setRespostaOk(false);
  };

  const avancar = () => {
    if (numRodadaAtual === qtdRodadas) {
      fim = true;
      setRespostaOk(true);
      config.avancar ? config.avancar() : alert("acabou!");
    } else {
      iniciarRodada(numRodadaAtual + 1);
    }
  };

  const handleClickNext = (lado) => {
    if (lado === "direita") {
      win();
      setPontos(pontos + 1);

      if (pontos + 1 >= rodadaAtual.pontos) {
        fim = true;
        setRespostaOk(true);
      }
    } else {
      wrong();
    }
  };

  const handleClickPrevious = (lado) => {
    if (lado === "esquerda") {
      win();
      setPontos(pontos + 1);

      if (pontos + 1 >= rodadaAtual.pontos) {
        fim = true;
        setRespostaOk(true);
      }
    } else {
      wrong();
    }
  };

  if (!rodadaAtual) return null;

  return (
    <>
      <BoxTopo>
        <ItemBox1>
          <Text>{rodadaAtual.enunciado}</Text>
        </ItemBox1>
        <ItemBox2>
          <Button onClick={() => iniciarRodada(1)}>
            <i className="fas fa-undo-alt"></i> REPETIR
          </Button>
        </ItemBox2>
      </BoxTopo>

      <BoxPontuacao>
        <Text>
          {`${
            rodadaAtual.textoContador ? rodadaAtual.textoContador : "Pontos"
          }: (${pontos}/${rodadaAtual.pontos})`}
        </Text>
      </BoxPontuacao>

      {respostaOk ? (
        <Tl8Box2>
          <BoxConfirmation>
            <Text>PARABÉNS</Text>
            <Text>VOCÊ ACERTOU!</Text>
            <BtnConcluir onClick={avancar}>
              CONTINUAR <i className="fas fa-chevron-right"></i>{" "}
            </BtnConcluir>
          </BoxConfirmation>
        </Tl8Box2>
      ) : (
        <>
          <Container>
            <div
              onClick={() =>
                handleClickPrevious(rodadaAtual.itens[itemAtual].lado)
              }
            >
              <ArrowBackIcon />
              <Text2>{rodadaAtual.esquerda}</Text2>
            </div>

            <Content>
              {rodadaAtual.itens[itemAtual].imagem && (
                <img src={rodadaAtual.itens[itemAtual].imagem} alt="imagens" />
              )}
            </Content>

            <div
              onClick={() => handleClickNext(rodadaAtual.itens[itemAtual].lado)}
            >
              <ArrowForwardIcon />
              <Text2>{rodadaAtual.direita}</Text2>
            </div>
          </Container>

          <KeyboardEventHandler
            handleKeys={["left", "right"]}
            onKeyEvent={(key) => {
              if (key === "right") {
                handleClickNext(rodadaAtual.itens[itemAtual].lado);
              }
              if (key === "left") {
                handleClickPrevious(rodadaAtual.itens[itemAtual].lado);
              }
            }}
          />
        </>
      )}
    </>
  );
}
