import React, {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useContext,
} from "react";
import { Link } from "react-router-dom";
import api from "../services/api";
import getUserData from "../services/userData";
import { logout } from "../services/auth";
import SelecaoCor from "../components/SelecaoCor";
import SelecaoPersonagem from "../components/SelecaoPersonagem";
import SelecaoNivel from "../components/SelecaoNivel";
import AppContext from "../container/context";
import ijws from "../services/ijws";
import "../pages/styesLogin.css";
import EditIcon from "@mui/icons-material/Edit";

import corFundo from "../assets/imagens/cor-00.png";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import { validarCPF } from "../helpers/validar";
import { formatDateTime } from "../helpers";
import EditarPagamento from "./EditarPagamento";

let userData = {};

const Perfil = ({ history }) => {
  const { notification, confirm } = useContext(AppContext);

  const [nome, setNome] = useState("");
  const [perfilNome, setPerfilNome] = useState("");
  const [email, setEmail] = useState("");
  const [CPF, setCPF] = useState("");
  const [telefone, setTelefone] = useState("");
  const [assinatura, setAssinatura] = useState({});
  const [formaPagamento, setFormaPagamento] = useState({});
  const [cancelamento, setCancelamento] = useState(false);
  const [avatar, setAvatar] = useState(localStorage.getItem("avatar"));
  const [avatarSelecinado, setAvatarSelecionado] = useState(
    localStorage.getItem("avatar")
  );
  const [cor, setCor] = useState(localStorage.getItem("cor"));
  const [corSelecionada, setCorSelecionada] = useState(
    localStorage.getItem("cor")
  );
  const [alterarAvatar, setAlterarAvatar] = useState(false);
  const [alterarCor, setAlterarCor] = useState(false);
  const [alterarNivel, setAlterarNivel] = useState(false);
  const [nivelId, setNivel] = useState(0);
  const [nivelSelecionadoId, setNivelSelecionado] = useState(0);
  const [usuario, setUsuario] = useState({});
  const [saindo, setSaindo] = useState(false);
  const [dadosAssinatura, setDadosAssinatura] = useState({});
  const [detalhes, setDetalhes] = useState(false);
  const [encerrando, setEncerrando] = useState(false);
  const [suspensa, setSuspensa] = useState(false);
  const [editandoPagamento, setEditandoPagamento] = useState(false);

  useEffect(() => {
    carregar();
    assinaturasCarregar();
  }, []);

  const carregar = () => {
    userData = getUserData();

    ijws
      .get("usuarios/carregar")
      .then((usuario) => {
        setUsuario(usuario);
        setTelefone(usuario.telefone);
        setCPF(usuario.CPF);
        setNome(usuario.nome);
        setPerfilNome(usuario.perfilNome);
        setEmail(usuario.email);
        setNivel(usuario.nivelId);
        setNivelSelecionado(usuario.nivelId);

        if (
          usuario.assinaturaIdentificador &&
          usuario.assinaturaIdentificador != null
        ) {
          listarFormasPagamento(usuario.id);

          const vencimento = new Date(usuario.assinaturaVencimento);

          setAssinatura({
            id: usuario.assinaturaIdentificador,
            vencimento: `${vencimento.getDate()}/${
              vencimento.getMonth() + 1
            }/${vencimento.getFullYear()}`,
            cancelada: usuario.assinaturaCancelada,
            reembolsada: usuario.assinaturaReembolso,
            modalidade: usuario.assinaturaModalidade,
          });
        }
      })
      .catch((e) => console.log(e));
  };

  const assinaturasCarregar = () => {
    ijws
      .get("assinaturas/carregar")
      .then((res) => {
        if (res.modalidade === 1) {
          res.valorExibir =
            "R$" + (res.usuarioCompraValorCentavos / 100).toFixed(2);
        }

        if (res.modalidade === 2) {
          if (res.usuarioCompraQtdParcelas) {
            res.valorExibir =
              res.usuarioCompraQtdParcelas +
              "x " +
              "R$" +
              (res.usuarioCompraValorCentavos / 100).toFixed(2);
          } else {
            res.valorExibir =
              "R$" + (res.usuarioCompraValorCentavos / 100).toFixed(2);
          }
        }

        setDadosAssinatura(res);
      })
      .catch((e) => console.log(e));
  };

  const listarFormasPagamento = (usuarioId) => {
    api
      .post("pagamentos/formas", { usuarioId })
      .then((result) => {
        result.formas
          .filter((f) => f.default)
          .forEach((f) => setFormaPagamento(f));
      })
      .catch((e) => console.log(e));
  };

  const salvar = (e) => {
    e.preventDefault();

    let isOk = true;

    if (nome.length < 3) {
      isOk = false;

      notification("warning", "Nome inválido");
    }

    if (email.length < 5 || !email.includes("@") || !email.includes(".")) {
      isOk = false;

      notification("warning", "Email inválido");
    }

    if (!validarCPF(CPF.replace(/\D/g, ""))) {
      isOk = false;
      return notification("warning", "CPF inválido");
    }

    if (!isOk) return;

    const usuarioSalvar = {
      nome,
      CPF: CPF.replace(/\D/g, ""),
      email,
      telefone,
      perfilId: usuario.perfilId,
      perfilNome,
    };

    ijws
      .post("usuarios/editar", usuarioSalvar)
      .then((codes) => {
        return notification("success", "Dados alterados.");
      })
      .catch((e) => {
        const { status } = e.response;

        if (status === 1) {
          return notification("error", "Nome inválido");
        }
        if (status === 2) {
          return notification("error", "Email inválido");
        }
        if (status === 3) {
          return notification("error", "Email já em uso");
        }
        console.log(e);
      });
  };

  const sair = () => {
    logout();
    history.push("/");
  };

  const cancelarAssinatura = (e) => {
    e.preventDefault();

    setCancelamento(false);

    api
      .post("pagamentos/cancelamentoAssinatura", {
        usuarioId: userData.usuarioId,
      })
      .then((res) => {
        if (res.codes.includes(200)) {
          notification("success", "Assinatura cancelada.");

          return carregar();
        }

        if (res.codes.includes(600)) {
          notification(
            "error",
            "Não foi possível suspender o pagamento de sua assinatura junto ao Iugu. Tente novamente mais tarde."
          );
          return;
        }

        notification(
          "error",
          "Não foi possível cancelar a assinatura. Tente novamente mais tarde."
        );
      })
      .catch((e) => {
        notification("error", "Não foi possível cancelar sua assinatura.");

        console.log(e);
      });
  };

  const salvarNivel = () => {
    ijws
      .post("perfis/salvarNivel", {
        perfilId: userData.perfilId,
        nivelId: nivelSelecionadoId,
      })
      .then((res) => {
        setNivel(nivelSelecionadoId);
        notification("success", "Nível salvo!");

        return setAlterarNivel(false);
      })
      .catch((e) => {
        notification("error", "Não foi possível alterar o nível.");

        console.log(e);
      });
  };

  if (alterarAvatar)
    return (
      <SelecaoPersonagem
        continuar={() => {
          localStorage.setItem("avatar", avatarSelecinado);
          setAvatar(avatarSelecinado);
          setAlterarAvatar(false);
        }}
        voltar={() => setAlterarAvatar(false)}
        personagemSelecionado={avatarSelecinado}
        selecionarPersonagem={(avatar) => setAvatarSelecionado(avatar)}
      />
    );

  if (alterarCor)
    return (
      <SelecaoCor
        selecionarCor={(cor) => setCorSelecionada(cor)}
        corSelecionada={corSelecionada}
        continuar={() => {
          localStorage.setItem("cor", corSelecionada);
          setCor(corSelecionada);
          setAlterarCor(false);
        }}
        voltar={() => setAlterarCor(false)}
      />
    );

  if (alterarNivel)
    return (
      <SelecaoNivel
        continuar={() => {
          if (nivelSelecionadoId === 0)
            return notification("warning", "Selecione o nível.");

          salvarNivel();
        }}
        voltar={() => {
          setAlterarNivel(false);
          setNivelSelecionado(nivelId);
        }}
        nivelSelecionado={nivelSelecionadoId}
        selecionarNivel={(nivelId) => setNivelSelecionado(nivelId)}
      />
    );

  const handleCPFChange = (event) => {
    let value = event.target.value.replace(/\D/g, "");
    if (value.length > 3) {
      value = value.slice(0, 3) + "." + value.slice(3);
    }
    if (value.length > 7) {
      value = value.slice(0, 7) + "." + value.slice(7);
    }
    if (value.length > 11) {
      value = value.slice(0, 11) + "-" + value.slice(11, 13);
    }
    setCPF(value);
  };

  const handleTelefoneChange = (e) => {
    let input = e.target.value.replace(/\D/g, ""); // Remove todos os caracteres não numéricos

    if (input === "") {
      setTelefone("");
      return;
    }

    if (input.length > 11) {
      input = input.slice(0, 11);
    }

    if (input.length > 10) {
      // Formato para telefone com 11 dígitos
      input = input.replace(/^(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
    } else if (input.length > 5) {
      // Formato para telefone com 10 dígitos
      input = input.replace(/^(\d{2})(\d{4})(\d{0,4})/, "($1) $2-$3");
    } else if (input.length > 2) {
      // Formato para DDD e início do número
      input = input.replace(/^(\d{2})(\d{0,5})/, "($1) $2");
    } else {
      // Apenas DDD
      input = input.replace(/^(\d*)/, "($1");
    }

    setTelefone(input);
  };

  const encerrarAssinatura = () => {
    confirm({
      title: "Confirma o encerramento da assinatura?",
      label: "",
      onConfirm: () => {
        ijws
          .post("assinaturas/suspender", {
            assinaturaIdentificador: dadosAssinatura.identificador,
          })
          .then((res) => {
            notification("success", "Assinatura encerrada");
            setSuspensa(true);
          })
          .catch((e) => {
            const { status } = e.response;

            if (status === 1) {
              return notification(
                "error",
                "Assinatura com modalidade inválida para suspensão"
              );
            }
            if (status === 2) {
              return notification("error", "Assinatura já suspensa");
            }
            if (status === 3) {
              return notification("error", "Assinatura cancelada");
            }
            if (status === 101) {
              return notification(
                "error",
                "Erro ao suspender a assinatura na Iugu"
              );
            }
            console.log(e);
          });
      },
    });
  };

  return (
    <>
      {window.innerWidth > 800 ? (
        <div className="pagina-config">
          <div className="container-login">
            {cancelamento ? (
              <Fragment>
                <h1>Cancelamento de assinatura</h1>
                <p>Deseja realmente cancelar sua assinatura?</p>
                <form className="form-login" onSubmit={cancelarAssinatura}>
                  <input
                    type="submit"
                    className="btn-padrao btn-left"
                    value="Cancelar"
                  />
                  <div
                    id="sair"
                    className="btn-link"
                    onClick={() => setCancelamento(false)}
                  >
                    Voltar
                  </div>
                </form>
              </Fragment>
            ) : (
              <Fragment>
                <h1>PERFIL/CONFIGURAÇÕES</h1>
                <form
                  // className="form-login"
                  onSubmit={salvar}
                >
                  <img
                    alt="Avatar"
                    src={require(`../assets/imagens/${
                      avatares.includes(avatar) ? avatar : "personagem-01.png"
                    }`)}
                    className="personagem img-edit-perfil"
                    onClick={() => setAlterarAvatar(true)}
                    title="Clique para alterar o avatar"
                  />
                  <img
                    alt="Cor"
                    src={require(`../assets/imagens/${retornarImagemCor(cor)}`)}
                    className="img-edit-perfil"
                    onClick={() => setAlterarCor(true)}
                    title="Clique para alterar a cor"
                  />
                  <img
                    alt="Nível"
                    src={
                      nivelId === 1
                        ? require("../assets/imagens/nivel-01.png")
                        : require("../assets/imagens/nivel-02.png")
                    }
                    className="img-edit-perfil"
                    onClick={() => setAlterarNivel(true)}
                    style={{ width: 174, height: 161 }}
                    title="Clique para alterar o nível"
                  />
                  <h4>Dados cadastrais</h4>

                  <Grid container spacing={2} style={{ marginBottom: 10 }}>
                    <Grid item md={12} xs={12}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="Nome do aluno"
                        variant="outlined"
                        value={perfilNome}
                        onChange={(e) => setPerfilNome(e.target.value)}
                      />
                    </Grid>

                    <Grid item md={12} xs={12}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="Nome do responsável"
                        variant="outlined"
                        value={nome}
                        onChange={(e) => setNome(e.target.value)}
                      />
                    </Grid>

                    <Grid item md={12} xs={12}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="CPF do responsável"
                        variant="outlined"
                        value={CPF}
                        onChange={(e) => handleCPFChange(e)}
                      />
                    </Grid>

                    <Grid item md={12} xs={12}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="Email do responsável"
                        variant="outlined"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Grid>

                    <Grid item md={12} xs={12}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="Telefone do responsável"
                        variant="outlined"
                        value={telefone}
                        onChange={(e) => handleTelefoneChange(e)}
                      />
                    </Grid>
                  </Grid>

                  {assinatura.id && (
                    <Fragment>
                      {assinatura.cancelada ? (
                        <Fragment>
                          <h2>ASSINATURA CANCELADA</h2>
                          {assinatura.reembolsada ? (
                            <p>Assinatura cancelada e reembolsada.</p>
                          ) : (
                            <p>
                              Conteúdo acessível até o fim do período
                              contratado.
                            </p>
                          )}
                        </Fragment>
                      ) : (
                        <Fragment>
                          {formaPagamento && (
                            <Fragment>
                              <h2>PAGAMENTO</h2>
                              <p>{formaPagamento.description}</p>
                              <Link
                                to="/app/assinatura"
                                className="btn-padrao btn-left"
                              >
                                Alterar
                              </Link>
                              {assinatura.modalidade === 1 && (
                                <button
                                  id="sair"
                                  className="btn-link"
                                  onClick={() => setCancelamento(true)}
                                >
                                  Cancelar
                                </button>
                              )}
                            </Fragment>
                          )}
                        </Fragment>
                      )}
                    </Fragment>
                  )}
                </form>

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button variant="contained" color="primary" onClick={salvar}>
                    Salvar
                  </Button>

                  <button
                    id="sair"
                    className="btn-link"
                    onClick={() => history.push("/app")}
                  >
                    Voltar para o conteúdo
                  </button>
                </div>

                <div>
                  <h4>Minha assinatura</h4>
                  {dadosAssinatura.suspensao ? (
                    <div>
                      <h4>
                        Assinatura encerrada. Você continuará tendo acesso à
                        plataforma até{" "}
                        {formatDateTime(dadosAssinatura.vencimento, "D/M/Y")}{" "}
                        (data de vencimento da última mensalidade).
                      </h4>
                    </div>
                  ) : (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      {!dadosAssinatura.identificador && (
                        <h4>Você ainda não possui assinatura</h4>
                      )}
                      {dadosAssinatura.identificador && (
                        <Card sx={{ minWidth: 275, width: "100%" }}>
                          {!editandoPagamento ? (
                            <>
                              {!encerrando ? (
                                <>
                                  {!detalhes && (
                                    <CardContent style={{ textAlign: "start" }}>
                                      <h4>Plano</h4>
                                      <p>
                                        {dadosAssinatura.usuarioCompraDescricao}
                                      </p>
                                      <h4>Valor</h4>

                                      {dadosAssinatura.modalidade === 1 && (
                                        <p>
                                          R${" "}
                                          {(
                                            dadosAssinatura.usuarioCompraValorCentavos /
                                            100
                                          ).toFixed(2)}
                                        </p>
                                      )}
                                      {dadosAssinatura.modalidade === 2 && (
                                        <>
                                          {dadosAssinatura.usuarioCompraQtdParcelas ? (
                                            <p>
                                              {
                                                dadosAssinatura.usuarioCompraQtdParcelas
                                              }{" "}
                                              x R${" "}
                                              {(
                                                dadosAssinatura.usuarioCompraValorCentavos /
                                                100
                                              ).toFixed(2)}
                                            </p>
                                          ) : (
                                            <p>
                                              R${" "}
                                              {(
                                                dadosAssinatura.usuarioCompraValorCentavos /
                                                100
                                              ).toFixed(2)}
                                            </p>
                                          )}
                                        </>
                                      )}

                                      {dadosAssinatura.modalidade === 1 && (
                                        <>
                                          <h4>Renova em</h4>
                                          <p>
                                            {formatDateTime(
                                              dadosAssinatura.vencimento,
                                              "D/M/Y"
                                            )}
                                          </p>
                                        </>
                                      )}

                                      {dadosAssinatura.modalidade === 2 && (
                                        <>
                                          <h4>Vence em</h4>
                                          <p>
                                            {formatDateTime(
                                              dadosAssinatura.vencimento,
                                              "D/M/Y"
                                            )}
                                          </p>
                                        </>
                                      )}
                                    </CardContent>
                                  )}

                                  {detalhes && (
                                    <CardContent style={{ textAlign: "start" }}>
                                      <Grid
                                        container
                                        spacing={2}
                                        style={{ marginBottom: 10 }}
                                      >
                                        <Grid item md={12} xs={12}>
                                          <TextField
                                            fullWidth
                                            id="outlined-basic"
                                            label="Modalidade"
                                            variant="outlined"
                                            value={
                                              dadosAssinatura.modalidade === 1
                                                ? "Recorrente"
                                                : "Pacote adquirido"
                                            }
                                            disabled
                                          />
                                        </Grid>

                                        <Grid item md={12} xs={12}>
                                          <TextField
                                            fullWidth
                                            id="outlined-basic"
                                            label="Assinado em"
                                            variant="outlined"
                                            value={formatDateTime(
                                              dadosAssinatura.usuarioCompraOperacao,
                                              "D/M/Y"
                                            )}
                                            disabled
                                          />
                                        </Grid>

                                        <Grid item md={12} xs={12}>
                                          <TextField
                                            fullWidth
                                            id="outlined-basic"
                                            label="Plano"
                                            variant="outlined"
                                            value={
                                              dadosAssinatura.usuarioCompraDescricao
                                            }
                                            disabled
                                          />
                                        </Grid>

                                        <Grid item md={12} xs={12}>
                                          <TextField
                                            fullWidth
                                            id="outlined-basic"
                                            label="Valor"
                                            variant="outlined"
                                            value={dadosAssinatura.valorExibir}
                                            disabled
                                          />
                                        </Grid>
                                        {dadosAssinatura.modalidade === 1 && (
                                          <>
                                            <Grid item md={11} xs={11}>
                                              <TextField
                                                fullWidth
                                                id="outlined-basic"
                                                label="Método de pagamento"
                                                variant="outlined"
                                                value={
                                                  dadosAssinatura
                                                    .metodoPagamento.descricao
                                                }
                                                disabled
                                              />
                                            </Grid>
                                            <Grid item md={1} xs={1}>
                                              <IconButton
                                                title="Alterar método de pagamento"
                                                onClick={() =>
                                                  setEditandoPagamento(true)
                                                }
                                              >
                                                <EditIcon />
                                              </IconButton>
                                            </Grid>
                                          </>
                                        )}
                                        <Grid item md={12} xs={12}>
                                          <p>
                                            Precisa de ajuda com a assinatura?
                                            Entre em contato através do email{" "}
                                            <span
                                              style={{ fontWeight: "bold" }}
                                            >
                                              contato@inglesjuntos.com.br
                                            </span>
                                          </p>
                                        </Grid>
                                        {dadosAssinatura.modalidade === 1 && (
                                          <Grid item md={12} xs={12}>
                                            <Button
                                              variant="text"
                                              color="error"
                                              onClick={() =>
                                                setEncerrando(true)
                                              }
                                            >
                                              encerrar assinatura
                                            </Button>
                                          </Grid>
                                        )}
                                      </Grid>
                                    </CardContent>
                                  )}
                                </>
                              ) : (
                                <CardContent style={{ textAlign: "start" }}>
                                  {!suspensa ? (
                                    <>
                                      <h4>
                                        Deseja realmente encerrar sua
                                        assinatura?
                                      </h4>
                                      <p>
                                        Após o vencimento do ciclo atual, não
                                        será possível acessar a plataforma sem
                                        que uma nova assinatura seja criada.
                                      </p>
                                      <p>
                                        Precisa de ajuda com a assinatura? Entre
                                        em contato através do email{" "}
                                        <span style={{ fontWeight: "bold" }}>
                                          contato@inglesjuntos.com.br
                                        </span>
                                      </p>
                                    </>
                                  ) : (
                                    <>
                                      <h4>
                                        Assinatura encerrada. Você continuará
                                        tendo acesso à plataforma até{" "}
                                        {formatDateTime(
                                          dadosAssinatura.vencimento,
                                          "D/M/Y"
                                        )}
                                        .
                                      </h4>
                                    </>
                                  )}
                                </CardContent>
                              )}
                            </>
                          ) : (
                            <>
                              <CardContent style={{ textAlign: "start" }}>
                                <EditarPagamento
                                  atualizarPagamento={() => {
                                    setEditandoPagamento(false);
                                    carregar();
                                  }}
                                />
                              </CardContent>
                            </>
                          )}

                          <CardActions>
                            {!editandoPagamento ? (
                              <>
                                {!suspensa && (
                                  <>
                                    {!encerrando ? (
                                      <>
                                        {!detalhes && (
                                          <Button
                                            size="small"
                                            onClick={() => setDetalhes(true)}
                                          >
                                            Detalhes
                                          </Button>
                                        )}
                                        {detalhes && (
                                          <Button
                                            variant="contained"
                                            style={{
                                              backgroundColor: "gray",
                                              color: "white",
                                            }}
                                            size="small"
                                            onClick={() => setDetalhes(false)}
                                          >
                                            Voltar
                                          </Button>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        <Button
                                          variant="contained"
                                          color="primary"
                                          size="small"
                                          onClick={() => encerrarAssinatura()}
                                        >
                                          confirmar
                                        </Button>
                                        <Button
                                          variant="contained"
                                          style={{
                                            backgroundColor: "gray",
                                            color: "white",
                                          }}
                                          size="small"
                                          onClick={() => setEncerrando(false)}
                                        >
                                          Voltar
                                        </Button>
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                <Button
                                  variant="contained"
                                  style={{
                                    backgroundColor: "gray",
                                    color: "white",
                                  }}
                                  size="small"
                                  onClick={() => setEditandoPagamento(false)}
                                >
                                  Voltar
                                </Button>
                              </>
                            )}
                          </CardActions>
                        </Card>
                      )}
                    </div>
                  )}
                </div>

                <div>
                  <br />
                  <br />
                  <h2>FAQ/TUTORIAL</h2>
                  <p>
                    Tem dúvidas sobre a plataforma ou deseja refazer o tutorial?
                    Acesse nossa área de ajuda.
                  </p>
                  <button
                    className="btn-padrao"
                    onClick={() => history.push("/app/ajuda")}
                  >
                    Obter ajuda
                  </button>
                </div>

                <div className="footer-nav">
                  <button
                    className="btn-link ali-left"
                    onClick={() => setSaindo(true)}
                  >
                    Sair (encerrar sessão)
                  </button>
                </div>
              </Fragment>
            )}
          </div>
        </div>
      ) : (
        <div style={{ background: "white", padding: 10 }}>
          <div
            style={{
              marginBottom: 10,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div>
              <h1 style={{ fontSize: "1.2rem" }}>PERFIL/CONFIGURAÇÕES</h1>
              <button
                // style={{ marginTop: 10 }}
                id="sair"
                className="btn-link"
                onClick={() => history.push("/app")}
              >
                &lt; Voltar para o conteúdo
              </button>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginBottom: 10,
            }}
          >
            <div
              style={{
                width: "110px",
                backgroundImage: `url(${corFundo})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                textAlign: "center",
              }}
            >
              <img
                style={{ width: 90 }}
                alt="Avatar"
                src={require(`../assets/imagens/${
                  avatares.includes(avatar) ? avatar : "personagem-01.png"
                }`)}
                onClick={() => setAlterarAvatar(true)}
                title="Clique para alterar o avatar"
              />
            </div>
            <div
              onClick={() => setAlterarCor(true)}
              style={{
                width: "110px",
                backgroundImage: `url(${require(`../assets/imagens/${retornarImagemCor(
                  cor
                )}`)})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                textAlign: "center",
              }}
            ></div>
          </div>
          <div style={{ textAlign: "center" }}>
            <img
              style={{
                marginBottom: "10px",
                width: 144,
                height: 130,
              }}
              alt="Nível"
              src={
                nivelId === 1
                  ? require("../assets/imagens/nivel-01.png")
                  : require("../assets/imagens/nivel-02.png")
              }
              onClick={() => setAlterarNivel(true)}
              title="Clique para alterar o nível"
            />
          </div>
          <form
            // className="form-login"
            onSubmit={salvar}
          >
            {/* <input
              style={{ textAlign: "start" }}
              type="text"
              id="nome"
              placeholder="Nome da criança"
              value={perfilNome}
              onChange={(e) => setPerfilNome(e.target.value)}
            />
            <input
              style={{ textAlign: "start" }}
              type="text"
              id="nome"
              placeholder="Nome do responsável"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
            />
            <input
              style={{ textAlign: "start" }}
              type="text"
              id="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            /> */}
            <Grid container spacing={2} style={{ marginBottom: 10 }}>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Nome do aluno"
                  variant="outlined"
                  value={perfilNome}
                  onChange={(e) => setPerfilNome(e.target.value)}
                />
              </Grid>

              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Nome do responsável"
                  variant="outlined"
                  value={nome}
                  onChange={(e) => setNome(e.target.value)}
                />
              </Grid>

              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="CPF do responsável"
                  variant="outlined"
                  value={CPF}
                  onChange={(e) => handleCPFChange(e)}
                />
              </Grid>

              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Email do responsável"
                  variant="outlined"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>

              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Telefone do responsável"
                  variant="outlined"
                  value={telefone}
                  onChange={(e) => handleTelefoneChange(e)}
                />
              </Grid>
            </Grid>
            <input type="submit" className="btn-entrar" value="Salvar" />

            {assinatura.id && (
              <Fragment>
                {assinatura.cancelada ? (
                  <Fragment>
                    <h2>ASSINATURA CANCELADA</h2>
                    {assinatura.reembolsada ? (
                      <p>Assinatura cancelada e reembolsada.</p>
                    ) : (
                      <p>Conteúdo acessível até o fim do período contratado.</p>
                    )}
                  </Fragment>
                ) : (
                  <Fragment>
                    {formaPagamento && (
                      <Fragment>
                        <h2>PAGAMENTO</h2>
                        <p>{formaPagamento.description}</p>
                        <Link
                          to="/app/assinatura"
                          className="btn-padrao btn-left"
                        >
                          Alterar
                        </Link>
                        {assinatura.modalidade === 1 && (
                          <button
                            id="sair"
                            className="btn-link"
                            onClick={() => setCancelamento(true)}
                          >
                            Cancelar
                          </button>
                        )}
                      </Fragment>
                    )}
                  </Fragment>
                )}
              </Fragment>
            )}

            <div style={{ textAlign: "center" }}>
              <h4>Minha assinatura</h4>
              {dadosAssinatura.suspensao ? (
                <div>
                  <h4>
                    Assinatura encerrada. Você continuará tendo acesso à
                    plataforma até{" "}
                    {formatDateTime(dadosAssinatura.suspensao, "D/M/Y")}.
                  </h4>
                </div>
              ) : (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {!dadosAssinatura.identificador && (
                    <h4>Você ainda não possui assinatura</h4>
                  )}
                  {dadosAssinatura.identificador && (
                    <Card sx={{ minWidth: 275, width: "100%" }}>
                      {!editandoPagamento ? (
                        <>
                          {!encerrando ? (
                            <>
                              {!detalhes && (
                                <CardContent style={{ textAlign: "start" }}>
                                  <h4>Plano</h4>
                                  <p>
                                    {dadosAssinatura.usuarioCompraDescricao}
                                  </p>
                                  <h4>Valor</h4>

                                  {dadosAssinatura.modalidade === 1 && (
                                    <p>
                                      R${" "}
                                      {(
                                        dadosAssinatura.usuarioCompraValorCentavos /
                                        100
                                      ).toFixed(2)}
                                    </p>
                                  )}
                                  {dadosAssinatura.modalidade === 2 && (
                                    <>
                                      {dadosAssinatura.usuarioCompraQtdParcelas ? (
                                        <p>
                                          {
                                            dadosAssinatura.usuarioCompraQtdParcelas
                                          }{" "}
                                          x R${" "}
                                          {(
                                            dadosAssinatura.usuarioCompraValorCentavos /
                                            100
                                          ).toFixed(2)}
                                        </p>
                                      ) : (
                                        <p>
                                          R${" "}
                                          {(
                                            dadosAssinatura.usuarioCompraValorCentavos /
                                            100
                                          ).toFixed(2)}
                                        </p>
                                      )}
                                    </>
                                  )}

                                  {dadosAssinatura.modalidade === 1 && (
                                    <>
                                      <h4>Renova em</h4>
                                      <p>
                                        {formatDateTime(
                                          dadosAssinatura.vencimento,
                                          "D/M/Y"
                                        )}
                                      </p>
                                    </>
                                  )}

                                  {dadosAssinatura.modalidade === 2 && (
                                    <>
                                      <h4>Vence em</h4>
                                      <p>
                                        {formatDateTime(
                                          dadosAssinatura.vencimento,
                                          "D/M/Y"
                                        )}
                                      </p>
                                    </>
                                  )}
                                </CardContent>
                              )}

                              {detalhes && (
                                <CardContent style={{ textAlign: "start" }}>
                                  <Grid
                                    container
                                    spacing={2}
                                    style={{ marginBottom: 10 }}
                                  >
                                    <Grid item md={12} xs={12}>
                                      <TextField
                                        fullWidth
                                        id="outlined-basic"
                                        label="Modalidade"
                                        variant="outlined"
                                        value={
                                          dadosAssinatura.modalidade === 1
                                            ? "Recorrente"
                                            : "Pacote adquirido"
                                        }
                                        disabled
                                      />
                                    </Grid>

                                    <Grid item md={12} xs={12}>
                                      <TextField
                                        fullWidth
                                        id="outlined-basic"
                                        label="Assinado em"
                                        variant="outlined"
                                        value={formatDateTime(
                                          dadosAssinatura.usuarioCompraOperacao,
                                          "D/M/Y"
                                        )}
                                        disabled
                                      />
                                    </Grid>

                                    <Grid item md={12} xs={12}>
                                      <TextField
                                        fullWidth
                                        id="outlined-basic"
                                        label="Plano"
                                        variant="outlined"
                                        value={
                                          dadosAssinatura.usuarioCompraDescricao
                                        }
                                        disabled
                                      />
                                    </Grid>

                                    <Grid item md={12} xs={12}>
                                      <TextField
                                        fullWidth
                                        id="outlined-basic"
                                        label="Valor"
                                        variant="outlined"
                                        value={dadosAssinatura.valorExibir}
                                        disabled
                                      />
                                    </Grid>
                                    {dadosAssinatura.modalidade === 1 && (
                                      <>
                                        <Grid item md={11} xs={11}>
                                          <TextField
                                            fullWidth
                                            id="outlined-basic"
                                            label="Método de pagamento"
                                            variant="outlined"
                                            value={
                                              dadosAssinatura.metodoPagamento
                                                .descricao
                                            }
                                            disabled
                                          />
                                        </Grid>
                                        <Grid item md={1} xs={1}>
                                          <IconButton
                                            title="Editar pagamento"
                                            onClick={() =>
                                              setEditandoPagamento(true)
                                            }
                                          >
                                            <EditIcon />
                                          </IconButton>
                                        </Grid>
                                      </>
                                    )}
                                    <Grid item md={12} xs={12}>
                                      <p>
                                        Precisa de ajuda com a assinatura? Entre
                                        em contato através do email{" "}
                                        <span style={{ fontWeight: "bold" }}>
                                          contato@inglesjuntos.com.br
                                        </span>
                                      </p>
                                    </Grid>
                                    {dadosAssinatura.modalidade === 1 && (
                                      <Grid item md={12} xs={12}>
                                        <Button
                                          variant="text"
                                          color="error"
                                          onClick={() => setEncerrando(true)}
                                        >
                                          encerrar assinatura
                                        </Button>
                                      </Grid>
                                    )}
                                  </Grid>
                                </CardContent>
                              )}
                            </>
                          ) : (
                            <CardContent style={{ textAlign: "start" }}>
                              {!suspensa ? (
                                <>
                                  <h4>
                                    Deseja realmente encerrar sua assinatura?
                                  </h4>
                                  <p>
                                    Após o vencimento do ciclo atual, não será
                                    possível acessar a plataforma sem que uma
                                    nova assinatura seja criada.
                                  </p>
                                  <p>
                                    Precisa de ajuda com a assinatura? Entre em
                                    contato através do email{" "}
                                    <span style={{ fontWeight: "bold" }}>
                                      contato@inglesjuntos.com.br
                                    </span>
                                  </p>
                                </>
                              ) : (
                                <>
                                  <h4>
                                    Assinatura encerrada. Você continuará tendo
                                    acesso à plataforma até{" "}
                                    {formatDateTime(
                                      dadosAssinatura.vencimento,
                                      "D/M/Y"
                                    )}
                                    .
                                  </h4>
                                </>
                              )}
                            </CardContent>
                          )}
                        </>
                      ) : (
                        <>
                          <CardContent style={{ textAlign: "start" }}>
                            <EditarPagamento
                              atualizarPagamento={() => {
                                setEditandoPagamento(false);
                                setDetalhes(false);
                                carregar();
                              }}
                            />
                          </CardContent>
                        </>
                      )}

                      <CardActions>
                        {!editandoPagamento ? (
                          <>
                            {!suspensa && (
                              <>
                                {!encerrando ? (
                                  <>
                                    {!detalhes && (
                                      <Button
                                        size="small"
                                        onClick={() => setDetalhes(true)}
                                      >
                                        Detalhes
                                      </Button>
                                    )}
                                    {detalhes && (
                                      <Button
                                        variant="contained"
                                        style={{
                                          backgroundColor: "gray",
                                          color: "white",
                                        }}
                                        size="small"
                                        onClick={() => setDetalhes(false)}
                                      >
                                        Voltar
                                      </Button>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      size="small"
                                      onClick={() => encerrarAssinatura()}
                                    >
                                      confirmar
                                    </Button>
                                    <Button
                                      variant="contained"
                                      style={{
                                        backgroundColor: "gray",
                                        color: "white",
                                      }}
                                      size="small"
                                      onClick={() => setEncerrando(false)}
                                    >
                                      Voltar
                                    </Button>
                                  </>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <Button
                              variant="contained"
                              style={{
                                backgroundColor: "gray",
                                color: "white",
                              }}
                              size="small"
                              onClick={() => setEditandoPagamento(false)}
                            >
                              Voltar
                            </Button>
                          </>
                        )}
                      </CardActions>
                    </Card>
                  )}
                </div>
              )}
            </div>

            <div style={{ textAlign: "center" }}>
              <br />
              <br />
              <h2>FAQ/TUTORIAL</h2>
              <p>
                Tem dúvidas sobre a plataforma ou deseja refazer o tutorial?
                Acesse nossa área de ajuda.
              </p>
              <button
                className="btn-padrao"
                onClick={() => history.push("/app/ajuda")}
              >
                Obter ajuda
              </button>
            </div>
          </form>
          <button
            style={{ marginTop: 10 }}
            id="sair"
            className="btn-link"
            onClick={() => setSaindo(true)}
          >
            Sair
          </button>
        </div>
      )}

      <Dialog
        open={saindo}
        onClose={() => setSaindo(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Após sair, para voltar a utilizar a plataforma e ter acesso ao
            conteúdo será necessário efetuar um novo login. Deseja continuar?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              logout();
              history.push("/");
            }}
          >
            Confirmar
          </Button>
          <Button onClick={() => setSaindo(false)} autoFocus>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Perfil;

const retornarImagemCor = (cor) => {
  switch (cor) {
    case "#7B5CA4":
      return "cor-01.png";
    case "#F3910D":
      return "cor-02.png";
    case "#0894CE":
      return "cor-03.png";
    case "#6EC5C7":
      return "cor-04.png";
    case "#2B9533":
      return "cor-05.png";
    case "#E70773":
      return "cor-06.png";
    case "#F49B66":
      return "cor-07.png";
    case "#CB3939":
      return "cor-08.png";
    default:
      return "cor-01.png";
  }
};

const avatares = [
  "personagem-01.png",
  "personagem-02.png",
  "personagem-03.png",
  "personagem-04.png",
  "personagem-05.png",
  "personagem-06.png",
  "personagem-07.png",
  "personagem-08.png",
  "personagem-09.png",
  "personagem-10.png",
  "personagem-11.png",
  "personagem-12.png",
];
