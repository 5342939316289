import React, {Fragment} from 'react';

const Termos = () => (
    <Fragment>
        <h6>O que é o programa Inglês Juntos?</h6>
        <p className="termos-text">
        Inglês Juntos é um programa de inglês para crianças que conta com videoaulas, músicas, jogos e atividades interativas para a prática diária do idioma. 
        </p>
        <h6>Qual é a metodologia utilizada?</h6>
        <p className="termos-text">
        A metodologia do programa Inglês Juntos utiliza técnicas como o English Mix (mixagem de códigos em Inglês e Português) e aposta no ensino diário para inserir o idioma de maneira natural na rotina das crianças.
        </p>     
        <h6>O programa é voltado para crianças a partir de que idade?</h6>
        <p className="termos-text">
        O método Inglês Juntos foi desenvolvido pensando no ensino de idiomas para crianças entre 3 e 12 anos de idade. O conteúdo e a complexidade do vocabulário evoluem de acordo com a fase de desenvolvimento da criança.
        </p>       
        <h6>Quanto tempo leva para que a criança comece a falar inglês?</h6>
        <p className="termos-text">
        Os resultados começam a aparecer desde o primeiro dia, mas quanto mais exposto ao contato com o inglês, mais rapidamente a criança passará a se comunicar no idioma trabalhado. O primeiro passo é a utilização de palavras soltas, que gradualmente se transformam em frases curtas e, em seguida, na elaboração de sentenças mais complexas
        </p>
        <h6>Os pais precisam falar inglês para utilizar a plataforma?</h6>
        <p className="termos-text">
        Não é necessário que os pais ou a família sejam fluentes em inglês para utilizar a plataforma! O aprendizado em família pode, inclusive, beneficiar adultos que desejam desenvolver a sua prática no idioma.
        </p>
        <h6>Quantas vezes na semana preciso acessar a plataforma? Qual a duração das aulas?</h6>
        <p className="termos-text">
        As aulas são diárias (5x na semana), com cerca de 30 minutos de prática por dia. Esta é a nossa recomendação para que a metodologia seja aplicada da melhor forma, mas é claro que você pode adaptar à sua rotina!
        </p>        
        <h6>As videoaulas são transmitidas ao vivo ou são gravadas?</h6>
        <p className="termos-text">
        As aulas são gravadas e ficam disponíveis para você assistir na hora que quiser. Todas as aulas têm uma combinação diferente de atividades, entre vídeos, jogos, músicas, danças e outras propostas didáticas.
        </p>
        <h6>Qual é o tempo total de duração do curso?</h6>
        <p className="termos-text">
        Não existe um prazo determinado para o curso, como aconteceria em um curso tradicional. A metodologia do Inglês Juntos é cíclica, portanto não existem níveis, sequência ou duração definida. Apostamos no aprendizado da criança a partir da prática diária do idioma!
        </p>
        <h6>É possível acessar Inglês Juntos pelo tablet ou celular?</h6>
        <p className="termos-text">
        Por enquanto, não possuímos um aplicativo disponível para dispositivos móveis. Caso você esteja acessando pelo navegador do tablet ou celular, é possível visualizar apenas as videoaulas, uma vez que as atividades e games não são compatíveis com esses dispositivos. Para uma melhor experiência, recomendamos o acesso pelo computador!
        </p>
        <h6>Como faço para assinar Inglês Juntos?</h6>
        <p className="termos-text">
        A opção de assinar Inglês Juntos aparece quando você finaliza a sua aula experimental ou acessa a área exclusiva dos pais, no menu superior.
        </p>
        <h6>Como é feito o pagamento?</h6>
        <p className="termos-text">
        Uma vez que você assina o nosso plano, o pagamento é realizado de forma automática e recorrente no seu cartão de crédito, por meio da nossa plataforma.
        </p>
        <h6>A ferramenta de pagamento é segura?</h6>
        <p className="termos-text">
        Atualmente, utilizamos a plataforma de pagamento da iugu. A iugu é uma empresa com mais de 7 anos de mercado, que atende a grandes empresas, como Conta Azul, Contabilizei, Ingresse, Olist, entre outras. Temos confiança de que é uma solução segura e de qualidade para oferecer aos nossos clientes! 
        </p>
        <h6>A assinatura do Inglês Juntos tem carência ou fidelidade?</h6>
        <p className="termos-text">
        Diferente dos cursos de idiomas tradicionais, Inglês Juntos não tem fidelidade - você pode cancelar a qualquer momento, se desejar!
        </p>
        <h6>Como faço o cancelamento da minha assinatura?</h6>
        <p className="termos-text">
        <b>Cancelamento e reembolso</b>: 
        O cancelamento será realizado
        pelo e-mail contato@inglesjuntos.com.br. Se você comprou
        uma assinatura do Inglês Juntos através do nosso site e
        decidiu não continuar, escreva-nos nos primeiros 3 dias. Nós
        cancelaremos sua assinatura e reembolsaremos a compra.
        Se você não quer o serviço de assinatura nem as cobranças
        correspondentes, você deve encerrar o acesso de assinatura
        fornecido no período de teste antes da expiração deste, pelo
        nosso e-mail de contato.
        Feito o cancelamento, a cessação do acesso à inscrição
        entrará em vigor no dia seguinte ao último dia do seu período
        de inscrição.
        No caso do cancelamento ser solicitado após o período de
        experiência de 03 (três) dias, a assinatura será cancelada, mas
        não haverá reembolso do valor pago.<br/>
        <b>Renovação automática</b>: após a renovação automática da sua
        assinatura, você tem 03 (três) dias para desistência e
        cancelamento.
        </p>
        <h6>O pagamento de uma mensalidade dá acesso a quantas aulas?</h6>
        <p className="termos-text">
        Cada mensalidade dá acesso a 2 novas unidades, com 10 aulas cada uma. Realizando a prática diária (de segunda a sexta), isso significa que você terá acesso a 4 semanas de conteúdo exclusivo a cada mensalidade.
        </p>
        <p className="termos-text">
        Não encontrou o que procurava por aqui? Se tiver outras dúvidas, fale com a gente no e-mail contato@inglesjuntos.com.br ou então nas nossas redes sociais!
        </p>
    </Fragment>
)

export default Termos;