import React from 'react';  
import ReactPlayer from 'react-player/vimeo'; 

export const Vimeo = ({ url }) => {
    return(
        <div
            style={{width: '100%', height:'100%'}}
        >
            <ReactPlayer
                width="100%"
                height="100%"
                url={url}
                config={{
                    vimeo: {
                        playerOptions:{
                            controls: true,
                            title:true
                        }
                    }
                }}
            />
        </div>
    ); 
}; 



