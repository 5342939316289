import React, { useState, useEffect, useContext, useRef } from "react";
import getUserData from "../services/userData";
import ijws from "../services/ijws";
import serverHost from "../services/serverHost";
import soundEffects from "../libs/soundEffects";
import SelecaoNivel from "../components/SelecaoNivel";
import SelecaoCor from "../components/SelecaoCor";
import SelecaoPersonagem from "../components/SelecaoPersonagem";
import TermosUso from "../components/TermosUso";
import Tutorial from "../components/Tutorial";
import Pagamento from "../components/pagamento/index";
import { formatDateTime } from "../helpers";
import AppContext from "../container/context";
import "../styles/in.css";

import { Box, Grid } from "@mui/material";

import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const Unidades = ({ configHeader, refresh, history }) => {
  const { notification } = useContext(AppContext);
  const [unidades, setUnidades] = useState([]);
  const [finalizacaoCadastro, setFinalizarCadastro] = useState(undefined);
  const [exibirTutorial, setExibirTutorial] = useState(false);
  const [infoDisponibilidade, setInfoDisponibilidade] = useState(false);
  const [ocultandoNaoIniciados, setOcultandoNaoIniciados] = useState(false);
  const [ocultandoFinalizados, setOcultandoFinalizados] = useState(true);

  const [index, setIndex] = useState(0);
  const [dragStartX, setDragStartX] = useState(null);
  const [dragging, setDragging] = useState(false);
  const containerRef = useRef(null);
  const cardWidth = 300;

  const [indexAndamento, setIndexAndamento] = useState(0);
  const [andamentoDragStartX, setAndamentoDragStartX] = useState(null);
  const [AndamentoDragging, setAndamentoDragging] = useState(false);
  const andamentoContainerRef = useRef(null);

  const [indexFinalizado, setIndexFinalizado] = useState(0);
  const [finalizadoDragStartX, setFinalizadoDragStartX] = useState(null);
  const [finalizadoDragging, setFinalizadoDragging] = useState(false);
  const finalizadoContainerRef = useRef(null);

  const userData = getUserData();

  useEffect(() => {
    carregar();
    configHeader({});
  }, []);

  const carregar = () => {
    ijws
      .get("agenda/unidades", { perfilId: userData.perfilId })
      .then((retorno) => {
        if (retorno.pendenciaCadastral === 1) {
          const finalizacaoCadastro = {
            assinante: retorno.assinaturaIdentificador ? true : false,
            aceiteTermos: retorno.aceiteTermos ? true : false,
            usuarioEmail: retorno.usuarioEmail,
            promocao: retorno.promocaoChave
              ? {
                  chave: retorno.promocaoChave,
                  descricao: retorno.promocaoDescricao,
                }
              : undefined,
          };

          setFinalizarCadastro(finalizacaoCadastro);
          return;
        }

        if (retorno.pendenciaCadastral === 2) {
          setFinalizarCadastro(undefined);
          setExibirTutorial(true);
          setUnidades(retorno.unidades);
          return;
        }

        if (retorno.unidades.some((u) => u.ultimoAcesso && !u.finalizacao)) {
          setOcultandoNaoIniciados(true);
        }

        setUnidades(retorno.unidades);
        refresh();
      })
      .catch((e) => {
        const { status } = e;

        console.log("status: " + status);
      });
  };

  const abrirUnidade = (unidade, nova, ordem) => {
    soundEffects.click();
    if (unidade.usuarioAdministrador)
      return history.push(`/app/aulas/${unidade.id}`);

    if (nova) {
      if (!unidade.liberada && !unidade.usuarioAcessoCompleto)
        return notification(
          "warning",
          `Unidade disponível a partir de ${formatDateTime(
            unidade.liberacao,
            "D/M/Y"
          )}`
        );
      if (unidadesEmAndamento.length > 0)
        return notification(
          "warning",
          `Antes de iniciar uma nova, finalize a unidade em andamento!`
        );

      if (ordem > 0)
        return notification("warning", `Finalize as unidades anteriores!`);
    }

    history.push(`/app/aulas/${unidade.id}`);
  };

  const finalizarCadastro = (cor, avatar, nivelId) => {
    localStorage.setItem("cor", cor);
    localStorage.setItem("avatar", avatar);

    ijws
      .post("perfis/salvarNivel", {
        perfilId: userData.perfilId,
        nivelId,
      })
      .then((status) => {
        notification("success", `Cadastro finalizado!`);
        setFinalizarCadastro(undefined);
        return carregar();
      })
      .catch((e) => {
        notification("error", `Não foi possível finalizar o cadastro.`);
        console.log(e);
      });
  };

  const registrarTutorial = () => {
    ijws.post("perfis/registrarTutorial", { perfilId: userData.perfilId });

    setExibirTutorial(false);
  };

  if (finalizacaoCadastro)
    return (
      <CadastroInterno
        finalizar={finalizarCadastro}
        dados={finalizacaoCadastro}
        userData={userData}
      />
    );

  if (exibirTutorial) return <Tutorial registrarTutorial={registrarTutorial} />;

  if (infoDisponibilidade)
    return <InfoDisponibilidade voltar={() => setInfoDisponibilidade(false)} />;

  let badge = "";
  let alt = "";
  let color = "";

  const unidadesEmAndamento = unidades.filter(
    (u) => u.ultimoAcesso && !u.finalizacao
  );
  const unidadesNaoIniciadas = unidades.filter((u) => !u.ultimoAcesso);
  const unidadesFinalizadas = unidades.filter((u) => u.finalizacao);

  const handleNext = () => {
    if (index > unidadesNaoIniciadas.length) return;
    setIndex((prevIndex) => prevIndex + 1);
  };

  const handleBack = () => {
    if (index + 1 > unidadesNaoIniciadas.length) {
      setIndex((prevIndex) => prevIndex - 1);
      return;
    }
    if (index === 0) {
      return;
    }
    setIndex(
      (prevIndex) =>
        (prevIndex - 1 + unidadesNaoIniciadas.length) %
        unidadesNaoIniciadas.length
    );
  };

  const handleMouseDown = (e) => {
    setDragStartX(e.clientX);
    setDragging(true);
  };

  const handleMouseMove = (e) => {
    if (dragging) {
      const dragDistance = e.clientX - dragStartX;
      containerRef.current.style.transform = `translateX(${
        -index * cardWidth + dragDistance
      }px)`;
    }
  };

  const handleMouseUp = (e) => {
    if (dragging) {
      const dragDistance = e.clientX - dragStartX;
      if (dragDistance > 50 && index > 0) {
        handleBack();
      } else if (
        dragDistance < -50 &&
        index < unidadesNaoIniciadas.length - 1
      ) {
        handleNext();
      }
      setDragging(false);
    }
  };

  const handleTouchStart = (e) => {
    setDragStartX(e.touches[0].clientX);
    setDragging(true);
  };

  const handleTouchMove = (e) => {
    if (dragging) {
      const dragDistance = e.touches[0].clientX - dragStartX;
      containerRef.current.style.transform = `translateX(${
        -index * cardWidth + dragDistance
      }px)`;
    }
  };

  const handleTouchEnd = (e) => {
    if (dragging) {
      const dragDistance = e.changedTouches[0].clientX - dragStartX;
      finalizeDrag(dragDistance);
    }
  };

  const finalizeDrag = (dragDistance) => {
    if (dragDistance > 50 && index > 0) {
      handleBack();
    } else if (dragDistance < -50 && index < unidadesNaoIniciadas.length - 1) {
      handleNext();
    } else {
      containerRef.current.style.transform = `translateX(${
        -index * cardWidth
      }px)`;
    }
    setDragging(false);
  };

  //Em andamento

  const handleNextAndamento = () => {
    if (index > unidadesEmAndamento.length) return;
    setIndexAndamento(
      (prevIndex) => (prevIndex + 1) % unidadesEmAndamento.length
    );
  };

  const handleBackAndamento = () => {
    if (indexAndamento + 1 > unidadesEmAndamento.length) {
      setIndexAndamento((prevIndex) => prevIndex - 1);
      return;
    }
    if (index === 0) {
      return;
    }
    setIndex(
      (prevIndex) =>
        (prevIndex - 1 + unidadesEmAndamento.length) %
        unidadesEmAndamento.length
    );
  };

  const handleMouseDownAndamento = (e) => {
    setAndamentoDragStartX(e.clientX);
    setAndamentoDragging(true);
  };

  const handleMouseMoveAndamento = (e) => {
    if (AndamentoDragging) {
      const dragDistance = e.clientX - andamentoDragStartX;
      andamentoContainerRef.current.style.transform = `translateX(${
        -indexAndamento * 300 + dragDistance
      }px)`;
    }
  };

  const handleMouseUpAndamento = (e) => {
    if (AndamentoDragging) {
      const dragDistance = e.clientX - andamentoDragStartX;
      if (dragDistance > 50 && indexAndamento > 0) {
        handleBack();
      } else if (
        dragDistance < -50 &&
        indexAndamento < unidadesEmAndamento.length - 1
      ) {
        handleNext();
      }
      setDragging(false);
    }
  };

  const handleTouchStartAndamento = (e) => {
    setAndamentoDragStartX(e.touches[0].clientX);
    setAndamentoDragging(true);
  };

  const handleTouchMoveAndamento = (e) => {
    if (AndamentoDragging) {
      const dragDistance = e.touches[0].clientX - dragStartX;
      andamentoContainerRef.current.style.transform = `translateX(${
        -indexAndamento * 300 + dragDistance
      }px)`;
    }
  };

  const handleTouchEndAndamento = (e) => {
    if (AndamentoDragging) {
      const dragDistance = e.changedTouches[0].clientX - andamentoDragStartX;
      finalizeDragAndamento(dragDistance);
    }
  };

  const finalizeDragAndamento = (dragDistance) => {
    if (dragDistance > 50 && index > 0) {
      handleBackAndamento();
    } else if (dragDistance < -50 && index < unidadesEmAndamento.length - 1) {
      handleNextAndamento();
    } else {
      andamentoContainerRef.current.style.transform = `translateX(${
        -indexAndamento * 300
      }px)`;
    }
    setAndamentoDragging(false);
  };

  //Finalizados

  const handleNextFinalizado = () => {
    if (indexFinalizado > unidadesFinalizadas.length) return;
    setIndexFinalizado(
      (prevIndex) => (prevIndex + 1) % unidadesFinalizadas.length
    );
  };

  const handleBackFinalizado = () => {
    if (indexFinalizado + 1 > unidadesFinalizadas.length) {
      setIndexFinalizado((prevIndex) => prevIndex - 1);
      return;
    }
    if (index === 0) {
      return;
    }
    setIndexFinalizado(
      (prevIndex) =>
        (prevIndex - 1 + unidadesFinalizadas.length) %
        unidadesFinalizadas.length
    );
  };

  const handleMouseDownFinalizado = (e) => {
    setFinalizadoDragStartX(e.clientX);
    setFinalizadoDragging(true);
  };

  const handleMouseMoveFinalizado = (e) => {
    if (finalizadoDragging) {
      const dragDistance = e.clientX - finalizadoDragStartX;
      finalizadoContainerRef.current.style.transform = `translateX(${
        -indexFinalizado * cardWidth + dragDistance
      }px)`;
    }
  };

  const handleMouseUpFinalizado = (e) => {
    if (finalizadoDragging) {
      const dragDistance = e.clientX - finalizadoDragStartX;
      if (dragDistance > 50 && indexFinalizado > 0) {
        handleBackFinalizado();
      } else if (
        dragDistance < -50 &&
        indexFinalizado < unidadesFinalizadas.length - 1
      ) {
        handleNextFinalizado();
      }
      setFinalizadoDragging(false);
    }
  };

  const handleTouchStartFinalizado = (e) => {
    setFinalizadoDragStartX(e.touches[0].clientX);
    setFinalizadoDragging(true);
  };

  const handleTouchMoveFinalizado = (e) => {
    if (finalizadoDragging) {
      const dragDistance = e.touches[0].clientX - dragStartX;
      finalizadoContainerRef.current.style.transform = `translateX(${
        -indexFinalizado * cardWidth + dragDistance
      }px)`;
    }
  };

  const handleTouchEndFinalizado = (e) => {
    if (finalizadoDragging) {
      const dragDistance = e.changedTouches[0].clientX - finalizadoDragStartX;
      finalizeDragFinalizado(dragDistance);
    }
  };

  const finalizeDragFinalizado = (dragDistance) => {
    if (dragDistance > 50 && index > 0) {
      handleBackFinalizado();
    } else if (dragDistance < -50 && index < unidadesFinalizadas.length - 1) {
      handleNextFinalizado();
    } else {
      finalizadoContainerRef.current.style.transform = `translateX(${
        -indexFinalizado * cardWidth
      }px)`;
    }
    setFinalizadoDragging(false);
  };

  return (
    <div className="container-unidade">
      {unidadesEmAndamento.length > 0 && (
        <div style={{ marginTop: 60, textAlign: "center" }}>
          <div className="grupo-unidades">
            <h4 className="texto-grupo-unidades">Unidades em andamento</h4>
          </div>
        </div>
      )}

      <Grid container style={{ marginTop: 15 }}>
        {/* <Grid
          item
          md={2}
          xs={2}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1,
          }}
        >
          <IconButton onClick={handleBackAndamento}>
            <ArrowLeftIcon
              style={{
                fontSize: window.innerWidth < 600 ? 50 : 130,
                color: "white",
              }}
            />
          </IconButton>
        </Grid> */}
        <Grid item md={12} xs={12}>
          <Box
            overflow="hidden"
            position="relative"
            // onMouseDown={handleMouseDownAndamento}
            // onMouseMove={handleMouseMoveAndamento}
            // onMouseUp={handleMouseUpAndamento}
            // onMouseLeave={() => setAndamentoDragging(false)}
            // onTouchStart={handleTouchStartAndamento}
            // onTouchMove={handleTouchMoveAndamento}
            // onTouchEnd={handleTouchEndAndamento}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Box
              display="flex"
              // ref={andamentoContainerRef}
              // transition={dragging ? "none" : "transform 0.3s ease-in-out"}
              // style={{
              //   transform: `translateX(-${indexAndamento * cardWidth}px)`,
              // }}
            >
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-around",
                }}
              >
                {unidadesEmAndamento.map((unidade, key) => {
                  const capa =
                    unidade.capa !== ""
                      ? `${serverHost() + "/" + unidade.capa}`
                      : "../assets/imagens/sem-capa.png";

                  badge = "";
                  color = unidade.liberada
                    ? "rgb(122, 92, 163)"
                    : "rgb(108, 108, 108)";
                  alt = "Unidade aula";

                  return (
                    <div
                      key={key}
                      style={{
                        margin: "20px 10px",
                        flex: "1 1 calc(25% - 20px)",
                        textAlign: "center",
                      }}
                    >
                      <div
                        style={{
                          margin: " 10px 10px 10px",
                        }}
                      >
                        <img
                          onClick={() => abrirUnidade(unidade, false, key)}
                          height={380}
                          width={window.innerWidth < 600 ? 230 : 300}
                          src={capa}
                          alt=""
                          style={{
                            borderRadius: 15,
                            border: "solid",
                            borderWidth: 7,
                            borderColor: color,
                            cursor: "pointer",
                          }}
                          className="image"
                        />
                      </div>
                      <div style={{ textAlign: "center" }}>
                        <h4
                          style={{
                            margin: "10px 0 0",
                            fontFamily: "Bungee",
                            fontSize: "25px",
                            lineHeight: "1em",
                            letterSpacing: "1px",
                            textTransform: "uppercase",
                            color: "#fff",
                          }}
                        >
                          {unidade.titulo}{" "}
                          {unidade.usuarioAdministrador &&
                            ` (${unidade.numero})`}
                        </h4>
                      </div>
                    </div>
                  );
                })}
              </div>
            </Box>
          </Box>
        </Grid>
        {/* <Grid
          item
          md={2}
          xs={2}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <IconButton onClick={handleNextAndamento}>
            <ArrowRightIcon
              style={{
                fontSize: window.innerWidth < 600 ? 50 : 130,
                color: "white",
              }}
            />
          </IconButton>
        </Grid> */}
      </Grid>

      {unidadesNaoIniciadas.length > 0 && (
        <div style={{ marginTop: 60, textAlign: "center" }}>
          <div
            className="grupo-unidades"
            style={{ cursor: "pointer" }}
            onClick={() => setOcultandoNaoIniciados(!ocultandoNaoIniciados)}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <h4 className="texto-grupo-unidades">
                Unidades Não iniciadas ({unidadesNaoIniciadas.length})
              </h4>
              {ocultandoNaoIniciados ? (
                <ArrowDropUpIcon />
              ) : (
                <ArrowDropDownIcon />
              )}
            </div>
          </div>
        </div>
      )}
      {!ocultandoNaoIniciados && (
        <Grid container>
          {/* <Grid
          item
          md={2}
          xs={2}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1,
          }}
        >
          <IconButton onClick={handleBack}>
            <ArrowLeftIcon
              style={{
                fontSize: window.innerWidth < 600 ? 50 : 130,
                color: "white",
              }}
            />
          </IconButton>
        </Grid> */}
          <Grid item md={12} xs={12}>
            <Box
              overflow="hidden"
              position="relative"
              // onMouseDown={handleMouseDown}
              // onMouseMove={handleMouseMove}
              // onMouseUp={handleMouseUp}
              // onMouseLeave={() => setDragging(false)}
              // onTouchStart={handleTouchStart}
              // onTouchMove={handleTouchMove}
              // onTouchEnd={handleTouchEnd}
            >
              <Box
                display="flex"
                // ref={containerRef}
                // transition={dragging ? "none" : "transform 0.3s ease-in-out"}
                // style={{ transform: `translateX(-${index * cardWidth}px)` }}
              >
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-around",
                  }}
                >
                  {unidadesNaoIniciadas.map((unidade, key) => {
                    const capa =
                      unidade.capa !== ""
                        ? `${serverHost() + "/" + unidade.capa}`
                        : "../assets/imagens/sem-capa.png";

                    badge = unidade.liberada
                      ? require("../assets/imagens/badge-new-01.png")
                      : require("../assets/imagens/badge-bloqueado-01.png");
                    color = unidade.liberada
                      ? "rgb(122, 92, 163)"
                      : "rgb(108, 108, 108)";
                    alt = "Unidade aula";

                    return (
                      <div
                        key={key}
                        style={{
                          margin: "20px 10px",
                          flex: "1 1 calc(25% - 20px)",
                          textAlign: "center",
                        }}
                      >
                        <div style={{ textAlign: "center" }}>
                          {badge && (
                            <img
                              style={{ position: "relative", zIndex: 1 }}
                              width={110}
                              src={badge}
                              alt={alt}
                            />
                          )}
                        </div>

                        <div
                          style={{
                            margin: "-50px 10px 10px 10px",
                          }}
                        >
                          <img
                            onClick={() => abrirUnidade(unidade, true, key)}
                            height={380}
                            width={window.innerWidth < 600 ? 230 : 300}
                            src={capa}
                            alt=""
                            style={{
                              borderRadius: 15,
                              border: "solid",
                              borderWidth: 7,
                              borderColor: color,
                              cursor: "pointer",
                            }}
                            className="image"
                          />
                        </div>
                        <div style={{ textAlign: "center" }}>
                          <h4
                            style={{
                              margin: "10px 0 0",
                              fontFamily: "Bungee",
                              fontSize: "25px",
                              lineHeight: "1em",
                              letterSpacing: "1px",
                              textTransform: "uppercase",
                              color: "#fff",
                            }}
                          >
                            {unidade.titulo}{" "}
                            {unidade.usuarioAdministrador &&
                              ` (${unidade.numero})`}
                          </h4>
                        </div>

                        <div style={{ marginTop: 15, textAlign: "center" }}>
                          {unidade.liberada ? (
                            <div className="disponibilidade-unidade">
                              <h4 className="texto-disponibilidade-unidade">
                                Disponível!
                              </h4>
                            </div>
                          ) : (
                            <div
                              className="disponibilidade-unidade"
                              onClick={() => setInfoDisponibilidade(true)}
                              title="Por que preciso esperar a data de liberação desta unidade?"
                            >
                              <h4 className="texto-disponibilidade-unidade">
                                Disponível a partir de{" "}
                                {formatDateTime(unidade.liberacao, "D/M/Y")}
                              </h4>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Box>
            </Box>
          </Grid>
          {/* <Grid
          item
          md={2}
          xs={2}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <IconButton onClick={handleNext}>
            <ArrowRightIcon
              style={{
                fontSize: window.innerWidth < 600 ? 50 : 130,
                color: "white",
              }}
            />
          </IconButton>
        </Grid> */}
        </Grid>
      )}

      {unidadesFinalizadas.length > 0 && (
        <div style={{ marginTop: 60, textAlign: "center" }}>
          <div
            className="grupo-unidades"
            style={{ cursor: "pointer" }}
            onClick={() => setOcultandoFinalizados(!ocultandoFinalizados)}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <h4 className="texto-grupo-unidades">
                Unidades Finalizadas ({unidadesFinalizadas.length})
              </h4>
              {ocultandoFinalizados ? (
                <ArrowDropUpIcon />
              ) : (
                <ArrowDropDownIcon />
              )}
            </div>
          </div>
        </div>
      )}
      {!ocultandoFinalizados && (
        <Grid container style={{ marginTop: 15 }}>
          {/* <Grid
          item
          md={2}
          xs={2}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1,
          }}
        >
          <IconButton onClick={handleBackFinalizado}>
            <ArrowLeftIcon
              style={{
                fontSize: window.innerWidth < 600 ? 50 : 130,
                color: "white",
              }}
            />
          </IconButton>
        </Grid> */}
          <Grid item md={12} xs={12}>
            <Box
              overflow="hidden"
              position="relative"
              // onMouseDown={handleMouseDownFinalizado}
              // onMouseMove={handleMouseMoveFinalizado}
              // onMouseUp={handleMouseUpFinalizado}
              // onMouseLeave={() => setFinalizadoDragging(false)}
              // onTouchStart={handleTouchStartFinalizado}
              // onTouchMove={handleTouchMoveFinalizado}
              // onTouchEnd={handleTouchEndFinalizado}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Box
                display="flex"
                // ref={finalizadoContainerRef}
                // transition={
                //   finalizadoDragging ? "none" : "transform 0.3s ease-in-out"
                // }
                // style={{
                //   transform: `translateX(-${indexFinalizado * cardWidth}px)`,
                // }}
              >
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-around",
                  }}
                >
                  {unidadesFinalizadas.map((unidade, key) => {
                    const capa =
                      unidade.capa !== ""
                        ? `${serverHost() + "/" + unidade.capa}`
                        : "../assets/imagens/sem-capa.png";

                    badge = require("../assets/imagens/badge-done-01.png");
                    color = "rgb(55, 132, 32)";
                    alt = "Unidade finalizada";

                    return (
                      <div
                        key={key}
                        style={{
                          margin: "20px 10px",
                          flex: "1 1 calc(25% - 20px)",
                          textAlign: "center",
                        }}
                      >
                        <div style={{ textAlign: "center" }}>
                          {badge && (
                            <img
                              style={{ position: "relative", zIndex: 1 }}
                              width={110}
                              src={badge}
                              alt={alt}
                            />
                          )}
                        </div>
                        <div
                          style={{
                            margin: "-50px 10px 10px 10px",
                          }}
                        >
                          <img
                            onClick={() => abrirUnidade(unidade, false, key)}
                            height={380}
                            width={window.innerWidth < 600 ? 230 : 300}
                            src={capa}
                            alt=""
                            style={{
                              borderRadius: 15,
                              border: "solid",
                              borderWidth: 7,
                              borderColor: color,
                              cursor: "pointer",
                            }}
                            className="image"
                          />
                        </div>
                        <div style={{ textAlign: "center" }}>
                          <h4
                            style={{
                              margin: "10px 0 0",
                              fontFamily: "Bungee",
                              fontSize: "25px",
                              lineHeight: "1em",
                              letterSpacing: "1px",
                              textTransform: "uppercase",
                              color: "#fff",
                            }}
                          >
                            {unidade.titulo}{" "}
                            {unidade.usuarioAdministrador &&
                              ` (${unidade.numero})`}
                          </h4>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Box>
            </Box>
          </Grid>
          {/* <Grid
          item
          md={2}
          xs={2}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <IconButton onClick={handleNextFinalizado}>
            <ArrowRightIcon
              style={{
                fontSize: window.innerWidth < 600 ? 50 : 130,
                color: "white",
              }}
            />
          </IconButton>
        </Grid> */}
        </Grid>
      )}

      {/* <ul className="itens">
        {unidadesFinalizadas.map((unidade, key) => {
          const capa =
            unidade.capa !== ""
              ? `url("${serverHost() + "/" + unidade.capa}")`
              : `url(${require("../assets/imagens/sem-capa.png")})`;

          badge = require("../assets/imagens/badge-done-01.png");
          color = "rgb(55, 132, 32)";
          alt = "Unidade finalizada";

          return (
            <li key={key} onClick={() => abrirUnidade(unidade, false, key)}>
              {badge && <img className="badge" src={badge} alt={alt} />}
              <div
                className="badge-border"
                style={{ backgroundImage: capa, borderColor: color }}
              ></div>
              <h4>
                {unidade.titulo}{" "}
                {unidade.usuarioAdministrador && ` (${unidade.numero})`}
              </h4>
            </li>
          );
        })}
      </ul> */}
    </div>
  );
};

const CadastroInterno = ({ finalizar, dados, userData }) => {
  const { notification } = useContext(AppContext);
  const [fase, setFase] = useState(definirFase(dados));
  const [cor, setCor] = useState("");
  const [avatar, setAvatar] = useState("");
  const [nivelId, setNivel] = useState("");

  switch (fase) {
    case "ASSINATURA":
      return (
        <Pagamento
          desc={"Assine o Inglês Juntos para ter acesso ao conteúdo!"}
          usuarioId={userData.usuarioId}
          usuarioEmail={dados.usuarioEmail}
          continuar={() => {
            setFase(dados.aceiteTermos ? "COR" : "TERMOS");
          }}
          promocao={dados.promocao}
        />
      );

    case "TERMOS":
      return <TermosUso continuar={() => setFase("COR")} />;

    case "COR":
      return (
        <SelecaoCor
          selecionarCor={(cor) => setCor(cor)}
          corSelecionada={cor}
          continuar={() => {
            if (cor === "") return notification("warning", "Selecione a cor.");
            setFase("PERSONAGEM");
          }}
          voltar={() => setFase("TERMOS")}
        />
      );

    case "PERSONAGEM":
      return (
        <SelecaoPersonagem
          continuar={() => {
            if (avatar === "")
              return notification("warning", "Selecione o personagem.");
            setFase("NIVEL");
          }}
          voltar={() => setFase("COR")}
          personagemSelecionado={avatar}
          selecionarPersonagem={(avatar) => setAvatar(avatar)}
        />
      );

    case "NIVEL":
      return (
        <SelecaoNivel
          continuar={() => {
            if (nivelId === 0)
              return notification("warning", "Selecione o nível.");

            finalizar(cor, avatar, nivelId);
          }}
          voltar={() => setFase("PERSONAGEM")}
          nivelSelecionado={nivelId}
          selecionarNivel={(nivelId) => setNivel(nivelId)}
        />
      );

    default:
      return null;
  }
};

const InfoDisponibilidade = ({ voltar }) => {
  return (
    <div className="pagina-login">
      <div className="container-login">
        <h2>Por que preciso esperar a data de liberação desta unidade?</h2>

        <p>
          Em nossa metodologia, encaramos a língua inglesa como um aprendizado
          prático e não teórico. Por isso, concluímos que melhor que estudar
          sobre a língua inglesa é utilizar o inglês. Essa é a mesma lógica
          adotada pelas escolas bilíngues ao redor do Brasil.
        </p>

        <p>
          Veja um exemplo: uma pessoa até pode estudar muito sobre natação, mas
          somente aprenderá a nadar se entrar na água e aos poucos ir aprendendo
          os movimentos necessários para se movimentar. Logo, ter um hábito
          regular de nadar tem mais valor que estudar tudo sobre natação em um
          dia.
        </p>

        <p>
          Quem quer estudar sobre a língua inglesa até consegue fazer isso de
          forma acelerada... Porém, quem deseja praticar (falar) a língua
          inglesa precisa fazer isso de forma regular.
        </p>

        <p>
          Essa é a razão pela qual nosso método disponibiliza apenas duas
          unidades ao mês e recomendamos que não seja feita mais que uma aula
          por dia. Assim, o aluno terá tempo de assimilar, reter e praticar o
          conteúdo, além de criar um hábito de contato diário com a língua.
        </p>

        <p>
          Caso você já tenha terminado as duas unidades deste mês, sugerimos que
          retorne para as aulas que mais gostou ou teve maiores dificuldades até
          que esta nova unidade esteja disponível. Ou talvez cantar mais um
          pouco até ficar super afinado? 🎤🎼😊
        </p>
        <br />
        <br />
        <br />
        <div className="footer-nav">
          {voltar && (
            <button
              className="btn-link ali-left"
              onClick={voltar}
              href="#"
            >{`< Voltar para as unidades`}</button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Unidades;

const definirFase = (dados) => {
  if (!dados.assinante) return "ASSINATURA";

  if (!dados.aceiteTermos) return "TERMOS";

  return "COR";
};
