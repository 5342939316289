import serverHost from '../../services/serverHost';

const configMemoria = (identificador) => {
    switch(identificador){
        case 'alfabetizados_unit2_aula10_atividade2':
            return {
                modalidade: 'tempo',
                tempoAbertura: 5,
                textoAbertura: 'OBSERVE BEM AS IMAGENS ANTES QUE ELAS SUMAM!',
                audioAbertura: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-2/aula-10/video-2/abertura.ogg`,
                rodadas: [
                    {
                        enunciado: 'ONDE ESTAVA A APPLE?',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-2/aula-10/video-2/enunciado_apple.ogg`,
                        itemEncontrar: 'APPLE',
                        itens: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/apple.jpg`,
                                id: 'APPLE'
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/banana.jpg`,
                                id: 'BANANA'
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/strawberry.jpg`,
                                id: 'STRAWBERRY'
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/orange.jpg`,
                                id: 'ORANGE'
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/avocado.jpg`,
                                id: 'AVOCADO'
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/grapes.jpg`,
                                id: 'GRAPES'
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/orange.jpg`,
                                id: 'ORANGE'
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/peach.jpg`,
                                id: 'PEACH'
                            }
                        ]
                    },
                    {
                        enunciado: 'ONDE ESTAVA O LEMON?',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-2/aula-10/video-2/enunciado_lemon.ogg`,
                        itemEncontrar: 'LEMON',
                        itens: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/apple.jpg`,
                                id: 'APPLE'
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/banana.jpg`,
                                id: 'BANANA'
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/strawberry.jpg`,
                                id: 'STRAWBERRY'
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/orange.jpg`,
                                id: 'ORANGE'
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/avocado.jpg`,
                                id: 'AVOCADO'
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/grapes.jpg`,
                                id: 'GRAPES'
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/lemon.jpg`,
                                id: 'LEMON'
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/peach.jpg`,
                                id: 'PEACH'
                            }
                        ]
                    },
                    {
                        enunciado: 'ONDE ESTAVA O AVOCADO?',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-2/aula-10/video-2/enunciado_avocado.ogg`,
                        itemEncontrar: 'AVOCADO',
                        itens: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/apple.jpg`,
                                id: 'APPLE'
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/banana.jpg`,
                                id: 'BANANA'
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/strawberry.jpg`,
                                id: 'STRAWBERRY'
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/orange.jpg`,
                                id: 'ORANGE'
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/avocado.jpg`,
                                id: 'AVOCADO'
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/grapes.jpg`,
                                id: 'GRAPES'
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/lemon.jpg`,
                                id: 'LEMON'
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/peach.jpg`,
                                id: 'PEACH'
                            }
                        ]
                    },
                    {
                        enunciado: 'ONDE ESTAVA AS GRAPES?',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-2/aula-10/video-2/enunciado_grapes.ogg`,
                        itemEncontrar: 'GRAPES',
                        itens: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/apple.jpg`,
                                id: 'APPLE'
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/banana.jpg`,
                                id: 'BANANA'
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/strawberry.jpg`,
                                id: 'STRAWBERRY'
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/orange.jpg`,
                                id: 'ORANGE'
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/avocado.jpg`,
                                id: 'AVOCADO'
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/grapes.jpg`,
                                id: 'GRAPES'
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/lemon.jpg`,
                                id: 'LEMON'
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/peach.jpg`,
                                id: 'PEACH'
                            }
                        ]
                    }
                ]
            }

        default: return {};
    }
}

export default configMemoria;