/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState, Fragment} from 'react';
import {click, win, wrong} from '../../libs/soundEffects';

let audio = undefined;

const Agrupar = ({config}) => {
    const [respostaOk, setRespostaOk] = useState(false);
    const [grupos, setGrupos] = useState([]);
    const [itensValidados, setItensValidados] = useState([]);

    useEffect(() => {
        iniciar();
    }, []);

    const iniciar = () => {
        if(config.audioEnunciado){
            const audio = new Audio(config.audioEnunciado);

            audio.play();        
        }

        const grupos = [];

        config.grupos.forEach(grupo => {
            grupos.push({
                id: grupo.id,
                titulo: grupo.titulo,
                cor: grupo.cor,
                itens: config.itens.filter(i => i.grupoId === grupo.id)
            })
        });

        setGrupos(grupos);
    }

    const avancar = () => {
        config.avancar ? config.avancar() : alert('Fim!');
    }

    const verificarResposta = (palavra, grupoId) => {
        const gruposEditar = [...grupos];
        const itensValidadosEditar = [...itensValidados];

        gruposEditar
        .filter(g => g.id === grupoId)
        .forEach(grupo => grupo.itens
            .filter(item => item.nome.toUpperCase() === palavra.toUpperCase()).forEach(item => {
                item.validado = true;
                win();

                itensValidadosEditar.push(item.nome);

                gruposEditar.forEach(grupo => grupo.itens.sort((a, b) => {
                    if(a.validado && b.validado) return 0;
                    if(a.validado && !b.validado) return -1;

                    return 1;
                }));

                setGrupos(gruposEditar);
                setItensValidados(itensValidadosEditar);

                if(itensValidadosEditar.length === config.itens.length){
                    setRespostaOk(true);
                }
            }));
    }

    return(
        <div style={styles.container}>
            <div style={styles.cabecalho}>
                <p>{config.enunciado}</p>
                <button style={styles.btnRepetir} onClick={iniciar}>
                    <i class="fas fa-undo-alt" style={styles.btnRepetirIcon}></i> REPETIR
                </button>
            </div>
            <div style={styles.conteudo}>
                {config.itens.map((item, key) => 
                    <div style={itensValidados.filter(i => i === item.nome).length > 0 ? styles.conteudoItemCorreto : styles.conteudoItem}>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            {item.imagem && (
                                <img src={item.imagem} style={{height: 150}}/>
                            )}
                            <p><b>{item.nome}</b></p>
                        </div>
                    </div>
                )}
            </div>
            {respostaOk ?
                <div style={styles.conteudoFinal}>
                    <p><b>{`PARABÉNS! ATIVIDADE FINALIZADA!`}</b></p>
                    <button style={styles.btnContinuar} onClick={avancar}>
                        CONTINUAR <i class="fas fa-chevron-right" style={styles.btnContinuarIcon}></i>
                    </button>
                </div>
            :
                <div style={styles.conteudo}>
                    {grupos.map((grupo, key) => 
                        <div key={key} className="box-grupo" style={{background: grupo.cor ? grupo.cor : undefined}}>
                            <p style={{fontSize: 24}}>{grupo.titulo}</p>
                            {grupo.itens.map((item, key) => 
                                <div key={key} className="box-grupo-input">
                                    <input 
                                        className="p-text-input"
                                        type="text"
                                        onChange={e => verificarResposta(e.target.value, grupo.id)}
                                        value={item.validado ? item.nome : undefined}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </div>
            }            
        </div>
    )
    
    return(
        <div className="content">
            <div className="container">
                <Fragment>
                    <div className="box-topo">
                        <div className="item-box-topo-1">
                            <p className="p-text">{config.enunciado}</p>
                        </div>
                        
                        <div className="item-box-topo-2">
                            <a className="btn-repetir" onClick={iniciar}><i className="fas fa-undo-alt"></i> REPETIR</a>
                        </div>
                    </div>
                    <div className="box-palavras">
                        {config.itens.map((item, key) => 
                            <div key={key} className={itensValidados.filter(i => i === item.nome).length > 0 ? "palavra-item palavra-item-marcada" : "palavra-item"}>
                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                    {
                                        item.imagem && (
                                            <img src={item.imagem} style={{height: 150}}/>
                                        )
                                    }
                                    {item.nome}
                                </div>
                            </div>
                        )}
                    </div>
                    {respostaOk ?
                        <div className="box-palavras">
                            <div 
                                className="tl7-box-confirmacao"
                                style={{
                                    width: config.imagemLargura ? config.imagemLargura : 524,
                                    height: config.imagemAltura ? config.imagemAltura : 524
                                }}
                            >
                                <p className="p-text">PARABÉNS</p>
                                <p className="p-text">VOCÊ ACERTOU!</p>
                                <a className="btn-concluir" onClick={avancar}>CONTINUAR <i className="fas fa-chevron-right"></i> </a>
                            </div>
                        </div>                        
                    :
                        <div className="box-palavras">
                            {grupos.map((grupo, key) => 
                                <div key={key} className="box-grupo" style={{background: grupo.cor ? grupo.cor : undefined}}>
                                    <p style={{fontSize: 24}}>{grupo.titulo}</p>
                                    {grupo.itens.map((item, key) => 
                                        <div key={key} className="box-grupo-input">
                                            <input 
                                                className="p-text-input"
                                                type="text"
                                                onChange={e => verificarResposta(e.target.value, grupo.id)}
                                                value={item.validado ? item.nome : undefined}
                                            />
                                        </div>
                                    )}
                                </div>
                            )}                        
                        </div>  
                    }                                                    
                </Fragment>                
            </div>
        </div>
    );
}

export default Agrupar;

const styles = {
    container: {
        backgroundColor: 'white', 
        margin: 5, 
        borderRadius: 15, 
        padding: 5
    },
    cabecalho: {
        backgroundColor: 'RGB(0,177,181)', 
        margin: 5, 
        borderRadius: 15, 
        padding: 5,
        color: 'white',
        fontWeight: 'bolder',
        display: 'flex',
        justifyContent: 'space-between'
    },
    conteudo: {
        display: 'flex',
        justifyContent: 'center',
        alignItens: 'center',
        flexWrap: 'wrap',
        borderRadius: 15,
        backgroundColor: 'RGB(193,239,226)',
        margin: 5,
        marginTop: 10
    },
    conteudoFinal: {
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        textAlign: 'center',
        alignItens: 'center',
        flexWrap: 'wrap',
        borderRadius: 15,
        backgroundColor: 'RGB(193,239,226)',
        margin: 5,
        marginTop: 10,
        padding: 10
    },
    conteudoItem: {
        touchAction: 'none',
        marginTop: 5,
        marginLeft: 5,
        marginRitht: 5,
        marginBottom: 5,
        borderRadius: 15,
        backgroundColor: 'RGB(255,206,0)',
        padding: 10,
        textAlign: 'center'
    },
    conteudoItemCorreto: {
        margin: 5,
        borderRadius: 15,
        backgroundColor: 'RGB(107, 255, 107)',
        padding: 10,
        textAlign: 'center'
    },
    conteudoItemIncorreto: {
        margin: 5,
        borderRadius: 15,
        backgroundColor: 'RGB(255, 107, 107)',
        padding: 10,
        textAlign: 'center'
    },
    btnRepetir: {
        backgroundColor: 'RGB(71, 226, 230)',
        border: 'none',
        color: 'white',
        borderRadius: 15
    },
    btnRepetirIcon: {
        color: 'RGB(71, 226, 230)',
        marginBottom: 5
    },
    btnContinuar: {
        backgroundColor: 'RGB(0, 144, 245)',
        border: 'none',
        color: 'white',
        borderRadius: 15,
        maxHeight: 60,
        padding: 10
    },
    btnContinuarIcon: {
        color: 'RGB(0, 144, 245)',
        marginBottom: 5
    }
}