import React, { useEffect, useState } from 'react';
import {
  BoxConfirmation,
  BoxLamp,
  BoxPontuacao,
  BoxTopo,
  BtnConcluir,
  Button,
  Container,
  Content,
  ItemBox1,
  ItemBox2,
  Text,
  Tl8Box2,
} from './styles/stylesOuvir';
import { click, win, wrong } from '../libs/soundEffects';

let rodadaAlternarItens = 0;
let fim = false;

export default function OuvirClicar({ config }) {
  const qtdRodadas = config.rodadas ? config.rodadas.length : 0;
  const [rodadaAtual, setRodadaAtual] = useState(undefined);
  const [numRodadaAtual, setNumRodadaAtual] = useState(0);
  const [itemAtual, setItemAtual] = useState(0);
  const [itemAudio, setItemAudio] = useState(0);
  const [pontos, setPontos] = useState(0);
  const [respostaOk, setRespostaOk] = useState(undefined);

  useEffect(() => {
    if (config.audioInicio) {
      const audio = new Audio(config.audioInicio);

      audio.play();
    }

    iniciarRodada(1);
    if (!config.imagensClicar) {
      alternarItens(0);
    }
  }, []);

  const alternarItens = (numero) => {
    const itensAlternar = config.rodadas[rodadaAlternarItens].imagensClicar;

    const novoItem = itensAlternar[numero + 1] ? numero + 1 : 0;
    setItemAtual(novoItem);
  };

  const iniciarRodada = (numero) => {
    const rodada = config.rodadas[numero - 1];

    if (!rodada) return;

    if (rodada.audioEnunciado) {
      const audio = new Audio(rodada.audioEnunciado);

      audio.play();
    }

    setNumRodadaAtual(numero);
    setRodadaAtual(rodada);
    fim = false;
    setPontos(0);
    rodadaAlternarItens = numero - 1;
    setRespostaOk(false);
  };

  const avancar = () => {
    if (numRodadaAtual === qtdRodadas) {
      fim = true;
      setRespostaOk(true);
      config.avancar ? config.avancar() : alert('acabou!');
    } else {
      iniciarRodada(numRodadaAtual + 1);
    }
  };

  const handleChange = (num) => {
    let imagemId = rodadaAtual.imagensClicar[itemAtual].id;
    let audioId = rodadaAtual.itensAudio[itemAudio].id;

    if (num) {
      click();
    }

    if (imagemId === audioId) {
      alternarItens(itemAtual);
      if (num) {
        wrong();
      }
    } else {
      setPontos(pontos + 1);

      if (pontos + 1 >= rodadaAtual.pontos) {
        fim = true;
        setRespostaOk(true);
        win();
      }

      if (!fim) {
        alternarItens(itemAtual);
        nextAudio(itemAudio);
      }
    }
  };

  if (!rodadaAtual) return null;

  const nextAudio = (numero) => {
    const itensAlternar = config.rodadas[rodadaAlternarItens].itensAudio;

    const novoItem = itensAlternar[numero + 1] ? numero + 1 : 0;
    setItemAudio(novoItem);
  };

  let imgId = rodadaAtual.imagensClicar[itemAtual].id;
  let audId = rodadaAtual.itensAudio[itemAudio].id;

  if (!fim) {
    if (imgId === audId) {
      setTimeout(() => handleChange(), 4000);

      const audio = new Audio(rodadaAtual.itensAudio[itemAudio].audio);
      audio.play();
    } else {
      const audio = new Audio(rodadaAtual.itensAudio[itemAudio].audio);
      audio.play();
    }
  }

  return (
    <>
      <BoxTopo>
        <ItemBox1>
          <Text>{rodadaAtual.itensAudio[itemAudio].texto}</Text>
        </ItemBox1>
        <ItemBox2>
          <Button onClick={() => iniciarRodada(1)}>
            <i className="fas fa-undo-alt"></i> REPETIR
          </Button>
        </ItemBox2>
      </BoxTopo>

      <BoxPontuacao>
        <Text>
          {`${
            rodadaAtual.textoContador ? rodadaAtual.textoContador : 'Pontos'
          }: (${pontos}/${rodadaAtual.pontos})`}
        </Text>
      </BoxPontuacao>

      {respostaOk ? (
        <Tl8Box2>
          <BoxConfirmation>
            <Text>PARABÉNS</Text>
            <Text>VOCÊ ACERTOU!</Text>
            <BtnConcluir onClick={avancar}>
              CONTINUAR <i className="fas fa-chevron-right"></i>{' '}
            </BtnConcluir>
          </BoxConfirmation>
        </Tl8Box2>
      ) : (
        <>
          <Container>
            <Content>
              {rodadaAtual.imagensClicar[itemAtual].imagem && (
                <img
                  src={rodadaAtual.imagensClicar[itemAtual].imagem}
                  alt="imagens"
                />
              )}
            </Content>

            <BoxLamp onClick={() => handleChange(1)}>
              <img src={rodadaAtual.clicks[itemAtual].imagem} alt="" />
            </BoxLamp>
          </Container>
        </>
      )}
    </>
  );
}
