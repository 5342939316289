import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";

import Unidades from "../routes/Unidades";
import Aulas from "../routes/Aulas";
import Conteudo from "../routes/Conteudo";
import Perfil from "./Perfil";
import Templates from "../routes/Templates";
// import PageNotFOund from "./PageNotFound";
// import TesteUnity from "../routes/TesteUnity";
// import TesteCanvas from "../routes/TesteCanvas";
import Ajuda from "./Ajuda";
// import Assine from "./Assine";
// import Assinatura from "./Assinatura";

import "../styles/in.css";
import "../styles/atividades.css";
import "../styles/mobile.css";
import Header from "../components/Header";
import getUserData from "../services/userData";
import { AppContextProvider } from "../container/context";

const App = ({ match, location }) => {
  const [backgroundSound] = useState(
    new Audio(require("../assets/sounds/background.mp3"))
  );
  const [somAtivo, setSomAtivo] = useState(false);
  const [somBloqueado, setSomBloqueado] = useState(false);
  const [headerProps, setHeaderProps] = useState({});
  const [userData, setUserData] = useState(getUserData());
  const [fullScreen, setFullScreen] = useState(false);

  useEffect(() => {
    if (!somAtivo && !somBloqueado) iniciarSom();
  });

  const iniciarSom = () => {
    if (localStorage.getItem("BACK_SOUND") === "1" && !somAtivo) {
      alternarSom();
    }
  };

  const alternarSom = () => {
    if (somAtivo) {
      backgroundSound.pause();
      setSomAtivo(false);
      localStorage.setItem("BACK_SOUND", 0);
    } else {
      backgroundSound
        .play()
        .then(() => {
          setSomAtivo(true);
          localStorage.setItem("BACK_SOUND", 1);
        })
        .catch(() => console.log("Música de fundo bloqueada pelo navegador."));
    }
  };

  const bloquearSom = (bloquear) => {
    if (bloquear) {
      backgroundSound.pause();
      setSomAtivo(false);
    } else {
      iniciarSom();
    }

    setSomBloqueado(bloquear);
  };
  const toggleScreen = () => {
    setFullScreen(!fullScreen);
  };
  const configHeader = (data) => {
    setHeaderProps(data);
  };

  const refresh = () => {
    setUserData(getUserData());
  };

  return (
    <div
      className="pagina-in"
      style={{
        backgroundColor:
          location.pathname === "/app/perfil" ? "white" : userData.cor,
      }}
    >
      <AppContextProvider>
        {fullScreen === false && (
          <Header
            alternarSom={alternarSom}
            iniciarSom={iniciarSom}
            somAtivo={somAtivo}
            headerProps={headerProps}
            bloquearSom={bloquearSom}
            userData={userData}
          />
        )}
        <Switch>
          <Route
            path={`${match.url}/`}
            exact={true}
            render={(props) => (
              <Unidades
                {...props}
                configHeader={configHeader}
                refresh={refresh}
              />
            )}
          />
          <Route
            path={`${match.url}/aulas/:id`}
            render={(props) => <Aulas {...props} configHeader={configHeader} />}
          />

          <Route
            path={`${match.url}/conteudo/:id`}
            render={(props) => (
              <Conteudo
                {...props}
                configHeader={configHeader}
                toggleScreen={toggleScreen}
                fullScreen={fullScreen}
              />
            )}
          />

          {/* <Route
            path={`${match.url}/teste-atividade/:identificador?`}
            render={(props) => (
              <TesteUnity
                {...props}
                configHeader={configHeader}
                toggleScreen={toggleScreen}
                fullScreen={fullScreen}
              />
            )}
          /> */}

          <Route path={`${match.url}/perfil`} component={Perfil} />
          <Route path={`${match.url}/templates`} component={Templates} />
          <Route path={`${match.url}/ajuda`} component={Ajuda} />

          {/* <Route
          path={`${match.url}/aulas/:id`}
          render={(props) => <Aulas {...props} configHeader={configHeader} />}
        />
        <Route
          path={`${match.url}/conteudo/:id`}
          render={(props) => (
            <Conteudo
              {...props}
              configHeader={configHeader}
              toggleScreen={toggleScreen}
              fullScreen={fullScreen}
            />
          )}
        />
        
        <Route path={`${match.url}/teste-canvas`} component={TesteCanvas} />
        <Route path={`${match.url}/assine`} component={Assine} />
        <Route path={`${match.url}/perfil`} component={Perfil} />
        <Route path={`${match.url}/assinatura`} component={Assinatura} />
        <Route path={`${match.url}/ajuda`} component={Ajuda} />
        <Route component={PageNotFOund} /> */}
        </Switch>
      </AppContextProvider>
    </div>
  );
};

export default App;
