import React, { useState } from "react";
import PerguntasFrequentes from "../documents/PerguntasFrequentes";
import Tutorial from "../components/Tutorial";

const Ajuda = ({ history }) => {
  const [tutorial, setTutorial] = useState(false);

  if (tutorial)
    return <Tutorial registrarTutorial={() => setTutorial(false)} />;

  return (
    <>
      {window.innerWidth > 800 ? (
        <div className="pagina-config">
          <div className="container-login">
            <h1>CONTATO</h1>
            <p>Quer entrar em contato conosco?</p>
            <p>
              Email: <b>contato@inglesjuntos.com.br</b>
            </p>
            <p>
              WhatsApp: <b>41 3013-5708</b>
            </p>
            <p>
              Atendimento de segunda a sexta (exceto feriados), das 9h às 17h.
            </p>
            <h1>FAQ</h1>
            <p>Precisa de ajuda para utilizar Inglês Juntos?</p>
            <p>
              Clique no botão abaixo para ver o tutorial de acesso - ou então
              role até a seção de Perguntas Frequentes!
            </p>
            <button className="btn-padrao" onClick={() => setTutorial(true)}>
              Ver tutorial
            </button>
            <br />
            <br />
            <h1>PERGUNTAS FREQUENTES</h1>

            <div style={{ textAlign: "justify", marginBottom: 60 }}>
              <PerguntasFrequentes />
            </div>

            <div className="footer-nav">
              <button
                className="btn-link ali-left"
                onClick={() => history.push("/app")}
              >
                Voltar
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ background: "white", padding: 10 }}>
          <div style={{ textAlign: "center" }}>
            <h1>CONTATO</h1>
            <p>Quer entrar em contato conosco?</p>
            <p>
              Email: <b>contato@inglesjuntos.com.br</b>
            </p>
            <p>
              WhatsApp: <b>41 3013-5708</b>
            </p>
            <p>
              Atendimento de segunda a sexta (exceto feriados), das 9h às 17h.
            </p>
            <h1>FAQ</h1>
            <p>Precisa de ajuda para utilizar Inglês Juntos?</p>
            <p>
              Clique no botão abaixo para ver o tutorial de acesso - ou então
              role até a seção de Perguntas Frequentes!
            </p>
            <button className="btn-padrao" onClick={() => setTutorial(true)}>
              Ver tutorial
            </button>
            <br />
            <br />
            <h1>PERGUNTAS FREQUENTES</h1>
          </div>

          <div style={{ textAlign: "justify", marginBottom: 60 }}>
            <PerguntasFrequentes />
          </div>

          <div className="footer-nav">
            <button
              className="btn-link ali-left"
              onClick={() => history.push("/app")}
            >
              Voltar
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Ajuda;
