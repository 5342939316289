/* eslint-disable react/jsx-no-undef */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, {useEffect, useState, Fragment, useRef} from 'react';
import {click, win, wrong} from '../../libs/soundEffects';

let audio = undefined;

const MultiplaEscolha = ({config}) => {
    const inputRef = useRef(null);

    const qtdRodadas = config.rodadas ? config.rodadas.length : 0;

    const [rodadaAtual, setRodadaAtual] = useState(undefined);
    const [numRodadaAtual, setNumRodadaAtual] = useState(0);
    const [respostaOk, setRespostaOk] = useState(undefined);
    const [selecionadas, setSelecionadas] = useState([]);
    const [corretasSelecionadas, setCorretasSelecionadas] = useState([]);
    const [textoConfirmacao, setTextoConfirmacao] = useState('');
    const [opcaoSelecionada, setOpcaoSelecionada] = useState({});
    const [textoResposta, setTextoResposta] = useState('');
    const [time, setTime] = useState(false);

    useEffect(() => {
        if(config.audioInicio){
            const audio = new Audio(config.audioInicio);

            audio.play();        
        }

        iniciarRodada(1);
    }, []);

    const iniciarRodada = (numero) => {
        const rodada = config.rodadas[numero - 1];

        if(!rodada) return;

        rodada.qualquerOpcao = rodada.qualquerOpcao ? rodada.qualquerOpcao : rodada.opcoes.filter(o => o.correta).length === rodada.opcoes.length;
        if(rodada.audioEnunciado){            
            audio = new Audio(rodada.audioEnunciado);
            
            if(rodada.atrasoAudio){
                setTimeout(() => audio.play(), rodada.atrasoAudio*1000)
            } else {
                audio.play();
            }
        }

        setNumRodadaAtual(numero);
        setRodadaAtual(rodada);
        setRespostaOk(false);
        setSelecionadas([]);
        setCorretasSelecionadas([]);
        setTextoResposta('');
        setTime(false);
    }
   

    const selecionar = (numOpc, opcao) => {
        if(opcao.audio){
            const audioOpc = new Audio(opcao.audio);
            audioOpc.play();
        }

        if(inputRef) inputRef.current.focus();

        setSelecionadas([numOpc]);
        setOpcaoSelecionada(opcao);
    }

    const responder = (numOpc, opcao) => {
        const opcSelecionadas = [...selecionadas];

        if(!selecionadas.includes(numOpc)) opcSelecionadas.push(numOpc);

        setSelecionadas(opcSelecionadas);

        if(audio) audio.pause();

        if(opcao.correta || rodadaAtual.qualquerOpcao){
            if(opcao.audio){
                const audioOpc = new Audio(opcao.audio);
                audioOpc.play();
            }

            if(rodadaAtual.multipla){
                const opcCorretasSelecionadas = [...corretasSelecionadas];

                if(!corretasSelecionadas.includes(numOpc)) opcCorretasSelecionadas.push(numOpc);
                
                if(opcCorretasSelecionadas.length < rodadaAtual.opcoes.filter(o => o.correta).length){
                    return setCorretasSelecionadas(opcCorretasSelecionadas);
                }
            }

            if(opcao.audio){
                setTimeout(() => win(), opcao.duracaoAudio ? opcao.duracaoAudio*1000 : 2200);
            } else {
                win();
            }

            if(opcao.textoConfirmacao) setTextoConfirmacao(opcao.textoConfirmacao);
            
            switch(config.midia){
                case 'imagem':
                    return setRespostaOk(true);

                case 'cor':
                    return setRespostaOk(true);

                case 'video':
                    return setRespostaOk(opcao.video);

                case 'texto':
                    return setRespostaOk(true);

                default:
                    return setRespostaOk(opcao.imagem);
            }
        } else {
            if(opcao.audio){
                const audioOpc = new Audio(opcao.audio);
                audioOpc.play();
            } else {
                wrong();
            }
        }
    }

    const avancar = () => {
        click();

        if(numRodadaAtual === qtdRodadas){
            return config.avancar ? config.avancar() : alert('Fim!');
        } else {
            iniciarRodada(numRodadaAtual + 1);
        }
    }

    const validarTexto = () => {
        if(textoResposta.toLowerCase() === opcaoSelecionada.palavra){
            win();
            return avancar();
        }
        
        wrong();
    }

    let classeColunas = '';
    let alturaImagem = 0;

    if(!rodadaAtual) return null;

    // if(config.midia === 'texto') {
    //     return (
    //         <Texto 
    //             responder={responder} 
    //             avancar={avancar} 
    //             rodadaAtual={rodadaAtual}
    //             confirmacao={respostaOk}
    //             selecionadas={selecionadas}
    //         />
    //     );
    // }
    
    if(rodadaAtual.imagemEnunciado){
        switch(rodadaAtual.opcoes.length){
            case 3: 
                classeColunas = 'tl2-box-2-3colunas';
                alturaImagem = 270;

                break;
            default:
                classeColunas = 'tl2-box-2-2colunas';
                alturaImagem = 180;
                
                break;
        }
        
    } else {
        switch(rodadaAtual.opcoes.length){
            case 2:
                classeColunas = 'tl2-box-2-2colunas'
                alturaImagem = 360
                break;
    
            case 3: 
            
                classeColunas = 'tl2-box-2-3colunas'
                alturaImagem = 270
                break;
    
            case 4:
                classeColunas = 'tl2-box-2-2colunas'
                alturaImagem = 180
                break;
    
            default:
                classeColunas = 'tl2-box-2-3colunas'
                alturaImagem = 180
                break;
        }
    }

    let opcoesExibir = [];

    if(rodadaAtual.livre){
        opcoesExibir = rodadaAtual.opcoes.filter((opcao, i) => selecionadas.includes(i));
    } else {
        opcoesExibir = rodadaAtual.opcoes.filter(opcao => opcao.correta);
    }

    return(
        <div style={styles.container}>
            <div style={styles.cabecalho}>
                <p>{rodadaAtual.enunciado}</p>
                <button style={styles.btnRepetir} onClick={() => iniciarRodada(1)}>
                    <i class="fas fa-undo-alt" style={styles.btnRepetirIcon}></i> REPETIR
                </button>
            </div>
            {rodadaAtual.imagemEnunciado &&
                <div style={styles.conteudo}>
                    <div style={{textAlign: 'center'}}><img style={{height: 120}} src={rodadaAtual.imagemEnunciado}/></div>
                </div>
            }
            {rodadaAtual.videoEnunciado &&
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    {
                        rodadaAtual.videoEnunciadoMudo ?
                        <video 
                            autoPlay={true} 
                            loop={true}
                            src={rodadaAtual.videoEnunciado}
                            style={{height: 180}}
                            muted
                        />
                    :
                        <video 
                            autoPlay={true} 
                            loop={true}
                            src={rodadaAtual.videoEnunciado}
                            style={{height: 180}}
                        />
                    }
                </div>                
            }
            {respostaOk ? 
                <div style={styles.conteudoFinal}>
                    <div style={styles.conteudo}>
                        {selecionadas.filter(numOpc => rodadaAtual.opcoes[numOpc].correta || rodadaAtual.qualquerOpcao).map(numOpc => (
                            <div 
                                style={
                                    ((rodadaAtual.opcoes[numOpc].correta || rodadaAtual.qualquerOpcao) ? styles.conteudoItemCorreto : styles.conteudoItemIncorreto)
                                }
                            >
                                {config.midia === 'imagem' &&
                                    <img src={rodadaAtual.opcoes[numOpc].imagem} style={{width: 120}}/>
                                }
                                {config.midia === 'cor' &&
                                    <div style={{width: 120, height: 120, backgroundColor: rodadaAtual.opcoes[numOpc].cor}}/>
                                }
                                {config.midia === 'video' &&
                                    <video 
                                        autoPlay={true} 
                                        loop="true" 
                                        src={rodadaAtual.opcoes[numOpc].video}
                                        style={{width: 150}}
                                        muted
                                    />
                                }
                                {rodadaAtual.opcoes[numOpc].texto &&
                                    <p><b>{rodadaAtual.opcoes[numOpc].texto}</b></p>
                                }
                            </div>
                        ))}
                    </div>                    
                    {textoConfirmacao ?
                        <p><b>{textoConfirmacao}</b></p>
                    :
                        <p><b>{config.textoRespostaOk ? config.textoRespostaOk : (rodadaAtual.qualquerOpcao ? 'ATIVIDADE CONCLUÍDA!' : `CERTA RESPOSTA!`)}</b></p>
                    }
                    <button style={styles.btnContinuar} onClick={avancar}>
                        CONTINUAR <i class="fas fa-chevron-right" style={styles.btnContinuarIcon}></i>
                    </button>
                </div>
            :
                <div>
                    <div style={styles.conteudo}>
                        {rodadaAtual.opcoes.map((opcao, key) => (
                            <div 
                                style={
                                    selecionadas.includes(key) ? 
                                    ((opcao.correta || rodadaAtual.qualquerOpcao) ?  styles.conteudoItemCorreto : styles.conteudoItemIncorreto) : 
                                    styles.conteudoItem
                                }
                                onClick={() => rodadaAtual.escrever ? selecionar(key, opcao) : responder(key, opcao)}
                            >
                                {config.midia === 'imagem' &&
                                    <img src={opcao.imagem} style={{width: 120, backgroundColor: opcao.cor}}/>
                                }
                                {config.midia === 'video' &&
                                    <video 
                                        autoPlay={true} 
                                        loop="true" 
                                        src={opcao.video}
                                        style={{width: 150}}
                                        muted
                                    />
                                }
                                {config.midia === 'cor' &&
                                    <div style={{width: 120, height: 120, backgroundColor: opcao.cor}}/>
                                }
                                {opcao.texto &&
                                    <b><p>{opcao.texto}</p></b>
                                }
                            </div>
                        ))}
                    </div>
                    {rodadaAtual.escrever &&
                        <div style={styles.conteudoFinal}>
                            <div style={{padding: 10, width: '100%'}}>
                                <input 
                                    ref={inputRef}
                                    style={{borderStyle: 'none', width: '100%'}}
                                    placeholder={rodadaAtual.escreverRotulo ? rodadaAtual.escreverRotulo : rodadaAtual.escreverRotulo}
                                    type="text"
                                    value={textoResposta}
                                    onChange={e => setTextoResposta(e.target.value)}
                                />
                            </div>
                            <button style={styles.btnContinuar} onClick={validarTexto}>
                                CONFIRMAR <i class="fas fa-chevron-right" style={styles.btnContinuarIcon}></i>
                            </button>
                        </div>
                    }
                    {rodadaAtual.livre &&
                        <div style={styles.conteudoFinal}>
                            <button style={styles.btnContinuar} onClick={() => setRespostaOk(true)}>
                                CONFIRMAR <i class="fas fa-chevron-right" style={styles.btnContinuarIcon}></i>
                            </button>    
                        </div> 
                    }       
                </div>
            }
            
        </div>
    )
    
    return(
        <div className="content">
            <div className="container">
                {rodadaAtual ?
                    <Fragment>
                        <div className="box-topo">
                            <div className="item-box-topo-1">
                                <p className="p-text">{rodadaAtual.enunciado}</p>
                            </div>
                            <div className="item-box-topo-2">
                                <a className="btn-repetir" onClick={() => iniciarRodada(1)}><i className="fas fa-undo-alt"></i> REPETIR</a>
                            </div>
                        </div>
                        {rodadaAtual.imagemEnunciado &&
                            rodadaAtual.tempo ? 
                        (
                            <div style={{textAlign: 'center', height: `${setTimeout(() => (setTime(true)), rodadaAtual.tempo * 1000)}`, display: time ? 'none' : null}}><img style={{height: rodadaAtual.imagemEnunciado ? 270 : null}} src={rodadaAtual.imagemEnunciado}/></div>
                        ) : 
                            <div style={{textAlign: 'center', }}><img style={{height: rodadaAtual.imagemEnunciado ? 270 : null}} src={rodadaAtual.imagemEnunciado}/></div>
                        }
                        {rodadaAtual.videoEnunciado &&
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    {
                                        rodadaAtual.videoEnunciadoMudo ?
                                        <video 
                                            autoPlay={true} 
                                            loop={true}
                                            src={rodadaAtual.videoEnunciado}
                                            style={{height: 270}}
                                            muted
                                        />
                                    :
                                        <video 
                                            autoPlay={true} 
                                            loop={true}
                                            src={rodadaAtual.videoEnunciado}
                                            style={{height: 270}}
                                       />
                                    }
                                </div>
                           
                        }
                        {respostaOk ?
                            <div className="tl1-box-2" style={{display: 'flex', flexDirection: 'column'}}>
                                {/* <div className="tl1-figura-correta" style={{display: 'flex', flexDirection: 'row',margin: '0px auto', height: 270, width: '100%', alignItems: 'center', justifyContent: 'center'}}>
                                    {config.midia === 'imagem' &&
                                        opcoesExibir.map((opcao, key) => 
                                            <div key={key} style={{background:  opcao.cor ? opcao.cor : undefined, width: '100%', height: '100%'}}><img src={opcao.imagem}/></div>)
                                        
                                    }
                                    {config.midia === 'video' &&
                                        <video 
                                            autoPlay={true} 
                                            loop="true" 
                                            src={respostaOk}
                                            style={{height: alturaImagem}}
                                        />
                                    }
                                </div> */}
                                <div className="tl1-figura-confirmacao" style={{height: 400}}>
                                    <div className="tl1-figura-correta-botao" style={{display: 'flex', flexDirection: 'column'}}>
                                        {textoConfirmacao !== '' ?
                                            <p className="p-text">{textoConfirmacao}</p>
                                        :
                                            <p className="p-text" style={{marginRight: 5}}>{config.textoRespostaOk ? config.textoRespostaOk : `CERTA RESPOSTA!`}</p>
                                        }                                        
                                        <a onClick={() => setTime(false)}><a className="btn-concluir" onClick={avancar}>CONTINUAR <i className="fas fa-chevron-right"></i> </a></a>
                                    </div>
                                </div>
                            </div>
                        :
                        <Fragment>
                            <div className={`tl1-box-2 ${classeColunas}`}>
                                {rodadaAtual.opcoes.map((opcao, key) => 
                                    <div 
                                        key = {key}
                                        className={
                                            (selecionadas.includes(key + 1)) ? 
                                                ((opcao.correta || rodadaAtual.qualquerOpcao) ? "tl1-figura-correta" : "tl1-figura-errada") 
                                                : 
                                                "tl1-figura-0"
                                            } 
                                        onClick={() => rodadaAtual.escrever ? selecionar(key + 1, opcao) : responder(key + 1, opcao)}
                                        style={{background:  opcao.cor ? opcao.cor : undefined}}
                                    >
                                        {config.midia === 'imagem' &&
                                            <img  src={opcao.imagem}/>
                                        }
                                        {config.midia === 'video' &&
                                            <video 
                                                autoPlay={true} 
                                                loop="true" 
                                                src={opcao.video}
                                                style={{height: alturaImagem}}
                                                muted
                                            />
                                        }
                                        {opcao.texto &&
                                            <p>{opcao.texto}</p>
                                        }
                                    </div>
                                )}                                
                            </div> 
                            {rodadaAtual.escrever && 
                                <div className="caixa-texto-confirmacao">
                                    <div className="caixa-texto-confirmcao-input">
                                        <input 
                                            style={{borderStyle: 'none'}}
                                            className="p-text-input"
                                            placeholder={rodadaAtual.escreverRotulo ? rodadaAtual.escreverRotulo : rodadaAtual.escreverRotulo}
                                            type="text"
                                            value={textoResposta}
                                            onChange={e => setTextoResposta(e.target.value)}
                                        />
                                    </div>
                                    <div className="caixa-texto-btn-confirmar">
                                        <a className="btn-concluir" onClick={validarTexto}>CONFIRMAR <i className="fas fa-chevron-right"></i></a>
                                    </div>
                                </div>  
                            }
                            {rodadaAtual.livre &&
                                <div className="caixa-texto-btn-confirmar">
                                    <a className="btn-concluir" onClick={() => setRespostaOk(true)}>CONFIRMAR <i className="fas fa-chevron-right"></i></a>
                                </div>       
                            }                                            
                        </Fragment>                             
                        }                    
                    </Fragment>
                :
                    <h1>Carregando</h1>
                }
                
            </div>
        </div>
    );
}

export default MultiplaEscolha;

const Texto = ({rodadaAtual, responder, avancar, confirmacao, selecionadas}) => {
    const [time, setTime] = useState(false);

    return(
        <div className="content">
            <div className="container">
                <div className="box-topo">
                    <div className="item-box-topo-1">
                        <p className="p-text">{rodadaAtual.enunciado}</p>
                    </div>
                    <div className="item-box-topo-2">
                        <a className="btn-repetir"><i className="fas fa-undo-alt"></i> REPETIR</a>
                    </div>
                </div>
                {rodadaAtual.imagemEnunciado &&        
                    (
                        rodadaAtual.tempo ? 
                        (
                            <div style={{textAlign: 'center', height: `${setTimeout(() => (setTime(true)), rodadaAtual.tempo * 1000)}`, display: time ? 'none' : null}}><img style={{height: rodadaAtual.imagemEnunciado ? 270 : null}} src={rodadaAtual.imagemEnunciado}/></div>
                        ) : 
                        <div style={{textAlign: 'center', }}><img style={{height: rodadaAtual.imagemEnunciado ? 270 : null}} src={rodadaAtual.imagemEnunciado}/></div>
                    )
                } 
                {rodadaAtual.videoEnunciado &&
                    <div style={{textAlign: 'center'}}>
                        <video 
                            autoPlay={true} 
                            loop={true}
                            muted={true}
                            src={rodadaAtual.videoEnunciado}
                            style={{height: 270}}
                        />
                    </div>
                }
                <div className="tl3-box-2">
                    {confirmacao ?
                        <div>
                            {rodadaAtual.opcoes.filter(opcao => opcao.correta).map((opcao, key) => 
                                <div key={key} className="tl3-figura-0">
                                    <p className="tl3-text-fig">{opcao.texto}</p>
                                </div>
                            )}
                            
                            <div className="tl3-figura-confirmacao">
                                <div>
                                    <p className="p-text">CERTA RESPOSTA!</p>                              
                                    <a onClick={() => setTime(false)}><a className="btn-concluir" onClick={avancar}>CONTINUAR <i className="fas fa-chevron-right"></i> </a></a>
                                </div>
                            </div>
                        </div>                            
                    :
                        rodadaAtual.opcoes.map((opcao, key) =>
                            <div 
                                key={key} 
                                className={
                                    (selecionadas.includes(key)) ? 
                                        ((opcao.correta || rodadaAtual.qualquerOpcao) ? "tl2-figura-correta" : "tl2-figura-errada") 
                                        : 
                                        "tl1-figura-1"
                                    } 
                                onClick={() => responder(key, opcao)}
                            >
                                <p className="tl3-text-fig-0">{opcao.texto}</p>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

const styles = {
    container: {
        backgroundColor: 'white', 
        margin: 5, 
        borderRadius: 15, 
        padding: 5
    },
    cabecalho: {
        backgroundColor: 'RGB(0,177,181)', 
        margin: 5, 
        borderRadius: 15, 
        padding: 5,
        color: 'white',
        fontWeight: 'bolder',
        display: 'flex',
        justifyContent: 'space-between'
    },
    conteudo: {
        padding: 5,
        display: 'flex',
        justifyContent: 'center',
        alignItens: 'center',
        flexWrap: 'wrap',
        borderRadius: 15,
        backgroundColor: 'RGB(193,239,226)',
        margin: 5,
        marginTop: 10
    },
    conteudoFinal: {
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        textAlign: 'center',
        alignItens: 'center',
        flexWrap: 'wrap',
        borderRadius: 15,
        backgroundColor: 'RGB(193,239,226)',
        margin: 5,
        marginTop: 10,
        padding: 10
    },
    conteudoItem: {
        marginTop: 5,
        marginLeft: 5,
        marginRitht: 5,
        marginBottom: 5,
        borderRadius: 15,
        backgroundColor: 'RGB(255,206,0)',
        padding: 10,
        textAlign: 'center'
    },
    conteudoItemCorreto: {
        margin: 5,
        borderRadius: 15,
        backgroundColor: 'RGB(107, 255, 107)',
        padding: 10,
        textAlign: 'center'
    },
    conteudoItemIncorreto: {
        margin: 5,
        borderRadius: 15,
        backgroundColor: 'RGB(255, 107, 107)',
        padding: 10,
        textAlign: 'center'
    },
    btnRepetir: {
        backgroundColor: 'RGB(71, 226, 230)',
        border: 'none',
        color: 'white',
        borderRadius: 15
    },
    btnRepetirIcon: {
        color: 'RGB(71, 226, 230)',
        marginBottom: 5
    },
    btnContinuar: {
        backgroundColor: 'RGB(0, 144, 245)',
        border: 'none',
        color: 'white',
        borderRadius: 15,
        maxHeight: 60,
        padding: 10
    },
    btnContinuarIcon: {
        color: 'RGB(0, 144, 245)',
        marginBottom: 5
    }
}