import React, { useContext, useEffect, useRef, useState } from "react";
import api from "../../services/api";
import ijws from "../../services/ijws";
import Helmet from "react-helmet";
import { getQueryParams } from "../../helpers";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Grid,
  Radio,
  SvgIcon,
} from "@mui/material";
import AppContext from "../../container/context";
import CartaoCredito from "./CartaoCredito";
import PixIcon from "@mui/icons-material/Pix";
import PaymentIcon from "@mui/icons-material/Payment";
import cod from "../../assets/imagens/cod.png";
import Pix from "./Pix";
import "./stylesPagamento.css";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const Pagamento = ({
  location,
  usuarioId,
  usuarioEmail,
  continuar,
  desc,
  identificadorPlano,
  identificadorPromocao,
}) => {
  const { notification } = useContext(AppContext);
  const [planos, setPlanos] = useState([]);
  const [planoSelecionado, setPlanoSelecionado] = useState(0);
  // const [qtdParcelasSelecionada, setQtdParcelasSelecionada] = useState(1);
  const [opcoesParcelas, setOpcoesParcelas] = useState([]);
  // const [idContaIugu, setIdContaIugu] = useState("");
  const [carregandoParametros, setCarregandoParametros] = useState(true);
  const [processandoCompra, setProcessandoCompra] = useState(false);
  const [msgErro, setMsgErro] = useState("");
  const [usuario, setUsuario] = useState("");
  // const [promocao, setPromocao] = useState({});
  // const [codPromocional, setCodPromocional] = useState("");
  // const [inserirCodigo, setInserirCodigo] = useState(false);
  const [queryParams] = useState(
    location ? getQueryParams(location.search) : []
  );
  const [modoTeste, setModoTeste] = useState(false);
  const [modalidadePagamento, setModalidadePagamento] = useState(2);
  const [bloqueio, setBloqueio] = useState(false);
  const [dadosAssinatura, setDadosAssinatura] = useState({});
  const myDivRef = useRef(null);

  useEffect(() => {
    carregar();
    listarPlanos();

    if (queryParams["p"]) {
      // carregarPromocao(queryParams["p"]);
    }

    const parametros = getQueryParams(window.location.search);
    console.log("parametros", parametros);
    if (parametros.teste) setModoTeste(true);
  }, []);

  const carregar = () => {
    api
      .post("usuarios/assinatura", { id: usuarioId })
      .then((usuario) => {
        setUsuario(usuario);

        // setCartao({
        //   numero: "",
        //   titularNome: "",
        //   codigoVerificador: "",
        //   expiracao: "",
        // });

        // if (usuario.promocaoChave) {
        //   const promocao = {
        //     chave: usuario.promocaoChave,
        //     descricao: usuario.promocaoDescricao,
        //   };

        //   setPromocao(promocao);
        // }

        // const idContaIugu = usuario.iuguContaId
        //   ? cryptoJs.AES.decrypt(
        //       usuario.iuguContaId,
        //       "2020@perto@apps@2020"
        //     ).toString(cryptoJs.enc.Utf8)
        //   : "";

        // setIdContaIugu(idContaIugu);
        setCarregandoParametros(false);
      })
      .catch((e) => console.log(e));
  };

  const listarPlanos = () => {
    ijws
      .get("assinaturas/listarPlanos")
      .then((planos) => {
        console.log("planos", planos);
        setPlanos(planos);

        if (identificadorPlano) {
          const planoLocalizado = planos.filter(
            (p) => p.identificador === identificadorPlano
          );

          if (planoLocalizado.length > 0) selecionarPlano(planoLocalizado[0]);
        } else if (planos.length === 1) {
          selecionarPlano(planos[0]);
        }
      })
      .catch((e) =>
        notification(
          "error",
          "Não foi possível listar os planos disponíveis. Verifique sua conexão e tente novamente"
        )
      );
  };

  const selecionarPlano = (plano) => {
    setPlanoSelecionado(plano);
    if (plano.qtdParcelas > 1) {
      const opcoesPareclasPlano = montarOpcoesParcelamento(
        plano.valorCentavos / 100,
        plano.qtdParcelas
      );
      console.log("opcoesPareclasPlano", opcoesPareclasPlano);
      setOpcoesParcelas(opcoesPareclasPlano);
      // setQtdParcelasSelecionada(plano.qtdParcelas);
    } else {
      setOpcoesParcelas([]);
      // setQtdParcelasSelecionada(1);
    }
  };

  // const carregarPromocao = (chave) => {
  //   ijws
  //     .get("assinaturas/carregarPromocao", { chave })
  //     .then((promocao) => {
  //       if (promocao.valida) {
  //         setInserirCodigo(false);
  //         setPromocao(promocao);

  //         if (promocao.qtdParcelas > 1) {
  //           const opcoesPareclasPlano = montarOpcoesParcelamento(
  //             promocao.valor,
  //             promocao.qtdParcelas
  //           );
  //           setOpcoesParcelas(opcoesPareclasPlano);
  //           setQtdParcelasSelecionada(0);
  //         } else {
  //           setOpcoesParcelas([]);
  //           setQtdParcelasSelecionada(1);
  //         }

  //         return notification(
  //           "error",
  //           "Código promocional validado com sucesso!"
  //         );
  //       }

  //       notification("error", "Código promocional inválido.");
  //     })
  //     .catch((e) => console.log("Erro ao carregar a promoção: " + e));
  // };

  const inserirAssinatura = (qtdParcelasSelecionada) => {
    setProcessandoCompra(true);
    setBloqueio(true);
    console.log("qtdParcelasSelecionada", qtdParcelasSelecionada);
    console.log("modoTeste", modoTeste);
    console.log("modalidadePagamento", modalidadePagamento);
    console.log("planoSelecionado.id", planoSelecionado.id);
    ijws
      .post("pagamentos/inserirAssinatura", {
        teste: modoTeste,
        planoId: planoSelecionado.id,
        modalidadePagamento: modalidadePagamento,
        qtdParcelas: qtdParcelasSelecionada,
      })
      .then((res) => {
        console.log("assinatura", res);
        setProcessandoCompra(false);
        setDadosAssinatura(res);

        if (res.assinaturaAtiva) {
          continuar();
        }
        // else if (modalidadePagamento === 2) {
        //   setMsgErro(
        //     "Não foi possível processar o pagamento. Verique os dados e tente novamente."
        //   );
        // }
      })
      .catch((e) => {
        const errCodes = e.data;

        if (errCodes) {
          if (errCodes.includes(1)) {
            return setMsgErro("Plano inválido");
          }
          if (errCodes.includes(2)) {
            return setMsgErro("Plano fora do período de adesão");
          }
          if (errCodes.includes(3)) {
            return setMsgErro("Usuário já com assinatura ativa");
          }
          if (errCodes.includes(4)) {
            return setMsgErro("Forma de pagamento inválida");
          }
          if (errCodes.includes(5)) {
            return setMsgErro("Quantidade de parcelas inválida");
          }
        }

        setMsgErro(
          "Não foi possível processar o pagamento. Verique os dados e tente novamente."
        );
        setProcessandoCompra(false);
        console.log(e);
      });
  };

  const baixarBoleto = () => {
    const url = dadosAssinatura.boletoPDF;
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.download = "boleto.pdf";
    link.click();
  };

  const copiarCodigo = () => {
    navigator.clipboard
      .writeText(dadosAssinatura.boletoLinhaDigitavel)
      .then(() => {
        return notification(
          "success",
          "Código copiado para a área de transferência"
        );
      })
      .catch(() => {
        return notification("error", "Erro ao copiar codigo");
      });
  };

  return (
    <>
      {window.innerWidth > 800 ? (
        <div style={{ margin: 10 }}>
          <Helmet>
            <script
              type="text/javascript"
              src="https://js.iugu.com/v2"
            ></script>
          </Helmet>
          <div className="pagina-login">
            <div className="container-login">
              {desc ? (
                <p>{desc}</p>
              ) : (
                <img
                  alt="Logo da Inglês Juntos"
                  className="logo-login"
                  src={require("../../assets/imagens/logo.png")}
                />
              )}

              {usuarioEmail && (
                <div
                  style={{
                    backgroundColor: "#f9cd8d",
                    borderRadius: 25,
                    padding: 5,
                  }}
                >
                  <h3>Já é assinante?</h3>
                  <span>
                    Verifique se está acessando com o email utilizado na conta
                    com a assinatura.
                  </span>
                  <br />
                  <span>
                    Você está acessando com o email: <b>{usuarioEmail}</b>
                  </span>
                  <br />
                </div>
              )}

              <h1 style={{ fontSize: 25 }}>
                {usuario.assinaturaIdentificador
                  ? "Alterar meio de pagamento"
                  : "Finalize sua assinatura para ter acesso às videoaulas, músicas, atividades e muito mais!"}
              </h1>

              {carregandoParametros ? (
                <CircularProgress />
              ) : (
                <div>
                  <div>
                    <div className="form-login">
                      <div>
                        {planos.length > 0 ? (
                          <Box
                            sx={{
                              flexGrow: 1,
                              display: "flex",
                              flexWrap: "wrap",
                              justifyContent: "center",
                            }}
                          >
                            {planos.map((plano) => (
                              <Card
                                sx={{
                                  minWidth: 275,
                                  width: 300,
                                  margin: 2,
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "space-between",
                                }}
                              >
                                <CardContent sx={{ flexGrow: 1 }}>
                                  <Grid container>
                                    <Grid item xs={12} md={12}>
                                      <h1 style={{ fontSize: 20 }}>
                                        {plano.nome}
                                      </h1>
                                      <p>{plano.descricao}</p>
                                      {plano.modalideAssinatura === 1 ? (
                                        <p>
                                          R${" "}
                                          {(plano.valorCentavos / 100).toFixed(
                                            2
                                          )}
                                          /mês
                                        </p>
                                      ) : (
                                        <p>
                                          {plano.qtdParcelas}x R${" "}
                                          {(
                                            plano.valorCentavos /
                                            100 /
                                            plano.qtdParcelas
                                          ).toFixed(2)}
                                        </p>
                                      )}
                                    </Grid>
                                  </Grid>
                                </CardContent>
                                <Box sx={{ padding: 2 }}>
                                  <Button
                                    onClick={() => {
                                      selecionarPlano(plano);
                                      setTimeout(() => {
                                        if (myDivRef.current) {
                                          myDivRef.current.scrollIntoView({
                                            behavior: "smooth",
                                          });
                                        }
                                      }, 500);
                                    }}
                                    size="small"
                                  >
                                    Selecionar
                                  </Button>
                                </Box>
                              </Card>
                            ))}
                          </Box>
                        ) : (
                          <div>
                            <h6
                              style={{
                                color: "red",
                                marginLeft: 30,
                                marginRight: 30,
                              }}
                            >
                              {
                                "Não há planos disponíveis para adesão na data atual."
                              }
                            </h6>
                          </div>
                        )}
                        {planoSelecionado.id && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Card
                              sx={{
                                minWidth: 275,
                                width: 300,
                                margin: 2,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                              }}
                            >
                              <CardContent sx={{ flexGrow: 1 }}>
                                <Grid container>
                                  <Grid item xs={12} md={12}>
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        color: "#0894CE",
                                      }}
                                    >
                                      Plano selecionado
                                    </p>
                                    <h1 style={{ fontSize: 20 }}>
                                      {planoSelecionado.nome}
                                    </h1>
                                    <p>{planoSelecionado.descricao}</p>
                                    {planoSelecionado.modalideAssinatura ===
                                    1 ? (
                                      <p>
                                        R${" "}
                                        {(
                                          planoSelecionado.valorCentavos / 100
                                        ).toFixed(2)}
                                        /mês
                                      </p>
                                    ) : (
                                      <p>
                                        {planoSelecionado.qtdParcelas}x R${" "}
                                        {(
                                          planoSelecionado.valorCentavos /
                                          100 /
                                          planoSelecionado.qtdParcelas
                                        ).toFixed(2)}
                                      </p>
                                    )}
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </Card>
                          </div>
                        )}
                      </div>
                      <div ref={myDivRef}>
                        {planoSelecionado.modalideAssinatura === 1 && (
                          <CartaoCredito
                            opcoesParcelas={opcoesParcelas}
                            processandoCompra={processandoCompra}
                            processarCompra={(param) =>
                              setProcessandoCompra(param)
                            }
                            inserirAssinatura={inserirAssinatura}
                            modoTeste={modoTeste}
                          />
                        )}

                        {planoSelecionado.modalideAssinatura === 2 && (
                          <Grid container>
                            <Grid
                              item
                              md={4}
                              xs={window.innerWidth < 800 ? 12 : 4}
                              style={{
                                display: "flex",
                                justifyContent:
                                  window.innerWidth > 800 ? "center" : "start",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Radio
                                  disabled={bloqueio}
                                  checked={modalidadePagamento === 2}
                                  onChange={() => setModalidadePagamento(2)}
                                  value={2}
                                  name="radio-buttons"
                                  inputProps={{ "aria-label": "A" }}
                                />
                                <PaymentIcon />
                                <span
                                  style={{ fontSize: "0.8rem", paddingLeft: 5 }}
                                >
                                  Cartão de crédito
                                </span>
                              </div>
                            </Grid>
                            <Grid
                              item
                              md={4}
                              xs={window.innerWidth < 800 ? 12 : 4}
                              style={{
                                display: "flex",
                                justifyContent:
                                  window.innerWidth > 800 ? "center" : "start",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Radio
                                  disabled={bloqueio}
                                  checked={modalidadePagamento === 1}
                                  onChange={() => setModalidadePagamento(1)}
                                  value={1}
                                  name="radio-buttons"
                                  inputProps={{ "aria-label": "A" }}
                                />

                                <BarcodeIcon />
                                <span
                                  style={{ fontSize: "0.8rem", paddingLeft: 5 }}
                                >
                                  Boleto bancário
                                </span>
                              </div>
                            </Grid>
                            <Grid
                              item
                              md={4}
                              xs={window.innerWidth < 800 ? 12 : 4}
                              style={{
                                display: "flex",
                                justifyContent:
                                  window.innerWidth > 800 ? "center" : "start",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Radio
                                  disabled={bloqueio}
                                  checked={modalidadePagamento === 3}
                                  onChange={() => setModalidadePagamento(3)}
                                  value={3}
                                  name="radio-buttons"
                                  inputProps={{ "aria-label": "A" }}
                                />

                                <PixIcon />
                                <span
                                  style={{ fontSize: "0.8rem", paddingLeft: 5 }}
                                >
                                  Pix
                                </span>
                              </div>
                            </Grid>

                            <Grid item md={12} xs={12}>
                              {modalidadePagamento === 2 && (
                                <CartaoCredito
                                  opcoesParcelas={opcoesParcelas}
                                  processandoCompra={processandoCompra}
                                  processarCompra={(param) =>
                                    setProcessandoCompra(param)
                                  }
                                  inserirAssinatura={inserirAssinatura}
                                  modoTeste={modoTeste}
                                />
                              )}
                              {modalidadePagamento === 1 && (
                                <>
                                  {processandoCompra ? (
                                    <CircularProgress />
                                  ) : (
                                    <>
                                      {!dadosAssinatura.boletoLinhaDigitavel ? (
                                        <input
                                          style={{ marginTop: 20 }}
                                          type="submit"
                                          className="btn-padrao-lp"
                                          value="Gerar boleto"
                                          onClick={() => inserirAssinatura(1)}
                                        />
                                      ) : (
                                        <div>
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <div
                                              style={{
                                                background: "#efe3b0",
                                                // width: "50%",
                                                padding: 10,
                                                wordWrap: "break-word",
                                                overflowWrap: "break-word",
                                                whiteSpace: "pre-wrap",
                                                overflow: "hidden",
                                                textAlign: "center",
                                              }}
                                            >
                                              {
                                                dadosAssinatura.boletoLinhaDigitavel
                                              }
                                            </div>
                                            <div style={{ marginTop: 10 }}>
                                              <Button
                                                id="myInput"
                                                color="info"
                                                variant="text"
                                                title="Copiar código pix"
                                                onClick={copiarCodigo}
                                              >
                                                <ContentCopyIcon />
                                                copiar código
                                              </Button>
                                            </div>
                                          </div>
                                          <input
                                            style={{ marginTop: 20 }}
                                            type="submit"
                                            className="btn-padrao-lp"
                                            value="Visualizar boleto"
                                            onClick={baixarBoleto}
                                          />
                                        </div>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                              {modalidadePagamento === 3 && (
                                <>
                                  {processandoCompra ? (
                                    <CircularProgress />
                                  ) : (
                                    <>
                                      {!dadosAssinatura.PIXQRCode ? (
                                        <input
                                          style={{ marginTop: 20 }}
                                          type="submit"
                                          className="btn-padrao-lp"
                                          value="Gerar QR Code/Código copia e cola"
                                          onClick={() => inserirAssinatura(1)}
                                        />
                                      ) : (
                                        <div>
                                          <Pix
                                            dadosAssinatura={dadosAssinatura}
                                            continuar={continuar}
                                          />
                                        </div>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </Grid>
                          </Grid>
                        )}
                      </div>
                    </div>
                    {msgErro !== "" && (
                      <h2 style={{ color: "red" }}>{msgErro}</h2>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div style={{ textAlign: "center" }}>
          <Helmet>
            <script
              type="text/javascript"
              src="https://js.iugu.com/v2"
            ></script>
          </Helmet>
          <div
          // className="pagina-login"
          >
            <div className="container-pagamento">
              {desc ? (
                <p>{desc}</p>
              ) : (
                <img
                  alt="Logo da Inglês Juntos"
                  className="logo-login"
                  src={require("../../assets/imagens/logo.png")}
                />
              )}

              {usuarioEmail && (
                <div
                  style={{
                    backgroundColor: "#f9cd8d",
                    borderRadius: 25,
                    padding: 5,
                  }}
                >
                  <h3>Já é assinante?</h3>
                  <span>
                    Verifique se está acessando com o email utilizado na conta
                    com a assinatura.
                  </span>
                  <br />
                  <span>
                    Você está acessando com o email: <b>{usuarioEmail}</b>
                  </span>
                  <br />
                </div>
              )}

              <h1 style={{ fontSize: 25 }}>
                {usuario.assinaturaIdentificador
                  ? "Alterar meio de pagamento"
                  : "Finalize sua assinatura para ter acesso às videoaulas, músicas, atividades e muito mais!"}
              </h1>

              {carregandoParametros ? (
                <CircularProgress />
              ) : (
                <div>
                  <div>
                    <div className="form-pagamento">
                      <div>
                        {planos.length > 0 ? (
                          <Box
                            sx={{
                              flexGrow: 1,
                              display: "flex",
                              flexWrap: "wrap",
                              justifyContent: "center",
                            }}
                          >
                            {planos.map((plano) => (
                              <Card
                                sx={{
                                  minWidth: 275,
                                  width: "100%",
                                  margin: 2,
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "space-between",
                                }}
                              >
                                <CardContent sx={{ flexGrow: 1 }}>
                                  <Grid container>
                                    <Grid item xs={12} md={12}>
                                      <h1 style={{ fontSize: 20 }}>
                                        {plano.nome}
                                      </h1>
                                      <p>{plano.descricao}</p>
                                      {plano.modalideAssinatura === 1 ? (
                                        <p>
                                          R${" "}
                                          {(plano.valorCentavos / 100).toFixed(
                                            2
                                          )}
                                          /mês
                                        </p>
                                      ) : (
                                        <p>
                                          {plano.qtdParcelas}x R${" "}
                                          {(
                                            plano.valorCentavos /
                                            100 /
                                            plano.qtdParcelas
                                          ).toFixed(2)}
                                        </p>
                                      )}
                                    </Grid>
                                  </Grid>
                                </CardContent>
                                <Box sx={{ padding: 2 }}>
                                  <Button
                                    onClick={() => {
                                      selecionarPlano(plano);
                                      setTimeout(() => {
                                        if (myDivRef.current) {
                                          myDivRef.current.scrollIntoView({
                                            behavior: "smooth",
                                          });
                                        }
                                      }, 500);
                                    }}
                                    size="small"
                                  >
                                    Selecionar
                                  </Button>
                                </Box>
                              </Card>
                            ))}
                          </Box>
                        ) : (
                          <div>
                            <h6
                              style={{
                                color: "red",
                                marginLeft: 30,
                                marginRight: 30,
                              }}
                            >
                              {
                                "Não há planos disponíveis para adesão na data atual."
                              }
                            </h6>
                          </div>
                        )}
                        {planoSelecionado.id && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Card
                              sx={{
                                minWidth: 275,
                                width: "100%",
                                margin: 2,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                              }}
                            >
                              <CardContent sx={{ flexGrow: 1 }}>
                                <Grid container>
                                  <Grid item xs={12} md={12}>
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        color: "#0894CE",
                                      }}
                                    >
                                      Plano selecionado
                                    </p>
                                    <h1 style={{ fontSize: 20 }}>
                                      {planoSelecionado.nome}
                                    </h1>
                                    <p>{planoSelecionado.descricao}</p>
                                    {planoSelecionado.modalideAssinatura ===
                                    1 ? (
                                      <p>
                                        R${" "}
                                        {(
                                          planoSelecionado.valorCentavos / 100
                                        ).toFixed(2)}
                                        /mês
                                      </p>
                                    ) : (
                                      <p>
                                        {planoSelecionado.qtdParcelas}x R${" "}
                                        {(
                                          planoSelecionado.valorCentavos /
                                          100 /
                                          planoSelecionado.qtdParcelas
                                        ).toFixed(2)}
                                      </p>
                                    )}
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </Card>
                          </div>
                        )}
                      </div>

                      <div ref={myDivRef}>
                        {planoSelecionado.modalideAssinatura === 1 && (
                          <CartaoCredito
                            opcoesParcelas={opcoesParcelas}
                            processandoCompra={processandoCompra}
                            processarCompra={(param) =>
                              setProcessandoCompra(param)
                            }
                            inserirAssinatura={inserirAssinatura}
                            modoTeste={modoTeste}
                          />
                        )}
                        {planoSelecionado.modalideAssinatura === 2 && (
                          <Grid container>
                            <Grid
                              item
                              md={4}
                              xs={window.innerWidth < 800 ? 12 : 4}
                              style={{
                                display: "flex",
                                justifyContent:
                                  window.innerWidth > 800 ? "center" : "start",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Radio
                                  disabled={bloqueio}
                                  checked={modalidadePagamento === 2}
                                  onChange={() => setModalidadePagamento(2)}
                                  value={2}
                                  name="radio-buttons"
                                  inputProps={{ "aria-label": "A" }}
                                />
                                <PaymentIcon />
                                <span
                                  style={{ fontSize: "0.8rem", paddingLeft: 5 }}
                                >
                                  Cartão de crédito
                                </span>
                              </div>
                            </Grid>

                            <Grid
                              item
                              md={4}
                              xs={window.innerWidth < 800 ? 12 : 4}
                              style={{
                                display: "flex",
                                justifyContent:
                                  window.innerWidth > 800 ? "center" : "start",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Radio
                                  disabled={bloqueio}
                                  checked={modalidadePagamento === 3}
                                  onChange={() => setModalidadePagamento(3)}
                                  value={3}
                                  name="radio-buttons"
                                  inputProps={{ "aria-label": "A" }}
                                />

                                <PixIcon />
                                <span
                                  style={{ fontSize: "0.8rem", paddingLeft: 5 }}
                                >
                                  Pix
                                </span>
                              </div>
                            </Grid>
                            <Grid
                              item
                              md={4}
                              xs={window.innerWidth < 800 ? 12 : 4}
                              style={{
                                display: "flex",
                                justifyContent:
                                  window.innerWidth > 800 ? "center" : "start",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Radio
                                  disabled={bloqueio}
                                  checked={modalidadePagamento === 1}
                                  onChange={() => setModalidadePagamento(1)}
                                  value={1}
                                  name="radio-buttons"
                                  inputProps={{ "aria-label": "A" }}
                                />

                                <BarcodeIcon />
                                <span
                                  style={{ fontSize: "0.8rem", paddingLeft: 5 }}
                                >
                                  Boleto bancário
                                </span>
                              </div>
                            </Grid>
                            <Grid item md={12} xs={12}>
                              {modalidadePagamento === 2 && (
                                <CartaoCredito
                                  opcoesParcelas={opcoesParcelas}
                                  processandoCompra={processandoCompra}
                                  processarCompra={(param) =>
                                    setProcessandoCompra(param)
                                  }
                                  inserirAssinatura={inserirAssinatura}
                                  modoTeste={modoTeste}
                                />
                              )}
                              {modalidadePagamento === 1 && (
                                <>
                                  {processandoCompra ? (
                                    <CircularProgress />
                                  ) : (
                                    <>
                                      {!dadosAssinatura.boletoLinhaDigitavel ? (
                                        <input
                                          style={{ marginTop: 20 }}
                                          type="submit"
                                          className="btn-padrao-lp"
                                          value="Gerar boleto"
                                          onClick={() => inserirAssinatura(1)}
                                        />
                                      ) : (
                                        <div>
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <div
                                              style={{
                                                background: "#efe3b0",
                                                // width: "50%",
                                                padding: 10,
                                                wordWrap: "break-word",
                                                overflowWrap: "break-word",
                                                whiteSpace: "pre-wrap",
                                                overflow: "hidden",
                                                textAlign: "center",
                                              }}
                                            >
                                              {
                                                dadosAssinatura.boletoLinhaDigitavel
                                              }
                                            </div>

                                            <div style={{ marginTop: 10 }}>
                                              <Button
                                                id="myInput"
                                                color="info"
                                                variant="text"
                                                title="Copiar código pix"
                                                onClick={copiarCodigo}
                                              >
                                                <ContentCopyIcon />
                                                copiar código
                                              </Button>
                                            </div>
                                          </div>
                                          <input
                                            style={{ marginTop: 20 }}
                                            type="submit"
                                            className="btn-padrao-lp"
                                            value="Visualizar boleto"
                                            onClick={baixarBoleto}
                                          />
                                        </div>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                              {modalidadePagamento === 3 && (
                                <>
                                  {processandoCompra ? (
                                    <CircularProgress />
                                  ) : (
                                    <>
                                      {!dadosAssinatura.PIXQRCode ? (
                                        <input
                                          style={{
                                            marginTop: 20,
                                            fontSize: 18,
                                          }}
                                          type="submit"
                                          className="btn-padrao-lp"
                                          value="Gerar QRCode/código copia e cola"
                                          onClick={() => inserirAssinatura(1)}
                                        />
                                      ) : (
                                        <div>
                                          <Pix
                                            dadosAssinatura={dadosAssinatura}
                                            continuar={continuar}
                                          />
                                        </div>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </Grid>
                          </Grid>
                        )}
                      </div>
                    </div>
                    {msgErro !== "" && (
                      <h2 style={{ color: "red" }}>{msgErro}</h2>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Pagamento;

const montarOpcoesParcelamento = (valor, qtdParcelas = 1) => {
  const opcoesParcelas = [];

  for (var i = 1; i <= qtdParcelas; i++) {
    opcoesParcelas.push({
      qtd: i,
      desc: `${i}x de R$${(valor / i).toFixed(2)} sem juros`,
    });
  }

  return opcoesParcelas;
};

const BarcodeIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 24 24"
      style={{
        width: "1em",
        height: "1em",
        background: `url(${cod}) no-repeat center center`,
        backgroundSize: "contain",
      }}
    />
  );
};
