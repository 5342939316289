module.exports = {
  getQueryParams(query = "") {
    if (query === "") return {};

    let params = {};

    query
      .replace("?", "")
      .split("&")
      .forEach((p) => (params[p.split("=")[0]] = p.split("=")[1]));

    return params;
  },

  validarCPF(cpf = "") {
    let soma = 0;
    let resto = 0;

    if (cpf === "" || cpf === "00000000000") return false;

    for (var i = 1; i <= 9; i++)
      soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);

    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) resto = 0;

    if (resto !== parseInt(cpf.substring(9, 10))) return false;

    soma = 0;

    for (i = 1; i <= 10; i++)
      soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);

    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) resto = 0;

    if (resto !== parseInt(cpf.substring(10, 11))) return false;

    return true;
  },

  formatDateTime(isoDateTime = "", model) {
    let dateTimeContent = [];

    if (isoDateTime.includes("T")) {
      dateTimeContent = isoDateTime.split("T");
    } else {
      dateTimeContent = isoDateTime.split(" ");
    }

    const date = dateTimeContent[0];
    const time = dateTimeContent[1];

    let year = "",
      month = "",
      day = "",
      hours = "",
      minutes = "",
      seconds = "";

    if (date) {
      const dateContent = date.split("-");

      year = dateContent[0];
      month = dateContent[1];
      day = dateContent[2];
    }

    if (time) {
      const timeContent = time.split(":");

      hours = timeContent[0];
      minutes = timeContent[1];
      seconds = timeContent[2];
    }

    return model
      .replace("Y", year)
      .replace("M", month)
      .replace("D", day)
      .replace("h", hours)
      .replace("m", minutes)
      .replace("s", seconds);
  },
};
