import styled from 'styled-components';

export const BoxTopo = styled.div`
  grid-template-columns: 700px auto;
  display: grid;
  margin: 20px auto;
  padding: 15px;
  max-width: 970px;
  border-radius: 15px;
  background: #75dfeb;
  box-shadow: 8px 7px 10px -4px rgba(0, 0, 0, 0.14);

  @media (max-width: 980px) {
    grid-template-columns: auto;
    width: 86%;
  }
`;

export const ItemBox1 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;

  @media (max-width: 980px) {
    justify-content: center;
    text-align: center;
  }
`;

export const Text = styled.p`
  font-size: 26px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: #fff;

  @media (max-width: 980px) {
    p {
      margin-right: 0%;
      font-size: 25px;
    }
  }
`;

export const ItemBox2 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 980px) {
    justify-content: center;
  }
`;

export const Button = styled.a`
  padding: 18px;
  font-size: 25px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: #fff;
  width: 180px;
  border-radius: 15px;
  background: #91dbe4;
  box-shadow: 3px 7px 13px -7px rgba(0, 0, 0, 0.3);
  cursor: pointer;

  :hover {
    box-shadow: inset 0px 0px 5px 6px rgba(0, 0, 0, 0.04);
  }

  @media (max-width: 980px) {
    padding: 10px;
    font-size: 16px;
    width: 110px;
  }
`;

export const Tl8Box2 = styled.div`
  background: rgb(255, 255, 255);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (max-width: 980px) {
    grid-template-columns: auto auto auto auto;
    width: 92%;
    height: 500px;
  }
`;

export const BoxConfirmation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: grid;
  width: 524px;
  height: 524px;
  text-align: center;
  background: rgb(3, 255, 255, 0.8);
  border-radius: 15px;
`;

export const BtnConcluir = styled.a`
  padding: 18px;
  font-size: 25px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: #fff;
  min-width: 180px;
  border-radius: 15px;
  background: #5be389;
  box-shadow: 3px 7px 13px -7px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  text-decoration: none !important;
`;

export const BoxImageBG = styled.div`
  grid-template-columns: auto auto auto auto;
  display: grid;
  margin: 20px auto;
  background-repeat: no-repeat;
  background-size: contain;
`;

export const TextoConfirmacao = styled.div`
  padding: 15px;
  grid-gap: 20px;
  grid-template-columns: 650px auto;
  display: flex;
  justify-content: center;
  margin: 20px auto;
  max-width: 970px;
  height: 80px;
  border-radius: 15px;
  background: #e9bb3f;
`;

export const TextoInput = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 15px;
  align-items: center;
  text-align: center;
  border-radius: 15px;
  background: #ffffff;

  min-width: 300px;

  width: 100%;

  @media (max-width: 980px) {
    min-width: 250px;
  }

  input {
    font-size: 26px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    width: 100%;
    border: none;

    :focus {
      outline: none;
    }
  }
`;

export const TextoDica = styled.div`
  padding: 15px;
  grid-gap: 20px;
  grid-template-columns: 650px auto;
  display: flex;
  justify-content: center;
  margin: 20px auto;
  /* max-width: 970px; */
  width: 30%;
  height: 80px;
  border-radius: 15px;
  background: blue;

  p {
    font-size: 26px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
    text-transform: uppercase;
  }
`;

export const TextoInput2 = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 15px;
  align-items: center;
  text-align: center;
  border-radius: 15px;
  background: #ffffff;

  width: 100%;

  @media (max-width: 980px) {
    min-width: 250px;
  }
`;

export const Correta = styled.p`
  color: black;
  font-size: 26px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  background: white;
  border-radius: 15px;
`;
