import styled, {css} from 'styled-components';

export const Container = styled.div`
  max-width: 1000px;
  min-height: 660px;

  margin: 75px auto;
  background: #fff;
  border: 20px solid #fff;
  border-radius: 15px;
  padding: 10px;
`;

export const EnunciatedContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 15px;
  border-radius: 15px;
  background-color: #75dfeb;

  @media (max-width: 420px) {
    flex-direction: column;
  }

  p {
    font-size: 32px;
    font-weight: bold;
    font-family: Arial,Helvetica,sans-serif;
    color: #fff;
    flex: 1;
  }

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    background-color: #91dbe4;
    border-radius: 10px;
    width: 180px;
    height: 30px;
    padding: 15px;

    text-align: center;
    cursor: pointer;

    p {
      margin-left: 6px;
      font-size: 25px;
    }
  }
`;

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  margin-top: 20px;
  border-radius: 15px;
  background-color: #e9bb3f;
  padding: 15px;

  div {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    border-radius: 15px;

    cursor: pointer;

    p {
      font-size: 26px;
      color: #757575;
      text-align: center;
      font-family: Arial,Helvetica,sans-serif;
    }

    @media (max-width: 1260px) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }

    @media (max-width: 780px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    @media (max-width: 420px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    ${props => props.length && css`
      div {
        width: 400px;
        height: 400px;

        img {
          width: 350px;
          height: 350px;
        }
      }
    `}
  }

  ${props => props.render && css`
    flex-direction: column;    
  `}
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 6px 0px;

  background-color: #fff;
  width: 320px;
  height: 320px;
  border-radius: 10px;

  img {
    width: 270px;
    height: 270px;
  }

  @media (max-width: 1260px) {
    width: 260px;
    height: 260px;
    margin-top: 16px;

    img {
      width: 220px;
      height: 220px;
    }
  }

  @media (max-width: 780px) {
    width: 260px;
    height: 260px;

    img {
      width: 220px;
      height: 220px;
    }

    & + div {
      margin-top: 16px;
    }
  }

  @media (max-width: 420px) {
    width: 220px;
    height: 220px;

    img {
      width: 180px;
      height: 180px;
    }

    & + div {
      margin-top: 16px;
    }
  }
`;