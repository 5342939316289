import React from "react";

const Cores = ({ continuar, selecionarCor, corSelecionada, voltar }) => (
  <>
    {window.innerWidth > 800 ? (
      <div className="pagina-login">
        <div className="container-login">
          <h1>Selecione sua cor preferida</h1>
          <form
            className="form-login"
            onSubmit={(e) => {
              e.preventDefault();
              continuar();
            }}
          >
            <img
              alt="Cor 1"
              src={require("../assets/imagens/cor-01.png")}
              onClick={() => selecionarCor("#7B5CA4")}
              style={
                corSelecionada === "#7B5CA4" ? { borderStyle: "solid" } : {}
              }
            />
            <img
              alt="Cor 2"
              src={require("../assets/imagens/cor-02.png")}
              onClick={() => selecionarCor("#F3910D")}
              style={
                corSelecionada === "#F3910D" ? { borderStyle: "solid" } : {}
              }
            />
            <img
              alt="Cor 3"
              src={require("../assets/imagens/cor-03.png")}
              onClick={() => selecionarCor("#0894CE")}
              style={
                corSelecionada === "#0894CE" ? { borderStyle: "solid" } : {}
              }
            />
            <img
              alt="Cor 4"
              src={require("../assets/imagens/cor-04.png")}
              onClick={() => selecionarCor("#6EC5C7")}
              style={
                corSelecionada === "#6EC5C7" ? { borderStyle: "solid" } : {}
              }
            />
            <img
              alt="Cor 5"
              src={require("../assets/imagens/cor-05.png")}
              onClick={() => selecionarCor("#2B9533")}
              style={
                corSelecionada === "#2B9533" ? { borderStyle: "solid" } : {}
              }
            />
            <img
              alt="Cor 6"
              src={require("../assets/imagens/cor-06.png")}
              onClick={() => selecionarCor("#E70773")}
              style={
                corSelecionada === "#E70773" ? { borderStyle: "solid" } : {}
              }
            />
            <img
              alt="Cor 7"
              src={require("../assets/imagens/cor-07.png")}
              onClick={() => selecionarCor("#F49B66")}
              style={
                corSelecionada === "#F49B66" ? { borderStyle: "solid" } : {}
              }
            />
            <img
              alt="Cor 8"
              src={require("../assets/imagens/cor-08.png")}
              onClick={() => selecionarCor("#CB3939")}
              style={
                corSelecionada === "#CB3939" ? { borderStyle: "solid" } : {}
              }
            />
            <input type="submit" className="btn-padrao" value="Confirmar" />
          </form>
          <div className="footer-nav">
            {voltar && (
              <button className="btn-link ali-left" onClick={voltar} href="#">
                Voltar
              </button>
            )}
          </div>
        </div>
      </div>
    ) : (
      <div style={{ padding: 10 }}>
        <div style={{ textAlign: "center" }}>
          <h1>Selecione sua cor preferida</h1>

          <form
            style={{ marginTop: "20%" }}
            // className="form-login"
            onSubmit={(e) => {
              e.preventDefault();
              continuar();
            }}
          >
            <img
              width={110}
              alt="Cor 1"
              src={require("../assets/imagens/cor-01.png")}
              onClick={() => selecionarCor("#7B5CA4")}
              style={
                corSelecionada === "#7B5CA4" ? { borderStyle: "solid" } : {}
              }
            />
            <img
              width={110}
              alt="Cor 2"
              src={require("../assets/imagens/cor-02.png")}
              onClick={() => selecionarCor("#F3910D")}
              style={
                corSelecionada === "#F3910D" ? { borderStyle: "solid" } : {}
              }
            />
            <img
              width={110}
              alt="Cor 3"
              src={require("../assets/imagens/cor-03.png")}
              onClick={() => selecionarCor("#0894CE")}
              style={
                corSelecionada === "#0894CE" ? { borderStyle: "solid" } : {}
              }
            />
            <img
              width={110}
              alt="Cor 4"
              src={require("../assets/imagens/cor-04.png")}
              onClick={() => selecionarCor("#6EC5C7")}
              style={
                corSelecionada === "#6EC5C7" ? { borderStyle: "solid" } : {}
              }
            />
            <img
              width={110}
              alt="Cor 5"
              src={require("../assets/imagens/cor-05.png")}
              onClick={() => selecionarCor("#2B9533")}
              style={
                corSelecionada === "#2B9533" ? { borderStyle: "solid" } : {}
              }
            />
            <img
              width={110}
              alt="Cor 6"
              src={require("../assets/imagens/cor-06.png")}
              onClick={() => selecionarCor("#E70773")}
              style={
                corSelecionada === "#E70773" ? { borderStyle: "solid" } : {}
              }
            />
            <img
              width={110}
              alt="Cor 7"
              src={require("../assets/imagens/cor-07.png")}
              onClick={() => selecionarCor("#F49B66")}
              style={
                corSelecionada === "#F49B66" ? { borderStyle: "solid" } : {}
              }
            />
            <img
              width={110}
              alt="Cor 8"
              src={require("../assets/imagens/cor-08.png")}
              onClick={() => selecionarCor("#CB3939")}
              style={
                corSelecionada === "#CB3939" ? { borderStyle: "solid" } : {}
              }
            />
            <input
              style={{ marginTop: 15 }}
              type="submit"
              className="btn-padrao"
              value="Confirmar"
            />
          </form>
          <div style={{ marginTop: 15 }}>
            {voltar && (
              <button className="btn-link ali-left" onClick={voltar} href="#">
                Voltar
              </button>
            )}
          </div>
        </div>
      </div>
    )}
  </>
);

export default Cores;
