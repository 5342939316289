/* eslint-disable react-hooks/exhaustive-deps */
import React, {Fragment, useState, useEffect} from 'react';
import {click, win, wrong} from '../../libs/soundEffects';

let rodadaAlternarItens = 0;
let idArrastando;
let fim = false;

const Arrastar = ({config}) => {
    const qtdRodadas = config.rodadas ? config.rodadas.length : 0;

    const [rodadaAtual, setRodadaAtual] = useState(undefined);
    const [numRodadaAtual, setNumRodadaAtual] = useState(0);
    const [respostaOk, setRespostaOk] = useState(undefined);
    const [pontos, setPontos] = useState(0);
    const [itemAtual, setItemAtual] = useState(0);

    useEffect(() => {
        if(config.audioInicio){
            const audio = new Audio(config.audioInicio);

            audio.play();        
        }

        iniciarRodada(1);
        if(!config.itens) {
            setTimeout(() => alternarItens(0), config.tempoAbertura ? config.tempoAbertura * 1000 : 5000);
        }
    }, []);

    const alternarItens = (numero) => {
        const itensAlternar = config.rodadas[rodadaAlternarItens].itens;

        const novoItem = itensAlternar[numero + 1] ? numero + 1 : 0;

        // if(itensAlternar[novoItem].audio){
        //     const audio = new Audio(itensAlternar[novoItem].audio);

        //     audio.play();    
        // }

        setItemAtual(novoItem);

        if(!fim) setTimeout(() => alternarItens(novoItem), 1500);
    }

    const iniciarRodada = (numero) => {
        const rodada = config.rodadas[numero - 1];

        if(!rodada) return;

        if(rodada.audioEnunciado){
            const audio = new Audio(rodada.audioEnunciado);

            audio.play();        
        }

        setNumRodadaAtual(numero);
        setRodadaAtual(rodada);
        setPontos(0);
        setRespostaOk(false);

        rodadaAlternarItens = numero - 1;        
    }

    const avancar = () => {
        if(numRodadaAtual === qtdRodadas){
            setRespostaOk(true);
        } else {
            iniciarRodada(numRodadaAtual + 1);
        }
    }

    const encerrar = () => 
    {
        fim = true;
        config.avancar ? config.avancar() : alert('acabou!');
    }

    const dropRecipiente = (e, recipienteId) => {
        e.preventDefault();

        if(idArrastando === recipienteId){
            win();
            
            setPontos(pontos + 1);

            if(pontos + 1 >= rodadaAtual.pontos) {
                avancar();
            }
        } else {
            wrong();
        }
    }

    if(!rodadaAtual) return null;

    return(
        <div style={styles.container}>
            <div style={styles.cabecalho}>
                <p>{rodadaAtual.enunciado}</p>
                <button style={styles.btnRepetir} onClick={() => iniciarRodada(1)}>
                    <i class="fas fa-undo-alt" style={styles.btnRepetirIcon}></i> REPETIR
                </button>
            </div>
            <div style={styles.conteudo}>
                <p className="p-text">
                    {`${rodadaAtual.textoContador ? rodadaAtual.textoContador : 'Pontos'}: (${pontos}/${rodadaAtual.pontos})`}
                </p>
            </div>
            {respostaOk ? 
                <div style={styles.conteudoFinal}>
                    <p><b>{`PARABÉNS! ATIVIDADE FINALIZADA!`}</b></p>
                    <button style={styles.btnContinuar} onClick={encerrar}>
                        CONTINUAR <i class="fas fa-chevron-right" style={styles.btnContinuarIcon}></i>
                    </button>
                </div>
            :
                <div>
                    {config.itens ? 
                        <div style={styles.conteudo}>
                            {rodadaAtual.itens.map((item, key) => 
                                <div key={key} style={styles.conteudoItem}>
                                    <div style={{display: 'flex', flexDirection: 'column'}}>
                                        {item.imagem && (
                                            <img 
                                                draggable="true" 
                                                onDragStart={() => {idArrastando = item.recipienteId}}
                                                style={{height: 120, width: 120}} 
                                                src={item.imagem}
                                            />
                                        )}
                                        {item.nome}
                                    </div>
                                </div>
                            )}                        
                        </div>
                     : 
                        <div style={styles.conteudo}>
                            <div style={styles.conteudoItem}>
                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                    {rodadaAtual.itens[itemAtual].imagem && (
                                        <img 
                                            draggable="true" 
                                            onDragStart={() => {idArrastando = rodadaAtual.itens[itemAtual].recipienteId}}
                                            style={{height: 120, width: 120}} 
                                            src={rodadaAtual.itens[itemAtual].imagem}
                                        />
                                    )}
                                    {rodadaAtual.itens[itemAtual].nome}
                                </div>
                            </div>
                        </div>
                    }
                    
                    <div style={styles.conteudo}>
                        {rodadaAtual.recipientes.map((recipiente, key) => 
                            <div 
                                key={key} 
                                style={styles.conteudoItem}
                                onDrop={e => dropRecipiente(e, recipiente.id)} 
                                onDragOver={e => e.preventDefault()}
                            >
                                {recipiente.imagem &&
                                    <div style={{display: 'flex', flexDirection: 'column'}}>
                                        <div 
                                            key={key}                                            
                                            style={{
                                                backgroundImage: `url(${recipiente.imagem})`, 
                                                backgroundPosition: 'center',
                                                backgroundSize: 'cover',
                                                backgroundRepeat: 'no-repeat',
                                                backgroundColor: 'white',
                                                width: 120, 
                                                height: 120
                                            }}
                                        >
                                        </div>
                                    </div>
                                }
                                {recipiente.texto &&
                                    <div style={{display: 'flex', flexDirection: 'column'}}>
                                        <div 
                                            key={key}
                                        >
                                            <p><b>{recipiente.texto}</b></p>
                                        </div>
                                    </div>
                                }                                
                            </div>
                        )}
                    </div>
                </div>                
            }
        </div>
    )

    return(
        <div className="content">
            <div className="container">
                <div className="box-topo">
                    <div className="item-box-topo-1">
                        <p className="p-text">{rodadaAtual.enunciado}</p>
                    </div>
                    <div className="item-box-topo-2">
                        <a className="btn-repetir" onClick={() => iniciarRodada(1)}><i className="fas fa-undo-alt"></i> REPETIR</a>
                    </div>
                </div>
                <div className="box-pontuacao">
                    <p className="p-text">
                        {`${rodadaAtual.textoContador ? rodadaAtual.textoContador : 'Pontos'}: (${pontos}/${rodadaAtual.pontos})`}
                    </p>
                </div>

                {!respostaOk &&
                    config.itens ? (
                        <div className="box-palavras">
                            {rodadaAtual.itens.map((item, key) => 
                                <div key={key} >
                                    <div style={{display: 'flex', flexDirection: 'column'}}>
                                        {
                                            item.imagem && (
                                                <img 
                                                    draggable="true" 
                                                    onDragStart={() => {idArrastando = item.recipienteId}}
                                                    style={{height: 180}} 
                                                    src={item.imagem}
                                                />
                                            )
                                        }
                                        {item.nome}
                                    </div>
                                </div>
                            )}
                        </div>
                   ) : (
                        <div style={{textAlign: 'center'}}>
                            {rodadaAtual.itens[itemAtual].imagem &&
                                <img 
                                    draggable="true" 
                                    onDragStart={() => {idArrastando = rodadaAtual.itens[itemAtual].recipienteId}}
                                    style={{height: 180}} 
                                    src={rodadaAtual.itens[itemAtual].imagem}
                                />
                            }
                            {rodadaAtual.itens[itemAtual].texto &&
                                <p className="p-text">{rodadaAtual.itens[itemAtual].texto}</p>
                            }
                        </div>
                   )
                }
                
                {respostaOk ?
                    <div className="tl8-box-2">
                        <div className="tl7-box-confirmacao">
                            <p className="p-text">PARABÉNS</p>
                            <p className="p-text">VOCÊ ACERTOU!</p>
                            <a className="btn-concluir" onClick={encerrar}>CONTINUAR <i className="fas fa-chevron-right"></i> </a>
                        </div>
                    </div>
                :
                    <div className="tl8-box-2">
                        {rodadaAtual.recipientes.map((recipiente, key) =>  
                            <Fragment>
                                {recipiente.imagem &&
                                    <div 
                                        key={key} 
                                        className="arrastar-recipiente"
                                        onDrop={e => dropRecipiente(e, recipiente.id)} 
                                        onDragOver={e => e.preventDefault()}
                                        style={{backgroundImage: `url(${recipiente.imagem})`}}
                                    >
                                    </div>
                                }
                                {recipiente.texto &&
                                    <div 
                                        key={key} 
                                        className="arrastar-recipiente"
                                        onDrop={e => dropRecipiente(e, recipiente.id)} 
                                        onDragOver={e => e.preventDefault()}
                                    >
                                        <p className="arrastar-recpiente-texto">{recipiente.texto}</p>
                                    </div>
                                }
                            </Fragment>
                        )}
                    </div>
                }
            </div>
        </div>
    )

    // return(
    //     <div>
    //         <p>Drag the W3Schools image into the rectangle:</p>
    //             <div 
    //                 id="div1" 
    //                 onDrop={dropRed} 
    //                 onDragOver={e => e.preventDefault()}
    //                 style={{
    //                     width: 350,
    //                     height: 70,
    //                     padding: 10,
    //                     backgroundColor: 'red'
    //                 }}
    //             ></div>
    //             <div 
    //                 id="div2" 
    //                 onDrop={dropGreen} 
    //                 onDragOver={e => e.preventDefault()}
    //                 style={{
    //                     width: 350,
    //                     height: 70,
    //                     padding: 10,
    //                     backgroundColor: 'green'
    //                 }}
    //             ></div>
    //             <br/>
    //             <img id="drag1" src={config.imagem} draggable="true"></img>
    //     </div>
    // )
}

export default Arrastar;

const styles = {
    container: {
        backgroundColor: 'white', 
        margin: 5, 
        borderRadius: 15, 
        padding: 5
    },
    cabecalho: {
        backgroundColor: 'RGB(0,177,181)', 
        margin: 5, 
        borderRadius: 15, 
        padding: 5,
        color: 'white',
        fontWeight: 'bolder',
        display: 'flex',
        justifyContent: 'space-between'
    },
    conteudo: {
        display: 'flex',
        justifyContent: 'center',
        alignItens: 'center',
        flexWrap: 'wrap',
        borderRadius: 15,
        backgroundColor: 'RGB(193,239,226)',
        margin: 5,
        marginTop: 10
    },
    conteudoFinal: {
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        textAlign: 'center',
        alignItens: 'center',
        flexWrap: 'wrap',
        borderRadius: 15,
        backgroundColor: 'RGB(193,239,226)',
        margin: 5,
        marginTop: 10,
        padding: 10
    },
    conteudoItem: {
        touchAction: 'none',
        marginTop: 5,
        marginLeft: 5,
        marginRitht: 5,
        marginBottom: 5,
        borderRadius: 15,
        width: 120,
        height: 120,
        backgroundColor: 'RGB(255,206,0)',
        padding: 10,
        textAlign: 'center'
    },
    conteudoItemCorreto: {
        margin: 5,
        borderRadius: 15,
        backgroundColor: 'RGB(107, 255, 107)',
        padding: 10,
        textAlign: 'center'
    },
    conteudoItemIncorreto: {
        margin: 5,
        borderRadius: 15,
        backgroundColor: 'RGB(255, 107, 107)',
        padding: 10,
        textAlign: 'center'
    },
    btnRepetir: {
        backgroundColor: 'RGB(71, 226, 230)',
        border: 'none',
        color: 'white',
        borderRadius: 15
    },
    btnRepetirIcon: {
        color: 'RGB(71, 226, 230)',
        marginBottom: 5
    },
    btnContinuar: {
        backgroundColor: 'RGB(0, 144, 245)',
        border: 'none',
        color: 'white',
        borderRadius: 15,
        maxHeight: 60,
        padding: 10
    },
    btnContinuarIcon: {
        color: 'RGB(0, 144, 245)',
        marginBottom: 5
    }
}