import serverHost from '../../services/serverHost';

const configVisualizarMidia = (identificador) => {
    switch(identificador){
        case 'nao-alfabetizados_unit1_aula1_atividade3':
            return {
                enunciado: 'IMITE OS GESTOS DA PARTE DA MUSICA',
                rodadas: [
                    {
                        descricao: 'Faça os gestos conforme o vídeo e depois clique no botão abaixo para continuar!',
                        tipoMidia: 'video',
                        midia: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-1/aula-1/video-3/dog.mp4`
                    },
                    {
                        descricao: 'Faça os gestos conforme o vídeo e depois clique no botão abaixo para continuar!',
                        tipoMidia: 'video',
                        midia: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-1/aula-1/video-3/cat.mp4`
                    },
                    {
                        descricao: 'Faça os gestos conforme o vídeo e depois clique no botão abaixo para continuar!',
                        tipoMidia: 'video',
                        midia: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-1/aula-1/video-3/fish.mp4`
                    },
                    {
                        descricao: 'Faça os gestos conforme o vídeo e depois clique no botão abaixo para continuar!',
                        tipoMidia: 'video',
                        midia: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-1/aula-1/video-3/mouse.mp4`
                    },
                ]
            }

        default: return {}
    }
}

export default configVisualizarMidia;