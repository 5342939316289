export default (api) => {

  switch(api){
    case 'IJWS': 
      //return 'http://localhost:3600';
      return "https://pertobilingueadm.com.br/ijws"
    case 'PEIJ': 
      return "https://pertobilingueadm.com.br/peij"
    default: return "https://pertobilingueadm.com.br/peij"
  }
  
};
