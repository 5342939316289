/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState, useCallback} from 'react';
import {click, win, wrong} from '../../libs/soundEffects';

let audio = undefined;

const Memoria = ({config}) => {
    const qtdRodadas = config.rodadas ? config.rodadas.length : 0;

    const [rodadaAtual, setRodadaAtual] = useState(undefined);
    const [numRodadaAtual, setNumRodadaAtual] = useState(0);
    const [abertura, setAbertura] = useState(true);
    const [respostaOk, setRespostaOk] = useState(undefined);
    const [itensSelecionados, setItensSelecionados] = useState([]);
    const [itensRevelados, setItensRevelados] = useState([]);
    const [itensOcultar, setItensOcultar] = useState([]);

    useEffect(() => {
        if(config.audioInicio){
            const audio = new Audio(config.audioInicio);

            audio.play();        
        }

        iniciarRodada(1);
    }, [config.audioInicio]);

    const iniciarRodada = useCallback((numero) => {
        const rodada = config.rodadas[numero - 1];

        if(!rodada) return;

        setNumRodadaAtual(numero);
        setRodadaAtual(rodada);
        setItensSelecionados([]);
        setItensRevelados([]);
        setAbertura(true);
        setRespostaOk(false);

        if(config.audioAbertura){
            audio = new Audio(config.audioAbertura);
            audio.play();
        }
        
        setTimeout(() => {
            if(rodada.audioEnunciado){            
                audio = new Audio(rodada.audioEnunciado);
                
                if(rodada.atrasoAudio){
                    setTimeout(() => audio.play(), rodada.atrasoAudio*1000)
                } else {
                    audio.play();
                }
            }

            if(config.modalidade === 'sumir'){
                setItensOcultar([rodada.itemSumir]);

                setTimeout(() => {
                    setAbertura(false);

                    const revelados = []

                    rodada.itens.forEach((item, key) => {
                        revelados.push(key);
                    });

                    setItensOcultar([]);
                    setItensRevelados(revelados);

                }, rodada.tempoExibicao * 1000)
            } else {
                setAbertura(false);
            }
        }, config.tempoAbertura ? config.tempoAbertura*1000 : 0);        
    }, [config.audioAbertura, config.modalidade, config.rodadas, config.tempoAbertura])

    const selecionarItem = (index, item) => {

        if(item.audio){
            setTimeout(() => {
                audio = new Audio(item.audio);
                audio.play();

                validarItem(index, item);
            }, item.duracaoAudio * 1000);
        } else {
            validarItem(index, item);
        }
    }

    const validarItem = (index, item) => {
        if(abertura || itensSelecionados.includes(index)) return;    
        
        click();

        const itensSelecionadosNovo = [...itensSelecionados];

        itensSelecionadosNovo.push(index);

        setItensSelecionados(itensSelecionadosNovo);

        switch(config.modalidade){
            case 'tempo':
                if(item.id === rodadaAtual.itemEncontrar){
                    win();

                    setRespostaOk(true);                
                } else {
                    setTimeout(() => {
                        setItensSelecionados([]);
                        wrong();
                    }, 500)
                } 

                break;
            case 'pares':
                if(itensSelecionadosNovo.length === 2){
                    setTimeout(() => {                        
                        if(rodadaAtual.itens[itensSelecionadosNovo[0]].id === rodadaAtual.itens[itensSelecionadosNovo[1]].id){
                            win();

                            const itensReveladosNovo = itensRevelados.concat(itensSelecionadosNovo)

                            setItensRevelados(itensReveladosNovo);

                            if(itensReveladosNovo.length === rodadaAtual.itens.length) setRespostaOk(true);
                        } else {                            
                            wrong();
                        }
                        setItensSelecionados([]);                     
                    }, 500);
                }

                break;
            case 'sumir':
                if(item.id === rodadaAtual.itemSumir){
                    win();

                    setRespostaOk(true);
                } else {
                   wrong();
                }

            default: break;
        }               
    }

    const avancar = () => {
        if(numRodadaAtual === qtdRodadas){
            return config.avancar ? config.avancar() : alert('acabou!');
        } else {
            iniciarRodada(numRodadaAtual + 1);
        }
    }

    if(!rodadaAtual) return null;

    return(
        <div style={styles.container}>
            <div style={styles.cabecalho}>
                <p>{abertura ? config.textoAbertura : rodadaAtual.enunciado}</p>
                <button style={styles.btnRepetir} onClick={() => iniciarRodada(1)}>
                    <i class="fas fa-undo-alt" style={styles.btnRepetirIcon}></i> REPETIR
                </button>
            </div>            
            <div style={styles.conteudo}>
                {rodadaAtual.itens.filter(item => !itensOcultar.includes(item.id)).map((item, key) =>
                    <div 
                        style={abertura || itensRevelados.includes(key) || itensSelecionados.includes(key) ? {
                            backgroundImage: `url(${item.imagem})`, 
                            width: 120, 
                            height: 120,
                            borderRadius: 15,
                            padding: 10,
                            margin: 5,
                            boxShadow: '8px 7px 10px -4px rgba(0, 0, 0, 0.14)',
                            backgroundPosition: 'center',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat'
                        } : 
                            styles.conteudoItem}
                        onClick={abertura || itensSelecionados.includes(key) ? undefined : () => selecionarItem(key, item)}
                    >                        
                    </div>
                )}
            </div>
            {respostaOk &&
                <div style={styles.conteudoFinal}>                  
                    <p><b>PARABÉNS! VOCÊ ACERTOU!</b></p>
                    <button style={styles.btnContinuar} onClick={avancar}>
                        CONTINUAR <i class="fas fa-chevron-right" style={styles.btnContinuarIcon}></i>
                    </button>
                </div>
            }
        </div>
    )

    return(
        <div className="content">
            <div className="container">
                <div className="box-topo">
                    <div className="item-box-topo-1">
                        <p className="p-text">{abertura ? config.textoAbertura : rodadaAtual.enunciado}</p>
                    </div>
                    <div className="item-box-topo-2">
                        <a className="btn-repetir" onClick={() => iniciarRodada(1)}>
                            <i className="fas fa-undo-alt"></i> 
                            REPETIR
                        </a>
                    </div>
                </div>
                {respostaOk &&
                    <div className="box-palavras">
                        <div className="tl7-box-confirmacao" style={{height: 250}}>
                            <p className="p-text">PARABÉNS! VOCÊ ACERTOU!</p>
                            <a className="btn-concluir" onClick={avancar}>CONTINUAR <i className="fas fa-chevron-right"></i></a>
                        </div>
                    </div> 
                }
                <div className="tl8-box-2">
                    {rodadaAtual.itens.filter(item => !itensOcultar.includes(item.id)).map((item, key) => 
                        <div 
                            key={key} 
                            className={abertura || itensRevelados.includes(key) || itensSelecionados.includes(key) ? "tl8-figura-2" : "tl8-figura-1"} 
                            style={abertura || itensRevelados.includes(key)|| itensSelecionados.includes(key) ? {backgroundImage: `url(${item.imagem})`} : {}}
                            onClick={abertura || itensSelecionados.includes(key) ? undefined : () => selecionarItem(key, item)}
                        >
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Memoria;

const styles = {
    container: {
        backgroundColor: 'white', 
        margin: 5, 
        borderRadius: 15, 
        padding: 5
    },
    cabecalho: {
        backgroundColor: 'RGB(0,177,181)', 
        margin: 5, 
        borderRadius: 15, 
        padding: 5,
        color: 'white',
        fontWeight: 'bolder',
        display: 'flex',
        justifyContent: 'space-between'
    },
    conteudo: {
        display: 'flex',
        justifyContent: 'center',
        alignItens: 'center',
        flexWrap: 'wrap',
        borderRadius: 15,
        backgroundColor: 'RGB(193,239,226)',
        margin: 5,
        marginTop: 10
    },
    conteudoFinal: {
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        textAlign: 'center',
        alignItens: 'center',
        flexWrap: 'wrap',
        borderRadius: 15,
        backgroundColor: 'RGB(193,239,226)',
        margin: 5,
        marginTop: 10,
        padding: 10
    },
    conteudoItem: {
        marginTop: 5,
        marginLeft: 5,
        marginRitht: 5,
        marginBottom: 5,
        borderRadius: 15,
        width: 120,
        height: 120,
        backgroundColor: 'RGB(255,206,0)',
        padding: 10,
        textAlign: 'center'
    },
    conteudoItemCorreto: {
        margin: 5,
        borderRadius: 15,
        backgroundColor: 'RGB(107, 255, 107)',
        padding: 10,
        textAlign: 'center'
    },
    conteudoItemIncorreto: {
        margin: 5,
        borderRadius: 15,
        backgroundColor: 'RGB(255, 107, 107)',
        padding: 10,
        textAlign: 'center'
    },
    btnRepetir: {
        backgroundColor: 'RGB(71, 226, 230)',
        border: 'none',
        color: 'white',
        borderRadius: 15
    },
    btnRepetirIcon: {
        color: 'RGB(71, 226, 230)',
        marginBottom: 5
    },
    btnContinuar: {
        backgroundColor: 'RGB(0, 144, 245)',
        border: 'none',
        color: 'white',
        borderRadius: 15,
        maxHeight: 60,
        padding: 10
    },
    btnContinuarIcon: {
        color: 'RGB(0, 144, 245)',
        marginBottom: 5
    }
}