import React from "react";

const Niveis = ({ continuar, voltar, nivelSelecionado, selecionarNivel }) => (
  <>
    {window.innerWidth > 800 ? (
      <div className="pagina-login">
        <div className="container-login">
          <p>
            Escolha a experiência de conteúdo para seu filho <br />
            <span className="obs">Sua escolha mudará o tipo de conteúdo</span>
          </p>
          <form
            className="form-login"
            onSubmit={(e) => {
              e.preventDefault();
              continuar();
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div>
                <img
                  alt="Nível 1 - Alfabetizados"
                  src={require("../assets/imagens/nivel-01.png")}
                  onClick={() => selecionarNivel(1)}
                  style={{
                    border: `${
                      nivelSelecionado === 1
                        ? "4px solid #009933"
                        : "4px solid #fff"
                    }`,
                    marginBottom: 0,
                  }}
                />
                <h3 style={{ marginTop: 2 }}>Crianças de 3 A 7 anos</h3>
              </div>
              <div>
                <img
                  alt="Nível 2 - Não alfabetizados"
                  src={require("../assets/imagens/nivel-02.png")}
                  onClick={() => selecionarNivel(2)}
                  style={{
                    border: `${
                      nivelSelecionado === 2
                        ? "4px solid #009933"
                        : "4px solid #fff"
                    }`,
                    marginBottom: 0,
                  }}
                />
                <h3 style={{ marginTop: 2 }}>Crianças de 7 A 12 anos</h3>
              </div>
            </div>

            <input type="submit" className="btn-padrao" value="Continuar" />
          </form>
          <div className="footer-nav">
            {voltar && (
              <div className="btn-link ali-left" onClick={voltar} href="#">
                Voltar
              </div>
            )}
          </div>
        </div>
      </div>
    ) : (
      <div style={{ padding: 10 }}>
        <div style={{ textAlign: "center" }}>
          <p>
            Escolha a experiência de conteúdo para seu filho <br />
            <span style={{ fontSize: "0.8em" }} className="obs">
              Sua escolha mudará o tipo de conteúdo
            </span>
          </p>

          <form
            style={{ marginTop: "20%" }}
            // className="form-login"
            onSubmit={(e) => {
              e.preventDefault();
              continuar();
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div>
                <img
                  width={110}
                  alt="Nível 1 - Alfabetizados"
                  src={require("../assets/imagens/nivel-01.png")}
                  onClick={() => selecionarNivel(1)}
                  style={{
                    border: `${
                      nivelSelecionado === 1
                        ? "4px solid #009933"
                        : "4px solid #fff"
                    }`,
                    marginBottom: 0,
                  }}
                />
                <h3 style={{ marginTop: 2 }}>Crianças de 3 A 7 anos</h3>
              </div>
              <div>
                <img
                  width={110}
                  alt="Nível 2 - Não alfabetizados"
                  src={require("../assets/imagens/nivel-02.png")}
                  onClick={() => selecionarNivel(2)}
                  style={{
                    border: `${
                      nivelSelecionado === 2
                        ? "4px solid #009933"
                        : "4px solid #fff"
                    }`,
                    marginBottom: 0,
                  }}
                />
                <h3 style={{ marginTop: 2 }}>Crianças de 7 A 12 anos</h3>
              </div>
            </div>

            <input type="submit" className="btn-padrao" value="Continuar" />
          </form>
          <div style={{ marginTop: 15 }}>
            {voltar && (
              <div className="btn-link ali-left" onClick={voltar} href="#">
                Voltar
              </div>
            )}
          </div>
        </div>
      </div>
    )}
  </>
);

export default Niveis;
