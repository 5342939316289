import React, {useEffect, useState, Fragment} from 'react';
import {click, win, wrong} from '../libs/soundEffects';

let imagensRandomizar = [];

const SelecionarImagem = ({config}) => {
    const qtdRodadas = config.rodadas ? config.rodadas.length : 0;

    const [rodadaAtual, setRodadaAtual] = useState(undefined);
    const [numRodadaAtual, setNumRodadaAtual] = useState(0);
    const [respostaOk, setRespostaOk] = useState(undefined);
    const [pontos, setPontos] = useState(0);
    const [imagens, setImagens] = useState([]);

    useEffect(() => {
        if(config.audioInicio){
            const audio = new Audio(config.audioInicio);

            audio.play();        
        }

        iniciarRodada(1);
        randomizar();
    }, []);

    const randomizar = () => {
        const imagensAleatorio = [...imagensRandomizar];

        imagensAleatorio.forEach(i => i.ordem = Math.floor(Math.random() * 50));

        imagensAleatorio.sort((a, b) => {
            if(a.ordem < b.ordem) return -1;
            if(b.ordem < a.ordem) return 1;

            return 0
        });

        setImagens(imagensAleatorio);

        if(!respostaOk) setTimeout(randomizar, 1500);
    }

    const iniciarRodada = (numero) => {
        const rodada = config.rodadas[numero - 1];

        if(!rodada) return;

        if(rodada.audioEnunciado){
            const audio = new Audio(rodada.audioEnunciado);

            audio.play();        
        }

        setNumRodadaAtual(numero);
        setRodadaAtual(rodada);
        setPontos(0);
        setRespostaOk(false);
        setImagens(rodada.imagens);

        imagensRandomizar = rodada.imagens;        
    }

    const avancar = () => {
        if(numRodadaAtual === qtdRodadas){
            setRespostaOk(true);
        } else {
            iniciarRodada(numRodadaAtual + 1);
        }
    }

    const encerrar = () => {
        config.avancar ? config.avancar() : alert('acabou!');
    }

    const selecionar = (imagem) => {
        if(imagem.correta) {
            win();
            setPontos(pontos + 1);

            if(pontos + 1 === rodadaAtual.pontos) avancar();
        } else {
            wrong();
        }
    }

    if(!rodadaAtual) return null;

    return(
        <div className="content">
            <div className="container">
                <div className="box-topo">
                    <div className="item-box-topo-1">
                        <p className="p-text">{rodadaAtual.enunciado}</p>
                    </div>
                    <div className="item-box-topo-2">
                        <a className="btn-repetir" onClick={() => iniciarRodada(1)}><i className="fas fa-undo-alt"></i> REPETIR</a>
                    </div>
                </div>
                <div className="box-pontuacao">
                    <p className="p-text">
                        {`${rodadaAtual.textoContador ? rodadaAtual.textoContador : 'Pontos'}: (${pontos}/${rodadaAtual.pontos})`}
                    </p>
                </div>
                {respostaOk ?
                    <div className="tl8-box-2">
                        <div className="tl7-box-confirmacao">
                            <p className="p-text">PARABÉNS</p>
                            <p className="p-text">VOCÊ ACERTOU!</p>
                            <a className="btn-concluir" onClick={encerrar}>CONTINUAR <i className="fas fa-chevron-right"></i> </a>
                        </div>
                    </div>
                :
                    rodadaAtual.imagemUnica ? 
                        <img 
                            draggable="true" 
                            style={{height: 180}} 
                            src={{backgroundImage: `url(${imagens[0]})`}}
                        />
                    :
                        <div className="tl8-box-2">
                            {imagens.map((imagem, key) =>  
                                <div 
                                    key={key} 
                                    className="tl8-figura-2"
                                    style={{backgroundImage: `url(${imagem.imagem})`}}
                                    onClick={() => selecionar(imagem)}
                                >
                                </div>
                            )}
                        </div>
                }
            </div>
        </div>
    )
}

export default SelecionarImagem;