import React, { useState, useEffect, useContext } from "react";
import { Redirect } from "react-router-dom";
import InputMask from "react-input-mask";
import Pagamento from "../components/pagamento/index";
import { login, isAuthenticated } from "../services/auth";
import { getQueryParams } from "../helpers";
import { Checkbox, CircularProgress } from "@mui/material";
import AppContext from "../container/context";
import ijws from "../services/ijws";
import { validarCPF } from "../helpers/validar";
import "./styesLogin.css";

const SignUp = (props) => {
  const { notification } = useContext(AppContext);
  const history = props.history;
  const location = props.location;
  const queryParams = getQueryParams(location.search);

  const [promocao, setPromocao] = useState(queryParams["pr"] || null);
  const [plano, setPlano] = useState(queryParams["pl"] || null);
  const [fase, setFase] = useState("DADOS");
  const [salvando, setSalvando] = useState(false);
  const [usuarioId, setUsuarioId] = useState(0);
  const [dados, setDados] = useState({
    nome: localStorage.getItem("@IJ-DadosAulaExperimental-Nome") || "",
    email: localStorage.getItem("@IJ-DadosAulaExperimental-Email") || "",
    phone: localStorage.getItem("@IJ-DadosAulaExperimental-Telefone") || "",
    CPF: "",
    perfilNome: "",
    senha: "",
    confirmacaoSenha: "",
    deAcordoTermos: false,
  });

  useEffect(() => {
    if (queryParams["pr"]) setPromocao(queryParams["pr"]);
    if (queryParams["pl"]) setPlano(queryParams["pl"]);
  }, [queryParams]);

  const cadastrar = () => {
    const { nome, perfilNome, email, senha, phone } = dados;

    if (!validarCPF(dados.CPF.replace(/\D/g, ""))) {
      return notification("warning", "CPF inválido");
    }

    setSalvando(true);

    ijws
      .post("usuarios/inserir", {
        nome,
        CPF: dados.CPF.replace(/\D/g, ""),
        email,
        telefone: phone,
        senha,
        perfilNome,
        // aceiteTermos: false,
      })
      .then((res) => {
        notification("success", "Cadastro efetuado com sucesso!");
        setUsuarioId(res.usuarioId);
        setFase("PAGAMENTO");
        setSalvando(false);
        console.log("cadastro", res);
        return login(res.token);
      })
      .catch((e) => {
        const { status } = e.response;

        if (status === 1) {
          return notification("error", "Nome inválido");
        }
        if (status === 2) {
          return notification("error", "Email inválido");
        }
        if (status === 3) {
          return notification("error", "Senha inválida");
        }
        if (status === 4) {
          return notification("error", "CPF inválido");
        }
        if (status === 5) {
          return notification("error", "Já existe conta com o email");
        }
        console.log(e);

        setSalvando(false);
      });
  };

  const handleCPFChange = (event) => {
    let value = event.target.value.replace(/\D/g, "");
    if (value.length > 3) {
      value = value.slice(0, 3) + "." + value.slice(3);
    }
    if (value.length > 7) {
      value = value.slice(0, 7) + "." + value.slice(7);
    }
    if (value.length > 11) {
      value = value.slice(0, 11) + "-" + value.slice(11, 13);
    }
    preencher("CPF", value);
  };

  const preencher = (campo, valor) => {
    setDados((prevState) => ({
      ...prevState,
      [campo]: valor,
    }));
  };

  const continuarDados = () => {
    let isOk = true;

    const {
      nome,
      perfilNome,
      email,
      senha,
      confirmacaoSenha,
      deAcordoTermos,
      phone,
    } = dados;

    if (nome.length < 3) {
      isOk = false;
      notification("warning", "Nome do responsável inválido");
    }

    if (perfilNome.length < 3) {
      isOk = false;
      notification("warning", "Nome da criança inválido");
    }

    if (email.length < 5 || !email.includes("@") || !email.includes(".")) {
      isOk = false;
      notification("warning", "Email inválido");
    }

    const phoneResult = phone.replace(/_/g, "");

    if (phoneResult === "" || phoneResult.length < 12) {
      isOk = false;
      notification("warning", "Telefone inválido");
    }

    if (senha.length < 3) {
      isOk = false;
      notification("warning", "Senha inválida");
    }

    if (senha !== confirmacaoSenha) {
      isOk = false;
      notification("warning", "A confirmação não confere com a senha");
    }

    if (!deAcordoTermos) {
      isOk = false;
      notification(
        "warning",
        "Para continuar, é necessário estar de acordo com os termos de uso."
      );
    }

    if (!isOk) return;

    localStorage.removeItem("@IJ-DadosAulaExperimental-Nome");
    localStorage.removeItem("@IJ-DadosAulaExperimental-Email");
    localStorage.removeItem("@IJ-DadosAulaExperimental-Telefone");

    cadastrar();
  };

  if (isAuthenticated()) return <Redirect to={{ pathname: "/app" }} />;

  switch (fase) {
    case "DADOS":
      return (
        <Dados
          dados={dados}
          handleCPFChange={handleCPFChange}
          preencher={preencher}
          continuar={continuarDados}
          history={history}
          salvando={salvando}
        />
      );

    case "PAGAMENTO":
      return (
        <Pagamento
          usuarioId={usuarioId}
          continuar={() => history.push("/confirmacao-assinatura")}
          location={location}
          identificadorPromocao={promocao}
          identificadorPlano={plano}
        />
      );

    default:
      return null;
  }
};

const Dados = ({
  dados,
  handleCPFChange,
  preencher,
  continuar,
  history,
  salvando,
}) => (
  <>
    {window.innerWidth > 800 ? (
      <div className="pagina-login">
        <div className="container-login">
          <img
            alt="Logo da Inglês Juntos"
            className="logo-login"
            src={require("../assets/imagens/logo.png")}
          />
          <h3>
            Descubra como o inglês pode se tornar parte da rotina da sua
            família!
          </h3>

          <p>Informe os dados abaixo para criar uma nova conta</p>
          <form
            className="form-login"
            onSubmit={(e) => {
              e.preventDefault();
              continuar();
            }}
          >
            <input
              type="text"
              placeholder="Nome do responsável"
              id="nome"
              onChange={(e) => preencher("nome", e.target.value)}
              value={dados.nome}
            />
            <input
              type="text"
              placeholder="CPF do responsável"
              id="CPF"
              onChange={handleCPFChange}
              value={dados.CPF}
            />
            <input
              type="text"
              placeholder="Email"
              id="email"
              onChange={(e) => preencher("email", e.target.value)}
              value={dados.email}
            />

            <InputMask
              mask="99 99999-9999"
              id="phone"
              type="phone"
              placeholder="Telefone com DDD"
              onChange={(e) => preencher("phone", e.target.value)}
              value={dados.phone}
              required
            />

            <input
              type="password"
              placeholder="Senha"
              id="IJ-cadastro-senha"
              onChange={(e) => preencher("senha", e.target.value)}
              value={dados.senha}
            />
            <input
              type="password"
              placeholder="Confirmar senha"
              id="IJ-cadastro-confirmar-senha"
              onChange={(e) => preencher("confirmacaoSenha", e.target.value)}
              value={dados.confirmacaoSenha}
            />
            <input
              type="text"
              placeholder="Nome do aluno"
              id="perfilNome"
              onChange={(e) => preencher("perfilNome", e.target.value)}
              value={dados.perfilNome}
            />
            <div className="checkbox-container">
              <Checkbox
                checked={dados.deAcordoTermos}
                onChange={() =>
                  preencher("deAcordoTermos", !dados.deAcordoTermos)
                }
                name="checkedB"
                style={{ color: "#008fee" }}
              />{" "}
              Estou de acordo com os{" "}
              <span
                style={{ color: "#008fee", cursor: "pointer" }}
                onClick={() =>
                  window.open(`${window.location.origin}/termos-uso`, "_blank")
                }
              >
                termos de uso
              </span>{" "}
              do Inglês Juntos.
            </div>

            {salvando ? (
              <CircularProgress />
            ) : (
              <input
                type="submit"
                className="btn-padrao-lp"
                value="Continuar"
                style={{ display: "flex", justifyContent: "center" }}
              />
            )}
          </form>
          <div className="footer-nav">
            <button
              className="btn-link ali-left"
              onClick={() => history.push("/")}
            >
              Ir para o login
            </button>
          </div>
        </div>
      </div>
    ) : (
      <div>
        <div id="logo" style={{ textAlign: "center", padding: 10 }}>
          <img
            style={{ width: 300 }}
            alt="Logo da inglês juntos"
            src={require("../assets/imagens/logo.png")}
          />

          <h3>
            Descubra como o inglês pode se tornar parte da rotina da sua
            família!
          </h3>

          <p>Informe os dados abaixo para criar uma nova conta</p>
        </div>

        <form
          style={{ padding: 10 }}
          className="login"
          onSubmit={(e) => {
            e.preventDefault();
            continuar();
          }}
        >
          <input
            type="text"
            placeholder="Nome do responsável"
            id="nome"
            onChange={(e) => preencher("nome", e.target.value)}
            value={dados.nome}
          />
          <input
            type="text"
            placeholder="CPF do responsável"
            id="CPF"
            onChange={handleCPFChange}
            value={dados.CPF}
          />
          <input
            type="text"
            placeholder="Email"
            id="email"
            onChange={(e) => preencher("email", e.target.value)}
            value={dados.email}
          />

          <InputMask
            mask="99 99999-9999"
            id="phone"
            type="phone"
            placeholder="Telefone com DDD"
            onChange={(e) => preencher("phone", e.target.value)}
            value={dados.phone}
            required
          />

          <input
            type="password"
            placeholder="Senha"
            id="IJ-cadastro-senha"
            onChange={(e) => preencher("senha", e.target.value)}
            value={dados.senha}
          />
          <input
            type="password"
            placeholder="Confirmar senha"
            id="IJ-cadastro-confirmar-senha"
            onChange={(e) => preencher("confirmacaoSenha", e.target.value)}
            value={dados.confirmacaoSenha}
          />
          <input
            type="text"
            placeholder="Nome do aluno"
            id="perfilNome"
            onChange={(e) => preencher("perfilNome", e.target.value)}
            value={dados.perfilNome}
          />

          <div style={{ paddingBottom: 10, textAlign: "left", fontSize: 18 }}>
            <Checkbox
              checked={dados.deAcordoTermos}
              onChange={() =>
                preencher("deAcordoTermos", !dados.deAcordoTermos)
              }
              name="checkedB"
              style={{ color: "#008fee" }}
            />{" "}
            Estou de acordo com os{" "}
            <span
              style={{ color: "#008fee", cursor: "pointer" }}
              onClick={() =>
                window.open(`${window.location.origin}/termos-uso`, "_blank")
              }
            >
              termos de uso
            </span>{" "}
            do Inglês Juntos.
          </div>

          {salvando ? (
            <CircularProgress />
          ) : (
            <input
              type="submit"
              className="btn-entrar"
              value="Continuar"
              style={{ display: "flex", justifyContent: "center" }}
            />
          )}
        </form>

        <div style={{ textAlign: "center", paddingBottom: 20 }}>
          <button
            style={{
              textDecoration: "none",
              fontSize: "1em",
              fontWeight: 500,
              color: "#008fee",
              background: "none",
              border: "none",
              outline: "none",
            }}
            onClick={() => history.push("/")}
          >
            Ir para o login
          </button>
        </div>
      </div>
    )}
  </>
);

export default SignUp;
