/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';
import { click, win } from '../libs/soundEffects';
import {
  BoxCards,
  BoxTopo,
  BoxVideo,
  BtnRepeat,
  Cards,
  ItemBox1,
  ItemBox2,
  Text,
  BoxConfirmation,
  BtnConcluir,
} from './styles/stylesVisualizarEscolher';

let audio = undefined;

export default function VisualizarEscolher({ config }) {
  const qtdRodadas = config.rodadas ? config.rodadas.length : 0;

  const [rodadaAtual, setRodadaAtual] = useState(undefined);
  const [numRodadaAtual, setNumRodadaAtual] = useState(0);
  const [abertura, setAbertura] = useState(true);
  const [respostaOk, setRespostaOk] = useState(undefined);
  const [itensSelecionados, setItensSelecionados] = useState([]);
  const [itensRevelados, setItensRevelados] = useState([]);

  useEffect(() => {
    if (config.audioInicio) {
      const audio = new Audio(config.audioInicio);

      audio.play();
    }

    iniciarRodada(1);
  }, [config.audioInicio]);

  const iniciarRodada = useCallback(
    (numero) => {
      const rodada = config.rodadas[numero - 1];

      if (!rodada) return;

      setNumRodadaAtual(numero);
      setRodadaAtual(rodada);
      setItensSelecionados([]);
      setItensRevelados([]);
      setAbertura(true);
      setRespostaOk(false);

      if (config.audioAbertura) {
        audio = new Audio(config.audioAbertura);
        audio.play();
      }

      setTimeout(
        () => {
          if (rodada.audioEnunciado) {
            audio = new Audio(rodada.audioEnunciado);

            if (rodada.atrasoAudio) {
              setTimeout(() => audio.play(), rodada.atrasoAudio * 1000);
            } else {
              audio.play();
            }
          }

          setAbertura(false);
        },
        config.tempoAbertura ? config.tempoAbertura * 1000 : 0
      );
    },
    [
      config.audioAbertura,
      config.modalidade,
      config.rodadas,
      config.tempoAbertura,
    ]
  );

  const selecionarItem = (index, item) => {
    if (item.audio) {
      setTimeout(() => {
        audio = new Audio(item.audio);
        audio.play();

        virarCard(index, item);
      }, item.duracaoAudio * 1000);
    } else {
      virarCard(index, item);
    }
  };

  const virarCard = (index, item) => {
    if (abertura || itensSelecionados.includes(index)) return;

    click();
    const itensSelecionadosNovo = [...itensSelecionados];

    itensSelecionadosNovo.push(index);

    setItensSelecionados(itensSelecionadosNovo);

    if (item.id === rodadaAtual.itemEncontrar) {
      win();

      setRespostaOk(true);
    }
  };

  const avancar = () => {
    if (numRodadaAtual === qtdRodadas) {
      return config.avancar ? config.avancar() : alert('acabou!');
    } else {
      iniciarRodada(numRodadaAtual + 1);
    }
  };

  if (!rodadaAtual) return null;

  return (
    <>
      <BoxTopo>
        <ItemBox1>
          <Text>{abertura ? config.textoAbertura : rodadaAtual.enunciado}</Text>
        </ItemBox1>
        <ItemBox2>
          <BtnRepeat onClick={() => iniciarRodada(1)}>
            <i className="fas fa-undo-alt"></i> REPETIR
          </BtnRepeat>
        </ItemBox2>
      </BoxTopo>

      {respostaOk && (
        <BoxConfirmation>
          <Text>PARABÉNS! VOCÊ ACERTOU!</Text>
          <BtnConcluir onClick={avancar}>
            CONTINUAR <i className="fas fa-chevron-right"></i>
          </BtnConcluir>
        </BoxConfirmation>
      )}

      {abertura ? (
        <BoxVideo>
          <video autoPlay={true} src={rodadaAtual.video}></video>
        </BoxVideo>
      ) : (
        <BoxCards>
          {rodadaAtual.opcoes.map((opcao, key) => (
            <Cards
              key={key}
              className={
                itensSelecionados.includes(key) && opcao.correta
                  ? 'tl1-figura-correta'
                  : 'tl1-figura-0'
              }
              style={
                abertura ||
                itensRevelados.includes(key) ||
                itensSelecionados.includes(key)
                  ? {
                      backgroundImage: `url(${opcao.imagemAtras})`,
                    }
                  : { backgroundImage: `url(${opcao.imagemFrente})` }
              }
              onClick={
                abertura || itensSelecionados.includes(key)
                  ? undefined
                  : () => selecionarItem(key, opcao)
              }
            ></Cards>
          ))}
        </BoxCards>
      )}
    </>
  );
}
