import styled from 'styled-components';

export const BoxTopo = styled.div`
  grid-template-columns: 700px auto;
  display: grid;
  margin: 20px auto;
  padding: 15px;
  max-width: 970px;
  border-radius: 15px;
  background: #75dfeb;
  box-shadow: 8px 7px 10px -4px rgba(0, 0, 0, 0.14);

  @media (max-width: 980px) {
    grid-template-columns: auto;
    width: 86%;
  }
`;

export const ItemBox1 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;

  @media (max-width: 980px) {
    justify-content: center;
    text-align: center;
  }
`;

export const Text = styled.p`
  font-size: 26px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: #fff;

  @media (max-width: 980px) {
    p {
      margin-right: 0%;
      font-size: 25px;
    }
  }
`;

export const ItemBox2 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 980px) {
    justify-content: center;
  }
`;

export const Button = styled.a`
  padding: 18px;
  font-size: 25px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: #fff;
  width: 180px;
  border-radius: 15px;
  background: #91dbe4;
  box-shadow: 3px 7px 13px -7px rgba(0, 0, 0, 0.3);
  cursor: pointer;

  :hover {
    box-shadow: inset 0px 0px 5px 6px rgba(0, 0, 0, 0.04);
  }

  @media (max-width: 980px) {
    padding: 10px;
    font-size: 16px;
    width: 110px;
  }
`;

export const Content = styled.div`
  text-align: center;
  height: 250px;
  img {
    height: 180px;
  }

  @media (max-width: 980px) {
    img {
      height: 150px;
    }
  }
`;

export const BoxBotaoCentro = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #75dfeb;
  border-radius: 15px;
  padding: 15px;
  max-width: 970px;
`;

export const BtnConcluir = styled.a`
  padding: 18px;
  font-size: 25px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: #fff;
  min-width: 180px;
  border-radius: 15px;
  background: #5be389;
  box-shadow: 3px 7px 13px -7px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  text-decoration: none !important;
`;
