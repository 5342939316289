import styled from 'styled-components';

export const BoxTopo = styled.div`
  grid-template-columns: 700px auto;
  display: grid;
  margin: 20px auto;
  padding: 15px;
  max-width: 970px;
  border-radius: 15px;
  background: #75dfeb;
  box-shadow: 8px 7px 10px -4px rgba(0, 0, 0, 0.14);

  @media (max-width: 980px) {
    grid-template-columns: auto;
    width: 86%;
  }
`;

export const ItemBox1 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;

  @media (max-width: 980px) {
    justify-content: center;
    text-align: center;
  }
`;

export const Text = styled.p`
  font-size: 26px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: #fff;

  @media (max-width: 980px) {
    p {
      margin-right: 0%;
      font-size: 25px;
    }
  }
`;

export const Text2 = styled.p`
  font-size: 26px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: black;

  @media (max-width: 980px) {
    p {
      margin-right: 0%;
      font-size: 25px;
    }
  }
`;

export const ItemBox2 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 980px) {
    justify-content: center;
  }
`;

export const BtnRepeat = styled.a`
  padding: 18px;
  font-size: 25px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: #fff;
  width: 180px;
  border-radius: 15px;
  background: #91dbe4;
  box-shadow: 3px 7px 13px -7px rgba(0, 0, 0, 0.3);
  cursor: pointer;

  :hover {
    box-shadow: inset 0px 0px 5px 6px rgba(0, 0, 0, 0.04);
  }

  @media (max-width: 980px) {
    padding: 10px;
    font-size: 16px;
    width: 110px;
  }
`;

export const Content = styled.div`
  cursor: default;
  text-align: center;
  height: 350px;
  img {
    height: 350px;
    border-radius: 15px;
  }

  @media (max-width: 980px) {
    height: 210px;
    img {
      height: 200px;
    }
  }
`;

export const BoxBotaoCentro = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #75dfeb;
  border-radius: 15px;
  padding: 15px;
  max-width: 970px;
  margin: 20px auto;

  @media (max-width: 980px) {
    grid-template-columns: auto;
    width: 86%;
  }
`;

export const BtnConcluir = styled.a`
  padding: 18px;
  font-size: 25px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: #fff;
  min-width: 180px;
  border-radius: 15px;
  background: #5be389;
  box-shadow: 3px 7px 13px -7px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  text-decoration: none !important;
`;

export const Tl8Box2 = styled.div`
  background: rgb(255, 255, 255);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (max-width: 980px) {
    grid-template-columns: auto auto auto auto;
    width: 92%;
    height: 500px;
  }
`;

export const BoxConfirmation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: grid;
  /* width: 524px; */
  height: 250px;
  text-align: center;
  background: rgb(3, 255, 255, 0.8);
  border-radius: 15px;
`;

export const Container = styled.div`
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: center;
`;

export const BoxPontuacao = styled.div`
  display: grid;
  padding: 15px;
  max-width: 970px;
  border-radius: 15px;
  background: #75dfeb;
  box-shadow: 8px 7px 10px -4px rgba(0, 0, 0, 0.14);
  text-align: center;
  margin: 20px auto;
`;

export const BoxLamp = styled.div`
  cursor: pointer;
  img {
    height: 300px;
  }

  @media (max-width: 980px) {
    img {
      height: 200px;
    }
  }
`;

export const Tela1Box2 = styled.div`
  padding: 15px;
  grid-gap: 20px;
  display: flex;
  margin: 20px auto;
  max-width: 970px;
  border-radius: 15px;
  background: rgb(233, 187, 63);
  box-shadow: inset 0px 0px 5px 6px rgba(0, 0, 0, 0.04);

  @media (max-width: 420px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 980px) {
    padding: 15px;
    grid-gap: 15px;
    grid-template-columns: auto;
    width: 86%;
    height: auto;
  }
`;

export const Tela1Fig0 = styled.div`
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 10px solid #fff;
  border-radius: 15px;
  background: #ffffff;
  text-align: center;
  cursor: pointer;
  max-width: 450px;
  overflow: hidden;

  video {
    height: 350px;
  }

  button {
    display: none;
  }

  @media (max-width: 900px) {
    height: 215px;
    video {
      height: 215px;
    }
  }
`;

export const customModal = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '15px',
    background: 'rgb(233, 187, 63)',
  },
};

export const TelaModal = styled.div`
  border-radius: 15px;

  cursor: pointer;
  overflow: hidden;

  video {
    height: 400px;
  }

  @media (max-width: 980px) {
    video {
      height: 280px;
    }
  }

  @media (max-width: 420px) {
    max-width: 220px;
    max-height: 200px;
  }
`;

export const BoxClose = styled.div`
  text-align: end;
  margin-bottom: 8px;
`;

export const BtnEsc = styled.button`
  border: none;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: #fff;
  width: 50px;
  border-radius: 15px;
  background: #ff5141;
  box-shadow: 3px 7px 13px -7px rgba(0, 0, 0, 0.3);
  cursor: pointer;

  :hover {
    box-shadow: inset 0px 0px 5px 6px rgba(0, 0, 0, 0.04);
  }

  @media (max-width: 980px) {
    width: 50px;
  }
`;

export const BoxVideo = styled.div`
  padding: 15px;
  margin: 20px auto;
  max-width: 970px;
  text-align: center;
  border-radius: 15px;
  background: rgb(233, 187, 63);

  video {
    height: 400px;
    border-radius: 15px;
    border: 20px solid #fff;
  }

  @media (max-width: 980px) {
    video {
      height: 280px;
      border-radius: 15px;
      border: 20px solid #fff;
    }
  }
`;

export const BoxCards = styled.div`
  padding: 15px;
  margin: 20px auto;
  max-width: 970px;
  text-align: center;
  border-radius: 15px;
  background: rgb(233, 187, 63);
  /* background: rgb(255, 255, 255); */
  display: flex;
  justify-content: center;
`;

export const Cards = styled.div`
  border-radius: 10px;
  background: rgb(255, 252, 54);
  box-shadow: 8px 7px 10px -4px rgba(0, 0, 0, 0.14);
  cursor: pointer;
  height: 260px;
  width: 225px;
  margin-right: 10px;

  :hover {
    /* opacity: 0; */
    cursor: pointer;
  }

  background-size: 129px;
  background-repeat: no-repeat;

  @media (max-width: 980px) {
    height: 150px;
    background-size: 60px 156px;
  }
`;

export const TelaFigura = {
  // display: flex;
  // flex-direction: row;
  // justify-content: center;
  // padding: 10px;
  // flex-direction: column;
  // align-items: center;
  border: '10px solid #1dd138',
  // border-radius: 15px;
  // background: #ffffff;
  // /* max-width: 250px; */
  // overflow: hidden;
  // /* background: rgb(144 197 153); */
  // text-align: center;
  // cursor: pointer;
};
