/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react';
import {click, win, wrong} from '../../libs/soundEffects';

import {FaSyncAlt} from 'react-icons/fa'

import { Container, EnunciatedContainer, OptionsContainer, ImageContainer } from './styles';

let audio = undefined;

function MultiplaEscolha({config}) {
  const qtdRodadas = config.rodadas ? config.rodadas.length : 0;

  const [rodadaAtual, setRodadaAtual] = useState(undefined);
  const [numRodadaAtual, setNumRodadaAtual] = useState(0);
  const [respostaOk, setRespostaOk] = useState(undefined);
  const [selecionadas, setSelecionadas] = useState([]);
  const [corretasSelecionadas, setCorretasSelecionadas] = useState([]);
  const [textoConfirmacao, setTextoConfirmacao] = useState('');
  const [opcaoSelecionada, setOpcaoSelecionada] = useState({});
  const [textoResposta, setTextoResposta] = useState('');
  const [time, setTime] = useState(false);

  useEffect(() => {
    if(config.audioInicio){
        const audio = new Audio(config.audioInicio);

        audio.play();        
    }

    iniciarRodada(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  const iniciarRodada = (numero) => {
    const rodada = config.rodadas[numero - 1];

    if(!rodada) return;

    if(rodada.audioEnunciado){            
        audio = new Audio(rodada.audioEnunciado);
        
        if(rodada.atrasoAudio){
            setTimeout(() => audio.play(), rodada.atrasoAudio*1000)
        } else {
            audio.play();
        }
    }

    setNumRodadaAtual(numero);
    setRodadaAtual(rodada);
    setRespostaOk(false);
    setSelecionadas([]);
    setCorretasSelecionadas([]);
    setTextoResposta('');
    setTime(false);
  }

  const selecionar = (numOpc, opcao) => {
    if(opcao.audio){
        const audioOpc = new Audio(opcao.audio);
        audioOpc.play();
    }

    setSelecionadas([numOpc]);
    setOpcaoSelecionada(opcao);
  }

  const responder = (numOpc, opcao) => {
    const opcSelecionadas = [...selecionadas];

    if(!selecionadas.includes(numOpc)) opcSelecionadas.push(numOpc);

    setSelecionadas(opcSelecionadas);

    if(audio) audio.pause();

    if(opcao.correta || rodadaAtual.qualquerOpcao){
        if(opcao.audio){
            const audioOpc = new Audio(opcao.audio);
            audioOpc.play();
        }

        if(rodadaAtual.multipla){
            const opcCorretasSelecionadas = [...corretasSelecionadas];

            if(!corretasSelecionadas.includes(numOpc)) opcCorretasSelecionadas.push(numOpc);
            
            if(opcCorretasSelecionadas.length < rodadaAtual.opcoes.filter(o => o.correta).length){
                return setCorretasSelecionadas(opcCorretasSelecionadas);
            }
        }

        if(opcao.audio){
            setTimeout(() => win(), opcao.duracaoAudio ? opcao.duracaoAudio*1000 : 2200);
        } else {
            win();
        }

        if(opcao.textoConfirmacao) setTextoConfirmacao(opcao.textoConfirmacao);
        
        switch(config.midia){
            case 'imagem':
                return setRespostaOk(true);

            case 'video':
                return setRespostaOk(opcao.video);

            case 'texto':
                return setRespostaOk(true);

            default:
                return setRespostaOk(opcao.imagem);
        }
    } else {
        if(opcao.audio){
            const audioOpc = new Audio(opcao.audio);
            audioOpc.play();
        } else {
            wrong();
        }
    }
  }

  const avancar = () => {
    click();

    if(numRodadaAtual === qtdRodadas){
        return config.avancar ? config.avancar() : alert('Fim!');
    } else {
        iniciarRodada(numRodadaAtual + 1);
    }
  }

  const validarTexto = () => {
    if(textoResposta.toLowerCase() === opcaoSelecionada.palavra){
        win();
        return avancar();
    }
    
    wrong();
  }

  let classeColunas = '';
  let alturaImagem = 0;

  if(!rodadaAtual) return null;

  // OUTRAS COISAS AQUI

  let opcoesExibir = [];

  if(rodadaAtual.livre){
    opcoesExibir = rodadaAtual.opcoes.filter((opcao, i) => selecionadas.includes(i + 1));
  } else {
    opcoesExibir = rodadaAtual.opcoes.filter(opcao => opcao.correta);
  }

  return (
    <Container>
      {rodadaAtual && (
        <>
          <EnunciatedContainer>
            <p>{rodadaAtual.enunciado}</p>
            <div onClick={() => iniciarRodada(1)}>
              <FaSyncAlt size={30} color="#fff" />
              <p>REPETIR</p>
            </div>
          </EnunciatedContainer>

          <OptionsContainer render={config.midia === 'texto'} length={rodadaAtual.opcoes.length === 4}>
            {config.midia === 'imagem' && (
              <div>
                {rodadaAtual.opcoes.map((opcao, key) => (
                  <ImageContainer key={key} onClick={() => rodadaAtual.escrever ? selecionar(key + 1, opcao) : responder(key + 1, opcao)} 
                  style={{
                    border: `${(selecionadas.includes(key + 1)) ? 
                      ((opcao.correta || rodadaAtual.qualquerOpcao) ? '4px solid #00e600': "4px solid #ff0000") 
                      : 
                      "none"
                  }}`
                  }}>
                    <img src={opcao.imagem} alt="Imagem Alternativa"/>
                  </ImageContainer>
                ))}
              </div>
            )}

            {config.midia === 'texto' && (
              rodadaAtual.opcoes.map((opcao, key) => (
                <div key={key}  onClick={() => rodadaAtual.escrever ? selecionar(key + 1, opcao) : responder(key + 1, opcao)}>
                  <p>{opcao.texto}</p>
                </div>
              ))
            )}
          </OptionsContainer>
        </>
      )}
    </Container>
  )
}

export default MultiplaEscolha;