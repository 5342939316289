import { jwtDecode } from "jwt-decode";
import { getToken } from "./auth";

export default () => {
  const userData = jwtDecode(getToken());

  const cor = localStorage.getItem("cor");
  const avatar = localStorage.getItem("avatar");

  userData.cor = cor ? cor : "#0894CE";
  userData.avatar = avatar ? avatar : "personagem-01.png";

  return userData;
};
