import { Button, CircularProgress } from "@mui/material";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
const idContaIugu = process.env.REACT_APP_IUGU_CONTA_ID;

export default function TokenCartaoTestes() {
  const [tokenGerado, setTokenGerado] = useState("");
  const [processarCompra, setProcessarCompra] = useState(false);
  const [msg, setMsg] = useState("");

  const salvarPagamento = () => {
    setProcessarCompra(true);
    const cartao = {
      numero: "4111111111111111",
      nome: "TESTE",
      sobrenome: "TESTE",
      expiracaoMes: 12,
      expiracaoAno: 2030,
      codigoVerificador: 123,
    };
    try {
      const Iugu = window.Iugu;
      Iugu.setAccountID(idContaIugu);
      Iugu.setTestMode(true);
      const cc = Iugu.CreditCard(
        cartao.numero.replace(/\s+/g, ""),
        cartao.expiracaoMes,
        cartao.expiracaoAno,
        cartao.nome,
        cartao.sobrenome,
        cartao.codigoVerificador
      );
      Iugu.createPaymentToken(cc, (response) => {
        if (response.errors) {
          if (response.errors.number) {
            setMsg("Número do cartão inválido.");
          }
          if (response.errors.verification_value) {
            setMsg("Iugu: Código verificador inválido.");
          }
          setProcessarCompra(false);
        } else {
          const requisicao = {
            //usuarioId,
            token: response.id,
            descricao: `${response.extra_info.brand} ${response.extra_info.display_number}`,
          };
          setTokenGerado(requisicao.token);
          setProcessarCompra(false);
        }
      });
    } catch (e) {
      setMsg(
        "Não foi possível conectar-se ao servidor da Iugu para processar o pagamento. Por favor tente novamente mais tarde."
      );
      setProcessarCompra(false);
      console.log(e);
    }
  };

  return (
    <div
      style={{
        textAlign: "center",
        paddingTop: 50,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Helmet>
        <script type="text/javascript" src="https://js.iugu.com/v2"></script>
      </Helmet>
      <div>
        <div>
          <Button variant="contained" color="primary" onClick={salvarPagamento}>
            Gerar token
          </Button>
        </div>

        <div
          style={{
            background: "white",
            width: 200,
            marginTop: 10,
            borderRadius: 5,
          }}
        >
          <p>Token gerado:</p>
          <p>{tokenGerado}</p>
        </div>

        {processarCompra && (
          <div
            style={{
              background: "white",
              width: 200,
              marginTop: 10,
              borderRadius: 5,
              color: "red",
            }}
          >
            <CircularProgress />
          </div>
        )}

        {msg && (
          <div
            style={{
              background: "white",
              width: 200,
              marginTop: 10,
              borderRadius: 5,
              color: "red",
            }}
          >
            <p>{msg}</p>
          </div>
        )}
      </div>
    </div>
  );
}
