import React, { Fragment, useEffect, useState } from "react";
import Routes from "./routes";

const ErrorBoundary = ({ children }) => {
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState(null);
  const [errorInfo, setErrorInfo] = useState(null);
  const [handleError, setHandleError] = useState(false);

  useEffect(() => {
    const handleCatch = (error, errorInfo) => {
      setError(error);
      setErrorInfo(errorInfo);
      setHasError(true);
    };

    const originalErrorBoundary = Error.prototype.toString;
    Error.prototype.toString = function () {
      handleCatch(this, this.stack);
      return originalErrorBoundary.call(this);
    };

    return () => {
      Error.prototype.toString = originalErrorBoundary;
    };
  }, []);

  if (hasError && window.innerWidth >= 800) {
    return (
      <div className="container_Page_Error">
        <div className="page_Error">
          {!handleError && (
            <img
              className="logo-login"
              alt="Logo da inglês juntos"
              src={require("./assets/imagens/logo.png")}
            />
          )}
          <div className="container_Error">
            <div className="title_Error">
              Ops... aconteceu algo inesperado e não foi possível prosseguir.
              Por favor, recarregue a página e tente novamente.
              <br />
              <br />
              Se o erro persistir, entre em contato conosco através do email
              contato@inglesjuntos.com.br ou pelo Whatsapp 41 3013-5708.
            </div>
            {handleError && (
              <div className="error_Stack">
                <div className="error_Text">
                  {error && error.toString()}
                  <br />
                  {errorInfo?.componentStack}
                </div>
              </div>
            )}
            <input
              type="submit"
              className="btn-padrao-lp"
              value="Recarregar Página"
              onClick={() => {
                window.location.reload();
              }}
            />
          </div>
          <p
            className="error_Link"
            onClick={() => setHandleError(!handleError)}
          >
            Detalhes (aréa técnica)
          </p>
        </div>
      </div>
    );
  }

  if (hasError && window.innerWidth < 800) {
    return (
      <div className="container_Page_Error">
        <div className="page_Error">
          {!handleError && (
            <img
              className="logo_Mobile"
              alt="Logo da inglês juntos"
              src={require("./assets/imagens/logo.png")}
            />
          )}

          <div className="container_Error">
            {handleError ? (
              <div className="error_Stack_Mobile">
                <div className="error_Text">
                  {error && error.toString()}
                  <br />
                  {errorInfo?.componentStack}
                </div>
              </div>
            ) : (
              <div className="title_Error_Mobile">
                Ops... aconteceu algo inesperado e não foi possível prosseguir.
                Por favor, recarregue a página e tente novamente.
                <br />
                <br />
                Se o erro persistir, entre em contato conosco através do email
                contato@inglesjuntos.com.br ou pelo Whatsapp 41 3013-5708.
              </div>
            )}
            <input
              type="submit"
              className="btn-padrao-lp"
              value="Recarregar Página"
              onClick={() => {
                window.location.reload();
              }}
            />
          </div>
          <p
            className="error_Link_Mobile"
            onClick={() => setHandleError(!handleError)}
          >
            Detalhes (aréa técnica)
          </p>
        </div>
      </div>
    );
  }

  return children;
};

const App = () => {
  useEffect(() => {
    const { location } = window;

    // if(location.protocol !== 'https:' && location.hostname !== 'localhost' && location.hostname !== '192.168.15.7') {
    //   location.replace(`https:${location.href.substring(location.protocol.length)}`);
    // }
  }, []);

  return (
    // <ErrorBoundary>
      <Fragment>
        <Routes />
        {/* <NotificationContainer /> */}
      </Fragment>
    // </ErrorBoundary>
  );
};

export default App;
