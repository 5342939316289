import React, { useState, useEffect } from "react";
// import Api from "../services/api";
import ijws from "../services/ijws";
import { Helmet } from "react-helmet";
import { Button } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const PasswordRecover = (props) => {
  const [exibirSenha, setExibirSenha] = useState(false);
  const [exibirSenhaConfirmar, setExibirSenhaConfirmar] = useState(false);
  const [token] = useState(props.match.params.token);
  const [nome, setNome] = useState("");
  const [senha, setSenha] = useState("");
  const [confirmacaoSenha, setConfirmacaoSenha] = useState("");
  const [mensagem, setMensagem] = useState("");
  const [tokenOk, setTokenOk] = useState(false);
  const [fim, setFim] = useState(false);

  useEffect(() => {
    if (!token || token === "") {
      setMensagem("Link inválido.");
    } else {
      ijws
        .get("usuarios/validarTokenRedefinicaoSenha", { token })
        .then((res) => {
          if (!res.valido) {
            setMensagem("Link inválido.");
          } else if (res.expirado) {
            setMensagem(
              "O link expirou. Solicite novamente a recuperação da senha."
            );
          } else if (res.valido) {
            setTokenOk(true);
            setNome(res.usuarioNome);
          }
        })
        .catch((err) => {
          console.log(err);
          setMensagem("Não foi possível validar o link.");
        });
    }
  }, [token]);

  const confirmar = () => {
    if (senha.length < 3) {
      setMensagem("A senha deve ter no mínimo 3 caracteres.");
      return;
    }

    if (senha !== confirmacaoSenha) {
      setMensagem("A confirmação é diferente da senha informada.");
      return;
    }
    ijws
      .post("usuarios/redefinirSenhaViaToken", { token, senha })
      .then((res) => {
        if (res.token) {
          setMensagem("Senha redefinida com sucesso!");
          setFim(true);
        }
      })
      .catch((err) => {
        const errCodes = err.data;

        if (errCodes) {
          if (errCodes.includes(1)) {
            setMensagem("Senha inválida (mínimo: 3 caracteres)");
          }
          if (errCodes.includes(2)) {
            setMensagem("Token expirado");
          }
        }
      });
  };

  return (
    <div>
      <Helmet>
        <title>Inglês Juntos - Redefinição de senha</title>
        <meta name="description" content="Reactify Blank Page" />
      </Helmet>

      <div className="rct-session-wrapper">
        <div>
          <div>
            <div>
              <div className="container-login-1">
                <img
                  className="logo-login"
                  style={{ marginTop: 40 }}
                  src={require("../assets/imagens/logo.png")}
                  alt="Logo da Inglês Juntos"
                />
                <div>
                  <h2>Redefinição de senha</h2>
                </div>
                <div className="text-container">
                  {mensagem !== "" && (
                    <h4 style={{ color: fim ? "green" : "red" }}>{mensagem}</h4>
                  )}
                  {tokenOk && !fim && (
                    <form className="form-login">
                      <input
                        type="text"
                        placeholder="Nome"
                        id="nome"
                        disabled={true}
                        value={nome}
                      />
                      <div style={{ width: "100%" }}>
                        <div className="box-password">
                          <input
                            type={exibirSenha ? "text" : "password"}
                            placeholder="Senha"
                            id="senha"
                            onChange={(e) => setSenha(e.target.value)}
                          />
                          <span
                            id="btn-olho"
                            onClick={() => setExibirSenha(!exibirSenha)}
                          >
                            <i className="material-icons">
                              {exibirSenha ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </i>
                          </span>
                        </div>
                        <div className="box-password">
                          <input
                            value={confirmacaoSenha}
                            type={exibirSenhaConfirmar ? "text" : "password"}
                            placeholder="Confirme a senha"
                            id="senha"
                            onChange={(e) =>
                              setConfirmacaoSenha(e.target.value)
                            }
                          />
                          <span
                            id="btn-olho"
                            onClick={() =>
                              setExibirSenhaConfirmar(!exibirSenhaConfirmar)
                            }
                          >
                            <i className="material-icons">
                              {exibirSenhaConfirmar ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </i>
                          </span>
                        </div>
                      </div>

                      <Button
                        color="primary"
                        className="text-white w-100"
                        variant="contained"
                        size="large"
                        onClick={confirmar}
                      >
                        Confirmar
                      </Button>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordRecover;
