import styled from 'styled-components';

export const InputText = styled.div`
  width: 70%;
  text-align: center;
  background: #75dfeb;
  margin-left: 15px;
  border-radius: 15px;
  div {
    width: 100%;
    border-radius: 15px;
  }
  input {
    text-align: center;
    text-transform: uppercase;
  }
`;

export const BoxTopo = styled.div`
  grid-template-columns: 700px auto;
  display: grid;
  margin: 20px auto;
  padding: 15px;
  max-width: 970px;
  border-radius: 15px;
  background: #75dfeb;
  box-shadow: 8px 7px 10px -4px rgba(0, 0, 0, 0.14);

  @media (max-width: 980px) {
    grid-template-columns: auto;
    width: 86%;
  }
`;

export const ItemBox1 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;

  @media (max-width: 980px) {
    justify-content: center;
    text-align: center;
  }
`;

export const Text = styled.p`
  font-size: 26px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: #fff;

  @media (max-width: 980px) {
    p {
      margin-right: 0%;
      font-size: 25px;
    }
  }
`;

export const Text2 = styled.p`
  font-size: 26px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: black;

  @media (max-width: 980px) {
    p {
      margin-right: 0%;
      font-size: 25px;
    }
  }
`;

export const ItemBox2 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 980px) {
    justify-content: center;
  }
`;

export const Button = styled.a`
  padding: 18px;
  font-size: 25px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: #fff;
  width: 180px;
  border-radius: 15px;
  background: #91dbe4;
  box-shadow: 3px 7px 13px -7px rgba(0, 0, 0, 0.3);
  cursor: pointer;

  :hover {
    box-shadow: inset 0px 0px 5px 6px rgba(0, 0, 0, 0.04);
  }

  @media (max-width: 980px) {
    padding: 10px;
    font-size: 16px;
    width: 110px;
  }
`;

export const Content = styled.div`
  cursor: default;
  text-align: center;
  height: 300px;
  img {
    cursor: default;
    height: 300px;
  }
`;

export const BoxBotaoCentro = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const BtnConcluir = styled.a`
  padding: 18px;
  font-size: 25px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: #fff;
  min-width: 180px;
  border-radius: 15px;
  background: #5be389;
  box-shadow: 3px 7px 13px -7px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  text-decoration: none !important;
`;

export const Tl8Box2 = styled.div`
  background: rgb(255, 255, 255);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (max-width: 980px) {
    grid-template-columns: auto auto auto auto;
    width: 92%;
    height: 500px;
  }
`;

export const BoxConfirmation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: grid;
  width: 524px;
  height: 524px;
  text-align: center;
  background: rgb(3, 255, 255, 0.8);
  border-radius: 15px;
`;

export const Container = styled.div`
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: space-between;
  svg {
    font-size: 6.5rem;
    color: #75dfeb;
  }

  div {
    cursor: pointer;
  }
`;

export const BoxPontuacao = styled.div`
  display: grid;
  padding: 15px;
  max-width: 970px;
  border-radius: 15px;
  background: #75dfeb;
  box-shadow: 8px 7px 10px -4px rgba(0, 0, 0, 0.14);
  text-align: center;
  margin: 20px auto;
`;

export const BoxImage = styled.div`
  text-align: center;

  img {
    width: 270px;
  }
`;

export const BoxCores = styled.div`
  border-radius: 8px;
  /* background: rgb(255, 255, 255); */
  background: #000000;
  cursor: pointer;
  box-shadow: 3px 7px 13px -7px rgba(0, 0, 0, 0.3);
  min-height: 20px;

  @media (max-width: 980px) {
    border-radius: 5px;
  }
`;

export const BoxAllColors = styled.div`
  grid-gap: 13px;
  grid-template-columns: 40px 40px 40px 40px;
  display: grid;
  margin: 0 auto;
  padding: 10px;
  border: 10px solid #fff;
  border-radius: 15px;
  background: rgb(238, 121, 106);
  cursor: pointer;

  @media (max-width: 980px) {
    grid-template-columns: 25px 25px 25px 25px;
    grid-gap: 8px;
    border: 7px solid #fff;
    border-radius: 15px;
    background: rgb(238, 121, 106);
  }
`;

export const BoxDesenho = styled.div`
  background-position: center;
  background-repeat: no-repeat;
  /* position: absolute; */
  width: 690px;
  height: 565px;
  pointer-events: none;
`;

export const BoxMoldura = styled.div`
  padding: 15px;
  grid-gap: 20px;
  grid-template-columns: 700px auto;
  display: grid;
  margin: 20px auto;
  max-width: 970px;
  /* height: 560px; */
  border-radius: 15px;
  background: rgb(233, 187, 63);
  box-shadow: inset 0px 0px 5px 6px rgba(0, 0, 0, 0.04);

  @media (max-width: 980px) {
    grid-gap: 15px;
    grid-template-columns: 100%;
    width: 86%;
    height: auto;
  }
`;

export const BoxMoldura2 = styled.div`
  padding: 15px;
  grid-gap: 20px;
  grid-template-columns: 700px auto;
  display: grid;
  margin: 20px auto;
  max-width: 700px;
  /* height: 560px; */
  border-radius: 15px;
  background: yellow;
  box-shadow: inset 0px 0px 5px 6px rgba(0, 0, 0, 0.04);

  @media (max-width: 980px) {
    grid-gap: 15px;
    grid-template-columns: 100%;
    width: 86%;
    height: auto;
  }
`;

export const BoxCanvas = styled.div`
  padding: 10px;
  border-radius: 15px;
  background: rgb(255, 255, 255);
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: 60%;
  background-position: center;
  background-size: contain;

  border: 30px solid yellow;
  width: 630px;
  height: 565px;
  margin: auto;
  margin-top: 20px;
  padding-bottom: 0;

  @media (max-width: 980px) {
    height: 250px;
    border: 10px solid #fff;
    border-radius: 15px;
    background: rgb(255, 255, 255);
  }
`;

export const DesenhoBacked = styled.div`
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  width: 635px;
  height: 555px;
  pointer-events: none;
`;

export const BoxMenu = styled.div`
  grid-row-gap: 12px;
  grid-template-columns: auto;
  display: grid;
  margin: inherit;

  @media (max-width: 980px) {
    grid-column-gap: 10px;
    grid-template-columns: 46% 46%;
    grid-row-start: 3;
    grid-row-end: 4;
    display: grid;
    margin: 0 auto;
  }
`;

export const BoxMenu2 = styled.div`
  grid-row-gap: 12px;
  grid-template-columns: auto;
  display: grid;
  text-align: center;
  align-items: center;
  margin: inherit;

  @media (max-width: 980px) {
    grid-column-gap: 10px;
    grid-template-columns: 46% 46%;
    grid-row-start: 3;
    grid-row-end: 4;
    display: grid;
    margin: 0 auto;
  }
`;

export const BoxMessage = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  /* margin: 0 auto; */
  border-radius: 15px;
  background: #75dfeb;
  width: 220px;

  @media (max-width: 980px) {
    grid-template-columns: 50px 50px;
    height: 140px;
    border: 7px solid #fff;
    border-radius: 15px;
    background: rgb(203, 253, 145);
  }
`;
