import React, { Component } from "react";
import Unity, { UnityContent } from "react-unity-webgl";
import serverHost from "../services/serverHost";

export default class App extends Component {
  state = {
    isLoadOk: true,
  };

  constructor(props) {
    super(props);

    const build = props.identificador
      ? props.identificador.split(";")[1]
      : undefined;

    if (build) {
      //const build = props.ide

      this.unityContent = new UnityContent(
        `${serverHost()}/unity/Builds/${build}/Build/${build}.json`,
        `${serverHost()}/unity/Builds/${build}/Build/UnityLoader.js`
      );
      // console.log(`${serverHost()}/unity/Builds/${build}/Build/Builds.json`);
      const referencia = props.identificador.split(";")[2]
        ? props.identificador.split(";")[2]
        : "";
      // alert(referencia);
      // Create a new listener for our on loaded Event.
      // When the unity player is loaded, the event will
      // fire.
      this.unityContent.on("loaded", () => {
        // Now we can for example hide the loading overlay.

        this.unityContent.send(
          "Communication Manager",
          "ReceivedId",
          referencia
        );
      });

      this.unityContent.on("GameOver", (score) => {
        this.props.avancar();
      });

      this.unityContent.on("Error", (score) => {
        this.props.avancar();
      });
    } else {
      this.state.isLoadOk = false;
    }
  }

  render() {
    const { isLoadOk } = this.state;

    return (
      <div style={{ height: 600 }}>
        {isLoadOk ? (
          <Unity unityContent={this.unityContent} />
        ) : (
          <h6>Não foi possível carregar o conteúdo da atividade.</h6>
        )}
      </div>
    );
  }
}
