import React, {
  useEffect,
  useState,
  Fragment,
  useContext,
  useRef,
} from "react";
import userData from "../services/userData";
// import api from "../services/api";
import ijws from "../services/ijws";
import YouTube from "react-youtube";
import soundEffects from "../libs/soundEffects";
import Unity from "../components/Unity";
import AtividadeHtml from "../components/AtividadeHtml";
import { Vimeo } from "../components/Vimeo";
import AppContext from "../container/context";
import {
  Button,
  createTheme,
  Grid,
  IconButton,
  ThemeProvider,
} from "@mui/material";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ReplayIcon from "@mui/icons-material/Replay";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const Unidade = ({
  match,
  configHeader,
  history,
  fullScreen,
  toggleScreen,
}) => {
  const { notification } = useContext(AppContext);
  const [aulaId] = useState(Number(match.params.id));
  const [unidadeId, setUnidadeId] = useState(0);
  const [userDataState] = useState(userData());
  const [itens, setItens] = useState([]);
  const [itemAtivo, setItemAtivo] = useState(0);
  const [larguraTela, setLarguraTela] = useState(window.innerWidth);
  const [erroYoutube, setErroYoutube] = useState(undefined);
  const divRef = useRef(null);

  useEffect(() => {
    const handleResize = () => setLarguraTela(window.innerWidth);
    window.addEventListener("resize", handleResize);
    carregar();

    setTimeout(() => {
      scrollToDiv();
    }, 1000);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [aulaId]);

  const scrollToDiv = () => {
    if (divRef.current) {
      divRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const carregar = () => {
    const { perfilId } = userDataState;
    let itemAtivoLocal = -1;
    let qtdItens = 0;
    let itensLocal = [];

    ijws
      .get("agenda/conteudo", { perfilId, aulaId })
      .then((res) => {
        let unidadeIdLocal = res.aulaNumero ? res.aulaNumero : 0;

        res.conteudo.forEach((video) => {
          if (video.videoReferencia && !video.videoUrlVimeo) {
            itensLocal.push({
              videoId: video.videoId,
              tipo: "VIDEO",
              referencia: video.videoReferencia,
              visualizado: video.perfilVideoVisualizacao,
              titulo: video.videoTitulo,
              numero: video.videoOrdem,
              aulaNumero: video.aulaNumero,
            });

            qtdItens += 1;
            if (itemAtivoLocal === -1 && !video.perfilVideoVisualizacao)
              itemAtivoLocal = qtdItens - 1;
          }

          if (video.videoUrlVimeo) {
            itensLocal.push({
              videoId: video.videoId,
              tipo: "VIMEO",
              url: video.videoUrlVimeo,
              visualizado: video.perfilVideoVisualizacao,
              titulo: video.videoTitulo,
              numero: video.videoOrdem,
              aulaNumero: video.aulaNumero,
            });

            qtdItens += 1;
            if (itemAtivoLocal === -1 && !video.perfilVideoVisualizacao)
              itemAtivoLocal = qtdItens - 2;
          }

          if (video.videoIdentificadorAtividade) {
            itensLocal.push({
              videoId: video.videoId,
              tipo: "ATIVIDADE",
              identificador: video.videoIdentificadorAtividade,
              visualizado: video.perfilVideoAtividadeRealizada,
              titulo: video.videoTitulo,
              numero: video.videoOrdem,
              aulaNumero: video.aulaNumero,
            });

            qtdItens += 1;

            if (itemAtivoLocal === -1 && !video.perfilVideoAtividadeRealizada)
              itemAtivoLocal = qtdItens - 3;
          }
        });

        itensLocal.push({ tipo: "FIM" });
        if (itemAtivoLocal === -1) itemAtivoLocal = 0;
        configHeader({
          titulo: definirTitulo({
            numero: unidadeIdLocal,
            titulo: itensLocal[itemAtivoLocal].titulo,
          }),
          contexto: "conteudo",
          voltar: () => history.goBack(),
        });
        setUnidadeId(unidadeIdLocal);
        setItens(itensLocal);
        setItemAtivo(itemAtivoLocal);
      })
      .catch((e) => console.log("Erro: " + e));
  };

  const registrarAcesso = (videoId, atividade) => {
    const { perfilId } = userDataState;

    ijws.post("perfis/registrarVideo", { perfilId, videoId, atividade });
  };

  const proximoItem = (auto = false) => {
    soundEffects.click();

    if (!itens[itemAtivo].visualizado && itens[itemAtivo].videoId) {
      registrarAcesso(
        itens[itemAtivo].videoId,
        itens[itemAtivo].tipo === "ATIVIDADE"
      );
    }

    if (itens[itemAtivo + 1]) {
      configHeader({
        titulo: definirTitulo({
          numero: unidadeId,
          titulo: itens[itemAtivo + 1].titulo,
        }),
        contexto: "conteudo",
      });
      setItemAtivo(itemAtivo + 1);
    } else {
      notification("success", "Aula finalizada!");
      history.goBack();
    }
  };

  const itemAnterior = () => {
    const qtdItensRetornar = itens[itemAtivo - 1].tipo === "ATIVIDADE" ? 2 : 1;

    if (itens[itemAtivo - qtdItensRetornar]) {
      soundEffects.click();
      configHeader({
        titulo: definirTitulo({
          numero: unidadeId,
          titulo: itens[itemAtivo - qtdItensRetornar].titulo,
        }),
        contexto: "conteudo",
      });
      setItemAtivo(itemAtivo - 1);
    }
  };

  const opts = {
    height: window.innerHeight - 30,
    width: "100%",
    playerVars: {
      autoplay: 0,
      controls: 2,
      modestbranding: 1,
      rel: 0,
      showinfo: 0,
      enablejsapi: 1,
    },
  };

  const item = itens[itemAtivo];
  if (!item) return null;

  const mobileSize = larguraTela < 600;

  return (
    <>
      {mobileSize ? (
        <div
          style={{
            padding: 15,
            backgroundColor: "white",
            borderRadius: 25,
            marginTop: 30,
            marginLeft: 10,
            marginRight: 10,
          }}
        >
          <h3>
            Para uma melhor experiência, utilize com o dispositivo no modo
            paisagem (deitado)!
          </h3>
        </div>
      ) : (
        <Grid container style={{ marginTop: 10 }}>
          <Grid
            item
            md={2}
            xs={1}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {itens[itemAtivo - 1] && (
              <IconButton size="large">
                <ArrowLeftIcon
                  style={{ fontSize: 130, color: "white" }}
                  onClick={itemAnterior}
                />
              </IconButton>
            )}
          </Grid>

          <Grid
            ref={divRef}
            item
            md={8}
            xs={10}
            style={{ background: "white", borderRadius: 10, padding: 6 }}
          >
            {item.tipo === "VIDEO" &&
              (erroYoutube ? (
                <h3>
                  Não foi possível reproduzir o vídeo. Código do erro:{" "}
                  {erroYoutube}
                </h3>
              ) : (
                <YouTube
                  videoId={item.referencia}
                  opts={opts}
                  // onReady={this._onReady}
                  onEnd={proximoItem}
                  onError={(ey) => setErroYoutube(ey.data)}
                />
              ))}

            {item.tipo === "ATIVIDADE" && (
              <>
                {item.identificador.substring(0, 1) === "U" ? (
                  <Unity
                    identificador={item.identificador}
                    avancar={() => proximoItem()}
                  />
                ) : (
                  <AtividadeHtml
                    identificador={item.identificador}
                    avancar={() => proximoItem()}
                    toggleScreen={toggleScreen}
                    // isMobile={mobileSize}
                  />
                )}
              </>
            )}

            {item.tipo === "VIMEO" && (
              <>
                <Vimeo url={item.url} />
              </>
            )}

            {item.tipo === "FIM" && (
              <div
                style={{
                  // height: 580,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p className="p-text" style={{ color: "#000" }}>
                  PARABÉNS! VOCÊ FINALIZOU A AULA!
                </p>

                <div style={{ display: "flex", flexDirection: "column" }}>
                  <ThemeProvider theme={theme}>
                    <Button
                      size="large"
                      style={{
                        color: "white",
                        justifyContent: "space-between",
                      }}
                      variant="contained"
                      color="primary"
                      onClick={() => setItemAtivo(0)}
                    >
                      Repetir <ReplayIcon style={{ marginLeft: 10 }} />
                    </Button>
                  </ThemeProvider>

                  <ThemeProvider theme={theme}>
                    <Button
                      size="large"
                      style={{
                        color: "white",
                        marginTop: 20,
                        justifyContent: "space-between",
                      }}
                      variant="contained"
                      color="secondary"
                      onClick={proximoItem}
                    >
                      CONTINUAR{" "}
                      <ArrowForwardIosIcon style={{ marginLeft: 10 }} />
                    </Button>
                  </ThemeProvider>
                  {/* <a
                    style={{ width: 245, marginBottom: 20 }}
                    className="btn-repetir"
                    onClick={() => setItemAtivo(0)}
                  >
                    <i className="fas fa-undo-alt"></i> REPETIR
                  </a>
                  <a
                    style={{ width: 245, textAlign: "center" }}
                    className="btn-concluir"
                    onClick={() => proximoItem()}
                  >
                    CONTINUAR{" "}
                    <i className="fas fa-chevron-right" aria-hidden="true"></i>
                  </a> */}
                </div>
              </div>
            )}
          </Grid>
          <Grid
            item
            md={2}
            xs={1}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {itens[itemAtivo + 1] && (
              <IconButton size="small">
                <ArrowRightIcon
                  style={{ fontSize: 130, color: "white" }}
                  onClick={() => proximoItem()}
                />
              </IconButton>
            )}
          </Grid>
        </Grid>
        // <div className="container-atividades">
        //   {fullScreen === false && (
        //     <>
        //       {itens[itemAtivo - 1] && (
        //         <div className="seta-01" onClick={itemAnterior}></div>
        //       )}

        //       {itens[itemAtivo + 1] && (
        //         <div className="seta-02" onClick={() => proximoItem()}></div>
        //       )}
        //     </>
        //   )}

        //   <div
        //     className={fullScreen === false ? "atividades" : ""}
        //     style={{ height: "100%" }}
        //   >
        //     {item.tipo === "VIDEO" &&
        //       (erroYoutube ? (
        //         <h3>
        //           Não foi possível reproduzir o vídeo. Código do erro:{" "}
        //           {erroYoutube}
        //         </h3>
        //       ) : (
        //         <YouTube
        //           videoId={item.referencia}
        //           opts={opts}
        //           // onReady={this._onReady}
        //           onEnd={() => proximoItem()}
        //           onError={(ey) => setErroYoutube(ey.data)}
        //         />
        //       ))}
        //     {item.tipo === "ATIVIDADE" && (
        //       <>
        //         {item.identificador.substring(0, 1) === "U" ? (
        //           <Unity
        //             identificador={item.identificador}
        //             avancar={() => proximoItem()}
        //           />
        //         ) : (
        //           <AtividadeHtml
        //             identificador={item.identificador}
        //             avancar={() => proximoItem()}
        //             toggleScreen={toggleScreen}
        //             // isMobile={mobileSize}
        //           />
        //         )}
        //       </>
        //     )}
        //     {item.tipo === "VIMEO" && (
        //       <>
        //         <Vimeo url={item.url} />
        //       </>
        //     )}
        //     {item.tipo === "FIM" && (
        //       <div
        //         style={{
        //           height: 580,
        //           display: "flex",
        //           flexDirection: "column",
        //           alignItems: "center",
        //           justifyContent: "center",
        //         }}
        //       >
        //         <p className="p-text" style={{ color: "#000" }}>
        //           PARABÉNS! VOCÊ FINALIZOU A AULA!
        //         </p>

        //         <div style={{ display: "flex", flexDirection: "column" }}>
        //           <a
        //             style={{ width: 245, marginBottom: 20 }}
        //             className="btn-repetir"
        //             onClick={() => setItemAtivo(0)}
        //           >
        //             <i className="fas fa-undo-alt"></i> REPETIR
        //           </a>
        //           <a
        //             style={{ width: 245, textAlign: "center" }}
        //             className="btn-concluir"
        //             onClick={() => proximoItem()}
        //           >
        //             CONTINUAR{" "}
        //             <i className="fas fa-chevron-right" aria-hidden="true"></i>
        //           </a>
        //         </div>
        //       </div>
        //     )}
        //   </div>
        // </div>
      )}
    </>
  );
};

const definirTitulo = (item) => {
  if (!item.titulo) {
    return "AULA FINALIZADA!";
  } else {
    return `Aula ${item.numero} - ${item.titulo}`;
  }
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#91DBE4",
    },
    secondary: {
      main: "#5BE289",
    },
  },
});

export default Unidade;
