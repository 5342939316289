import React from "react";
import corFundo from "../assets/imagens/cor-00.png";

const Personagens = ({
  continuar,
  voltar,
  selecionarPersonagem,
  personagemSelecionado,
}) => (
  <>
    {window.innerWidth > 800 ? (
      <div className="pagina-login">
        <div className="container-login">
          <h1>Selecione seu personagem</h1>
          <form
            className="form-login"
            onSubmit={(e) => {
              e.preventDefault();
              continuar();
            }}
          >
            <img
              alt="Personagem 1"
              src={require("../assets/imagens/personagem-01.png")}
              className="personagem"
              onClick={() => selecionarPersonagem("personagem-01.png")}
              style={
                personagemSelecionado === "personagem-01.png"
                  ? { borderStyle: "solid" }
                  : {}
              }
            />
            <img
              alt="Personagem 2"
              src={require("../assets/imagens/personagem-02.png")}
              className="personagem"
              onClick={() => selecionarPersonagem("personagem-02.png")}
              style={
                personagemSelecionado === "personagem-02.png"
                  ? { borderStyle: "solid" }
                  : {}
              }
            />
            <img
              alt="Personagem 3"
              src={require("../assets/imagens/personagem-03.png")}
              className="personagem"
              onClick={() => selecionarPersonagem("personagem-03.png")}
              style={
                personagemSelecionado === "personagem-03.png"
                  ? { borderStyle: "solid" }
                  : {}
              }
            />
            <img
              alt="Personagem 4"
              src={require("../assets/imagens/personagem-04.png")}
              className="personagem"
              onClick={() => selecionarPersonagem("personagem-04.png")}
              style={
                personagemSelecionado === "personagem-04.png"
                  ? { borderStyle: "solid" }
                  : {}
              }
            />
            <img
              alt="Personagem 5"
              src={require("../assets/imagens/personagem-05.png")}
              className="personagem"
              onClick={() => selecionarPersonagem("personagem-05.png")}
              style={
                personagemSelecionado === "personagem-05.png"
                  ? { borderStyle: "solid" }
                  : {}
              }
            />
            <img
              alt="Personagem 6"
              src={require("../assets/imagens/personagem-06.png")}
              className="personagem"
              onClick={() => selecionarPersonagem("personagem-06.png")}
              style={
                personagemSelecionado === "personagem-06.png"
                  ? { borderStyle: "solid" }
                  : {}
              }
            />
            <img
              alt="Personagem 7"
              src={require("../assets/imagens/personagem-07.png")}
              className="personagem"
              onClick={() => selecionarPersonagem("personagem-07.png")}
              style={
                personagemSelecionado === "personagem-07.png"
                  ? { borderStyle: "solid" }
                  : {}
              }
            />
            <img
              alt="Personagem 8"
              src={require("../assets/imagens/personagem-08.png")}
              className="personagem"
              onClick={() => selecionarPersonagem("personagem-08.png")}
              style={
                personagemSelecionado === "personagem-08.png"
                  ? { borderStyle: "solid" }
                  : {}
              }
            />
            <img
              alt="Personagem 9"
              src={require("../assets/imagens/personagem-09.png")}
              className="personagem"
              onClick={() => selecionarPersonagem("personagem-09.png")}
              style={
                personagemSelecionado === "personagem-09.png"
                  ? { borderStyle: "solid" }
                  : {}
              }
            />
            <img
              alt="Personagem 10"
              src={require("../assets/imagens/personagem-10.png")}
              className="personagem"
              onClick={() => selecionarPersonagem("personagem-10.png")}
              style={
                personagemSelecionado === "personagem-10.png"
                  ? { borderStyle: "solid" }
                  : {}
              }
            />
            <img
              alt="Personagem 11"
              src={require("../assets/imagens/personagem-11.png")}
              className="personagem"
              onClick={() => selecionarPersonagem("personagem-11.png")}
              style={
                personagemSelecionado === "personagem-11.png"
                  ? { borderStyle: "solid" }
                  : {}
              }
            />
            <img
              alt="Personagem 12"
              src={require("../assets/imagens/personagem-12.png")}
              className="personagem"
              onClick={() => selecionarPersonagem("personagem-12.png")}
              style={
                personagemSelecionado === "personagem-12.png"
                  ? { borderStyle: "solid" }
                  : {}
              }
            />
            <input type="submit" className="btn-padrao" value="Confirmar" />
          </form>
          <div className="footer-nav">
            <button className="btn-link ali-left" onClick={voltar} href="#">
              Voltar
            </button>
          </div>
        </div>
      </div>
    ) : (
      <div style={{ padding: 10 }}>
        <div style={{ textAlign: "center" }}>
          <h1>Selecione seu personagem</h1>

          <form
            style={{
              marginTop: "20%",
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
            // className="form-login"
            onSubmit={(e) => {
              e.preventDefault();
              continuar();
            }}
          >
            <div
              style={{
                marginTop: 10,

                width: "110px",
                backgroundImage: `url(${corFundo})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                textAlign: "center",
                borderStyle:
                  personagemSelecionado === "personagem-01.png" ? "solid" : "",
              }}
            >
              <img
                style={{ width: 90 }}
                alt="Personagem 1"
                src={require("../assets/imagens/personagem-01.png")}
                onClick={() => selecionarPersonagem("personagem-01.png")}
                title="Clique para alterar o avatar"
              />
            </div>
            <div
              style={{
                marginTop: 10,

                width: "110px",
                backgroundImage: `url(${corFundo})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                textAlign: "center",
                borderStyle:
                  personagemSelecionado === "personagem-02.png" ? "solid" : "",
              }}
            >
              <img
                style={{ width: 90 }}
                alt="Personagem 2"
                src={require("../assets/imagens/personagem-02.png")}
                onClick={() => selecionarPersonagem("personagem-02.png")}
              />
            </div>
            <div
              style={{
                marginTop: 10,

                width: "110px",
                backgroundImage: `url(${corFundo})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                textAlign: "center",
                borderStyle:
                  personagemSelecionado === "personagem-03.png" ? "solid" : "",
              }}
            >
              <img
                style={{ width: 90 }}
                alt="Personagem 3"
                src={require("../assets/imagens/personagem-03.png")}
                onClick={() => selecionarPersonagem("personagem-03.png")}
              />
            </div>

            <div
              style={{
                marginTop: 10,
                width: "110px",
                backgroundImage: `url(${corFundo})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                textAlign: "center",
                borderStyle:
                  personagemSelecionado === "personagem-04.png" ? "solid" : "",
              }}
            >
              <img
                style={{ width: 90 }}
                alt="Personagem 4"
                src={require("../assets/imagens/personagem-04.png")}
                onClick={() => selecionarPersonagem("personagem-04.png")}
              />
            </div>
            <div
              style={{
                marginTop: 10,
                width: "110px",
                backgroundImage: `url(${corFundo})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                textAlign: "center",
                borderStyle:
                  personagemSelecionado === "personagem-05.png" ? "solid" : "",
              }}
            >
              <img
                style={{ width: 90 }}
                alt="Personagem 5"
                src={require("../assets/imagens/personagem-05.png")}
                onClick={() => selecionarPersonagem("personagem-05.png")}
              />
            </div>
            <div
              style={{
                marginTop: 10,
                width: "110px",
                backgroundImage: `url(${corFundo})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                textAlign: "center",
                borderStyle:
                  personagemSelecionado === "personagem-06.png" ? "solid" : "",
              }}
            >
              <img
                style={{ width: 90 }}
                alt="Personagem 6"
                src={require("../assets/imagens/personagem-06.png")}
                onClick={() => selecionarPersonagem("personagem-06.png")}
              />
            </div>
            <div
              style={{
                marginTop: 10,
                width: "110px",
                backgroundImage: `url(${corFundo})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                textAlign: "center",
                borderStyle:
                  personagemSelecionado === "personagem-07.png" ? "solid" : "",
              }}
            >
              <img
                style={{ width: 90 }}
                alt="Personagem 7"
                src={require("../assets/imagens/personagem-07.png")}
                onClick={() => selecionarPersonagem("personagem-07.png")}
              />
            </div>
            <div
              style={{
                marginTop: 10,
                width: "110px",
                backgroundImage: `url(${corFundo})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                textAlign: "center",
                borderStyle:
                  personagemSelecionado === "personagem-08.png" ? "solid" : "",
              }}
            >
              <img
                style={{ width: 90 }}
                alt="Personagem 8"
                src={require("../assets/imagens/personagem-08.png")}
                onClick={() => selecionarPersonagem("personagem-08.png")}
              />
            </div>
            <div
              style={{
                marginTop: 10,
                width: "110px",
                backgroundImage: `url(${corFundo})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                textAlign: "center",
                borderStyle:
                  personagemSelecionado === "personagem-09.png" ? "solid" : "",
              }}
            >
              <img
                style={{ width: 90 }}
                alt="Personagem 9"
                src={require("../assets/imagens/personagem-09.png")}
                onClick={() => selecionarPersonagem("personagem-09.png")}
              />
            </div>
            <div
              style={{
                marginTop: 10,
                width: "110px",
                backgroundImage: `url(${corFundo})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                textAlign: "center",
                borderStyle:
                  personagemSelecionado === "personagem-10.png" ? "solid" : "",
              }}
            >
              <img
                style={{ width: 90 }}
                alt="Personagem 10"
                src={require("../assets/imagens/personagem-10.png")}
                onClick={() => selecionarPersonagem("personagem-10.png")}
              />
            </div>
            <div
              style={{
                marginTop: 10,
                width: "110px",
                backgroundImage: `url(${corFundo})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                textAlign: "center",
                borderStyle:
                  personagemSelecionado === "personagem-11.png" ? "solid" : "",
              }}
            >
              <img
                style={{ width: 90 }}
                alt="Personagem 11"
                src={require("../assets/imagens/personagem-11.png")}
                onClick={() => selecionarPersonagem("personagem-11.png")}
              />
            </div>
            <div
              style={{
                marginTop: 10,
                width: "110px",
                backgroundImage: `url(${corFundo})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                textAlign: "center",
                borderStyle:
                  personagemSelecionado === "personagem-12.png" ? "solid" : "",
              }}
            >
              <img
                style={{ width: 90 }}
                alt="Personagem 12"
                src={require("../assets/imagens/personagem-12.png")}
                onClick={() => selecionarPersonagem("personagem-12.png")}
              />
            </div>

            <input
              style={{ marginTop: 15 }}
              type="submit"
              className="btn-padrao"
              value="Confirmar"
            />
          </form>
          <div style={{ marginTop: 10 }}>
            <button className="btn-link ali-left" onClick={voltar} href="#">
              Voltar
            </button>
          </div>
        </div>
      </div>
    )}
  </>
);

export default Personagens;
