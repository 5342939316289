//import api from './api';
import axios from "axios";
import { getToken } from "./auth";
import serverHost from './serverHost';

const api = axios.create({
  baseURL: serverHost('PEIJ'),
  timeout: 90000
});

api.interceptors.request.use(async config => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

export default  {
    post(route, params, tentativa = 1){
        return new Promise((resolve, reject) => {
            api.post(route, params)
            .then(res => resolve(res.data))
            .catch(err => {
                console.log(err)

                if(!err.response && tentativa < 3) this.post(route, params, tentativa + 1);

                const {status} = err.response;
    
                if(status === 401) {
                    localStorage.removeItem('user_id');
                    return window.location.replace('/painel/signin?999');
                }
    
                if(status === 403) return window.location.replace('/painel/app/notauthorized');
    
                reject(err);
            });
        });
    }
}

// exports.post = (route, params) => {
//     return new Promise((resolve, reject) => {
//         // const config = {
//         //     headers: {'x-access-token': localStorage.getItem('user_id')}
//         // }

//         api.post(route, params)
//         .then(res => resolve(res.data))
//         .catch(err => {
//             const {status} = err.response;

//             if(status === 401) {
//                 localStorage.removeItem('user_id');
//                 return window.location.replace('/painel/signin?999');
//             }

//             if(status === 403) return window.location.replace('/painel/app/notauthorized');

//             reject(err);
//         });
//     });
// }