import React, { useEffect, useState } from 'react';
import { click, win } from '../../libs/soundEffects';

const Escrever = ({config}) => {
    const qtdRodadas = config.rodadas ? config.rodadas.length : 0;

    const [respostaOk, setRespostaOk] = useState(undefined);
    const [resposta, setResposta] = useState('');
    const [rodadaAtual, setRodadaAtual] = useState(undefined);
    const [numRodadaAtual, setNumRodadaAtual] = useState(0);
    const [respostaQuase, setRespostaQuase] = useState(undefined);
  
    useEffect(() => {
      iniciarRodada(1);
    }, []);
  
    const iniciarRodada = (numero) => {
      const rodada =
        config.rodadas && config.rodadas.length > 0
          ? config.rodadas[numero - 1]
          : config;
  
      if (!rodada) return;
  
      if (rodada.audioEnunciado) {
        const audio = new Audio(rodada.audioEnunciado);
  
        audio.play();
      }
  
      setNumRodadaAtual(numero);
      setRodadaAtual(rodada);
      setResposta('');
      setRespostaOk(false);
      setRespostaQuase(false);
    };
  
    const avancar = () => {
      click();
  
      if (numRodadaAtual === qtdRodadas || qtdRodadas === 0) {
        return config.avancar ? config.avancar() : alert('Fim!');
      } else {
        iniciarRodada(numRodadaAtual + 1);
      }
    };
  
    const preencher = (resposta) => {
      setResposta(resposta);
  
      if (resposta.toLowerCase() === rodadaAtual.resposta) {
        win();
        setRespostaOk(true);
      }
  
      if (rodadaAtual.variacao) {
        let alternativas = rodadaAtual.variacoes.filter((i) => i === resposta);
  
        if (alternativas.length > 0) {
          win();
          setRespostaOk(true);
          setRespostaQuase(true);
        }
      }
    };
  
    if (!rodadaAtual) return null;
};



export default Escrever;

const styles = {
    container: {
        backgroundColor: 'white', 
        margin: 5, 
        borderRadius: 15, 
        padding: 5
    },
    cabecalho: {
        backgroundColor: 'RGB(0,177,181)', 
        margin: 5, 
        borderRadius: 15, 
        padding: 5,
        color: 'white',
        fontWeight: 'bolder',
        display: 'flex',
        justifyContent: 'space-between'
    },
    conteudo: {
        display: 'flex',
        justifyContent: 'center',
        alignItens: 'center',
        flexWrap: 'wrap',
        borderRadius: 15,
        backgroundColor: 'RGB(193,239,226)',
        margin: 5,
        marginTop: 10
    },
    conteudoFinal: {
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        textAlign: 'center',
        alignItens: 'center',
        flexWrap: 'wrap',
        borderRadius: 15,
        backgroundColor: 'RGB(193,239,226)',
        margin: 5,
        marginTop: 10,
        padding: 10
    },
    conteudoItem: {
        marginTop: 5,
        marginLeft: 5,
        marginRitht: 5,
        marginBottom: 5,
        borderRadius: 15,
        width: 120,
        height: 120,
        backgroundColor: 'RGB(255,206,0)',
        padding: 10,
        textAlign: 'center'
    },
    conteudoItemCorreto: {
        margin: 5,
        borderRadius: 15,
        backgroundColor: 'RGB(107, 255, 107)',
        padding: 10,
        textAlign: 'center'
    },
    conteudoItemIncorreto: {
        margin: 5,
        borderRadius: 15,
        backgroundColor: 'RGB(255, 107, 107)',
        padding: 10,
        textAlign: 'center'
    },
    btnRepetir: {
        backgroundColor: 'RGB(71, 226, 230)',
        border: 'none',
        color: 'white',
        borderRadius: 15
    },
    btnRepetirIcon: {
        color: 'RGB(71, 226, 230)',
        marginBottom: 5
    },
    btnContinuar: {
        backgroundColor: 'RGB(0, 144, 245)',
        border: 'none',
        color: 'white',
        borderRadius: 15,
        maxHeight: 60,
        padding: 10
    },
    btnContinuarIcon: {
        color: 'RGB(0, 144, 245)',
        marginBottom: 5
    }
}