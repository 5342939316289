/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {click, win} from '../libs/soundEffects';

const Texto = ({config}) => {
    const qtdRodadas = config.rodadas ? config.rodadas.length : 0;

    const [respostaOk, setRespostaOk] = useState(undefined);
    const [resposta, setResposta] = useState('');
    const [rodadaAtual, setRodadaAtual] = useState(undefined);
    const [numRodadaAtual, setNumRodadaAtual] = useState(0);

    useEffect(() => {


        iniciarRodada(1);
    }, []);

    const iniciarRodada = (numero) => {
        const rodada = config.rodadas && config.rodadas.length > 0 ? config.rodadas[numero - 1] : config;

        if(!rodada) return;

        if(rodada.audioEnunciado){
            const audio = new Audio(rodada.audioEnunciado);

            audio.play();        
        }

        setNumRodadaAtual(numero);
        setRodadaAtual(rodada);
        setResposta('');
        setRespostaOk(false);
    }

    const avancar = () => {
        click();

        if(numRodadaAtual === qtdRodadas || qtdRodadas === 0){
            return config.avancar ? config.avancar() : alert('Fim!');
        } else {
            iniciarRodada(numRodadaAtual + 1);
        }
    }

    const preencher = (resposta) => {
        setResposta(resposta);

        if(resposta.toLowerCase() === rodadaAtual.resposta) {
            win();
            setRespostaOk(true);
        }
    }

    if(!rodadaAtual) return null;

    return(
        <div className="content">
            <div className="container">
                <div className="box-topo">
                    <div className="item-box-topo-1">
                        <p className="p-text">{rodadaAtual.enunciado}</p>
                    </div>
                    <div className="item-box-topo-2">
                        <a className="btn-repetir" onClick={() => iniciarRodada(1)}><i className="fas fa-undo-alt"></i> REPETIR</a>
                    </div>
                </div>
                {respostaOk ?
                    <div className="tl8-box-2">
                        <div className="tl7-box-confirmacao">
                            <p className="p-text">PARABÉNS</p>
                            <a className="btn-concluir" onClick={avancar}>CONTINUAR <i className="fas fa-chevron-right"></i> </a>
                        </div>
                    </div>
                :
                   <>
                      <div className="tl8-box-2">
                        <div className="box-imagem-bg" style={
                            {
                                backgroundImage: `url(${rodadaAtual.imagem})`, 
                                width: rodadaAtual.imagemLargura ? rodadaAtual.imagemLargura : 524,
                                height: rodadaAtual.imagemAltura ? rodadaAtual.imagemAltura : 524
                            }}
                        >
                        </div>
                      </div>

                      <div className="escrever-caixa-texto-confirmacao" style={{height: 340, flexDirection: 'column'}}>
                      <div className="caixa-texto-confirmcao-input" style={{width: '98%'}}>
                          <textarea 
                              style={{borderStyle: 'none'}}
                              disabled={respostaOk}
                              className="p-text-input"
                              type="text"
                              style={{ width: '100%', border: 'none', height: 250}}
                              placeholder={rodadaAtual.rotulo}
                          />
                      </div>

                      <div className="caixa-texto-btn-confirmar">
                        <a className="btn-concluir" onClick={() => setRespostaOk(true)}>CONFIRMAR <i className="fas fa-chevron-right"></i></a>
                      </div>   
                      </div>  
                   </>
                }
            </div>
        </div>
    )
}

export default Texto;