import React, { useEffect, useState } from 'react';
import KeyboardEventHandler from 'react-keyboard-event-handler';

import {click, win, wrong} from '../libs/soundEffects';

const Arrow = ({ config }) => {
  const qtdRodadas = config.rodadas ? config.rodadas.length : 0;

  const [respostaOk, setRespostaOk] = useState(undefined);
  const [resposta, setResposta] = useState('');
  const [rodadaAtual, setRodadaAtual] = useState(undefined);
  const [numRodadaAtual, setNumRodadaAtual] = useState(0);

  useEffect(() => {

    iniciarRodada(1);
  }, [])

  const iniciarRodada = (numero) => {
    const rodada = config.rodadas && config.rodadas.length > 0 ? config.rodadas[numero - 1] : config;

    if(!rodada) return;

    if(rodada.audioEnunciado){
        const audio = new Audio(rodada.audioEnunciado);

        audio.play();        
    }

    setNumRodadaAtual(numero);
    setRodadaAtual(rodada);
    setResposta('');
    setRespostaOk(false);
  }

  const avancar = () => {
    win();

    if(numRodadaAtual === qtdRodadas){
      setRespostaOk(true);
    } else {
      iniciarRodada(numRodadaAtual + 1);
    }
  }

  const encerrar = () => {
    config.avancar ? config.avancar() : alert('acabou!');
  }

  if(!rodadaAtual) return null;

  return (
    <div className="content">
      <div className="container">
          <div className="box-topo">
              <div className="item-box-topo-1">
                  <p className="p-text">{rodadaAtual.enunciado}</p>
              </div>
              <div className="item-box-topo-2">
                <a className="btn-repetir">
                  <i className="fas fa-undo-alt"></i> REPETIR
                </a>
              </div>
            </div>
              {respostaOk ?
                <div className="box-palavras">
                  <div className="tl7-box-confirmacao" style={{height: 500}}>
                      <p className="p-text">PARABÉNS! VOCÊ ACERTOU!</p>
                      <a className="btn-concluir" onClick={encerrar}>CONTINUAR <i className="fas fa-chevron-right"></i></a>
                  </div>
                </div>
                : 
                <div className="tl8-box-2">
                  <img src={rodadaAtual.imagem} />
                </div>
              }


            <KeyboardEventHandler
              handleKeys={[rodadaAtual.tecla]}
              onKeyEvent={(key, e) => avancar()} 
            />
      </div>
    </div>
  );
}

export default Arrow;