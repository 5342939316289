import React, { Fragment, useState, useEffect } from 'react';
import { click, win, wrong } from '../libs/soundEffects';

let rodadaAlternarItens = 0;
let fim = false;

const Imitar = ({ config }) => {
  const qtdRodadas = config.rodadas ? config.rodadas.length : 0;

  const [rodadaAtual, setRodadaAtual] = useState(undefined);
  const [numRodadaAtual, setNumRodadaAtual] = useState(0);
  const [respostaOk, setRespostaOk] = useState(undefined);
  const [itemAtual, setItemAtual] = useState(0);

  useEffect(() => {
    if (config.audioInicio) {
      const audio = new Audio(config.audioInicio);

      audio.play();
    }

    iniciarRodada(1);
    if (!config.itens) {
      setTimeout(
        () => alternarItens(0),
        config.tempoAbertura ? config.tempoAbertura * 1000 : 5000
      );
    }
  }, []);

  const alternarItens = (numero) => {
    const itensAlternar = config.rodadas[rodadaAlternarItens].itens;

    const novoItem = itensAlternar[numero + 1] ? numero + 1 : 0;

    if (itensAlternar[novoItem].audio) {
      const audio = new Audio(itensAlternar[novoItem].audio);

      audio.play();
    }

    setItemAtual(novoItem);

    if (!fim) setTimeout(() => alternarItens(novoItem), 1500);
  };

  const iniciarRodada = (numero) => {
    const rodada = config.rodadas[numero - 1];

    if (!rodada) return;

    if (rodada.audioEnunciado) {
      const audio = new Audio(rodada.audioEnunciado);

      audio.play();
    }

    setNumRodadaAtual(numero);
    setRodadaAtual(rodada);
    setRespostaOk(false);

    rodadaAlternarItens = numero - 1;
  };

  const avancar = () => {
    if (numRodadaAtual === qtdRodadas) {
      encerrar();
      //setRespostaOk(true);
    } else {
      iniciarRodada(numRodadaAtual + 1);
    }
  };

  const encerrar = () => {
    fim = true;
    config.avancar ? config.avancar() : alert('acabou!');
  };

  const concluir = () => {
    setRespostaOk(true);
  };

  if (!rodadaAtual) return null;

  return (
    <div className="content">
      <div className="container">
        <div className="box-topo">
          <div className="item-box-topo-1">
            <p className="p-text">{rodadaAtual.enunciado}</p>
          </div>
          <div className="item-box-topo-2">
            <a className="btn-repetir" onClick={() => iniciarRodada(1)}>
              <i className="fas fa-undo-alt"></i> REPETIR
            </a>
          </div>
        </div>

        {!respostaOk && (
          <>
            <div style={{ textAlign: 'center' }}>
              {rodadaAtual.itens[itemAtual].imagem && (
                <img
                  style={{ height: 330, width: '500px' }}
                  src={rodadaAtual.itens[itemAtual].imagem}
                />
              )}
            </div>

            <div
              className="box-botao-centro"
              style={{ background: '#75dfeb', borderRadius: 15, padding: 15 }}
            >
              <a className="btn-concluir" onClick={avancar}>
                <i className="fas fa-check"></i>{' '}
                {numRodadaAtual === qtdRodadas ? 'CONCLUIR' : 'CONTINUAR'}
              </a>
            </div>
          </>
        )}

        {respostaOk && (
          <div className="tl8-box-2">
            <div className="tl7-box-confirmacao">
              <p className="p-text">PARABÉNS</p>
              <p className="p-text">VOCÊ CONSEGUIU!</p>
              <a className="btn-concluir" onClick={encerrar}>
                CONTINUAR <i className="fas fa-chevron-right"></i>{' '}
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Imitar;
