import React, { useState, createContext } from "react";
import CustomizedSnackbars from "../components/alert/Alert";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const AppContext = createContext({});

const AppContextProvider = ({ children }) => {
  const [novoAlerta, setNovoAlerta] = useState(null);
  const [confirmData, setConfirmData] = useState(null);
  const [titulo, setTitulo] = useState("");

  const notification = (tipo, msg) => {
    const novoAlerta = {
      type: tipo,
      text: msg,
    };

    setNovoAlerta(novoAlerta);
    setTimeout(() => setNovoAlerta(false), 3000);
  };

  const confirm = (confirmData) => {
    setConfirmData(confirmData);
  }

  const intitular = (titulo) => {
    setTitulo(titulo ? titulo : "");
  };

  return (
    <AppContext.Provider value={{ notification, confirm, intitular, titulo }}>
      {novoAlerta && (
        <CustomizedSnackbars
          texto={novoAlerta.text}
          tipo={novoAlerta.type}
        ></CustomizedSnackbars>
      )}
      {confirmData && (
        <ConfirmDialog confirmData={confirmData} closeDialog={() => setConfirmData(null)}/>
      )}
      {children}
    </AppContext.Provider>
  );
};

export { AppContextProvider };

const ConfirmDialog = ({confirmData, closeDialog}) => {
  const confirm = () => {
    if(confirmData.onConfirm) confirmData.onConfirm();
    closeDialog();
  }

  const close = () => {
    if(confirmData.onClose) confirmData.onClose();
    closeDialog();
  }

  return(
    <Dialog
        open={true}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {confirmData.title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
        {confirmData.label}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={confirm} autoFocus>{confirmData.confirmLabel ? confirmData.confirmLabel : 'Confirmar'}</Button>
        <Button onClick={close} style={{color: '#949494'}}>{confirmData.closeLabel ? confirmData.closeLabel : 'Fechar'}</Button>        
      </DialogActions>
    </Dialog>
  )
}

export default AppContext;
