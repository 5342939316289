import { IconButton } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

const scrollToRef = (ref) => {
  ref.current.scrollIntoView({
    behavior: "smooth",
    block: "start",
  });
};

const Tutorial = ({ registrarTutorial }) => {
  const [pagina, setpagina] = useState(1);
  const [audioAtivo, setAudioAtivo] = useState(true);
  const [audio, setAudio] = useState(undefined);

  const doc = useRef(null);

  useEffect(() => {
    if (window.innerWidth > 800) {
      setTimeout(() => {
        scrollToRef(doc);
      }, 500);
    }
  }, []);

  const avancarPagina = () => {
    scrollToRef(doc);
    if (pagina < 12) {
      const paginaAtual = pagina + 1;

      setpagina(paginaAtual);

      selecionarAudio(paginaAtual);
    }
  };

  const voltarPagina = () => {
    if (pagina > 1) {
      const paginaAtual = pagina - 1;

      setpagina(paginaAtual);

      selecionarAudio(paginaAtual);
    }
  };

  const selecionarAudio = (pagina) => {
    if (audio) audio.pause();

    if (audioAtivo && [2, 3, 4, 5, 6, 7, 9, 10, 11, 12].includes(pagina)) {
      if (audio) audio.pause();

      const novoAudio = new Audio(
        require(`../assets/sounds/tutorial-${pagina}.mp3`)
      );
      novoAudio.play();

      setAudio(novoAudio);
    }
  };

  const alternarAudio = () => {
    if (audio) audioAtivo ? audio.pause() : audio.play();

    setAudioAtivo(!audioAtivo);
  };

  const finalizar = () => {
    if (audio) audio.pause();

    registrarTutorial();
  };

  return (
    <>
      {window.innerWidth > 800 ? (
        <div className="container-atividades">
          {pagina > 1 && <div className="seta-01" onClick={voltarPagina}></div>}
          {pagina < 12 && (
            <div className="seta-02" onClick={avancarPagina}></div>
          )}
          <div className="tutorial" style={{ textAlign: "right" }} ref={doc}>
            <img
              style={{ width: "100%" }}
              alt="Tutorial"
              src={require(`../assets/imagens/tutorial-${pagina}.png`)}
            />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                className="header-som"
                onClick={alternarAudio}
                style={{ textAlign: "left" }}
              >
                <img
                  alt={audioAtivo ? "Desativar som" : "Ativar som"}
                  src={require(`../assets/imagens/${
                    audioAtivo ? "sound-3.png" : "mute-3.png"
                  }`)}
                  id="com-som"
                />
              </div>
              {pagina === 12 ? (
                <button
                  className="btn-padrao btn-right"
                  onClick={finalizar}
                  style={{ marginRight: 0 }}
                >
                  Concluir
                </button>
              ) : (
                <button id="sair" className="btn-link" onClick={finalizar}>
                  Pular tutorial
                </button>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div
            style={{
              display: "block",
              marginTop: "40%",
              maxWidth: "1140px",
              background: "#fff",
              border: "20px solid #fff",
              borderRadius: "15px",
            }}
            ref={doc}
          >
            <img
              style={{ width: "100%" }}
              alt="Tutorial"
              src={require(`../assets/imagens/tutorial-${pagina}.png`)}
            />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                className="header-som"
                onClick={alternarAudio}
                style={{ textAlign: "left" }}
              >
                <img
                  alt={audioAtivo ? "Desativar som" : "Ativar som"}
                  src={require(`../assets/imagens/${
                    audioAtivo ? "sound-3.png" : "mute-3.png"
                  }`)}
                  id="com-som"
                />
              </div>
              {pagina === 12 ? (
                <button
                  className="btn-padrao btn-right"
                  onClick={finalizar}
                  style={{ marginRight: 0, width: "50%" }}
                >
                  Concluir
                </button>
              ) : (
                <button id="sair" className="btn-link" onClick={finalizar}>
                  Pular tutorial
                </button>
              )}
            </div>
          </div>
          <div
            id="fixar"
            style={{
              position: "fixed",
              bottom: 0,
              left: 0,
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <IconButton size="large">
                <ArrowLeftIcon
                  style={{ fontSize: 130, color: "white" }}
                  onClick={voltarPagina}
                />
              </IconButton>
            </div>
            <div>
              <IconButton size="small">
                <ArrowRightIcon
                  style={{ fontSize: 130, color: "white" }}
                  onClick={avancarPagina}
                />
              </IconButton>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Tutorial;
