import React, { Fragment, useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import soundEffects from "../libs/soundEffects";
import AppContext from "../container/context";
import { Grid } from "@mui/material";
import back from "../assets/imagens/back.png";
import bloqueio from "../assets/imagens/bloqueado-01.png";
import "./stylesGaveta.css";
import locked from "../assets/imagens/bloqueado-02.png";

const Header = (props) => {
  const { notification } = useContext(AppContext);

  const [userData, setUserData] = useState({});
  const [gaveta, setGaveta] = useState(false);
  const [valor1] = useState(1 + Math.floor(9 * Math.random()));
  const [valor2] = useState(1 + Math.floor(9 * Math.random()));
  const [resultado, setResultado] = useState(0);
  const [contexto, setContexto] = useState("");
  const [isHovered, setIsHovered] = useState(false);

  let Esquerda, Centro, Direita;

  useEffect(() => {
    definirContexto(props.location.pathname, setContexto);

    props.bloquearSom(contexto === "conteudo" || contexto === "no-header");

    setUserData(props.userData);
    setIsHovered(false);
  }, [props, contexto]);

  const voltar = (path) => {
    soundEffects.click();

    if (props.headerProps.voltar) return props.headerProps.voltar();

    if (path !== "") return props.history.push(path);

    props.history.goBack();
  };

  if (contexto === "no-header") return null;

  switch (props.headerProps.contexto) {
    case "aulas":
      Esquerda = () => (
        <Grid
          item
          md={window.innerWidth < 600 ? 4 : 2}
          xs={window.innerWidth < 600 ? 4 : 2}
          onClick={() => {
            voltar("/app");
            setIsHovered(false);
          }}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            transform:
              isHovered && window.innerWidth > 800 ? "rotate(-3deg)" : "none",
            msTransform:
              isHovered && window.innerWidth > 800 ? "rotate(-3deg)" : "none", // Para IE 9
            transition: "transform 0.3s ease", // Suaviza a transformação
          }}
          onMouseEnter={() => {
            window.innerWidth > 800 && setIsHovered(true);
          }}
          onMouseLeave={() => {
            window.innerWidth > 800 && setIsHovered(false);
          }}
        >
          <div
            style={{
              backgroundColor: userData.cor,
              borderRadius: 10,
              padding: 10,
              margin: 10,
            }}
          >
            <img src={back} alt="" height={"100%"} width={"100%"} />
          </div>
        </Grid>
      );
      Centro = () => (
        <Grid
          item
          md={window.innerWidth < 600 ? 4 : 8}
          xs={window.innerWidth < 600 ? 4 : 8}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h1
            style={{
              color: userData.cor,
              fontSize: 30,
              textTransform: "uppercase",
              fontFamily: "Bungee",
            }}
          >
            {props.headerProps.titulo}
          </h1>
        </Grid>
      );
      Direita = () => (
        <Grid
          item
          md={window.innerWidth < 600 ? 4 : 2}
          xs={window.innerWidth < 600 ? 4 : 2}
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}
          onClick={() => setGaveta(true)}
        >
          <div
            title="Área dos pais"
            style={{
              cursor: "pointer",
              margin: 10,
              textAlign: "end",
            }}
          >
            <img src={bloqueio} alt="" height={"70%"} width={"70%"} />
          </div>
        </Grid>
      );

      break;

    case "conteudo":
      Esquerda = () => (
        <Grid
          item
          md={window.innerWidth < 600 ? 4 : 2}
          xs={window.innerWidth < 600 ? 4 : 2}
          onClick={() => {
            voltar("");
            setIsHovered(false);
          }}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            transform:
              isHovered && window.innerWidth > 800 ? "rotate(-3deg)" : "none",
            msTransform:
              isHovered && window.innerWidth > 800 ? "rotate(-3deg)" : "none", // Para IE 9
            transition: "transform 0.3s ease", // Suaviza a transformação
          }}
          onMouseEnter={() => {
            window.innerWidth > 800 && setIsHovered(true);
          }}
          onMouseLeave={() => {
            window.innerWidth > 800 && setIsHovered(false);
          }}
        >
          <div
            style={{
              backgroundColor: userData.cor,
              borderRadius: 10,
              padding: 10,
              margin: 10,
            }}
          >
            <img src={back} alt="" height={"100%"} width={"100%"} />
          </div>
        </Grid>
      );
      Centro = () => (
        <Grid
          item
          md={window.innerWidth < 600 ? 4 : 8}
          xs={window.innerWidth < 600 ? 4 : 8}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h1
            style={{
              color: userData.cor,
              fontSize: 30,
              textTransform: "uppercase",
              fontFamily: "Bungee",
            }}
          >
            {props.headerProps.titulo}
          </h1>
        </Grid>
      );
      Direita = () => (
        <Grid
          item
          md={window.innerWidth < 600 ? 4 : 2}
          xs={window.innerWidth < 600 ? 4 : 2}
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}
          onClick={() => setGaveta(true)}
        >
          <div
            title="Área dos pais"
            style={{
              cursor: "pointer",
              margin: 10,
              textAlign: "end",
            }}
          >
            <img src={bloqueio} alt="" height={"70%"} width={"70%"} />
          </div>
        </Grid>
      );

      break;

    default:
      Esquerda = () => (
        <Grid item md={4} xs={4}>
          <div>
            <div
              style={{
                backgroundColor: userData.cor,
                position: "absolute",
                display: "block",
                overflow: "hidden",
                width: "75px",
                height: "75px",
                top: "5px",
                left: "5px",
                padding: "15px 10px 0 10px",
                border: "0 solid",
                borderRadius: "100%",
              }}
              onClick={() => voltar("/app")}
            >
              <img
                style={{ width: 80 }}
                src={require(`../assets/imagens/${
                  avatares.includes(userData.avatar)
                    ? userData.avatar
                    : "personagem-01.png"
                }`)}
                alt="Avatar"
              />
            </div>
            <div
              style={{
                color: userData.cor,
                position: "absolute",
                left: 100,
                top: 70,
                fontFamily: "Bungee",
              }}
            >
              {userData.perfilNome}
            </div>
          </div>
        </Grid>
      );
      Centro = () => (
        <Grid
          item
          md={4}
          xs={4}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <img
              style={{
                width: window.innerWidth < 600 ? "150px" : "200px",
              }}
              src={require(`../assets/imagens/logo.png`)}
              alt="Logo"
            />
          </div>
        </Grid>
      );
      Direita = () => (
        <Grid
          item
          md={4}
          xs={4}
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}
          onClick={() => setGaveta(true)}
        >
          <div
            title="Área dos pais"
            style={{
              cursor: "pointer",
              margin: 10,
              textAlign: "end",
            }}
          >
            <img src={bloqueio} alt="" height={"70%"} width={"70%"} />
          </div>
        </Grid>
      );

      break;
  }

  return (
    <Fragment>
      <div id="header">
        <Grid container>
          <Esquerda />
          <Centro />
          <Direita />
        </Grid>
      </div>

      {gaveta && (
        <>
          {window.innerWidth > 800 ? (
            <div id="gaveta">
              <div
                className="gaveta-bg-container"
                onClick={() => setGaveta(false)}
              >
                <div className="gaveta-bg"></div>
              </div>
              <div className="gaveta-popup">
                <div className="gaveta-cont">
                  <h2>Controle de acesso</h2>
                  <p>Acesso exclusivo dos pais</p>
                  <form>
                    <div className="chave">
                      {`${valor1} + ${valor2}`} =
                      <input
                        type="text"
                        placeholder="?"
                        maxLength="2"
                        onChange={(e) => setResultado(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();

                            if (Number(resultado) === valor1 + valor2) {
                              setGaveta(false);
                              props.history.push("/app/perfil");
                            } else {
                              notification(
                                "warning",
                                "Informe o resultado correto da equação"
                              );
                            }
                          }
                        }}
                      />
                    </div>
                    <input
                      type="button"
                      value="Acessar"
                      onClick={() => {
                        if (Number(resultado) === valor1 + valor2) {
                          setGaveta(false);
                          props.history.push("/app/perfil");
                        } else {
                          notification(
                            "warning",
                            "Informe o resultado correto da equação"
                          );
                        }
                      }}
                    />
                    <input
                      type="button"
                      value="Voltar"
                      onClick={(e) => {
                        setGaveta(false);
                      }}
                      style={{ fontSize: 24, marginTop: 10, marginLeft: 10 }}
                    />
                  </form>
                  <div>
                    <button
                      className="btn-link btn-ajuda"
                      onClick={(e) => {
                        e.preventDefault();
                        setGaveta(false);
                        props.history.push("/app/ajuda");
                      }}
                    >
                      Obter ajuda
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div
              style={{
                height: window.innerHeight,
                width: "100%",
                top: 0,
                left: 0,
                backgroundColor: "#ff9a00",
                position: "fixed",
                float: "left",
                zIndex: 1,
              }}
            >
              <div style={{ marginTop: 20, padding: 10, textAlign: "center" }}>
                <img src={locked} alt="" width={80} />
              </div>

              <div style={{ textAlign: "center", padding: 10 }}>
                <div className="gavetaMobile-cont">
                  <h2 id="gavetaMobileh2">Controle de acesso</h2>
                  <p id="gavetaMobileP">Acesso exclusivo dos pais</p>
                  <form>
                    <div id="gavetaMobileChave">
                      {`${valor1} + ${valor2}`} =
                      <input
                        type="text"
                        placeholder="?"
                        maxLength="2"
                        onChange={(e) => setResultado(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();

                            if (Number(resultado) === valor1 + valor2) {
                              setGaveta(false);
                              props.history.push("/app/perfil");
                            } else {
                              notification(
                                "warning",
                                "Informe o resultado correto da equação"
                              );
                            }
                          }
                        }}
                      />
                    </div>
                    <div>
                      <div>
                        <input
                          style={{
                            marginTop: "40px",
                            padding: "25px 30px",
                            border: "5px solid #fff",
                            borderRadius: "15px",
                            fontFamily: "'Bungee', sans-serif",
                            fontSize: "30px",
                            lineHeight: "1em",
                            color: "#fff",
                            textTransform: "uppercase",
                            background: "transparent",
                            cursor: "pointer",
                          }}
                          type="button"
                          value="Acessar"
                          onClick={() => {
                            if (Number(resultado) === valor1 + valor2) {
                              setGaveta(false);
                              props.history.push("/app/perfil");
                            } else {
                              notification(
                                "warning",
                                "Informe o resultado correto da equação"
                              );
                            }
                          }}
                        />
                      </div>
                      <div>
                        <input
                          style={{
                            marginLeft: 10,
                            marginTop: 10,
                            padding: "25px 30px",
                            border: "5px solid #fff",
                            borderRadius: "15px",
                            fontFamily: "'Bungee', sans-serif",
                            fontSize: 20,
                            lineHeight: "1em",
                            color: "#fff",
                            textTransform: "uppercase",
                            background: "transparent",
                            cursor: "pointer",
                          }}
                          type="button"
                          value="Voltar"
                          onClick={(e) => {
                            setGaveta(false);
                          }}
                        />
                      </div>
                    </div>
                  </form>
                  <div style={{ marginTop: 10 }}>
                    <button
                      style={{
                        padding: "10px 0 9px",
                        fontSize: "1em",
                        fontWeight: 500,
                        color: "#008fee",
                        textDecoration: "none",
                        cursor: "pointer",
                        background: "none",
                        border: "none",
                        outline: "none",
                      }}
                      // className="btn-link btn-ajuda"
                      onClick={(e) => {
                        e.preventDefault();
                        setGaveta(false);
                        props.history.push("/app/ajuda");
                      }}
                    >
                      Obter ajuda
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </Fragment>
  );
};

export default withRouter(Header);

const definirContexto = (pathname, setContexto) => {
  if (pathname.includes("aulas")) return setContexto("aulas");
  if (pathname.includes("conteudo")) return setContexto("conteudo");
  if (pathname.includes("perfil")) return setContexto("no-header");
  if (pathname.includes("ajuda")) return setContexto("no-header");
  if (pathname.includes("assine")) return setContexto("no-header");
  if (pathname.includes("assinatura")) return setContexto("no-header");

  return setContexto("default");
};

const avatares = [
  "personagem-01.png",
  "personagem-02.png",
  "personagem-03.png",
  "personagem-04.png",
  "personagem-05.png",
  "personagem-06.png",
  "personagem-07.png",
  "personagem-08.png",
  "personagem-09.png",
  "personagem-10.png",
  "personagem-11.png",
  "personagem-12.png",
];
