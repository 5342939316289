/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import {
  BoxBotaoCentro,
  BoxTopo,
  BtnConcluir,
  Button,
  Content,
  ItemBox1,
  ItemBox2,
  Text,
} from './styles/stylesVisualizar';

let rodadaAlternarItens = 0;
let fim = false;

export default function VisualizarAlternando({ config }) {
  const qtdRodadas = config.rodadas ? config.rodadas.length : 0;

  const [rodadaAtual, setRodadaAtual] = useState(undefined);
  const [numRodadaAtual, setNumRodadaAtual] = useState(0);
  const [itemAtual, setItemAtual] = useState(0);

  useEffect(() => {
    if (config.audioInicio) {
      const audio = new Audio(config.audioInicio);

      audio.play();
    }

    iniciarRodada(1);
    if (!config.itens) {
      setTimeout(
        () => alternarItens(0),
        config.tempoAbertura ? config.tempoAbertura * 1000 : 5000
      );
    }
  }, []);

  const alternarItens = (numero) => {
    const itensAlternar = config.rodadas[rodadaAlternarItens].itens;

    const novoItem = itensAlternar[numero + 1] ? numero + 1 : 0;

    setItemAtual(novoItem);

    if (!fim) setTimeout(() => alternarItens(novoItem), 5000);
  };

  const iniciarRodada = (numero) => {
    const rodada = config.rodadas[numero - 1];

    if (!rodada) return;

    if (rodada.audioEnunciado) {
      const audio = new Audio(rodada.audioEnunciado);

      audio.play();
    }

    setNumRodadaAtual(numero);
    setRodadaAtual(rodada);
    fim = false;

    rodadaAlternarItens = numero - 1;
    alternarItens(numero);
  };

  const avancar = () => {
    if (numRodadaAtual === qtdRodadas) {
      fim = true;
      config.avancar ? config.avancar() : alert('acabou!');
    } else {
      iniciarRodada(numRodadaAtual + 1);
    }
  };

  if (!rodadaAtual) return null;

  return (
    <>
      <BoxTopo>
        <ItemBox1>
          <Text>{rodadaAtual.enunciado}</Text>
        </ItemBox1>
        <ItemBox2>
          <Button onClick={() => iniciarRodada(1)}>
            <i className="fas fa-undo-alt"></i> REPETIR
          </Button>
        </ItemBox2>
      </BoxTopo>

      <Content>
        {rodadaAtual.itens[itemAtual].imagem && (
          <img src={rodadaAtual.itens[itemAtual].imagem} alt="" />
        )}
      </Content>
      <BoxBotaoCentro>
        <BtnConcluir onClick={avancar}>
          <i className="fas fa-check"></i>
          {numRodadaAtual === qtdRodadas ? 'CONCLUIR' : 'CONTINUAR'}
        </BtnConcluir>
      </BoxBotaoCentro>
    </>
  );
}
