import React, {useEffect, useContext} from 'react';
import socketClient from "socket.io-client";
import AppContext from "../../container/context";

const Pix = ({dadosAssinatura, continuar}) => {
    const { notification } = useContext(AppContext);
    
    useEffect(() => {
        if(dadosAssinatura.usuarioCompraId) iniciarSocket(dadosAssinatura.usuarioCompraId);
    }, []);

    const iniciarSocket = (usuarioCompraId) => {
        console.log('iniciar socket');
        //const socketHost = "http://localhost:3600";
        const socketHost = "https://pertobilingueadm.com.br";
    
        const socket = socketClient(socketHost, {
            //path: "/socket.io",
            path: "/ijws/socket.io",
            query: {
                usuarioCompraId,
            },
        });
        socket.on("connect_error", (err) => {
          console.log(`connect_error due to ${err.message}`);
        });
        socket.on("PIX_PAGAMENTO", continuar);
    };

    const copiarPix = () => {
        navigator.clipboard
          .writeText(dadosAssinatura.PIXCopiaCola)
          .then(() => {
            return notification(
              "success",
              "Código copiado para a área de transferência"
            );
          })
          .catch(() => {
            return notification("error", "Erro ao copiar codigo");
          });
      };

    return(        
        <div>
            <img
                src={dadosAssinatura.PIXQRCode}
                alt=""
                width={200}
                height={200}
            />
            <h4 style={{ fontSize: "0.9rem" }}>
                Código copia e cola:
            </h4>
            <div
                style={{
                display: "flex",
                justifyContent: "center",
                }}
            >
                <div
                style={{
                    background: "#efe3b0",
                    // width: "50%",
                    padding: 10,
                    wordWrap: "break-word",
                    overflowWrap: "break-word",
                    whiteSpace: "pre-wrap",
                    overflow: "hidden",
                    textAlign: "center",
                }}
                >
                {dadosAssinatura.PIXCopiaCola}
                </div>
            </div>
            <input
                style={{ marginTop: 20 }}
                type="submit"
                className="btn-padrao-lp"
                value="Cópiar código"
                onClick={copiarPix}
            />
        </div>
    )
}

export default Pix;
