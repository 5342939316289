import styled from 'styled-components';

export const BoxTopo = styled.div`
  grid-template-columns: 700px auto;
  display: grid;
  margin: 20px auto;
  padding: 15px;
  max-width: 970px;
  border-radius: 15px;
  background: #75dfeb;
  box-shadow: 8px 7px 10px -4px rgba(0, 0, 0, 0.14);

  @media (max-width: 980px) {
    grid-template-columns: auto;
    width: 86%;
  }
`;

export const ItemBox1 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;

  @media (max-width: 980px) {
    justify-content: center;
    text-align: center;
  }
`;

export const Text = styled.p`
  font-size: 26px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: #fff;

  @media (max-width: 980px) {
    p {
      margin-right: 0%;
      font-size: 25px;
    }
  }
`;

export const ItemBox2 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 980px) {
    justify-content: center;
  }
`;

export const Button = styled.a`
  padding: 18px;
  font-size: 25px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: #fff;
  width: 180px;
  border-radius: 15px;
  background: #91dbe4;
  box-shadow: 3px 7px 13px -7px rgba(0, 0, 0, 0.3);
  cursor: pointer;

  :hover {
    box-shadow: inset 0px 0px 5px 6px rgba(0, 0, 0, 0.04);
  }

  @media (max-width: 980px) {
    padding: 10px;
    font-size: 16px;
    width: 110px;
  }
`;

export const Content = styled.div`
  padding: 15px;
  grid-gap: 20px;
  grid-template-columns: 650px auto;
  display: grid;
  margin: 20px auto;
  max-width: 970px;
  height: 250px;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 15px;
  background: rgb(225 188 139);
  box-shadow: 8px 8px 0px 0px rgba(0, 0, 0, 0.548);

  @media (max-width: 980px) {
    grid-gap: 15px;
    grid-template-columns: auto;
    width: 86%;
    height: auto;
  }
`;

export const TelaFig1 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 15px;
  background: rgb(155, 124, 78);
  box-shadow: inset 0px 4px 5px 0px rgba(0, 0, 0, 0.541);

  img {
    height: 150px;
  }
`;

export const Imagem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: rgb(225 188 139);

  img {
    height: 200px;
  }

  @media (max-width: 980px) {
    grid-row-start: 1;
    height: 180px;
    img {
      height: 180px;
    }
  }
`;

export const Teclado = styled.div`
  padding: 15px;
  grid-gap: 20px;
  grid-template-columns: auto auto auto auto auto auto auto auto auto auto auto auto auto;
  display: grid;
  margin: 20px auto;
  max-width: 970px;
  height: 140px;
  border-radius: 15px;

  @media (max-width: 980px) {
    grid-template-columns: auto auto auto auto auto auto;
    grid-gap: 12px;
    height: auto;
  }
`;

export const Teclas = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 10px;
  background: rgb(225 188 139);
  box-shadow: 5px 5px 0px 0px rgba(0, 0, 0, 0.548);
  cursor: pointer;

  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: #fff;
  font-size: 50px;
`;

export const Tela1 = styled.div`
  padding: 15px;
  grid-gap: 20px;
  display: flex;
  justify-content: center;
  margin: 20px auto;
  max-width: 970px;
  border-radius: 15px;
  background: rgb(233, 187, 63);
  box-shadow: inset 0px 0px 5px 6px rgba(0, 0, 0, 0.04);
`;

export const BoxConfirmation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: grid;
  width: 524px;
  height: 524px;
  text-align: center;
  background: rgb(3, 255, 255, 0.8);
  border-radius: 15px;
`;

export const BtnConcluir = styled.a`
  padding: 18px;
  font-size: 25px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: #fff;
  min-width: 180px;
  border-radius: 15px;
  background: #5be389;
  box-shadow: 3px 7px 13px -7px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  text-decoration: none !important;
`;

export const BoxGameOver = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: grid;
  width: 524px;
  height: 524px;
  text-align: center;
  background: #ff5141;
  border-radius: 15px;
  border: 1px solid black;
`;

export const BoxImg = styled.div`
  width: 100%;
  text-align: left;

  img {
    height: 150px;
  }
`;

export const BoxText = styled.div`
  margin-left: -38%;
  width: 212%;
`;
