import axios from "axios";
import { getToken } from "./auth";
import serverHost from "./serverHost";

export default {
  get(route, data) {
    return request("get", route, data);
  },
  post(route, data) {
    return request("post", route, data);
  },
  put(route, data) {
    return request("put", route, data);
  },
  delete(route, data) {
    return request("delete", route, data);
  },
};

const api = axios.create({
  baseURL: serverHost("IJWS"),
  timeout: 90000,
});

api.interceptors.request.use(async (config) => {
  const token = getToken();
  if (token) {
    config.headers.authorization = `Bearer ${token}`;
  }
  return config;
});

function request(method = "post", route, data) {
  return new Promise((resolve, reject) => {
    api({
      method,
      url: route,
      data,
      params: method === "get" ? data : undefined,
    })
      .then((res) => resolve(res.data))
      .catch((err) => {
        // if (!err.response && tentativa < 3) {
        //   return request(method, route, data, tentativa + 1);
        // }

        const { status, data } = err.response;

        // if (status === 401) {
        //   localStorage.removeItem("user_id");
        //   return window.location.replace("/painel/signin?999");
        // }

        // if (status === 403) {
        //   return window.location.replace("/painel/app/notauthorized");
        // }

        reject({ status, data });
      });
  });
}
