import { borderRadius } from '@mui/system';
import React from 'react';

const MultiplaEscola = () => {
    return(
        <div style={styles.container}>
            <div style={styles.cabecalho}>
                <p>Texto enunciado texto enunciado texto enunciado texto enunciado texto enunciado texto enunciado texto enunciado texto enunciado texto enunciado texto enunciado</p>
                <button style={styles.btnRepetir}><i class="fas fa-undo-alt" style={styles.btnRepetirIcon}></i> REPETIR</button>
                    {/* <a className="btn-repetir" onClick={() => {}}><i className="fas fa-undo-alt"></i> REPETIR</a>                */}
            </div>
            <div style={styles.conteudo}>
                <div style={styles.conteudoItem}>ITEM</div>
                <div style={styles.conteudoItem}>ITEM</div>
                <div style={styles.conteudoItem}>ITEM</div>
                <div style={styles.conteudoItem}>ITEM</div>
                <div style={styles.conteudoItem}>ITEM</div>
                <div style={styles.conteudoItem}>ITEM</div>
                <div style={styles.conteudoItem}>ITEM</div>
                <div style={styles.conteudoItem}>ITEM</div>
                <div style={styles.conteudoItem}>ITEM</div>
                <div style={styles.conteudoItem}>ITEM</div>
                <div style={styles.conteudoItem}>ITEM</div>
                <div style={styles.conteudoItem}>ITEM</div>
                <div style={styles.conteudoItem}>ITEM</div>
                <div style={styles.conteudoItem}>ITEM</div>
                <div style={styles.conteudoItem}>ITEM</div>
                <div style={styles.conteudoItem}>ITEM</div>
                <div style={styles.conteudoItem}>ITEM</div>
            </div>
        </div>
    )
}

export default MultiplaEscola;

const styles = {
    container: {
        backgroundColor: 'white', 
        margin: 5, 
        borderRadius: 15, 
        padding: 5
    },
    cabecalho: {
        backgroundColor: 'RGB(0,177,181)', 
        margin: 5, 
        borderRadius: 15, 
        padding: 5,
        color: 'white',
        fontWeight: 'bolder',
        display: 'flex',
        justifyContent: 'space-between'
    },
    conteudo: {
        display: 'flex',
        justifyContent: 'center',
        alignItens: 'center',
        flexWrap: 'wrap',
        borderRadius: 15,
        backgroundColor: 'RGB(193,239,226)',
        margin: 5,
        marginTop: 10
    },
    conteudoItem: {
        margin: 5,
        borderRadius: 15,
        backgroundColor: 'RGB(255,206,0)',
        padding: 5
    },
    btnRepetir: {
        backgroundColor: 'RGB(71, 226, 230)',
        border: 'none',
        color: 'white',
        borderRadius: 15
    },
    btnRepetirIcon: {
        color: 'RGB(71, 226, 230)',
        marginBottom: 5
    }
}