import React from "react";
import "./styesLogin.css";

export default function TermosUso() {
  return (
    <div
      style={{
        padding: 10,
        background: "white",
        margin: 10,
        borderRadius: 10,
      }}
    >
      <div id="logo" style={{ textAlign: "center" }}>
        <img
          style={{ width: 300 }}
          alt="Logo da inglês juntos"
          src={require("../assets/imagens/logo.png")}
        />
      </div>

      <p style={{ fontWeight: "bold", fontSize: 17 }}>1. TERMOS</p>
      <p style={{ fontSize: 17 }}>
        Ao acessar ao site Inglês Juntos, concorda em cumprir estes termos de
        serviço, todas as leis e regulamentos aplicáveis e concorda que é
        responsável pelo cumprimento de todas as leis locais aplicáveis. Se você
        não concordar com algum desses termos, está proibido de usar ou acessar
        este site. Os materiais contidos neste site são protegidos pelas leis de
        direitos autorais e marcas comerciais aplicáveis.
      </p>

      <p style={{ fontWeight: "bold", fontSize: 17 }}>2. USO DA LICENÇA</p>
      <p style={{ fontSize: 17 }}>
        É concedida permissão para baixar temporariamente uma cópia dos
        materiais (informações ou software) no site Inglês Juntos , apenas para
        visualização transitória pessoal e não comercial. Esta é a concessão de
        uma licença, não uma transferência de título e, sob esta licença, você
        não pode:
      </p>

      <p style={{ fontSize: 17 }}>1. modificar ou copiar os materiais;</p>
      <p style={{ fontSize: 17 }}>
        2. usar os materiais para qualquer finalidade comercial ou para exibição
        pública (comercial ou não comercial);
      </p>
      <p style={{ fontSize: 17 }}>
        3. tentar descompilar ou fazer engenharia reversa de qualquer software
        contido no site Inglês Juntos;
      </p>
      <p style={{ fontSize: 17 }}>
        4. remover quaisquer direitos autorais ou outras notações de propriedade
        dos materiais;
      </p>
      <p style={{ fontSize: 17 }}>
        ou 5. transferir os materiais para outra pessoa ou 'espelhe' os
        materiais em qualquer outro servidor.
      </p>
      <p style={{ fontSize: 17 }}>
        Esta licença será automaticamente rescindida se você violar alguma
        dessas restrições e poderá ser rescindida por Inglês Juntos a qualquer
        momento. Ao encerrar a visualização desses materiais ou após o término
        desta licença, você deve apagar todos os materiais baixados em sua
        posse, seja em formato eletrónico ou impresso.
      </p>
      <p style={{ fontWeight: "bold", fontSize: 17 }}>
        3. ISENÇÃO DE RESPONSABILIDADE
      </p>
      <p style={{ fontSize: 17 }}>
        Os materiais no site da Inglês Juntos são fornecidos 'como estão'.
        Inglês Juntos não oferece garantias, expressas ou implícitas, e, por
        este meio, isenta e nega todas as outras garantias, incluindo, sem
        limitação, garantias implícitas ou condições de comercialização,
        adequação a um fim específico ou não violação de propriedade intelectual
        ou outra violação de direitos. Além disso, o Inglês Juntos não garante
        ou faz qualquer representação relativa à precisão, aos resultados
        prováveis ou à confiabilidade do uso dos materiais em seu site ou de
        outra forma relacionado a esses materiais ou em sites vinculados a este
        site.
      </p>
      <p style={{ fontWeight: "bold", fontSize: 17 }}>4. LIMITAÇÕES</p>
      <p style={{ fontSize: 17 }}>
        Em nenhum caso o Inglês Juntos ou seus fornecedores serão responsáveis
        por quaisquer danos (incluindo, sem limitação, danos por perda de dados
        ou lucro ou devido a interrupção dos negócios) decorrentes do uso ou da
        incapacidade de usar os materiais em Inglês Juntos, mesmo que Inglês
        Juntos ou um representante autorizado da Inglês Juntos tenha sido
        notificado oralmente ou por escrito da possibilidade de tais danos. Como
        algumas jurisdições não permitem limitações em garantias implícitas, ou
        limitações de responsabilidade por danos consequentes ou incidentais,
        essas limitações podem não se aplicar a você
      </p>
      <p style={{ fontWeight: "bold", fontSize: 17 }}>
        5. PRECISÃO DOS MATERIAIS
      </p>
      <p style={{ fontSize: 17 }}>
        Os materiais exibidos no site da Inglês Juntos podem incluir erros
        técnicos, tipográficos ou fotográficos. Inglês Juntos não garante que
        qualquer material em seu site seja preciso, completo ou atual. Inglês
        Juntos pode fazer alterações nos materiais contidos em seu site a
        qualquer momento, sem aviso prévio. No entanto, Inglês Juntos não se
        compromete a atualizar os materiais.
      </p>
      <p style={{ fontWeight: "bold", fontSize: 17 }}>
        6. PRODUTOS OU SERVIÇOS
      </p>
      <p style={{ fontSize: 17 }}>
        Certos produtos ou serviços podem estar disponíveis exclusivamente
        online através do site. Todas as descrições de produtos ou preços de
        produtos são sujeitos a alteração a qualquer momento sem notificação, a
        nosso critério exclusivo. Reservamos o direito de descontinuar qualquer
        produto a qualquer momento. Qualquer oferta por qualquer produto ou
        serviço feito nesse site é nula onde proibida. Não garantimos que a
        qualidade de quaisquer produtos, serviços, informações ou outros
        materiais comprados ou obtidos por você vão atender às suas
        expectativas, ou que quaisquer erros no Serviço serão corrigidos.
      </p>
      <p style={{ fontWeight: "bold", fontSize: 17 }}>7. ASSINATURA</p>
      <p style={{ fontSize: 17 }}>
        Uma assinatura anual da acesso a todo conteúdo da plataforma Inglês
        Juntos por um ano a partir da data da assinatura. Para tal, uma cobrança
        será feita no cartão, que fora cadastrado no momento da compra. Esta
        assinatura será renovada automaticamente todo ano. A Inglês Juntos não
        armazena nenhum cartão de crédito, todo gerenciamento de pagamentos e de
        cartões de crédito é feita pela Iugu.
      </p>
      <p style={{ fontWeight: "bold", fontSize: 17 }}>
        8. MODIFICAÇÕES AO SERVIÇO E PREÇOS
      </p>
      <p style={{ fontSize: 17 }}>
        Os nossos preços estão sujeitos a alteração sem notificação prévia aos
        usuários. Podemos modificar ou descontinuar o Serviço(ou qualquer parte
        ou conteúdo do mesmo), sem notificação, a qualquer momento. Assim, não
        devemos ser responsabilizados por qualquer modificação, alteração de
        preço, suspensão ou descontinuação do Serviço.
      </p>
      <p style={{ fontWeight: "bold", fontSize: 17 }}>
        9. TERMOS DE CANCELAMENTO
      </p>
      <p style={{ fontSize: 17 }}>
        <span style={{ fontWeight: "bold", fontSize: 17 }}>
          {" "}
          Cancelamento e reembolso:
        </span>{" "}
        O cancelamento será realizado pelo e-mail contato@inglesjuntos.com.br.
        Se você comprou uma assinatura do Inglês Juntos através do nosso site e
        decidiu não continuar, escreva-nos nos primeiros 3 dias. Nós
        cancelaremos sua assinatura e reembolsaremos a compra. Se você não quer
        o serviço de assinatura nem as cobranças correspondentes, você deve
        encerrar o acesso de assinatura fornecido no período de teste antes da
        expiração deste, pelo nosso e-mail de contato. Feito o cancelamento, a
        cessação do acesso à inscrição entrará em vigor no dia seguinte ao
        último dia do seu período de inscrição. No caso do cancelamento ser
        solicitado após o período de experiência de 03 (três) dias, a assinatura
        será cancelada, mas não haverá reembolso do valor pago.
      </p>
      <p style={{ fontSize: 17 }}>
        {" "}
        <span style={{ fontWeight: "bold", fontSize: 17 }}>
          Renovação automática:
        </span>{" "}
        após a renovação automática da sua assinatura, você tem 03 (três) dias
        para desistência e cancelamento.
      </p>
      <p style={{ fontWeight: "bold", fontSize: 17 }}>10. LINKS</p>
      <p style={{ fontSize: 17 }}>
        O Inglês Juntos não analisou todos os sites vinculados ao seu site e não
        é responsável pelo conteúdo de nenhum site vinculado. A inclusão de
        qualquer link não implica endosso por Inglês Juntos do site. O uso de
        qualquer site vinculado é por conta e risco do usuário.
      </p>
      <p style={{ fontWeight: "bold", fontSize: 17 }}>11. MODIFICAÇÕES</p>
      <p style={{ fontSize: 17 }}>
        O Inglês Juntos pode revisar estes termos de serviço do site a qualquer
        momento, sem aviso prévio. Ao usar este site, você concorda em ficar
        vinculado à versão atual desses termos de serviço.
      </p>
      <p style={{ fontWeight: "bold", fontSize: 17 }}>LEI APLICAVEL</p>
      <p style={{ fontSize: 17 }}>
        Estes termos e condições são regidos e interpretados de acordo com as
        leis do Inglês Juntos e você se submete irrevogavelmente à jurisdição
        exclusiva dos tribunais naquele estado ou localidade.
      </p>
    </div>
  );
}
