import React, {useEffect, useState, Fragment} from 'react';
import {click, win, wrong} from '../libs/soundEffects';

const Cliques = ({config}) => {
    const qtdRodadas = config.rodadas ? config.rodadas.length : 0;

    const [contador, setContador] = useState(1);
    const [respostaOk, setRespostaOk] = useState(undefined);
    const [resposta, setResposta] = useState('');
    const [rodadaAtual, setRodadaAtual] = useState(undefined);
    const [numRodadaAtual, setNumRodadaAtual] = useState(0);

    useEffect(() => {


        iniciarRodada(1);
    }, []);

    const iniciarRodada = (numero) => {
        const rodada = config.rodadas && config.rodadas.length > 0 ? config.rodadas[numero - 1] : config;

        if(!rodada) return;

        if(rodada.audioEnunciado){
            const audio = new Audio(rodada.audioEnunciado);

            audio.play();        
        }

        setNumRodadaAtual(numero);
        setRodadaAtual(rodada);
        setResposta('');
        setRespostaOk(false);
    }

    const avancar = () => {
        click();

        if(numRodadaAtual === qtdRodadas || qtdRodadas === 0){
            return config.avancar ? config.avancar() : alert('Fim!');
        } else {
            iniciarRodada(numRodadaAtual + 1);
        }
    }

    const handleClique = () => {
      setContador(contador + 1)

      if(contador === rodadaAtual.contador) {
        setRespostaOk(true)

        setContador(1)
      }
    }

    if(!rodadaAtual) return null;

    return(
        <div className="content">
            <div className="container">
                <div className="box-topo">
                    <div className="item-box-topo-1">
                        <p className="p-text">{rodadaAtual.enunciado}</p>
                    </div>
                    <div className="item-box-topo-2">
                        <a className="btn-repetir" onClick={() => iniciarRodada(1)}><i className="fas fa-undo-alt"></i> REPETIR</a>
                    </div>
                </div>
                {respostaOk ?
                    <div className="tl8-box-2">
                        <div className="tl7-box-confirmacao">
                            <p className="p-text">PARABÉNS</p>
                            <p className="p-text">VOCÊ ACERTOU!</p>
                            <a className="btn-concluir" onClick={avancar}>CONTINUAR <i className="fas fa-chevron-right"></i> </a>
                        </div>
                    </div>
                :
                  <>
                    <div className="box-pontuacao" style={{width: "100%"}}>
                      <p className="p-text">
                          CLIQUES: {`${contador-1}/${rodadaAtual.contador}`}
                      </p>
                    </div>
                    <div className="tl8-box-2">
                      <a onClick={handleClique}><img src={rodadaAtual.imagem} /></a>
                    </div>
                  </>
                }
            </div>
        </div>
    )
}

export default Cliques;