module.exports = {
    retornarImagemCor(cor){
        switch(cor){
            case '#7B5CA4': return 'cor-01.png';
            case '#F3910D': return 'cor-02.png';
            case '#0894CE': return 'cor-03.png';
            case '#6EC5C7': return 'cor-04.png';
            case '#2B9533': return 'cor-05.png';
            case '#E70773': return 'cor-06.png';
            case '#F49B66': return 'cor-07.png';
            case '#CB3939': return 'cor-08.png';
            default: return 'cor-01.png';
        }
    },

    getQueryParams(query=""){
        if(query === "") return {};

        let params = {};
    
        query.replace("?", "").split("&").forEach(p => params[p.split("=")[0]] = p.split("=")[1]);

        return params;
    }, 

    formatDateTime(isoDateTime = '', model){

        let dateTimeContent = [];
    
        if(isoDateTime.includes('T')){
            dateTimeContent = isoDateTime.split('T');
        } else {
            dateTimeContent = isoDateTime.split(' ');
        }    
    
        const date = dateTimeContent[0];
        const time = dateTimeContent[1];
    
        let year = '', month = '', day = '', hours = '', minutes = '', seconds = '';
    
        if(date){
            const dateContent = date.split('-');
    
            year = dateContent[0];
            month = dateContent[1];
            day = dateContent[2];
        }
    
        if(time){
            const timeContent = time.split(':');
    
            hours = timeContent[0];
            minutes = timeContent[1];
            seconds = timeContent[2];
        }
    
        return model
            .replace('Y', year)
            .replace('M', month)
            .replace('D', day)
            .replace('h', hours)
            .replace('m', minutes)
            .replace('s', seconds)
    }
}