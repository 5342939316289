import React, { useEffect, useState } from 'react';
import { click, win, wrong } from '../libs/soundEffects';
import {
  BoxTopo,
  ItemBox1,
  ItemBox2,
  Button,
  Content,
  TelaFig1,
  Text,
  Imagem,
  Teclado,
  Teclas,
  Tela1,
  BoxConfirmation,
  BtnConcluir,
  BoxGameOver,
  BoxImg,
  BoxText,
} from './styles/stylesForca';

import img1 from '../assets/forca/BOLO_01.png';
import img2 from '../assets/forca/BOLO_02.png';
import img3 from '../assets/forca/BOLO_03.png';
import img4 from '../assets/forca/BOLO_04.png';
import img5 from '../assets/forca/BOLO_05.png';
import img6 from '../assets/forca/BOLO_06.png';
import img7 from '../assets/forca/BOLO_07.png';
import img8 from '../assets/forca/BOLO_08.png';
import img9 from '../assets/forca/BOLO_09.png';

let audio = undefined;

export default function Forca({ config }) {
  const qtdRodadas = config.rodadas ? config.rodadas.length : 0;
  const [rodadaAtual, setRodadaAtual] = useState(undefined);
  const [numRodadaAtual, setNumRodadaAtual] = useState(0);
  const [respostaOk, setRespostaOk] = useState(undefined);
  const [letters, setLetters] = useState([]);
  const [life, setLife] = useState(undefined);
  const [isWinner, setIsWinner] = useState(0);
  const [erros, setErros] = useState(undefined);
  let gameOver = false;
  const images = [img1, img2, img3, img4, img5, img6, img7, img8, img9];

  const verifyWord = (letter) => {
    const secretWord = rodadaAtual.palavra;

    const palavra = [];

    secretWord.split('').forEach((letterTo, index) => {
      if (letterTo === letter) palavra.push(index);
    });

    return palavra;
  };

  const confirmLetter = (evt) => {
    click();
    let ltr = evt.target.innerText;
    const resultVerifyWord = verifyWord(ltr);

    if (letters.includes(ltr)) {
      return;
    }

    if (resultVerifyWord.length === 0) {
      setLife(life - 1);
      setErros(erros + 1);
    } else {
      let holdLetter = letters;

      resultVerifyWord.forEach((indexOfLetter) => {
        holdLetter[indexOfLetter] = ltr;
      });

      setLetters([...holdLetter]);
      setIsWinner(isWinner - resultVerifyWord.length);

      if (isWinner === 1) {
        win();
        setRespostaOk(true);
      }
    }
  };

  useEffect(() => {
    if (config.audioInicio) {
      const audio = new Audio(config.audioInicio);

      audio.play();
    }

    iniciarRodada(1);
  }, []);

  const iniciarRodada = (numero) => {
    const rodada = config.rodadas[numero - 1];

    if (!rodada) return;

    if (rodada.audioEnunciado) {
      audio = new Audio(rodada.audioEnunciado);

      if (rodada.atrasoAudio) {
        setTimeout(() => audio.play(), rodada.atrasoAudio * 1000);
      } else {
        audio.play();
      }
    }

    let wordSize = rodada.palavra.length;

    setNumRodadaAtual(numero);
    setRodadaAtual(rodada);
    setRespostaOk(false);
    setLetters([...Array(wordSize).fill(' - ')]);
    setIsWinner(wordSize);
    setLife(8);
    setErros(0);
    gameOver = false;
  };

  const avancar = () => {
    click();

    if (numRodadaAtual === qtdRodadas) {
      return config.avancar ? config.avancar() : alert('Fim!');
    } else {
      iniciarRodada(numRodadaAtual + 1);
    }
  };

  if (life < 1) {
    wrong();
    gameOver = true;
  }

  const generateButtons = () => {
    return 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('').map((ltr) => (
      <Teclas key={ltr} value={ltr} onClick={confirmLetter}>
        {ltr}
      </Teclas>
    ));
  };

  if (!rodadaAtual) return null;

  return (
    <div>
      {rodadaAtual ? (
        <>
          <BoxTopo>
            <ItemBox1>
              <Text>{rodadaAtual.enunciado}</Text>
            </ItemBox1>
            <ItemBox2>
              <Button onClick={() => iniciarRodada(1)}>
                <i className="fas fa-undo-alt"></i> REPETIR
              </Button>
            </ItemBox2>
          </BoxTopo>
          <Content>
            <TelaFig1>
              <BoxImg>
                <img src={rodadaAtual.imagemDica} alt="" />
              </BoxImg>
              <BoxText>
                <Text style={{ marginRight: '0%', fontSize: '45px' }}>
                  {letters}
                </Text>
              </BoxText>
            </TelaFig1>
            <Imagem>
              <img src={images[erros]} alt="" />
            </Imagem>
            {/* <Text>ERROS: {erros}</Text> */}
          </Content>
          {!gameOver ? (
            <>
              {respostaOk ? (
                <Tela1>
                  <BoxConfirmation>
                    <Text>PARABÉNS</Text>
                    <Text>VOCÊ ACERTOU!</Text>
                    <BtnConcluir onClick={avancar}>
                      CONTINUAR <i className="fas fa-chevron-right"></i>{' '}
                    </BtnConcluir>
                  </BoxConfirmation>
                </Tela1>
              ) : (
                <Teclado>{generateButtons()}</Teclado>
              )}
            </>
          ) : (
            <Tela1>
              <BoxGameOver>
                <Text>QUE PENA O BOLO ACABOU!</Text>
                <Text>VAMOS TENTAR DE NOVO?</Text>
                <BtnConcluir onClick={() => iniciarRodada(1)}>
                  <i className="fas fa-undo-alt"></i> REPETIR
                </BtnConcluir>
              </BoxGameOver>
            </Tela1>
          )}
        </>
      ) : (
        <h1>Carregando</h1>
      )}
    </div>
  );
}
