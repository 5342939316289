import serverHost from '../../services/serverHost';

const configAcharItem = (identificador) => {
    switch(identificador){
        case 'nao-alfabetizados_unit1_aula2_atividade1':
            return {
                imagem: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-1/aula-2/video-1/find_dog.jpg`,
                rodadas: [
                    {
                        enunciado: 'ESCOLHA O DOG QUE NÃO CONSEGUE FLY',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-1/aula-2/video-1/dog_nao_consegue.mp3`,
                        blocosResposta: [10]
                    },
                    {
                        enunciado: 'ESCOLHA O DOG QUE CONSEGUE FLY',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-1/aula-2/video-1/dog_consegue.mp3`,
                        blocosResposta: [7]
                    }
                ]
            }

        case 'nao-alfabetizados_unit1_aula4_atividade1':
            return {
                imagem: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-1/aula-4/video-1/pets.jpg`,
                rodadas: [
                    {
                        enunciado: 'ONDE ESTÁ O MOUSE?',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-1/aula-4/video-1/pets.mp3`,
                        blocosResposta: [8]
                    }
                ]
            }

        case 'nao-alfabetizados_unit1_aula8_atividade1':
            return {
                imagem: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-1/aula-8/video-1/imagem.jpg`,
                rodadas: [
                    {
                        enunciado: 'ENCONTRE O BIRD QUE CONSEGUE FLY!',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-1/aula-8/video-1/enunciado.mp3`,
                        blocosResposta: [2, 6]
                    }
                ]
            }

        case 'alfabetizados_unit1_aula2_atividade2':
            return {
                imagem: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-1/aula-2/video-2/imagem.jpg`,
                rodadas: [
                    {
                        enunciado: 'VOCÊ CONSEGUE DESCOBRIR ONDE ESTÁ O BOARD GAME NO QUARTO DE AKEMI?',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-1/aula-2/video-2/enunciado.mp3`,
                        blocosResposta: [9]
                    }
                ]
            }

        case 'alfabetizados_unit1_aula6_atividade2':
            return {
                imagem: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-1/aula-6/video-2/imagem.jpg`,
                rodadas: [
                    {
                        enunciado: 'ENCONTRE OS SEGUINTES TOYS: TEDDY BEAR',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-1/aula-6/video-2/enunciado-teddy-bear.mp3`,
                        blocosResposta: [14]
                    },
                    {
                        enunciado: 'ENCONTRE OS SEGUINTES TOYS: BALL',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-1/aula-6/video-2/enunciado-ball.mp3`,
                        blocosResposta: [15]
                    },
                    {
                        enunciado: 'ENCONTRE OS SEGUINTES TOYS: CAR',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-1/aula-6/video-2/enunciado-car.mp3`,
                        blocosResposta: [15, 16]
                    }
                ]
            }

        case 'nao-alfabetizados_unit2_aula4_atividade1':
            return {
                imagem: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-4/video-1/imagem.jpg`,
                rodadas: [
                    {
                        enunciado: 'ONDE ESTÁ O SHIP?',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-4/video-1/enunciado.ogg`,
                        blocosResposta: [5, 6, 9, 10]
                    }
                ]
            }

        case 'nao-alfabetizados_unit2_aula2_atividade2':
            return {
                imagem: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-2/video-2/imagem.png`,
                imagemLargura: 676,
                imagemAltura: 505,
                rodadas: [
                    {
                        enunciado: 'ONDE ESTÁ O TRAIN?',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-2/video-2/enunciado.ogg`,
                        blocosResposta: [12, 16]
                    }
                ]
            }

        case 'nao-alfabetizados_unit2_aula8_atividade2':
            return {
                imagem: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-8/video-2/imagem.png`,
                imagemLargura: 676,
                imagemAltura: 505,
                rodadas: [
                    {
                        enunciado: 'QUAIS PERSONAGENS ESTÃO ANDANDO DE BIKE NA IMAGEM?',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-8/video-2/enunciado.ogg`,
                        blocosResposta: [5, 9]
                    }
                ]
            }

        case 'nao-alfabetizados_unit2_aula9_atividade2':
            return {
                imagem: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-9/video-2/imagem.png`,
                imagemLargura: 511,
                imagemAltura: 626,
                rodadas: [
                    {
                        enunciado: 'ENCONTRE TICKETS PARA OS FRIENDS VIAJAREM JUNTOS! VOCÊ VAI PRECISAR ACHAR 5 TICKETS',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-9/video-2/enunciado.ogg`,
                        multipla: true,
                        textoContador: 'TICKETS ENCONTRADOS',
                        blocosResposta: [5, 7, 10, 13, 16]
                    }
                ]
            }

        case 'alfabetizados_unit2_aula3_atividade2':
            return {
                imagem: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-2/aula-3/video-2/imagem.jpg`,
                imagemLargura: 676,
                imagemAltura: 676,
                rodadas: [
                    {
                        enunciado: 'ENCONTRE OS 5 STRAWBERRIES ESCONDIDOS NA IMAGEM!',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-2/aula-3/video-2/enunciado.ogg`,
                        multipla: true,
                        textoContador: 'STRAWBERRIES ENCONTRADOS',
                        blocosResposta: [6, 7, 8, 9, 14]
                    }
                ]
            } 

        default: return {}
    }
}

export default configAcharItem;