import serverHost from '../../services/serverHost';

const configAgrupar = (identificador) => {
    switch(identificador){
        case 'alfabetizados_unit2_aula8_atividade2':
            return {
                enunciado: 'ESCREVA O NOME DE CADA FRUIT NA COLUNA CORRETA:',
                audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-2/aula-8/video-2/enunciado.ogg`,
                itens: [
                    {
                        nome: 'APPLE',
                        grupoId: 1
                    },
                    {
                        nome: 'PEAR',
                        grupoId: 2
                    },
                    {
                        nome: 'PEACH',
                        grupoId: 3
                    },
                    {
                        nome: 'LEMON',
                        grupoId: 2
                    },
                    {
                        nome: 'ORANGE',
                        grupoId: 3
                    },
                    {
                        nome: 'AVOCADO',
                        grupoId: 2
                    },
                    {
                        nome: 'STRAWBERRY',
                        grupoId: 1
                    }
                ],
                grupos: [
                    {
                        id: 1,
                        titulo: 'FRUTAS VERMELHAS',
                        cor: 'red'
                    },
                    {
                        id: 2,
                        titulo: 'FRUTAS VERDES',
                        cor: 'green'
                    },
                    {
                        id: 3,
                        titulo: 'FRUTAS LARANJAS',
                        cor: 'orangered'
                    }
                ]
            }        
    }
}

export default configAgrupar;