import serverHost from '../../services/serverHost';

const configMultiplaEscolha = (identificador) => {
    switch(identificador){
        case 'nao-alfabetizados_unit1_aula1_atividade1':
            return {
                midia: 'imagem',
                rodadas: [
                    {
                        enunciado: "Quem é o Jack Tiger?",
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-1/aula-1/video-1/pergunta_tigre.ogg`,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-1/aula-1/video-1/tigre.png`,
                                correta: true
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-1/aula-1/video-1/canguru.png`
                            }
                        ]
                    },
                    {
                        enunciado: "Quem é o Kim Kangaroo?",
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-1/aula-1/video-1/pergunta_canguru.ogg`,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-1/aula-1/video-1/tigre.png`,
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-1/aula-1/video-1/canguru.png`,
                                correta: true
                            }
                        ]
                    }
                ]
            }

        case 'nao-alfabetizados_unit1_aula3_atividade1':
            return {
                midia: 'imagem',
                rodadas: [
                    {
                        enunciado: "QUAL DESTES É UM CAT?",
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-1/aula-3/video-1/enunciado.mp3`,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/bird.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/bird.mp3`
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/cat.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/cat.mp3`,
                                correta: true
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/dog.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/dog.mp3`
                            }
                        ],
                    }
                ]
            }

        case 'alfabetizados-unit1-aula1-atividade3':
            return {
                midia: 'video',
                rodadas: [                        
                    {
                        enunciado: "QUAL GESTO COMBINA COM ESTE TRECHO DA MÚSICA?",
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-1/aula-1/video-3/enunciado-toy.mp3`,
                        opcoes: [
                            {
                                video: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-1/aula-1/video-3/Like-To-Do.m4v`
                            },
                            {
                                video: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-1/aula-1/video-3/Toy.m4v`,
                                correta: true
                            },
                            {
                                video: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-1/aula-1/video-3/Doll.m4v`
                            }
                        ]
                    },
                    {
                        enunciado: "QUAL GESTO COMBINA COM ESTE TRECHO DA MÚSICA?",
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-1/aula-1/video-3/enunciado-blocks.mp3`,
                        opcoes: [
                            {
                                video: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-1/aula-1/video-3/Girl-Boy.m4v`
                            },
                            {
                                video: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-1/aula-1/video-3/Blocks.m4v`,
                                correta: true
                            },
                            {
                                video: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-1/aula-1/video-3/Doll.m4v`
                            }                                
                        ]
                    },
                    {
                        enunciado: "QUAL GESTO COMBINA COM ESTE TRECHO DA MÚSICA?",
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-1/aula-1/video-3/enunciado-puppet.mp3`,
                        opcoes: [
                            {
                                video: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-1/aula-1/video-3/Puppet.m4v`,
                                correta: true
                            },
                            {
                                video: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-1/aula-1/video-3/Blocks.m4v`
                            },
                            {
                                video: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-1/aula-1/video-3/Trucks.m4v`
                            }                                
                        ]
                    }
                ]
            }

        case 'nao-alfabetizados_unit1_aula5_atividade1':
            return {
                midia: 'imagem',
                rodadas: [
                    {
                        enunciado: 'O QUE O RABBIT CONSEGUE FAZER?',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-1/aula-5/video-1/enunciado.mp3`,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/mouse2.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/acoes/eat.mp3`
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/cat.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/acoes/play.mp3`                       
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/rabbit.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/acoes/hop.mp3`,
                                correta: true
                            }
                        ],
                    }
                ]
            }

        case 'nao-alfabetizados_unit1_aula6_atividade2':
            return {
                midia: 'imagem',
                rodadas: [
                    {
                        enunciado: "OUÇA A PALAVRA E ESCOLHA O PET",
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-1/aula-6/video-2/pergunta_dog.mp3`,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/dog.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/dog.mp3`,
                                correta: true
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/rabbit.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/rabbit.mp3`,
                            }
                        ]
                    },                    
                    {
                        enunciado: "OUÇA A PALAVRA E ESCOLHA O PET",
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-1/aula-6/video-2/pergunta_cat.mp3`,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/dog2.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/dog.mp3`,                             
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/cat2.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/cat.mp3`,
                                correta: true
                            }
                        ]
                    },
                    {
                        enunciado: "OUÇA A PALAVRA E ESCOLHA O PET",
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-1/aula-6/video-2/pergunta_mouse.mp3`,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/mouse.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/mouse.mp3`,
                                correta: true
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/dog.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/dog.mp3`,
                            }
                        ]
                    }
                ]
            }

        case 'nao-alfabetizados_unit1_aula7_atividade1':
            return {
                midia: 'imagem',
                rodadas: [
                    {
                        enunciado: 'O QUE O FISH FAZ?',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-1/aula-7/video-1/enunciado.mp3`,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/fish2.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/acoes/swim.mp3`,
                                correta: true
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/cat.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/acoes/play.mp3`
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/dog2.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/acoes/hunt.mp3`
                            }
                        ]
                    }
                ]
            }

        case 'nao-alfabetizados_unit1_aula9_atividade1':
            return {
                midia: 'imagem',
                rodadas: [
                    {
                        enunciado: 'QUAL É SEU PET FAVORITO?',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-1/aula-9/video-1/enunciado.mp3`,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/bird2.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/bird.mp3`,
                                correta: true
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/cat.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/cat.mp3`,
                                correta: true
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/dog2.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/dog.mp3`,
                                correta: true
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/fish.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/fish.mp3`,
                                correta: true
                            }
                        ]
                    }
                ],
                textoRespostaOk: 'QUE LEGAL!'
            }

        case 'nao-alfabetizados_unit1_aula9_atividade2':
            return {
                midia: 'imagem',
                rodadas: [
                    {
                        enunciado: 'O DOG CONSEGUE FLY?',
                        imagemEnunciado: `${serverHost()}/storage/ij-atividades/comum/dog.jpg`,
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-1/aula-9/video-2/pergunta_dog.mp3`,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/yes.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/yes.mp3`,
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/no.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/no.mp3`,
                                correta: true,
                                textoConfirmacao: 'NO!'
                            }
                        ]
                    },
                    {
                        enunciado: 'O CAT CONSEGUE FLY?',
                        imagemEnunciado: `${serverHost()}/storage/ij-atividades/comum/cat.jpg`,
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-1/aula-9/video-2/pergunta_cat.mp3`,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/yes.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/yes.mp3`,
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/no.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/no.mp3`,
                                correta: true,
                                textoConfirmacao: 'NO!'
                            }
                        ]
                    },
                    {
                        enunciado: 'O MOUSE CONSEGUE FLY?',
                        imagemEnunciado: `${serverHost()}/storage/ij-atividades/comum/mouse.jpg`,
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-1/aula-9/video-2/pergunta_mouse.mp3`,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/yes.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/yes.mp3`,
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/no.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/no.mp3`,
                                correta: true,
                                textoConfirmacao: 'NO!'
                            }
                        ]
                    },
                    {
                        enunciado: 'O BIRD CONSEGUE FLY?',
                        imagemEnunciado: `${serverHost()}/storage/ij-atividades/comum/bird.jpg`,
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-1/aula-9/video-2/pergunta_bird.mp3`,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/yes.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/yes.mp3`,
                                correta: true
                                ,
                                textoConfirmacao: 'YES!'
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/no.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/no.mp3`,                                
                            }
                        ]
                    }
                ]
            }

        case 'nao-alfabetizados_unit1_aula10_atividade1':
            return {
                midia: 'imagem',
                rodadas: [
                    {
                        enunciado: "O QUE ESTE PET FAZ?",
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-1/aula-10/video-1/enunciado.mp3`,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/dog2.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/acoes/hunt.mp3`,
                                correta: true,
                                textoConfirmacao: 'HUNT'
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/cat.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/acoes/play.mp3`,
                                correta: true,
                                textoConfirmacao: 'PLAY'
                            },
                        ]
                    },
                    {
                        enunciado: "O QUE ESTE PET FAZ?",
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-1/aula-10/video-1/enunciado.mp3`,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/bird.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/acoes/fly.mp3`,
                                correta: true,
                                textoConfirmacao: 'FLY'
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/mouse.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/acoes/eat.mp3`,
                                correta: true,
                                textoConfirmacao: 'EAT'
                            },
                        ]
                    },
                    {
                        enunciado: "O QUE ESTE PET FAZ?",
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-1/aula-10/video-1/enunciado.mp3`,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/fish2.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/acoes/swim.mp3`,
                                correta: true,
                                textoConfirmacao: 'SWIM'
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/rabbit.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/acoes/hop.mp3`,
                                correta: true,
                                textoConfirmacao: 'HOP'
                            },
                        ]
                    }
                ]
            }

        case 'nao-alfabetizados_unit1_aula10_atividade2':
            return {
                midia: 'imagem',
                rodadas: [
                    {
                        enunciado: 'QUE PET É ESSE?',
                        imagemEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-1/aula-10/video-2/dog.jpg`,
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-1/aula-10/video-2/enunciado.mp3`,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/dog2.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/dog.mp3`,
                                correta: true
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/bird.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/bird.mp3`,
                            },
                        ]
                    },
                    {
                        enunciado: 'QUE PET É ESSE?',
                        imagemEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-1/aula-10/video-2/cat.jpg`,
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-1/aula-10/video-2/enunciado.mp3`,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/rabbit.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/rabbit.mp3`
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/cat2.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/cat.mp3`,
                                correta: true
                            },
                        ]
                    },
                    {
                        enunciado: 'QUE PET É ESSE?',
                        imagemEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-1/aula-10/video-2/mouse.jpg`,
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-1/aula-10/video-2/enunciado.mp3`,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/mouse.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/mouse.mp3`,
                                correta: true
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/fish.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/fish.mp3`,                                
                            }
                        ]
                    }
                ]
            }

        case 'alfabetizados_unit1_aula1_atividade1': {
            return {
                midia: 'texto',
                rodadas: [
                    {
                        enunciado: 'PUXA, A AKEMI PERDEU SEU TOY FAVORITO! QUAL É O NOME DELE MESMO?',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-1/aula-1/video-1/enunciado.mp3`,
                        opcoes: [
                            {
                                texto: 'Tuffy',
                                audio: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-1/aula-1/video-1/tuffy.mp3`,
                                correta: true
                            },
                            {
                                texto: 'Caco',
                                audio: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-1/aula-1/video-1/caco.mp3`
                            },
                            {
                                texto: 'Bob',
                                audio: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-1/aula-1/video-1/bob.mp3`
                            }
                        ]
                    }
                ]
            }
        }

        case 'alfabetizados_unit1_aula1_atividade2':
            return{
                midia: 'imagem',
                rodadas: [
                    {
                        enunciado: 'QUAIS DESTAS IMAGENS MOSTRAM TOYS?',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-1/aula-1/video-2/enunciado.mp3`,
                        multipla: true,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/objetos/book.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/objetos/book.mp3`
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/objetos/ball.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/objetos/ball.mp3`,
                                correta: true
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/objetos/truck.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/objetos/truck.mp3`,
                                correta: true
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/objetos/robot.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/objetos/robot.mp3`,
                                correta: true
                            }
                        ]
                    }
                ]
            }

        case 'alfabetizados_unit1_aula2_atividade1':
            return {
                midia: 'imagem',
                rodadas: [
                    {
                        enunciado: 'O PAI DA AKEMI SUGERIU QUE ELES PLAY COM ALGUMA COISA! O QUE ERA MESMO?',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-1/aula-2/video-1/enunciado.mp3`,                        
                        multipla: true,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/objetos/ball.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/objetos/ball.mp3`,
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/objetos/book.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/objetos/book.mp3`
                            },                            
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/objetos/board-game.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/objetos/board-game.mp3`,
                                correta: true
                            }
                        ]
                    }
                ]
            }

        case 'alfabetizados_unit1_aula3_atividade1':
            return {
                midia: 'imagem',
                rodadas: [
                    {
                        enunciado: 'OS TOYS GANHARAM VIDA! QUAIS TOYS ESTAVAM CONVERSANDO NA ÚLTIMA CENA MESMO?',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-1/aula-3/video-1/enunciado.mp3`,
                        multipla: true,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/objetos/board-game.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/objetos/board-game.mp3`                                
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/objetos/pinwheel.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/objetos/pinwheel.mp3`,
                                correta: true
                            },   
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/objetos/ball.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/objetos/ball.mp3`,
                                correta: true
                            },                                                     
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/objetos/doll.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/objetos/doll.mp3`
                            }
                        ]
                    }
                ]
            }

        case 'alfabetizados_unit1_aula3_atividade2':
            return {
                midia: 'texto',
                rodadas: [
                    {
                        enunciado: 'ESTA É A SOMBRA DE QUAL TOY?',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-1/aula-3/video-2/enunciado.mp3`,
                        imagemEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-1/aula-3/video-2/ball.png`,
                        opcoes: [
                            {
                                texto: 'Ball',
                                audio: `${serverHost()}/storage/ij-atividades/comum/objetos/ball.mp3`,
                                correta: true
                            },
                            {
                                texto: 'Book',
                                audio: `${serverHost()}/storage/ij-atividades/comum/objetos/book.mp3`
                            },
                            {
                                texto: 'Pinwheel',
                                audio: `${serverHost()}/storage/ij-atividades/comum/objetos/pinwheel.mp3`,
                            }
                        ]
                    },
                    {
                        enunciado: 'ESTA É A SOMBRA DE QUAL TOY?',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-1/aula-3/video-2/enunciado.mp3`,
                        imagemEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-1/aula-3/video-2/pinwheel.png`,
                        opcoes: [
                            {
                                texto: 'Ball',
                                audio: `${serverHost()}/storage/ij-atividades/comum/objetos/ball.mp3`
                            },
                            {
                                texto: 'Book',
                                audio: `${serverHost()}/storage/ij-atividades/comum/objetos/book.mp3`
                            },
                            {
                                texto: 'Pinwheel',
                                audio: `${serverHost()}/storage/ij-atividades/comum/objetos/pinwheel.mp3`,
                                correta: true
                            }
                        ]
                    },
                ]
            }

        case 'alfabetizados_unit1_aula4_atividade1':
            return {
                midia: 'imagem',
                rodadas: [
                    {
                        enunciado: 'COMO OS TOYS VÃO CHEGAR NA GARAGEM? ELES VÃO DE...',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-1/aula-4/video-1/enunciado.mp3`,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/objetos/board-game.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/objetos/board-game.mp3`
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/objetos/pinwheel.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/objetos/pinwheel.mp3`
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/objetos/truck.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/objetos/truck.mp3`,
                                correta: true
                            }
                        ]
                    }
                ]
            }

        case 'alfabetizados_unit1_aula5_atividade1':
            return {
                midia: 'imagem',
                rodadas: [
                    {
                        enunciado: 'VOCÊ LEMBRA QUEM VIU O TUFFY, O TED BEAR DA AKEMI?',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-1/aula-5/video-1/enunciado.mp3`,
                        opcoes: [                            
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/objetos/pinwheel.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/objetos/pinwheel.mp3`
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/objetos/doll.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/objetos/doll.mp3`,
                                correta: true
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/objetos/ball.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/objetos/ball.mp3`,
                            }
                        ]
                    }
                ]
            }

        case 'alfabetizados_unit1_aula7_atividade1':
            return {
                midia: 'imagem',
                rodadas: [
                    {
                        enunciado: 'UFA! FINALMENTE ACHARAM O TUFFY. O TED BEAR DA AKEMI ESTAVA EM CIMA DO QUE?',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-1/aula-7/video-1/enunciado.mp3`,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/objetos/blocks.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/objetos/blocks.mp3`,
                                correta: true
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/objetos/car.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/objetos/car.mp3`
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/objetos/doll.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/objetos/doll.mp3`
                            }
                        ]
                    }
                ]
            }
    
        case 'alfabetizados_unit1_aula8_atividade1':
            return {
                midia: 'imagem',
                rodadas: [
                    {
                        enunciado: 'QUAIS TOYS APARECERAM NA ÚLTIMA CENA?',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-1/aula-8/video-1/enunciado.mp3`,
                        multipla: true,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/objetos/ball.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/objetos/ball.mp3`,
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/objetos/blocks.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/objetos/blocks.mp3`,
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/objetos/robot.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/objetos/robot.mp3`,
                                correta: true
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/objetos/bear.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/objetos/bear.mp3`,
                                correta: true
                            }
                        ]
                    }
                ]
            }

        case 'alfabetizados_unit1_aula8_atividade2':
            return {
                midia: 'imagem',                
                rodadas: [
                    {
                        enunciado: 'OUÇA E ESCOLHA O TOY CORRETO!',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-1/aula-8/video-2/enunciado.mp3`,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/objetos/bear.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/objetos/bear.mp3`
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/objetos/blocks.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/objetos/blocks.mp3`,
                                correta: true
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/objetos/doll.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/objetos/doll.mp3`
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/objetos/robot.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/objetos/robot.mp3`
                            }
                        ]
                    }
                ]
            }

        case 'alfabetizados_unit1_aula9_atividade1':
            return {
                midia: 'imagem',
                rodadas: [
                    {
                        enunciado: 'QUAL É O FAVORITE TOY DA AKEMI?',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-1/aula-9/video-1/enunciado.mp3`,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/objetos/bear.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/objetos/bear.mp3`,
                                correta: true
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/objetos/board-game.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/objetos/board-game.mp3`
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/objetos/action-figure.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/objetos/action-figure.mp3`
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/objetos/book.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/objetos/book.mp3`
                            }
                        ]
                    }
                ]
            }

        case 'alfabetizados_unit1_aula9_atividade2':
            return {
                midia: 'imagem',
                rodadas: [
                    {
                        enunciado: 'Escolha seu favorite toy entre estes e escreva abaixo.',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-1/aula-9/video-2/enunciado.mp3`,
                        qualquerOpcao: true,
                        escrever: true,
                        escreverRotulo: 'NOME DO TOY',
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/objetos/robot.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/objetos/robot.mp3`,
                                palavra: 'robot'
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/objetos/doll.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/objetos/doll.mp3`,
                                palavra: 'doll'
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/objetos/action-figure.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/objetos/action-figure.mp3`,
                                palavra: 'action figure'
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/objetos/bear.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/objetos/bear.mp3`,
                                palavra: 'teddy bear'
                            },
                        ]
                    },
                    {
                        enunciado: 'Escolha seu favorite toy entre estes e escreva abaixo.',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-1/aula-9/video-2/enunciado.mp3`,
                        qualquerOpcao: true,
                        escrever: true,
                        escreverRotulo: 'NOME DO TOY',
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/objetos/board-game.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/objetos/board-game.mp3`,
                                palavra: 'board game'
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/objetos/truck.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/objetos/truck.mp3`,
                                palavra: 'truck'
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/objetos/blocks.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/objetos/blocks.mp3`,
                                palavra: 'blocks'
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/objetos/car.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/objetos/car.mp3`,
                                palavra: 'car'
                            },
                        ]
                    },
                    {
                        enunciado: 'Escolha seu favorite toy entre estes e escreva abaixo.',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-1/aula-9/video-2/enunciado.mp3`,
                        qualquerOpcao: true,
                        escrever: true,
                        escreverRotulo: 'NOME DO TOY',
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/objetos/doll.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/objetos/doll.mp3`,
                                palavra: 'doll'
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/objetos/ball.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/objetos/ball.mp3`,
                                palavra: 'ball'
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/objetos/puppet.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/objetos/puppet.mp3`,
                                palavra: 'puppet'
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/objetos/pinwheel.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/objetos/pinwheel.mp3`,
                                palavra: 'pinwheel'
                            },
                        ]
                    }
                ]
            }

        case 'alfabetizados_unit1_aula10_atividade1':
            return {
                midia: 'imagem',
                rodadas: [
                    {
                        enunciado: 'QUE TIPO DE TOY ERA O TUFFY, O TOY QUE A AKEMI PERDEU?',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-1/aula-10/video-1/enunciado-1.mp3`,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/objetos/action-figure.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/objetos/action-figure.mp3`
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/objetos/board-game.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/objetos/board-game.mp3`
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/objetos/bear.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/objetos/bear.mp3`,
                                correta: true
                            }
                        ]
                    },
                    {
                        enunciado: 'QUAL FOI O TOY QUE ACHOU O TUFFY?',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-1/aula-10/video-1/enunciado-2.mp3`,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/objetos/ball.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/objetos/ball.mp3`
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/objetos/doll.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/objetos/doll.mp3`,
                                correta: true
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/objetos/pinwheel.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/objetos/pinwheel.mp3`
                            }
                        ]
                    }
                ]
            }

        case 'nao-alfabetizados_unit2_aula2_atividade1':
            return {
                midia: 'imagem',
                rodadas: [
                    {
                        enunciado: 'ONDE O TRAIN ANDA?',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-2/video-1/enunciado.ogg`,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/transporte/rail.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/transporte/rail.ogg`,
                                correta: true
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/transporte/street.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/transporte/street.ogg`
                            }
                        ]
                    }
                ]
            }

        case 'nao-alfabetizados_unit2_aula6_atividade1':
            return {
                midia: 'imagem',
                rodadas: [
                    {
                        enunciado: 'ONDE ESTE TRANSPORTATION ANDA?',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-6/video-1/enunciado.ogg`,
                        imagemEnunciado: `${serverHost()}/storage/ij-atividades/comum/transporte/train.png`,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/transporte/air.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/transporte/air.ogg`
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/transporte/rail.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/transporte/rail.ogg`,
                                correta: true
                            }
                        ]
                    },
                    {
                        enunciado: 'ONDE ESTE TRANSPORTATION ANDA?',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-6/video-1/enunciado.ogg`,
                        imagemEnunciado: `${serverHost()}/storage/ij-atividades/comum/transporte/plane.png`,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/transporte/rail.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/transporte/rail.ogg`
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/transporte/air.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/transporte/air.ogg`,
                                correta: true
                            }
                        ]
                    },
                    {
                        enunciado: 'ONDE ESTE TRANSPORTATION ANDA?',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-6/video-1/enunciado.ogg`,
                        imagemEnunciado: `${serverHost()}/storage/ij-atividades/comum/transporte/ship.png`,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/transporte/street.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/transporte/street.ogg`
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/transporte/ocean.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/transporte/ocean.ogg`,
                                correta: true
                            }
                        ]
                    },
                    {
                        enunciado: 'ONDE ESTE TRANSPORTATION ANDA?',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-6/video-1/enunciado.ogg`,
                        imagemEnunciado: `${serverHost()}/storage/ij-atividades/comum/transporte/bus.png`,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/transporte/ocean.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/transporte/ocean.ogg`
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/transporte/street.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/transporte/street.ogg`,
                                correta: true
                            }
                        ]
                    }
                ]
            }

        case 'nao-alfabetizados-unit2-aula7-atividade1':
            return {
                midia: 'imagem',
                rodadas: [
                    {
                        enunciado: 'CLIQUE NO CAR',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-7/video-1/enunciado.ogg`,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/transporte/ship.png`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/transporte/ship.ogg`,
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/transporte/car.png`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/transporte/car.ogg`,
                                correta: true
                            }
                        ]
                    },
                    {
                        enunciado: 'CLIQUE NO CAR',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-7/video-1/enunciado.ogg`,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/transporte/bus.png`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/transporte/bus.ogg`,
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/transporte/car.png`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/transporte/car.ogg`,
                                correta: true
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/transporte/train.png`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/transporte/train.ogg`,
                            }
                        ]
                    },
                    {
                        enunciado: 'CLIQUE NO CAR',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-7/video-1/enunciado.ogg`,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/transporte/bike.png`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/transporte/bike.ogg`,
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/transporte/bus.png`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/transporte/bus.ogg`,
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/transporte/car.png`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/transporte/car.ogg`,
                                correta: true
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/transporte/train.png`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/transporte/train.ogg`,
                            }
                        ]
                    },
                    {
                        enunciado: 'CLIQUE NO CAR',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-7/video-1/enunciado.ogg`,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/transporte/car.png`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/transporte/car.ogg`,
                                correta: true
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/transporte/bus.png`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/transporte/bus.ogg`,
                            },                            
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/transporte/plane.png`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/transporte/plane.ogg`,
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/transporte/ship.png`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/transporte/ship.ogg`,
                            }
                        ]
                    }
                ],
            }

        case 'nao-alfabetizados_unit2_aula8_atividade1':
            return {
                midia: 'imagem',
                rodadas: [
                    {
                        enunciado: 'ONDE OS PERSONAGENS FORAM ANDAR DE BIKE?',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-8/video-1/enunciado.ogg`,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/transporte/rail.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/transporte/rail.ogg`,
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/transporte/ocean.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/transporte/ocean.ogg`,
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/transporte/park.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/transporte/park.ogg`,
                                correta: true
                            }
                        ]
                    }
                ]
            }

        case 'nao-alfabetizados-unit2-aula9-atividade1':
            return {
                midia: 'imagem',
                rodadas: [
                    {
                        enunciado: 'QUAL É SEU MEIO DE TRANSPORTE FAVORITO?',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-9/video-1/enunciado.ogg`,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/transporte/car.png`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/transporte/car.ogg`,
                                correta: true,
                                textoConfirmacao: 'CAR'
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/transporte/plane.png`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/transporte/plane.ogg`,
                                correta: true,
                                textoConfirmacao: 'PLANE'
                            },                            
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/transporte/ship.png`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/transporte/ship.ogg`,
                                correta: true,
                                textoConfirmacao: 'SHIP'
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/transporte/train.png`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/transporte/train.ogg`,
                                correta: true,
                                textoConfirmacao: 'TRAIN'
                            }
                        ]
                    }
                ]
            }
        
        case 'alfabetizados_unit2_aula1_atividade1':
            return {
                midia: 'imagem',
                rodadas: [
                    {
                        enunciado: 'O QUE LILY E TOM QUEREM FAZER COM AS FRUITS?',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-2/aula-1/video-1/enunciado.mp3`,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/vitamin.png`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/frutas/vitamin.mp3`,
                                correta: true,
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/salad.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/frutas/salad.mp3`
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/cake.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/frutas/cake.mp3`
                            }
                        ]
                    }
                ]
            }

        case 'alfabetizados_unit2_aula2_atividade1':
            return {
                midia: 'imagem',
                rodadas: [
                    {
                        enunciado: 'QUAL FOI A PRIMEIRA FRUTA QUE ELES COLOCARAM NA VITAMIN?',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-2/aula-2/video-1/enunciado.mp3`,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/apple.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/frutas/apple.mp3`
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/banana.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/frutas/banana.mp3`
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/orange.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/frutas/orange.mp3`,
                                correta: true
                            }
                        ]
                    }
                ]
            }

        case 'alfabetizados_unit2_aula2_atividade2':
            return {
                midia: 'imagem',
                rodadas: [
                    {
                        enunciado: 'CLIQUE NA ORANGE:',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-2/aula-2/video-2/enunciado.mp3`,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/orange.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/frutas/orange.mp3`,
                                correta: true
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/strawberry.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/frutas/strawberry.mp3`                                
                            }
                        ]
                    },
                    {
                        enunciado: 'CLIQUE NA ORANGE:',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-2/aula-2/video-2/enunciado.mp3`,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/banana.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/frutas/banana.mp3`
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/apple.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/frutas/apple.mp3`
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/orange.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/frutas/orange.mp3`,
                                correta: true
                            }
                        ]
                    },
                    {
                        enunciado: 'CLIQUE NA ORANGE:',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-2/aula-2/video-2/enunciado.mp3`,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/orange.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/frutas/orange.mp3`,
                                correta: true
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/strawberry.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/frutas/strawberry.mp3`
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/peach.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/frutas/peach.mp3`
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/grapes.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/frutas/grapes.mp3`
                            }
                        ]
                    }
                ]
            }

        case 'alfabetizados_unit2_aula3_atividade1':
            return {
                midia: 'imagem',                
                rodadas: [
                    {
                        enunciado: 'QUAIS SÃO AS DUAS FRUTAS QUE O TOM LIKE, MESMO?',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-2/aula-3/video-1/enunciado.mp3`,
                        multipla: true,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/apple.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/frutas/apple.mp3`
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/banana.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/frutas/banana.mp3`
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/strawberry.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/frutas/strawberry.mp3`,
                                correta: true
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/orange.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/frutas/orange.mp3`,
                                correta: true
                            }
                        ]
                    }
                ]
            }

        case 'alfabetizados_unit2_aula4_atividade1':
            return {
                midia: 'texto',
                audioInicio: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-2/aula-4/video-1/enunciado.mp3`,                
                rodadas: [
                    {
                        enunciado: 'VOCÊ LEMBRA QUAL É A OPINIÃO DE TOM SOBRE ESTAS FRUITS?',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/comum/frutas/apple.mp3`,
                        imagemEnunciado: `${serverHost()}/storage/ij-atividades/comum/frutas/apple.jpg`,
                        atrasoAudio: 4,
                        opcoes: [
                            {
                                texto: 'Gosta'
                            },
                            {
                                texto: 'Não gosta',
                                correta: true
                            }
                        ]
                    },
                    {
                        enunciado: 'VOCÊ LEMBRA QUAL É A OPINIÃO DE TOM SOBRE ESTAS FRUITS?',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/comum/frutas/strawberry.mp3`,
                        imagemEnunciado: `${serverHost()}/storage/ij-atividades/comum/frutas/strawberry.jpg`,
                        opcoes: [
                            {
                                texto: 'Gosta',
                                correta: true
                            },
                            {
                                texto: 'Não gosta'
                            }
                        ]
                    },
                    {
                        enunciado: 'VOCÊ LEMBRA QUAL É A OPINIÃO DE TOM SOBRE ESTAS FRUITS?',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/comum/frutas/orange.mp3`,
                        imagemEnunciado: `${serverHost()}/storage/ij-atividades/comum/frutas/orange.jpg`,
                        opcoes: [
                            {
                                texto: 'Gosta',
                                correta: true
                            },
                            {
                                texto: 'Não gosta'
                            }
                        ]
                    }
                ]
            }

        case 'alfabetizados_unit2_aula5_atividade1':
            return {
                midia: 'imagem',
                rodadas: [
                    {
                        enunciado: `QUAL FOI A ÚLTIMA FRUIT QUE A LILY QUIS COLOCAR NA VITAMIN E O TOM DISSE "I DON'T LIKE"?`,
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-2/aula-5/video-1/enunciado.mp3`,
                        escrever: true,
                        escreverRotulo: 'NOME DO FRUIT (6 LETRAS)',
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/apple.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/frutas/apple.mp3`,
                                palavra: 'apple'
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/banana.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/frutas/banana.mp3`,
                                palavra: 'banana'
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/grapes.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/frutas/grapes.mp3`,
                                palavra: 'grapes',
                                correta: true
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/orange.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/frutas/orange.mp3`,
                                palavra: 'orange'
                            }
                        ]
                    }
                ]
            }

        case 'alfabetizados_unit2_aula6_atividade1':
            return {
                midia: 'imagem',                
                rodadas: [
                    {
                        multipla: true,
                        enunciado: 'QUAIS FRUITS FORAM COLOCADAS NA VITAMIN?',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-2/aula-6/video-1/enunciado.mp3`,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/apple.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/frutas/apple.mp3`,
                                correta: true
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/banana.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/frutas/banana.mp3`,                                
                                correta: true
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/grapes.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/frutas/grapes.mp3`
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/orange.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/frutas/orange.mp3`,
                                correta: true
                            }
                        ]
                    }
                ]
            }

        case 'alfabetizados_unit2_aula7_atividade1':
            return {
                midia: 'imagem',
                rodadas: [
                    {
                        enunciado: 'DE QUAL FRUIT O TOM GOSTA MENOS? OU SEJA, QUAL ELE HATE?',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-2/aula-7/video-1/enunciado.mp3`,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/apple.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/frutas/apple.mp3`
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/orange.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/frutas/orange.mp3`
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/grapes.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/frutas/grapes.mp3`
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/peach.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/frutas/peach.mp3`,
                                correta: true
                            }
                        ]
                    }
                ]
            }

        case 'alfabetizados_unit2_aula8_atividade1':
            return {
                midia: 'imagem',
                rodadas: [
                    {
                        enunciado: "QUAL É A FRUIT QUE LILY ESTAVA SEGURANDO NA ÚLTIMA CENA?",
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-2/aula-8/video-1/enunciado.mp3`,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/orange.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/frutas/orange.mp3`
                            },                            
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/grapes.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/frutas/grapes.mp3`
                            },                            
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/peach.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/frutas/peach.mp3`                                
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/avocado.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/frutas/avocado.mp3`,
                                correta: true
                            }
                        ]
                    }
                ]
            }

        case 'alfabetizados_unit2_aula9_atividade1':
            return {
                midia: 'imagem',
                rodadas: [
                    {
                        enunciado: 'QUAL FRUIT NÃO ESTAVA RIPE?',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-2/aula-9/video-1/enunciado.mp3`,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/avocado.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/frutas/avocado.mp3`
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/banana.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/frutas/banana.mp3`,
                                correta: true
                            },                            
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/peach.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/frutas/peach.mp3`
                            },                            
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/strawberry.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/frutas/strawberry.mp3`                                
                            }                            
                        ]
                    }
                ]
            }

        case 'alfabetizados_unit2_aula10_atividade1':
            return {
                midia: 'imagem',
                rodadas: [
                    {
                        enunciado: 'QUAIS AS FRUITS USADAS PARA A VITAMIN?',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-2/aula-10/video-1/enunciado.mp3`,
                        multipla: true,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/apple.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/frutas/apple.mp3`
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/banana.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/frutas/banana.mp3`,                                
                            },                            
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/orange.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/frutas/orange.mp3`,
                                correta: true
                            },                            
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/strawberry.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/frutas/strawberry.mp3`,
                                correta: true                                
                            }                            
                        ]
                    }
                ]
            }

        case 'nao-alfabetizados_unit2_aula1_atividade1':
            return {
                midia: 'imagem',
                rodadas: [
                    {
                        enunciado: 'QUE TIPO DE ANIMAL É O TOMMY?',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-1/video-1/enunciado.ogg`,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/dog.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/dog.mp3`
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/rabbit.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/rabbit.mp3`,
                                correta: true
                            }
                        ]
                    }
                ]
            }

        case 'nao-alfabetizadfos_unit2_aula1_atividade3':
            return {
                midia: 'video',
                audioInicio: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-1/video-3/enunciado.ogg`,  
                rodadas: [
                    {
                        enunciado: 'QUAL É O GESTO DESTA PARTE DA MÚSICA?',
                        atrasoAudio: 4,
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-1/video-3/train.mp3`,
                        opcoes: [
                            {
                                video: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-1/video-3/train.mp4`, 
                                correta: true
                            },
                            {
                                video: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-1/video-3/bus.mp4`
                            }
                        ]
                    },
                    {
                        enunciado: 'QUAL É O GESTO DESTA PARTE DA MÚSICA?',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-1/video-3/plane.mp3`,
                        opcoes: [
                            {
                                video: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-1/video-3/ship.mp4`                                
                            },
                            {
                                video: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-1/video-3/plane.mp4`,
                                correta: true
                            }
                        ]
                    }
                ]
            }

        case 'nao-alfabetizadfos_unit2_aula3_atividade1':
            return {
                midia: 'imagem',
                rodadas: [
                    {
                        enunciado: 'QUAL DESTES É UM BUS?',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-3/video-1/enunciado.ogg`,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/transporte/bus.png`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/transporte/bus.ogg`,                                
                                correta: true
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/transporte/train.png`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/transporte/train.ogg`,
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/transporte/plane.png`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/transporte/plane.ogg`
                            }
                        ]
                    }
                ]
            }

        case 'nao-alfabetizadfos_unit2_aula4_atividade2':
            return {
                midia: 'imagem',
                rodadas: [
                    {
                        enunciado: 'VOCÊ CONSEGUE ADIVINHAR QUAL É O MEIO DE TRANSPORTE PELA SOMBRA? QUAL DESTES É UM TRAIN?',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-4/video-2/enunciado_train.ogg`,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-4/video-2/train.jpg`,
                                correta: true
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-4/video-2/ship.jpg`,
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-4/video-2/bus.jpg`,
                            }
                        ]
                    },
                    {
                        enunciado: 'VOCÊ CONSEGUE ADIVINHAR QUAL É O MEIO DE TRANSPORTE PELA SOMBRA? QUAL DESTES É UM BUS?',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-4/video-2/enunciado_bus.ogg`,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-4/video-2/train.jpg`,
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-4/video-2/ship.jpg`,
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-4/video-2/bus.jpg`,
                                correta: true
                            }
                        ]
                    },
                    {
                        enunciado: 'VOCÊ CONSEGUE ADIVINHAR QUAL É O MEIO DE TRANSPORTE PELA SOMBRA? QUAL DESTES É UM BOAT?',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-4/video-2/enunciado_boat.ogg`,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-4/video-2/train.jpg`,
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-4/video-2/ship.jpg`,
                                correta: true
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-4/video-2/bus.jpg`,
                            }
                        ]
                    }
                ]
            }

        case 'nao-alfabetizadfos_unit2_aula5_atividade1':
            return {
                midia: 'imagem',
                rodadas: [
                    {
                        enunciado: 'ONDE O PLANE ANDA?',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-5/video-1/enunciado.ogg`,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/transporte/air.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/transporte/air.ogg`,
                                correta: true
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/transporte/street.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/transporte/street.ogg`
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/transporte/rail.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/transporte/rail.ogg`
                            }
                        ]
                    }
                ]
            }

        case 'nao-alfabetizadfos_unit2_aula6_atividade2':
            return {
                midia: 'imagem',
                rodadas: [
                    {
                        enunciado: 'ESCOLHA O TRANSPORTATION CERTO PARA AJUDAR O TOMMY A CHEGAR EM CASA!',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-6/video-2/enunciado.ogg`,
                        imagemEnunciado: `${serverHost()}/storage/ij-atividades/comum/transporte/ocean.jpg`,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/transporte/bus.png`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/transporte/bus.ogg`,  
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/transporte/ship.png`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/transporte/ship.ogg`, 
                                correta: true
                            }
                        ]
                    },
                    {
                        enunciado: 'ESCOLHA O TRANSPORTATION CERTO PARA AJUDAR O TOMMY A CHEGAR EM CASA!',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-6/video-2/enunciado.ogg`,
                        imagemEnunciado: `${serverHost()}/storage/ij-atividades/comum/transporte/rail.jpg`,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/transporte/train.png`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/transporte/train.ogg`, 
                                correta: true 
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/transporte/plane.png`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/transporte/plane.ogg`
                            }
                        ]
                    },
                    {
                        enunciado: 'ESCOLHA O TRANSPORTATION CERTO PARA AJUDAR O TOMMY A CHEGAR EM CASA!',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-6/video-2/enunciado.ogg`,
                        imagemEnunciado: `${serverHost()}/storage/ij-atividades/comum/transporte/street.jpg`,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/transporte/bus.png`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/transporte/bus.ogg`,  
                                correta: true
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/transporte/plane.png`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/transporte/plane.ogg`
                            }
                        ]
                    }
                ]
            }

        case 'nao-alfabetizados_unit2_aula10_atividade1':
            return {
                midia: 'imagem',
                rodadas: [
                    {
                        enunciado: 'QUAL MEIO DE TRANSPORTATION PODE ANDAR NESTE LUGAR?',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-10/video-1/enunciado.ogg`,
                        imagemEnunciado: `${serverHost()}/storage/ij-atividades/comum/transporte/ocean.jpg`,
                        opcoes: [                            
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/transporte/ship.png`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/transporte/ship.ogg`, 
                                correta: true
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/transporte/bus.png`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/transporte/bus.ogg`,  
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/transporte/train.png`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/transporte/train.ogg`,  
                            }
                        ]
                    },
                    {
                        enunciado: 'QUAL MEIO DE TRANSPORTATION PODE ANDAR NESTE LUGAR?',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-10/video-1/enunciado.ogg`,
                        imagemEnunciado: `${serverHost()}/storage/ij-atividades/comum/transporte/street.jpg`,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/transporte/ship.png`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/transporte/ship.ogg`
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/transporte/bus.png`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/transporte/bus.ogg`, 
                                correta: true 
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/transporte/plane.png`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/transporte/plane.ogg`
                            }
                        ]
                    },
                    {
                        enunciado: 'QUAL MEIO DE TRANSPORTATION PODE ANDAR NESTE LUGAR?',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-10/video-1/enunciado.ogg`,
                        imagemEnunciado: `${serverHost()}/storage/ij-atividades/comum/transporte/air.jpg`,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/transporte/bus.png`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/transporte/bus.ogg`                                
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/transporte/plane.png`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/transporte/plane.ogg`,
                                correta: true
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/transporte/train.png`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/transporte/train.ogg`
                            }
                        ]
                    },
                    {
                        enunciado: 'QUAL MEIO DE TRANSPORTATION PODE ANDAR NESTE LUGAR?',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-10/video-1/enunciado.ogg`,
                        imagemEnunciado: `${serverHost()}/storage/ij-atividades/comum/transporte/rail.jpg`,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/transporte/ship.png`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/transporte/ship.ogg`
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/transporte/train.png`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/transporte/train.ogg`,
                                correta: true
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/transporte/plane.png`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/transporte/plane.ogg`
                            }
                        ]
                    }
                ]
            }

        case 'nao-alfabetizados_unit2_aula10_atividade2':
            return {
                midia: 'imagem',
                audioInicio: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-10/video-2/enunciado.ogg`,
                rodadas: [
                    {
                        enunciado: `OUÇA A PALAVRA E ESCOLHA O MEIO DE TRANSPORTATION CERTO!`,
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/comum/transporte/train.ogg`,
                        atrasoAudio: 5,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/transporte/bike.png`
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/transporte/train.png`,
                                correta: true
                            }
                        ]
                    },
                    {
                        enunciado: `OUÇA A PALAVRA E ESCOLHA O MEIO DE TRANSPORTATION CERTO!`,
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/comum/transporte/bus.ogg`,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/transporte/bus.png`,
                                correta: true
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/transporte/ship.png`
                            }
                        ]
                    },
                    {
                        enunciado: `OUÇA A PALAVRA E ESCOLHA O MEIO DE TRANSPORTATION CERTO!`,
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/comum/transporte/car.ogg`,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/transporte/plane.png`
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/transporte/car.png`,
                                correta: true
                            }
                        ]
                    },
                    {
                        enunciado: `OUÇA A PALAVRA E ESCOLHA O MEIO DE TRANSPORTATION CERTO!`,
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/comum/transporte/bike.ogg`,
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/transporte/bike.png`,
                                correta: true
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/transporte/ship.png`
                            }
                        ]
                    }
                ]
            }

        case 'alfabetizados_unit2_aula1_atividade2':
            return {
                midia: 'imagem',
                textoRespostaOk: 'MUITO BOM!',
                rodadas: [
                    {
                        enunciado: 'ESCOLHA SUAS FAVORITE FRUITS PARA COLOCAR EM UMA VITAMIN:',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-2/aula-1/video-2/enunciado.mp3`,
                        imagemEnunciado: `${serverHost()}/storage/ij-atividades/comum/frutas/vitamin.png`,
                        multipla: true,
                        livre: true,                        
                        opcoes: [
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/apple.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/frutas/apple.mp3`,
                                correta: true
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/banana.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/frutas/banana.mp3`,
                                correta: true
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/orange.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/frutas/orange.mp3`,
                                correta: true
                            },
                            {
                                imagem: `${serverHost()}/storage/ij-atividades/comum/frutas/peach.jpg`,
                                audio: `${serverHost()}/storage/ij-atividades/comum/frutas/peach.mp3`,
                                correta: true
                            }
                        ]
                    }
                ]
            }

        case 'alfabetizados_unit2_aula1_atividade3':
            return {
                midia: 'texto',
                rodadas: [
                    {
                        enunciado: 'ESTE GESTO COMBINA COM QUAL TRECHO DA MÚSICA?',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-2/aula-1/video-3/enunciado.mp3`,
                        videoEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-2/aula-1/video-3/video1.mp4`,
                        opcoes: [
                            {
                                texto: 'JUMP ON ONE FOOT IF YOU LIKE PEARS',
                                audio: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-2/aula-1/video-3/jump_on_one_foot.mp3`,
                                duracaoAudio: 5,
                                correta: true
                            },
                            {
                                texto: 'IF YOU DON’T LIKE PEACHES, RAISE YOUR HANDS',
                                audio: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-2/aula-1/video-3/if_you_dont_like_peaches.mp3`
                            }
                        ]
                    },
                    {
                        enunciado: 'ESTE GESTO COMBINA COM QUAL TRECHO DA MÚSICA?',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-2/aula-1/video-3/enunciado.mp3`,
                        videoEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-2/aula-1/video-3/video2.mp4`,
                        opcoes: [
                            {
                                texto: 'EVERY SINGLE FRUIT',
                                audio: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-2/aula-1/video-3/every_single_fruit.mp3`,                                
                            },
                            {
                                texto: 'TOUCH YOUR ELBOW, IF YOU LIKE BANANAS',
                                audio: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-2/aula-1/video-3/touch_your_elbow.mp3`,
                                duracaoAudio: 5,
                                correta: true
                            }
                        ]
                    },
                    {
                        enunciado: 'ESTE GESTO COMBINA COM QUAL TRECHO DA MÚSICA?',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-2/aula-1/video-3/enunciado.mp3`,
                        videoEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-2/aula-1/video-3/video3.mp4`,
                        opcoes: [
                            {
                                texto: 'EVERY SINGLE FRUIT',
                                audio: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-2/aula-1/video-3/every_single_fruit.mp3`,
                                duracaoAudio: 5,
                                correta: true
                            },
                            {
                                texto: 'GROWS FROM THE ROOT',
                                audio: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-2/aula-1/video-3/grows_from_the_root.mp3`
                            }
                        ]
                    },
                ]
            }

        default: return {}
    }
}

export default configMultiplaEscolha;