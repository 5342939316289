import React, { useEffect, useState, Fragment } from 'react';
import { click, win, wrong } from '../../libs/soundEffects';
import { Card } from '../styles/stylesNomear';

let audio = undefined;

const Nomear = ({ config }) => {
    const qtdRodadas = config.rodadas ? config.rodadas.length : 0;

    const [rodadaAtual, setRodadaAtual] = useState(undefined);
    const [numRodadaAtual, setNumRodadaAtual] = useState(0);
    const [respostaOk, setRespostaOk] = useState(undefined);
    const [respostas, setRespostas] = useState([]);
    const [validados, setValidados] = useState([]);

    useEffect(() => {
        if (config.audioInicio) {
            const audio = new Audio(config.audioInicio);

            audio.play();
        }

        iniciarRodada(1);
    }, []);

    const iniciarRodada = (numero) => {
        const rodada = config.rodadas[numero - 1];

        if (!rodada) return;

        if (rodada.audioEnunciado) {
            audio = new Audio(rodada.audioEnunciado);

            if (rodada.atrasoAudio) {
                setTimeout(() => audio.play(), rodada.atrasoAudio * 1000);
            } else {
                audio.play();
            }
        }

        const respostas = [];

        rodada.itens.forEach((item, key) => {
            if (item.texto && item.texto !== '') respostas[key] = item.texto;
        });

        setRespostas(respostas);
        setNumRodadaAtual(numero);
        setRodadaAtual(rodada);
        setRespostaOk(false);
        setValidados([]);
    };

    const avancar = () => {
        click();

        if (numRodadaAtual === qtdRodadas) {
            return config.avancar ? config.avancar() : alert('Fim!');
        } else {
            iniciarRodada(numRodadaAtual + 1);
        }
    };

    const preencher = (texto, key) => {
        const respostasAtualizar = [...respostas];

        respostasAtualizar[key] = texto;

        setRespostas(respostasAtualizar);

        if (rodadaAtual.itens[key].nome === texto.toLowerCase()) {
            const validadosAtualizar = [...validados];

            validadosAtualizar.push(key);

            setValidados(validadosAtualizar);

            win();

            if (validadosAtualizar.length === rodadaAtual.itens.length) {
                setRespostaOk(true);
            }
        }
    };

    let classeColunas = '';
    let alturaImagem = 0;

    if (!rodadaAtual) return null;

    if (rodadaAtual.imagemEnunciado) {
        switch (rodadaAtual.opcoes.length) {
            case 3:
                classeColunas = 'tl2-box-2-3colunas';
                alturaImagem = 270;

                break;
            default:
                classeColunas = 'tl2-box-2-2colunas';
                alturaImagem = 180;

                break;
        }
    } else {
        switch (rodadaAtual.itens.length) {
            case 2:
                classeColunas = 'tl2-box-2-2colunas';
                alturaImagem = 360;
                break;

            case 3:
                classeColunas = 'tl2-box-2-3colunas';
                alturaImagem = 270;
                break;

            case 4:
                classeColunas = 'tl2-box-2-2colunas';
                alturaImagem = 180;
                break;

            default:
                classeColunas = 'tl2-box-2-2colunas';
                alturaImagem = 360;
                break;
        }
    }

    return(
        <div style={styles.container}>
            <div style={styles.cabecalho}>
                <p>{rodadaAtual.enunciado}</p>
                <button style={styles.btnRepetir} onClick={() => iniciarRodada(1)}>
                    <i class="fas fa-undo-alt" style={styles.btnRepetirIcon}></i> REPETIR
                </button>
            </div>  
            {rodadaAtual.imagemEnunciado &&
                <div style={styles.conteudo}>
                    <div style={{textAlign: 'center', }}><img style={{height: 120}} src={rodadaAtual.imagemEnunciado}/></div>
                </div>
            }
            {respostaOk ?
                <div style={styles.conteudoFinal}>
                    <p><b>{`CERTA RESPOSTA!`}</b></p>
                    <button style={styles.btnContinuar} onClick={avancar}>
                        CONTINUAR <i class="fas fa-chevron-right" style={styles.btnContinuarIcon}></i>
                    </button>
                </div>
            :
                <div style={styles.conteudo}>
                    {rodadaAtual.itens.map((item, key) => {
                        if(rodadaAtual.classe){
                            return(
                                <div style={styles.conteudoItem}>
                                    <p>{item.palavra}</p>
                                    <div>
                                        <input
                                            placeholder={item.rotulo ? item.rotulo : ''}
                                            type="text"
                                        />
                                    </div>
                                </div>
                            )
                        } else{
                            return(
                                <div
                                    key={key}
                                    style={validados.includes(key) ? styles.conteudoItemCorreto : styles.conteudoItem}
                                >
                                    <img
                                        style={{ height: 120 }}
                                        src={item.imagem}
                                    />
                                    <div>
                                        {validados.includes(key) ?
                                            <p><b>{item.nome.toUpperCase()}</b></p>
                                        :
                                            <input
                                                style={{ borderStyle: 'none', textTransform: 'capitalize' }}
                                                placeholder={item.rotulo ? item.rotulo : ''}
                                                disabled={validados.includes(key)}
                                                type="text"
                                                value={respostas[key]}
                                                onChange={(e) => preencher(e.target.value, key)}
                                            />
                                        }
                                    </div>
                                </div>
                            )
                        }
                    })}
                </div>
            }
        </div>
    )

    return (
        <div className="content">
            <div className="container">
                {rodadaAtual ? (
                    <Fragment>
                        <div className="box-topo">
                            <div className="item-box-topo-1">
                                <p className="p-text">{rodadaAtual.enunciado}</p>
                            </div>
                            <div className="item-box-topo-2">
                                <a className="btn-repetir" onClick={() => iniciarRodada(1)}>
                                    <i className="fas fa-undo-alt"></i> REPETIR
                                </a>
                            </div>
                        </div>
                        {rodadaAtual.imagemEnunciado && (
                            <div style={{ textAlign: 'center' }}>
                                <img
                                    style={{ height: 270 }}
                                    src={rodadaAtual.imagemEnunciado}
                                />
                            </div>
                        )}
                        {rodadaAtual.videoEnunciado && (
                            <div style={{ textAlign: 'center' }}>
                                <video
                                    autoPlay={true}
                                    loop="true"
                                    src={rodadaAtual.videoEnunciado}
                                    style={{ height: 270 }}
                                />
                            </div>
                        )}

                        {respostaOk ? (
                            <div className="tl1-box-1">
                                <div className="tl7-box-confirmacao">
                                    <p className="p-text">PARABÉNS</p>
                                    <p className="p-text">VOCÊ ACERTOU!</p>
                                    <a className="btn-concluir" onClick={avancar}>
                                        CONTINUAR <i className="fas fa-chevron-right"></i>{' '}
                                    </a>
                                </div>
                            </div>
                        ) : (
                            <div className={`tl1-box-2 ${classeColunas}`}>
                                {rodadaAtual.classe
                                    ? rodadaAtual.itens.map((item) => (
                                        <Card>
                                            <p>{item.palavra}</p>
                                            <div className="caixa-texto-confirmcao-input">
                                                <input
                                                    className="p-text-input-2"
                                                    placeholder={item.rotulo ? item.rotulo : ''}
                                                    type="text"
                                                />
                                            </div>
                                        </Card>
                                    ))
                                    : rodadaAtual.itens.map((item, key) => (
                                        <div
                                            key={key}
                                            className={
                                                validados.includes(key)
                                                    ? 'tl1-figura-correta'
                                                    : 'tl1-figura-0'
                                            }
                                        >
                                            <img
                                                style={{ height: alturaImagem }}
                                                src={item.imagem}
                                            />
                                            <div className="caixa-texto-confirmcao-input">
                                                <input
                                                    style={{ borderStyle: 'none' }}
                                                    className="p-text-input-2"
                                                    placeholder={item.rotulo ? item.rotulo : ''}
                                                    disabled={validados.includes(key)}
                                                    type="text"
                                                    value={respostas[key]}
                                                    onChange={(e) => preencher(e.target.value, key)}
                                                />
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        )}
                        {rodadaAtual.classe && (
                            <div className="caixa-texto-btn-confirmar">
                                <a className="btn-concluir" onClick={avancar}>
                                    CONFIRMAR <i className="fas fa-chevron-right"></i>
                                </a>
                            </div>
                        )}
                    </Fragment>
                ) : (
                    <h1>Carregando</h1>
                )}
            </div>
        </div>
    );
};

export default Nomear;

const styles = {
    container: {
        backgroundColor: 'white', 
        margin: 5, 
        borderRadius: 15, 
        padding: 5
    },
    cabecalho: {
        backgroundColor: 'RGB(0,177,181)', 
        margin: 5, 
        borderRadius: 15, 
        padding: 5,
        color: 'white',
        fontWeight: 'bolder',
        display: 'flex',
        justifyContent: 'space-between'
    },
    conteudo: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItens: 'center',
        flexWrap: 'wrap',
        borderRadius: 15,
        backgroundColor: 'RGB(193,239,226)',
        margin: 5,
        marginTop: 10
    },
    conteudoFinal: {
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        textAlign: 'center',
        alignItens: 'center',
        flexWrap: 'wrap',
        borderRadius: 15,
        backgroundColor: 'RGB(193,239,226)',
        margin: 5,
        marginTop: 10,
        padding: 10
    },
    conteudoItem: {
        marginTop: 5,
        marginLeft: 5,
        marginRitht: 5,
        marginBottom: 5,
        borderRadius: 15,
        backgroundColor: 'RGB(255,206,0)',
        padding: 10,
        textAlign: 'center'
    },
    conteudoItemCorreto: {
        margin: 5,
        borderRadius: 15,
        backgroundColor: 'RGB(107, 255, 107)',
        padding: 10,
        textAlign: 'center'
    },
    conteudoItemIncorreto: {
        margin: 5,
        borderRadius: 15,
        backgroundColor: 'RGB(255, 107, 107)',
        padding: 10,
        textAlign: 'center'
    },
    btnRepetir: {
        backgroundColor: 'RGB(71, 226, 230)',
        border: 'none',
        color: 'white',
        borderRadius: 15
    },
    btnRepetirIcon: {
        color: 'RGB(71, 226, 230)',
        marginBottom: 5
    },
    btnContinuar: {
        backgroundColor: 'RGB(0, 144, 245)',
        border: 'none',
        color: 'white',
        borderRadius: 15,
        maxHeight: 60,
        padding: 10
    },
    btnContinuarIcon: {
        color: 'RGB(0, 144, 245)',
        marginBottom: 5
    }
}