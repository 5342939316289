import styled from "styled-components";

export const Card = styled.div`
  margin: 0;
  height: 150px;
  border: solid;
  border-radius: 15px;
  border-width: 7px;

  display: flex;
  color: white;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  @media (max-width: 700px) {
    height: 150px;
  }

  span {
    font-family: "Bungee", sans-serif;
    font-size: 100px;
  }
`;

export const Container = styled.div`
  padding-bottom: 80px;
  background-position: left top, right bottom;
  background-repeat: no-repeat, no-repeat;
  background-attachment: fixed, fixed;

  ul {
    display: block;
    margin: 0 auto;
    max-width: 1430px;
    text-align: center;

    li {
      display: inline-table;
      margin: 75px 20px 0;
      width: 230px;
      /* cursor: pointer; */

      h3 {
        margin: 10px 0 0;
        font-family: "Bungee", sans-serif;
        font-size: 25px;
        line-height: 1em;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #fff;
      }
    }

    @media (max-width: 960px) {
      padding: 0;
    }
  }
`;
