import React, { useContext, useEffect, useState } from "react";
import userData from "../services/userData";
// import api from "../services/api";
import soundEffects from "../libs/soundEffects";

import { Card, Container } from "./styles";
import AppContext from "../container/context";
import ijws from "../services/ijws";

const Unidade = ({ match, configHeader, history }) => {
  const { notification } = useContext(AppContext);

  const [unidadeId] = useState(Number(match.params.id));
  const [userDataState] = useState(userData());
  const [aulas, setAulas] = useState([]);

  useEffect(() => {
    carregar();
  }, [unidadeId]);

  const carregar = () => {
    ijws
      .get("agenda/aulas", { perfilId: userDataState.perfilId, unidadeId })
      .then((res) => {
        let unidadeTitulo = res.unidadeTitulo ? `${res.unidadeTitulo}` : "";
        configHeader({ titulo: unidadeTitulo, contexto: "aulas" });
        setAulas(res.aulas);
      })
      .catch((e) => alert("Erro: " + e));
  };

  const abrirAula = (aula, aulaAtualId) => {
    soundEffects.click();

    if (
      !aula.dataHoraFinalizacao &&
      aula.id !== aulaAtualId &&
      !aula.usuarioAdministrador
    )
      return notification("warning", "Finalize as aulas anteriores!");

    history.push(`/app/conteudo/${aula.id}`);
  };

  let aulaAtualId = 0;
  let aulaAtualNumero = 0;
  let alt = "";
  let color = "";
  let badge = "";

  return (
    <Container>
      <ul>
        {aulas.map((aula, key) => {
          aulaAtualId =
            aulaAtualId === 0 && !aula.dataHoraFinalizacao
              ? aula.id
              : aulaAtualId;
          aulaAtualNumero =
            aulaAtualId === 0 && !aula.dataHoraFinalizacao
              ? aula.numero
              : aulaAtualNumero;

          const back = `${fundo(aula.numero)}`;

          if (aula.dataHoraFinalizacao) {
            color = "rgb(55, 132, 32)";
            alt = "Aula finalizada";
            badge = require("../assets/imagens/badge-done-01.png");
          } else {
            if (aula.id === aulaAtualId) {
              if (aula.assinaturaIdentificador) {
                color = "rgb(122, 92, 163)";
                alt = "Nova aula";
                badge = require("../assets/imagens/badge-new-01.png");
              } else {
                color = "rgb(108, 108, 108)";
                alt = "Aula exclusiva para assinantes";
                badge = require("../assets/imagens/badge-bloqueado-01.png");
              }
            } else {
              color = "rgb(108, 108, 108)";
              alt = `Aula número ${aula.numero}`;
              badge = require("../assets/imagens/badge-bloqueado-01.png");
            }
          }

          return (
            <li key={key} onClick={() => abrirAula(aula, aulaAtualId)}>
              <img
                style={{ width: "120px" }}
                className="badge"
                alt={alt}
                src={badge}
              />
              <Card
                style={{
                  background: back,
                  borderColor: color,
                }}
              >
                <span>{aula.numero}</span>
              </Card>
              <h3>Aula {aula.numero}</h3>
            </li>
          );
        })}
      </ul>
    </Container>
  );
};

export default Unidade;

const fundo = (numeroAula) => {
  switch (numeroAula) {
    case 1:
      return "rgba(0, 133, 150)";

    case 2:
      return "rgba(230, 16, 114)";

    case 3:
      return "rgba(243, 144, 21)";

    case 4:
      return "rgba(122, 92, 163)";

    case 5:
      return "rgba(47, 148, 54)";

    case 6:
      return "rgba(0, 133, 150)";

    case 7:
      return "rgba(230, 16, 114)";

    case 8:
      return "rgba(243, 144, 21)";

    case 9:
      return "rgba(122, 92, 163)";

    case 10:
      return "rgba(47, 148, 54)";

    default:
      return "white";
  }
};
