import styled from 'styled-components';

export const BoxTopo = styled.div`
  grid-template-columns: 700px auto;
  display: grid;
  margin: 20px auto;
  padding: 15px;
  max-width: 970px;
  border-radius: 15px;
  background: #75dfeb;
  box-shadow: 8px 7px 10px -4px rgba(0, 0, 0, 0.14);

  @media (max-width: 980px) {
    grid-template-columns: auto;
    width: 86%;
  }
`;

export const ButtonRepetir = styled.a`
  padding: 18px;
  font-size: 25px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: #fff;
  width: 180px;
  border-radius: 15px;
  background: #91dbe4;
  box-shadow: 3px 7px 13px -7px rgba(0, 0, 0, 0.3);
  cursor: pointer;

  :hover {
    box-shadow: inset 0px 0px 5px 6px rgba(0, 0, 0, 0.04);
  }

  @media (max-width: 980px) {
    padding: 10px;
    font-size: 16px;
    width: 110px;
  }
`;

export const ItemBoxTopo1 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;

  @media (max-width: 980px) {
    justify-content: center;
    text-align: center;
  }
`;

export const ItemBoxTopo2 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 980px) {
    justify-content: center;
  }
`;

export const Text = styled.p`
  font-size: 26px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: #fff;

  @media (max-width: 980px) {
    p {
      margin-right: 0%;
      font-size: 25px;
    }
  }
`;

export const TelaBox2 = styled.div`
  padding: 15px;
  grid-gap: 20px;
  grid-template-columns: auto auto;
  display: grid;
  margin: 20px auto;
  max-width: 970px;
  border-radius: 15px;
  background: rgb(233, 187, 63);
  box-shadow: inset 0px 0px 5px 6px rgba(0, 0, 0, 0.04);

  display: flex;
  flex-direction: column;

  @media (max-width: 980px) {
    grid-gap: 15px;
    grid-template-columns: auto;
    width: 86%;
    height: auto;
  }
`;

export const TelaBox3 = styled.div`
  padding: 15px;
  grid-gap: 20px;
  display: grid;
  margin: 20px auto;
  max-width: 970px;
  border-radius: 15px;
  background: rgb(233, 187, 63);
  box-shadow: inset 0px 0px 5px 6px rgba(0, 0, 0, 0.04);

  div {
    height: 250px;
    img {
      height: 250px;
    }
  }

  @media (max-width: 980px) {
    grid-gap: 15px;
    justify-content: space-evenly;
    width: 86%;
    height: auto;
    div {
      height: 70px;
      width: 100px;
      img {
        height: 100px;
      }
    }
  }
`;

export const TelaFiguraConfirm = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background: #03c2fc;
  text-align: center;
  cursor: pointer;

  height: 400px;
`;

export const TelaFigCorretaBtn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const BtnConcluir = styled.a`
  padding: 18px;
  font-size: 25px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: #fff;
  min-width: 180px;
  border-radius: 15px;
  background: #5be389;
  box-shadow: 3px 7px 13px -7px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  text-decoration: none !important;
`;

export const TelaFigCorreta = styled.style`
  /* display: flex;
        flex-direction: row;
        justify-content: center; */
  padding: 10px;
  flex-direction: column;
  align-items: center;
  border: 10px solid #1dd138;
  border-radius: 15px;
  background: #ffffff;
  /* max-width: 250px; */
  overflow: hidden;
  /* background: rgb(144 197 153); */
  text-align: center;
  cursor: pointer;
`;

export const Tla3Confirm = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 15px;
  margin: 10px;
  background: #03c2fc;
  text-align: center;
  height: 180px;
`;

export const Tla3Box2 = styled.div`
  padding: 15px;
  grid-gap: 20px;
  grid-template-columns: auto;
  display: grid;
  margin: 20px auto;
  max-width: 970px;
  border-radius: 15px;
  background: rgb(233, 187, 63);
  box-shadow: inset 0px 0px 5px 6px rgba(0, 0, 0, 0.04);

  @media (max-width: 980px) {
    grid-gap: 15px;
    width: 86%;
    height: auto;
  }
`;

export const Tla3Fig0 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 10px solid #fff;
  border-radius: 15px;
  background: #ffffff;
  text-align: center;
  cursor: pointer;

  :hover {
    border: 10px solid #75dfeb;
  }
`;

export const TextFig = styled.p`
  font-size: 26px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: #757575;
`;
