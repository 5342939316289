import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ReplayIcon from "@mui/icons-material/Replay";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const VisualizarMidia = ({ config }) => {
  const qtdRodadas = config.rodadas ? config.rodadas.length : 0;
  const [rodadaAtual, setRodadaAtual] = useState({});
  const [numRodadaAtual, setNumRodadaAtual] = useState(0);

  useEffect(() => {
    if (qtdRodadas > 0) iniciarRodada(1);
  }, []);

  const iniciarRodada = (numero) => {
    const rodada = config.rodadas[numero - 1];

    if (!rodada) return;

    setNumRodadaAtual(numero);
    setRodadaAtual(rodada);
  };

  const avancar = () => {
    if (numRodadaAtual === qtdRodadas) {
      return config.avancar ? config.avancar() : alert("Fim!");
    } else {
      iniciarRodada(numRodadaAtual + 1);
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.cabecalho}>
        <p>{config.enunciado}</p>
        <button style={styles.btnRepetir} onClick={() => iniciarRodada(1)}>
            <i class="fas fa-undo-alt" style={styles.btnRepetirIcon}></i> REPETIR
        </button>
      </div>
      <div style={styles.conteudo}>
        {rodadaAtual.tipoMidia === "imagem" && (
          <img
            draggable="true"
            style={{ width: 420 }}
            src={rodadaAtual.midia}
            alt=""
          />
        )}
        {rodadaAtual.tipoMidia === 'video' &&
          <video 
              autoPlay={true} 
              loop="true" 
              src={rodadaAtual.midia}
              style={{width: 420}}
          />
        }
        <button style={styles.btnContinuar} onClick={avancar}>
          CONTINUAR <i class="fas fa-chevron-right" style={styles.btnContinuarIcon}></i>
        </button>
      </div>
    </div>
  )

  return (
    <Container>
      <Top>
        <p>{config.enunciado}</p>
        <button onClick={() => iniciarRodada(1)}>
          <ReplayIcon />
          <p>REPETIR</p>
        </button>
      </Top>

      <Bottom>
        {rodadaAtual.tipoMidia === "imagem" && (
          <img
            draggable="true"
            style={{ height: 180 }}
            src={rodadaAtual.midia}
            alt=""
          />
        )}
        {rodadaAtual.tipoMidia === "video" && (
          <>
            <div>
              <video
                autoPlay={true}
                loop="true"
                src={rodadaAtual.midia}
                style={{ width: "100%" }}
              />
            </div>

            <div>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: 14,
                  color: "white",
                  textAlign: "center",
                }}
              >
                Faça os gestos conforme o vídeo e depois clique no botão abaixo
                para continuar!
              </p>
            </div>

            <button onClick={() => avancar()}>
              <NavigateNextIcon />
              <p>Continuar</p>
            </button>
          </>
        )}
      </Bottom>
    </Container>
  );
};

export default VisualizarMidia;

const Container = styled.div`
  height: 100%;
  min-height: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-color:red; */
`;

const Top = styled.div`
  color: red;
  display: flex;
  flex-direction: column;
  background-color: #75dfeb;
  align-items: center;
  justify-content: space-evenly;
  padding: 15px;
  border-radius: 20px;
  margin: 20px auto;
  box-shadow: 8px 7px 10px -4px rgb(000/14%);

  p {
    font-size: 14px;
    font-weight: bold;
    color: white;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  button {
    height: 35px;
    width: 120px;
    border-radius: 35px;
    border: none;
    background-color: #91dbe4;
    color: white;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
    outline: none;
    justify-content: center;
    border-radius: 25px;
    box-shadow: 3px 7px 13px -7px rgb(0 0 0 / 30%);
  }
  button:hover {
    color: #91dbe4;
    background-color: white;
    cursor: pointer;
    p {
      color: #91dbe4;
    }
  }
`;
const Bottom = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  align-items: center;
  justify-content: space-evenly;
  background-color: #e9bb3f;
  border-radius: 15px;
  margin: 10px;
  padding: 10px;

  button {
    height: 35px;
    width: 160px;
    border-radius: 35px;
    border: none;
    background-color: #91dbe4;
    color: white;
    font-size: 12px;
    font-weight: bold;
    display: flex;
    align-items: center;
    outline: none;
    justify-content: center;
    border-radius: 25px;
    box-shadow: 3px 7px 13px -7px rgb(0 0 0 / 30%);
  }
  button:hover {
    color: #91dbe4;
    background-color: white;
    cursor: pointer;
  }

  div {
    background-color: #cbfd91;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 10px;
  }
`;

const styles = {
  container: {
      backgroundColor: 'white', 
      margin: 5, 
      borderRadius: 15, 
      padding: 5
  },
  cabecalho: {
      backgroundColor: 'RGB(0,177,181)', 
      margin: 5, 
      borderRadius: 15, 
      padding: 5,
      color: 'white',
      fontWeight: 'bolder',
      display: 'flex',
      justifyContent: 'space-between'
  },
  conteudo: {
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItens: 'center',
    flexWrap: 'wrap',
    borderRadius: 15,
    backgroundColor: 'RGB(193,239,226)',
    margin: 5,
    marginTop: 10,
    padding: 10
  },
  conteudoFinal: {
      flexDirection: 'column',
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
      alignItens: 'center',
      flexWrap: 'wrap',
      borderRadius: 15,
      backgroundColor: 'RGB(193,239,226)',
      margin: 5,
      marginTop: 10,
      padding: 10
  },
  conteudoItem: {
      margin: 5,
      borderRadius: 15,
      backgroundColor: 'RGB(255,206,0)',
      padding: 5
  },
  conteudoItemCorreto: {
      margin: 5,
      borderRadius: 15,
      backgroundColor: 'RGB(107, 255, 107)',
      padding: 5
  },
  conteudoItemIncorreto: {
      margin: 5,
      borderRadius: 15,
      backgroundColor: 'RGB(255, 107, 107)',
      padding: 5
  },
  btnRepetir: {
      backgroundColor: 'RGB(71, 226, 230)',
      border: 'none',
      color: 'white',
      borderRadius: 15
  },
  btnRepetirIcon: {
      color: 'RGB(71, 226, 230)',
      marginBottom: 5
  },
  btnContinuar: {
      backgroundColor: 'RGB(0, 144, 245)',
      border: 'none',
      color: 'white',
      borderRadius: 15,
      maxHeight: 60,
      padding: 10,
      marginTop: 5
  },
  btnContinuarIcon: {
      color: 'RGB(0, 144, 245)',
      marginBottom: 5
  }
}