import { CircularProgress } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../container/context";
import ijws from "../../services/ijws";
const idContaIugu = process.env.REACT_APP_IUGU_CONTA_ID;

export default function CartaoCredito({
  opcoesParcelas,
  processandoCompra,
  processarCompra,
  inserirAssinatura,
}) {
  const [qtdParcelasSelecionada, setQtdParcelasSelecionada] = useState(1);
  const [cartao, setCartao] = useState({
    numero: "",
    titularNome: "",
    codigoVerificador: "",
    expiracao: "",
  });
  const [expiracao, setExpiracao] = useState("");
  const [msgErro, setMsgErro] = useState("");
  const { notification } = useContext(AppContext);

  const salvarPagamento = (e) => {
    e.preventDefault();
    processarCompra(true);
    setMsgErro("");

    let isOk = true;
    let nome = "";
    let sobrenome = "";
    let codigoVerificador = 0;
    let expiracaoAno = 0;
    let expiracaoMes = 0;

    const dataAtual = new Date();

    if (cartao.numero === "" || cartao.numero.length < 10) {
      notification("error", "Número do cartão inválido");
      isOk = false;
    }

    cartao.titularNome.split(" ").forEach((n, i) => {
      if (i === 0) {
        nome = n;
      } else {
        sobrenome += `${n} `;
      }
    });

    if (nome === "" || sobrenome === "") {
      notification("error", "Nome inválido");
      isOk = false;
    }

    if (
      cartao.codigoVerificador === "" ||
      cartao.codigoVerificador.length < 3
    ) {
      notification("error", "Código verificador inválido.");
      isOk = false;
    }

    cartao.expiracao.split("/").forEach((n, i) => {
      if (i === 0) expiracaoMes = n;
      if (i === 1) {
        expiracaoAno = n;
        if (expiracaoAno.length === 2)
          expiracaoAno = Number(expiracaoAno) + 2000;
      }

      if (i >= 1) return;
    });

    if (expiracaoAno < dataAtual.getFullYear()) {
      notification("error", "Ano de expiração inválido");
      isOk = false;
    }

    if (expiracaoMes < 1 || expiracaoMes > 12) {
      notification("error", "Mês de expiração inválido");
      isOk = false;
    }

    if (!isOk) return processarCompra(false);

    try {
      const Iugu = window.Iugu;
      Iugu.setAccountID(idContaIugu);
      if (cartao.titularNome.toUpperCase() === "TESTE TESTE") {
        Iugu.setTestMode(true);

        codigoVerificador = 123;
      } else {
        Iugu.setTestMode(false);

        codigoVerificador = cartao.codigoVerificador;
      }

      const cc = Iugu.CreditCard(
        cartao.numero,
        expiracaoMes,
        expiracaoAno,
        nome,
        sobrenome,
        codigoVerificador
      );

      Iugu.createPaymentToken(cc, (response) => {
        if (response.errors) {
          console.log(response.errors);

          if (response.errors.number) {
            setMsgErro("Número do cartão inválido.");
          }

          if (response.errors.verification_value) {
            setMsgErro("Iugu: Código verificador inválido.");
          }

          notification("error", "Verifique os dados e tente novamente.");

          processarCompra(false);
        } else {
          const requisicao = {
            token: response.id,
            descricao: `${response.extra_info.brand} ${response.extra_info.display_number}`,
            teste: "teste",
          };

          ijws
            .post("pagamentos/inserirForma", requisicao)
            .then((res) => {
              inserirAssinatura(qtdParcelasSelecionada);
            })
            .catch((e) => {
              const errCodes = e.data;

              if (errCodes) {
                if (errCodes.includes(1)) {
                  setMsgErro("Token inválido");
                }
                if (errCodes.includes(2)) {
                  setMsgErro("Descrição inválida");
                }
                if (errCodes.includes(101)) {
                  setMsgErro("Erro ao validar cliente na Iugu");
                }
                if (errCodes.includes(102)) {
                  setMsgErro("Erro ao criar cliente na Iugu");
                }
                if (errCodes.includes(103)) {
                  setMsgErro("Erro ao salvar forma de pagamento na Iugu");
                }
              }

              setMsgErro(
                "Não foi possível processar o pagamento. Verique os dados e tente novamente."
              );
            });
        }
      });
    } catch (e) {
      setMsgErro(
        "Não foi possível conectar-se ao servidor da Iugu para processar o pagamento. Por favor tente novamente mais tarde."
      );
      processarCompra(false);
      console.log(e);
    }
  };

  return (
    <div>
      {opcoesParcelas.length > 1 && (
        <div>
          <h3>Quantidade de parcelas</h3>
          <select
            name="qtdParcelas"
            id="qtdParcelas"
            style={{ padding: 10 }}
            onChange={(e) => setQtdParcelasSelecionada(e.target.value)}
          >
            <option key={0} value={0}>
              (Selecione)
            </option>
            {opcoesParcelas.map((opc, key) => (
              <option
                key={key + 1}
                value={opc.qtd}
                selected={opc.qtd === qtdParcelasSelecionada}
              >
                {opc.desc}
              </option>
            ))}
          </select>
        </div>
      )}
      <div>
        <h3>Dados do cartão</h3>
        <input
          type="text"
          disabled={processandoCompra}
          placeholder="Nome do titular do cartão"
          onChange={(e) => {
            cartao.titularNome = e.target.value;
            setCartao(cartao);
          }}
        />
        <input
          type="text"
          disabled={processandoCompra}
          placeholder="Número do cartão"
          value={cartao.numero}
          onChange={(e) => {
            let value = e.target.value.replace(/\D/g, "");
            if (value.length > 16) {
              value = value.slice(0, 16);
            }
            value = value.replace(/(\d{4})(?=\d)/g, "$1 ");
            cartao.numero = value;
            setCartao({ ...cartao, numero: value });
          }}
        />
        <div className={window.innerWidth > 800 && "input-duplo"}>
          <input
            type="text"
            disabled={processandoCompra}
            placeholder="Código verificador"
            value={cartao.codigoVerificador}
            onChange={(e) => {
              const { value } = e.target;
              if (/^\d{0,4}$/.test(value)) {
                setCartao({
                  ...cartao,
                  codigoVerificador: value,
                });
              }
            }}
          />
          <input
            type="text"
            disabled={processandoCompra}
            placeholder="Vencimento (MM/AA)"
            onChange={(e) => {
              const ultimoCaractere = e.target.value.charAt(
                e.target.value.length - 1
              );

              if (isNaN(ultimoCaractere) && ultimoCaractere !== "/") return;

              const apagando = cartao.expiracao.length > e.target.value.length;
              let expiracao = e.target.value;
              if (
                expiracao.length === 2 &&
                !apagando &&
                expiracao.charAt(expiracao.length - 1) !== "/"
              )
                expiracao += "/";

              if (expiracao.length > 5) return;

              cartao.expiracao = expiracao;
              setExpiracao(expiracao);
              setCartao(cartao);
            }}
            value={expiracao}
          />
        </div>
        <div style={{ textAlign: "center" }}>
          <p>
            <span>Dados de pagamento armazenados com segurança por </span>
            <a href="http://iugu.com" target="_blank" rel="noopener noreferrer">
              <img
                alt="Logo da Inglês Juntos"
                src={require("../../assets/imagens/iugu.png")}
                style={{
                  marginLeft: 10,
                  marginBottom: 0,
                  marginTop: 0,
                  width: 60,
                }}
              />
            </a>
          </p>
        </div>

        {processandoCompra ? (
          <CircularProgress />
        ) : (
          <input
            type="submit"
            className="btn-padrao-lp"
            value="Concluir pagamento"
            onClick={salvarPagamento}
          />
        )}
        <div style={{ marginTop: 30 }}>
          <img
            style={{ width: 300 }}
            alt="Logo da Inglês Juntos"
            src={require("../../assets/imagens/pagamento-cartoes.png")}
          />
        </div>
        <div>
          <img
            alt="Logo da Inglês Juntos"
            style={{ width: 300 }}
            src={require("../../assets/imagens/pagamento-seguranca.png")}
          />
        </div>

        {msgErro !== "" && <h2 style={{ color: "red" }}>{msgErro}</h2>}
        <br />
      </div>
    </div>
  );
}
