/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState, Fragment} from 'react';
import {click, win, wrong} from '../libs/soundEffects';

let idArrastando;

let _pecas = [];
let _pecaSelecionada = 0;
let _posicaoSelecionada = 0;

const QuebraCabeca = ({config}) => {    
    const qtdRodadas = config.rodadas ? config.rodadas.length : 0;

    const [rodadaAtual, setRodadaAtual] = useState(undefined);
    const [blocos, setBlocos] = useState([]);
    const [pecas, setPecas] = useState([]);
    const [numRodadaAtual, setNumRodadaAtual] = useState(0);
    const [respostaOk, setRespostaOk] = useState(undefined);
    const [pecaSelecionada, setPecaSelecionada] = useState(0);

    useEffect(() => {
        //document.addEventListener("keydown", tratarTeclas, false);

        if(config.audioInicio){
            const audio = new Audio(config.audioInicio);

            audio.play();        
        }

        iniciarRodada(0);
    }, []);

    const iniciarRodada = (numero) => {
        const rodada = config.rodadas[numero];

        if(!rodada) return;

        if(rodada.audioEnunciado){
            const audio = new Audio(rodada.audioEnunciado);

            audio.play();
        }

        const pecas = [];
        let posicoes = [];

        for(var i = 1; i <= 25; i++) {
            let fixa = false;

            rodada.pecas.filter(peca => peca.posicao === i && peca.fixa).forEach(peca => {
                pecas.push({
                    id: peca.id,
                    imagem: peca.imagem,
                    posicao: i,
                    posicaoCorreta: peca.posicao
                });

                fixa = true;
            });

            if(!fixa) posicoes.push(i);
        }

        rodada.pecas.filter(peca => !peca.fixa).forEach(peca => {
            const posicao = Math.floor(Math.random() * posicoes.length);
            
            pecas.push({
                id: peca.id,
                imagem: peca.imagem,
                posicao: posicoes[posicao],
                posicaoCorreta: peca.posicao
            });

            posicoes.splice(posicao, 1);
        })

        montarBlocos(pecas);

        setNumRodadaAtual(numero);
        setRodadaAtual(rodada);
        setRespostaOk(false);
    }

    const montarBlocos = (pecas) => {
        const blocosMontar = [];

        let posicaoPrimeiraPeca = 0;

        for(let i = 1; i <= 25; i++) {
            const pecaCorrespondente = pecas.filter(p => p.posicao === i);

            if(pecaCorrespondente.length > 0){
                if(posicaoPrimeiraPeca === 0) posicaoPrimeiraPeca = i;

                blocosMontar.push({
                    pecaId: pecaCorrespondente[0].id,
                    imagem: pecaCorrespondente.length > 0 ? pecaCorrespondente[0].imagem : ''
                });
            } else {
                blocosMontar.push({});
            }
        }

        setPecas(pecas);
        setBlocos(blocosMontar);        

        _pecas = pecas;

        selecionarPeca(posicaoPrimeiraPeca);
    }

    const tratarTeclas = (e) => {
        e.preventDefault();

        let posicaoAvancar = 0;

        console.log(e.key);        

        if(e.key === 'ArrowUp' || e.key === 'w'){
            if(_posicaoSelecionada > 5){
                posicaoAvancar = _posicaoSelecionada - 5;
            }
        }

        if(e.key === 'ArrowRight' || e.key === 'd'){
            if(_posicaoSelecionada%5 !== 0){
                posicaoAvancar = _posicaoSelecionada + 1;
            }
        }

        if(e.key === 'ArrowDown' || e.key === 's'){
            if(_posicaoSelecionada < 21){
                posicaoAvancar = _posicaoSelecionada + 5;
            }
        }

        if(e.key === 'ArrowLeft' || e.key === 'a'){
            if((_posicaoSelecionada - 1)%5 !== 0){
                posicaoAvancar = _posicaoSelecionada - 1;
            }
        }

        console.log('avançar para ' + posicaoAvancar);

        if(posicaoAvancar === 0 || _pecas.filter(p => p.posicao === posicaoAvancar).length > 0){
            wrong();
        } else {
            let posicaoCorreta = false;

            _pecas.filter(p => p.id === _pecaSelecionada).forEach(peca => {
                peca.posicao = posicaoAvancar;
                
                 posicaoCorreta = (peca.posicaoCorreta === posicaoAvancar);
            });

            const pecasNovo = [..._pecas];

            _posicaoSelecionada = posicaoAvancar;

            if(posicaoCorreta){
                win();

                if(_pecas.filter(p => p.posicaoCorreta === p.posicao).length === _pecas.length){
                    selecionarPeca(0);
                    setRespostaOk(true);
                    setPecaSelecionada(0);
                } else {
                    let novaSelecionada = posicaoAvancar + 1;

                    do {
                        novaSelecionada = novaSelecionada > 25 ? 1 : novaSelecionada + 1;

                    } while(_pecas.filter(p => (p.posicao === novaSelecionada && p.posicaoCorreta !== novaSelecionada)).length === 0);
    
                    _posicaoSelecionada = novaSelecionada;
    
                    selecionarPeca(novaSelecionada);
                }
            }

            setPecas(pecasNovo);
        }
    }

    const selecionarPeca = (posicao) => {
        const pecaCorrespondente = _pecas.filter(peca => peca.posicao === posicao);

        if(pecaCorrespondente.length === 0) return;
        if(pecaCorrespondente[0].posicaoCorreta === posicao) return;

        _pecaSelecionada = pecaCorrespondente[0].id;
        _posicaoSelecionada = posicao;

        setPecaSelecionada(_pecaSelecionada);
    }

    const avancar = () => {
        click();

        return config.avancar ? config.avancar() : alert('Fim!');
    }

    const dropRecipiente = (e, key) => {
        e.preventDefault();

        let posicaoMover = key + 1;

        if(posicaoMover === 0 || _pecas.filter(p => p.posicao === posicaoMover).length > 0){
            wrong();
        } else {
            let posicaoCorreta = false;

            _pecas.filter(p => p.id === idArrastando).forEach(peca => {
                peca.posicao = posicaoMover;
                
                posicaoCorreta = (peca.posicaoCorreta === posicaoMover);
            });

            const pecasNovo = [..._pecas];

            if(posicaoCorreta){
                win();

                if(_pecas.filter(p => p.posicaoCorreta === p.posicao).length === _pecas.length){
                    //selecionarPeca(0);
                    setRespostaOk(true);
                    setPecaSelecionada(0);
                }
            }

            setPecas(pecasNovo);

            //_posicaoSelecionada = posicaoAvancar;
        }



        // if(idArrastando === recipienteId){
        //     win();
            
        //     setPontos(pontos + 1);

        //     if(pontos + 1 >= rodadaAtual.pontos) {
        //         avancar();
        //     }
        // } else {
        //     wrong();
        // }
    }

    if(!rodadaAtual) return null;

    return (
        <div className="content">
            <div className="container">
                <div className="box-topo">
                    <div className="item-box-topo-1">
                        <p className="p-text">{rodadaAtual.enunciado}</p>
                    </div>
                    <div className="item-box-topo-2">
                        <a className="btn-repetir" onClick={() => iniciarRodada(0)}><i className="fas fa-undo-alt"></i> REPETIR</a>
                    </div>
                </div>
                <div style={{textAlign: 'center'}}>
                    <p style={{fontSize: 9}}><i>Clique e arraste para mover as peças</i></p>
                </div>
                
                { respostaOk &&
                    <div className="tl1-box-1">
                        <div className="tl7-box-confirmacao">
                            <p className="p-text">PARABÉNS</p>
                            <p className="p-text">VOCÊ COMPLETOU A IMAGEM!</p>
                            <a className="btn-concluir" onClick={avancar}>CONTINUAR <i className="fas fa-chevron-right"></i> </a>
                        </div>
                    </div>
                }
                <div className="box-quebra-cabeca">
                    {blocos.map((bloco, key) => {
                        const peca = verificarPecaBloco(key + 1, pecas)

                        return (
                            <div 
                                key={key}
                                className="peca-quebra-cabeca" 
                                style={peca ? definirEstiloPeca(peca, key + 1) : {}}
                                onClick={() => selecionarPeca(key + 1)}
                                draggable={peca ? "true" : "false"} 
                                onDragStart={peca ? () => {idArrastando = peca.id} : {}}
                                onDrop={e => dropRecipiente(e, key)} 
                                onDragOver={e => e.preventDefault()}
                            >
                                {/* {peca && pecaSelecionada === peca.id &&
                                    <div className="quebra-cabeca-peca-selecionada">
                                        <i className="fas fa-arrows-alt fa-3x" style={{position: 'absolute'}}></i>
                                    </div>
                                }                             */}
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default QuebraCabeca;

const verificarPecaBloco = (posicaoBloco, pecas) => {
    const pecaCorrespondente = pecas.filter(peca => peca.posicao === posicaoBloco);

    return pecaCorrespondente[0];
}

const definirEstiloPeca = (peca, posicao) => {
    return {
        backgroundImage: `url(${peca.imagem})`,
        opacity: peca.posicaoCorreta === posicao ? 1 : 0.7
    }
}