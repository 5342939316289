module.exports = {
    click(){
        const audio = new Audio(require('../assets/sounds/click.mp3'));

        audio.play();
    },

    win(){
        const audio = new Audio(require('../assets/sounds/win.mp3'));

        audio.play();
    },

    wrong(){
        const audio = new Audio(require('../assets/sounds/wrong.mp3'));

        audio.play();
    },
}