import React from "react";
// import TermosDoc from '../documents/Termos';

const Termos = ({ continuar, voltar, apenasVisualizacao }) => (
  <div className="pagina-login">
    <div className="container-login">
      <img
        alt="Logo da Inglês Juntos"
        className="logo-login"
        src={require("../assets/imagens/logo.png")}
      />
      <h2>Termos de uso</h2>
      {!apenasVisualizacao && (
        <p>Por favor, leia e aceite os termos a seguir</p>
      )}
      <a
        href="https://pertobilingueadm.com.br/peij/storage/termos/ij.pdf"
        target="_blank"
        rel="noopener noreferrer"
      >
        Visualizar termos de uso
      </a>
      {/* <div className="termos">
                <TermosDoc/>
            </div> */}
      <form
        className="form-login"
        onSubmit={(e) => {
          e.preventDefault();
          continuar();
        }}
      >
        {!apenasVisualizacao && (
          <input type="submit" className="btn-padrao" value="Aceitar" />
        )}
      </form>
      <div className="footer-nav">
        {voltar && (
          <button className="btn-link ali-left" onClick={voltar} href="#">
            Voltar
          </button>
        )}
      </div>
    </div>
  </div>
);

export default Termos;
