import React, { useState, useEffect, useRef } from "react";

let canvas, ctx, prevMouseX, prevMouseY, snapshot, offsetX, offsetY,
    isDrawing = false,
    selectedTool = "brush",
    brushWidth = 5,
    selectedColor = "#000",
    viewport = window.visualViewport,
    ongoingTouches = [];

const Canvas = ({notificarCarregamento, tentativa, corSelecionada}) => {
    const canvasRef = useRef(null);
    const [ultimaTentativa, setUltimaTentativa] = useState(0);
    //const [corSelecionada, setCorSelecionada] = useState(corSelecionada);

    useEffect(() => {
        //selectedTool = props.selectedTool;
        //selectedColor = props.selectedColor;
        selectedColor = corSelecionada;
        console.log(tentativa, ultimaTentativa);
        if(!ctx || ultimaTentativa !== tentativa) setTimeout(inicializar, ultimaTentativa !== tentativa ? 1000 : 0);
        setUltimaTentativa(tentativa);
    }, [tentativa, corSelecionada]);

    const inicializar = () => {
        canvas = canvasRef.current;
        setTimeout(() => {
            if(!canvas) return;

            ctx = canvas.getContext("2d");
            canvas.width = canvas.offsetWidth;
            canvas.height = canvas.offsetHeight;
            canvas.style.width = "100%";
            canvas.style.height = "100%";
            canvas.width = canvas.offsetWidth;
            canvas.height = canvas.offsetHeight;
            canvas.style.backgroundColor = "#fff";
            canvas.style.cursor = 'crosshair';
    
            canvas.addEventListener("mousedown", startDraw);
            canvas.addEventListener("mousemove", drawing);
            canvas.addEventListener("mouseup", () => isDrawing = false);
            
            canvas.addEventListener('touchstart', startDrawTouch);
            canvas.addEventListener('touchmove', drawingTouch);
            canvas.addEventListener('touchend', endDrawTouch);
            canvas.addEventListener('touchcancel', cancelDrawTouch);

            notificarCarregamento();
        }, 1000);
    }

    const startDraw = (e) => {
        isDrawing = true;
        prevMouseX = e.offsetX; 
        prevMouseY = e.offsetY;
        ctx.beginPath();
        ctx.lineWidth = brushWidth;
        ctx.strokeStyle = selectedColor;
        ctx.fillStyle = selectedColor;
        snapshot = ctx.getImageData(0, 0, canvas.width,
        canvas.height);
    }   
    
    const drawPencil = (e) => {
        ctx.lineTo(e.offsetX, e.offsetY);
        ctx.stroke();
    }    
    
    const drawing = (e) => {
        if (!isDrawing) return;
        ctx.putImageData(snapshot, 0, 0);
    
        drawPencil(e);
    }

    const startDrawTouch = e => {
        e.preventDefault();

        const touches = e.changedTouches;
        offsetX = canvas.getBoundingClientRect().left;
        offsetY = canvas.getBoundingClientRect().top;

        for (let i = 0; i < touches.length; i++) {
            ongoingTouches.push(copyTouch(touches[i]));
        }
    }

    const drawingTouch = e => {
        e.preventDefault();
        const touches = e.changedTouches;

        for(let i = 0; i < touches.length; i++) {
            const idx = ongoingTouchIndexById(touches[i].identifier);

            if(idx >= 0) {
                ctx.beginPath();
                ctx.moveTo(ongoingTouches[idx].clientX - offsetX, ongoingTouches[idx].clientY - offsetY);
                ctx.lineTo(touches[i].clientX - offsetX, touches[i].clientY - offsetY);
                ctx.lineWidth = 5;
                ctx.strokeStyle = selectedColor;
                ctx.lineJoin = "round";
                ctx.closePath();
                ctx.stroke();
                ongoingTouches.splice(idx, 1, copyTouch(touches[i]));  // swap in the new touch record
            }
        }
    }

    const endDrawTouch = e => {
        e.preventDefault();

        const touches = e.changedTouches;

        for (let i = 0; i < touches.length; i++) {
            let idx = ongoingTouchIndexById(touches[i].identifier);
            if (idx >= 0) {
                ctx.lineWidth = 5;
                ctx.fillStyle = selectedColor;
                ongoingTouches.splice(idx, 1);  // remove it; we're done
            }
        }
    }

    const cancelDrawTouch = e => {
        e.preventDefault();

        const touches = e.changedTouches;

        for (let i = 0; i < touches.length; i++) {
            let idx = ongoingTouchIndexById(touches[i].identifier);
            ongoingTouches.splice(idx, 1);  // remove it; we're done
        }
    }

    const copyTouch = ({ identifier, clientX, clientY }) => {
        return { identifier, clientX, clientY };
    }

    const ongoingTouchIndexById = (idToFind) => {
        for(let i = 0; i < ongoingTouches.length; i++) {
            const id = ongoingTouches[i].identifier;
            if (id === idToFind) {
                return i;
            }
        }
        return -1;    // not found
    }

    return (
        <canvas id="canvas" ref={canvasRef}></canvas>
    );
}

export default Canvas;