import React, { useEffect } from 'react';
import { click } from '../libs/soundEffects';
import axios from 'axios';

let fim = false;

const Material = ({ config }) => {
  useEffect(() => {
    if (config.audioEnunciado) {
      const audio = new Audio(config.audioEnunciado);

      audio.play();
    }
  }, []);

  const encerrar = () => {
    fim = true;
    config.avancar ? config.avancar() : alert('acabou!');
  };

  const handleDownload = () => {
    window.open(config.arquivo, '_blank');
    // axios({
    //   url: config.arquivo,
    //   method: 'GET',
    //   responseType: 'blob',
    // }).then((response) => {
    //    const url = window.URL.createObjectURL(new Blob([response.data]));
    //    const link = document.createElement('a');
    //    link.href = url;
    //    link.setAttribute('download', config.nomeArquivo);
    //    document.body.appendChild(link);
    //    link.click();
    // });
  };

  return (
    <div className="content">
      <div className="container">
        <div className="box-topo" style={{ placeContent: 'center' }}>
          <div className="item-box-topo-1" style={{ textAlign: 'center' }}>
            <p className="p-text">{config.enunciado}</p>
          </div>
        </div>

        <div className="download-container">
          <a
            className="btn-download"
            style={{ background: 'lightcoral' }}
            onClick={handleDownload}
          >
            <i className="fas fa-download"></i> DOWNLOAD
          </a>
        </div>

        <div className="box-botao-centro2">
          <a className="btn-concluir" onClick={encerrar}>
            <i className="fas fa-check"></i> CONTINUAR
          </a>
        </div>
      </div>
    </div>
  );
};

export default Material;
