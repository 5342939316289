import serverHost from '../../services/serverHost';

const configDesenho = (identificador) => {
    switch(identificador){
        case 'nao-alfabetizados_unit1_aula2_atividade4':
            return {
                enunciado: 'Desenhe um DOG bem bonito!'
            }

        case 'nao-alfabetizados_unit1_aula3_atividade4':
            return {
                enunciado: 'DESENHE VOCÊ COM UMA FANTASIA DE CAT!'
            }
        
        case 'nao-alfabetizados_unit1_aula4_atividade4':
            return {
                enunciado: 'DESENHE O QUE VOCÊ MAIS GOSTA DE EAT!'
            }

        case 'nao-alfabetizados_unit1_aula5_atividade4':
            return {
                enunciado: 'DESENHE O SEU RABBIT',
                audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-1/aula-5/video-4/enunciado.mp3`
            }

        case 'nao-alfabetizados_unit1_aula6_atividade4':
            return {
                enunciado: 'DESENHE UM PET FLYING',
                audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-1/aula-6/video-4/enunciado.mp3`
            }

        case 'nao-alfabetizados_unit1_aula7_atividade4':
            return {
                enunciado: 'DESENHE VOCÊ E OS FISH SWIMMING NO OCEANO!',
                audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-1/aula-7/video-4/enunciado.mp3`
            }

        case 'nao-alfabetizados_unit1_aula8_atividade2':
            return {
                enunciado: 'PINTE O BIRD!',
                audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-1/aula-8/video-2/enunciado.mp3`,
                imagem: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-1/aula-8/video-2/imagem.png`
            }

        case 'nao-alfabetizados_unit1_aula9_atividade4':
            return {
                enunciado: 'DESENHE ALGO QUE VOCÊ CONSEGUE FAZER E DIGA "YES, I CAN!"',
                audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-1/aula-9/video-4/enunciado.mp3`
            }

        case 'nao-alfabetizados_unit1_aula10_atividade4':
            return {
                enunciado: 'DESENHE VOCÊ AO LADO DO SEU PET FAVORITO',
                audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-1/aula-10/video-4/enunciado.mp3`
            }

        case 'nao-alfabetizados_unit5_aula2_atividade4':
            return {
                enunciado: 'DESENHE VOCÊ JOGANDO BASKETBALL COM SEUS FAMILIARES!',
                audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-5/aula-2/video-4/enunciado.ogg`                
            }

        case 'nao-alfabetizados_unit5_aula3_atividade4':
            return {
                enunciado: 'DESENHE UMA BOLA DE VOLLEYBALL!',
                audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-5/aula-3/video-4/enunciado.ogg`
            }

        case 'nao-alfabetizados_unit5_aula4_atividade4':
            return {
                enunciado: 'DESENHE VOCÊ USANDO O UNIFORME DE UM TIME DE SOCCER!',
                audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-5/aula-4/video-4/enunciado.ogg`
            }

        case 'nao-alfabetizados_unit5_aula6_atividade4':
            return {
                enunciado: 'DESENHE VOCÊ FAZENDO UM SPORT COM UM DOS PERSONAGENS!',
                audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-5/aula-6/video-4/enunciado.ogg`
            }
            
        case 'nao-alfabetizados_unit5_aula8_atividade4':
            return {
                enunciado: 'COMO FICARAM SEUS EQUIPAMENTOS PARA JOGAR GOLF? DESENHE-OS!',
                audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-5/aula-8/video-4/enunciado.ogg`
            }

        case 'nao-alfabetizados_unit5_aula9_atividade4':
            return {
                enunciado: 'DESENHE VOCÊ E SEUS FRIENDS FAZENDO UM SPORT!',
                audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-5/aula-9/video-4/enunciado.ogg`
            }

        case 'nao-alfabetizados_unit5_aula10_atividade4':
            return {
                enunciado: 'DESENHE VOCÊ FAZENDO SEU SPORT INVENTADO!',
                audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-5/aula-10/video-4/enunciado.ogg`
            }

        case 'nao-alfabetizados_unit6_aula2_atividade4':
            return {
                enunciado: 'DESENHE 4 OBJETOS EM FORMATO DE CIRCLE!',
                audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-6/aula-2/video-4/enunciado.ogg`
                
            }

        case 'nao-alfabetizados_unit6_aula3_atividade4':
            return {
                enunciado: 'COMO VOCÊ DESENHOU O SEU CUBO? DESENHE-O!',
                audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-6/aula-3/video-4/enunciado.ogg`
            }

        case 'nao-alfabetizados_unit6_aula4_atividade2':
            return {
                enunciado: 'DESENHE UM PEDAÇO BEM GOSTOSO DE PIZZA!',
                audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-6/aula-4/video-2/enunciado.ogg`
            }

        case 'nao-alfabetizados_unit6_aula4_atividade4':
            return {
                enunciado: 'DESENHE UM PEDAÇO DO SEU SABOR FAVORITO DE PIZZA!',
                audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-6/aula-4/video-4/enunciado.ogg`,
            }

        case 'nao-alfabetizados_unit6_aula5_atividade4':
            return {
                enunciado: 'DESENHE ALGO QUE TENHA ESTAMPA DE STARS!',
                audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-6/aula-5/video-4/enunciado.ogg`
            }

        case 'nao-alfabetizados_unit6_aula8_atividade4':
            return {
                enunciado: 'DESENHE UM HEART PARA ALGUÉM QUE VOCÊ LOVE!',
                audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-6/aula-8/video-4/enunciado.ogg`
            }

        case 'nao-alfabetizados_unit6_aula9_atividade4':
            return {
                enunciado: 'BE CREATIVE! DESENHE UMA CENA QUE TENHA PELO MENOS 1 TRIANGLE, 1 CIRCLE, 1 SQUARE, 1 STAR, 1 HEART E 1 DIAMOND',
                audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-6/aula-9/video-4/enunciado.ogg`
            }

        case 'nao-alfabetizados_unit6_aula10_atividade4':
            return {
                enunciado: 'DESENHE AS SHAPES QUE VOCÊ ESCOLHEU PARA FAZER COM MASSINHA',
                audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-6/aula-10/video-4/enunciado.ogg`
            }

        case 'alfabetizados_unit5_aula2_atividade4':
            return {
                enunciado: 'DESENHE UM INSTRUMENT QUE VOCÊ VIU NA SUA APRESENTAÇÃO FAVORITA DE CLASSICAL MUSIC',
                audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-5/aula-2/video-4/enunciado.ogg`
            }

        case 'alfabetizados_unit5_aula3_atividade4':
            return {
                enunciado: 'COMO FICOU SEU VIOLIN? DESENHE-O!'
            }

        case 'alfabetizados_unit5_aula6_atividade4':
            return {
                enunciado: 'DESENHE VOCÊ TOCANDO UM INSTRUMENT QUE VOCÊ JÁ APRENDEU NESTA UNIDADE!',
                audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-5/aula-6/video-4/enunciado.ogg`
            }

        case 'alfabetizados_unit5_aula8_atividade4':
            return {
                enunciado: 'DESENHE A SUA GUITAR FAVORITA!'
            }

        case 'alfabetizados_unit5_aula9_atividade4':
            return {
                enunciado: 'DESENHE VOCÊ E SUA ORCHESTRA FAZENDO UMA APRESENTAÇÃO!',
                audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-5/aula-9/video-4/enunciado.ogg`
            }

        case 'alfabetizados_unit5_aula10_atividade4':
            return {
                enunciado: 'DESENHE A MEMÓRIA QUE O INSTRUMENT ESCOLHIDO FEZ VOCÊ LEMBRAR',
                audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-5/aula-10/video-4/enunciado.ogg`
            }

        case 'alfabetizados_unit6_aula2_atividade4':
            return {
                enunciado: 'DESENHE DOIS OBJETOS QUE TENHAM FORMA DE CYLINDER E DOIS OBJETOS QUE TENHAM FORMA DE RECTANGLE',
                audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-6/aula-2/video-4/enunciado.ogg`
            }

        case 'alfabetizados_unit6_aula3_atividade4':
            return {
                enunciado: 'COMO FICOU SUA PIPA EM FORMATO DE RHOMBUS? DESENHE-A!',
                audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-6/aula-3/video-4/enunciado.ogg`
            }

        case 'alfabetizados_unit6_aula4_atividade4':
            return {
                enunciado: 'COMO FICOU SEU CUBE DECORADO? DESENHE-O!',
                audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-6/aula-4/video-4/enunciado.ogg`
            }

        case 'alfabetizados_unit6_aula5_atividade2':
            return {
                enunciado: 'VOCÊ CONSEGUE FAZER UM DESENHO UTILIZANDO APENAS TRIANGLES? VAMOS TENTAR!',
                audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-6/aula-5/video-2/enunciado.ogg`
            }

        case 'alfabetizados_unit6_aula6_atividade4':
            return {
                enunciado: 'DESENHE SUAS 4 IMAGENS FAVORITAS DO CARTAZ!',
                audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-6/aula-6/video-4/enunciado.ogg`
            }

        case 'alfabetizados_unit6_aula7_atividade4':
            return {
                enunciado: 'DESENHE ALGO QUE TENHA ESTAMPA DE STARS!',
                audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-6/aula-7/video-4/enunciado.ogg`
            }

        case 'alfabetizados_unit6_aula8_atividade4':
            return {
                enunciado: 'DESENHE ALGO EM VOLTA DESTA SHAPE!',
                audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-6/aula-8/video-4/enunciado.ogg`,
                imagem: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-6/aula-8/video-4/background.png`
            }

        case 'alfabetizados_unit6_aula9_atividade4':
            return {
                enunciado: 'DESENHE UMA BANDEIRA QUE TENHA PELO MENOS TRÊS SHAPES DIFERENTES!',
                audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-6/aula-9/video-4/enunciado.ogg`
            }

        case 'alfabetizados_unit1_aula2_atividade4':
            return {
                enunciado: 'DESENHE A CAPA DO SEU BOOK FAVORITO AO LADO DA CAPA DO BOOK FAVORITO DE ALGUM PARENTE',
                audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-1/aula-2/video-4/enunciado.mp3`
            }

        case 'alfabetizados_unit1_aula3_atividade4':
            return {
                enunciado: 'DESENHE O SEU PINWHEEL',
                audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-1/aula-3/video-4/enunciado.mp3`
            }

        case 'alfabetizados_unit1_aula4_atividade4':
            return {
                enunciado: 'AGORA, DESENHE O CAR OU O TRUCK QUE VOCÊ ENCONTROU',
                audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-1/aula-4/video-4/enunciado.mp3`
            }

        case 'alfabetizados_unit1_aula5_atividade4':
            return {
                enunciado: 'DESENHE O SEU PUPPET!',
                audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-1/aula-5/video-4/enunciado.mp3`
            }

        case 'alfabetizados_unit1_aula6_atividade4':
            return {
                enunciado: 'RECRIE 4 FLASHCARDS DA UNIDADE',
                audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-1/aula-6/video-4/enunciado.mp3`
            }

        case 'alfabetizados_unit1_aula7_atividade4':
            return {
                enunciado: 'DESENHE O SEU ROBOT',
                audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-1/aula-7/video-4/enunciado.mp3`
            }

        case 'alfabetizados_unit1_aula8_atividade4':
            return {
                enunciado: 'DESENHE UM ACTION FIGURE OU UMA DOLL EM UMA POSE ENGRAÇADA!',
                audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-1/aula-8/video-4/enunciado.mp3`
            }

        case 'alfabetizados_unit1_aula9_atividade4':
            return {
                enunciado: 'DESENHE O SEU RECYCLED TOY!',
                audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-1/aula-9/video-4/enunciado.mp3`
            }

        case 'alfabetizados_unit1_aula10_atividade10': //TODO: alterar para alfabetizados_unit1_aula10_atividade4
            return {
                enunciado: 'RECRIE A SUA CENA FAVORITA DA HISTÓRIA!',
                audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-1/aula-10/video-4/enunciado.mp3`
            }

        case 'nao-alfabetizados_unit2_aula1_atividade2':
            return {
                enunciado: 'PINTE OS MEIOS DE TRANSPORTATION',
                audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-1/video-2/enunciado.ogg`,
                imagem: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-1/video-2/imagem.png`
            }

        case 'nao-alfabetizados_unit2_aula2_atividade4':
            return {
                enunciado: 'DESENHE UM TRAIN NESTE RAIL',
                audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-2/video-4/enunciado.ogg`,
                imagem: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-2/video-4/imagem.jpg`
            }

        case 'nao-alfabetizados_unit2_aula3_atividade4':
            return {
                enunciado: 'DESENHE O SEU BUS!',
                audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-3/video-4/enunciado.ogg`
            }

        case 'nao-alfabetizados_unit2_aula4_atividade4':
            return {
                enunciado: 'DESENHE O SEU SHIP DE PAPEL!',
                audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-4/video-4/enunciado.ogg`,
            }

        case 'nao-alfabetizados_unit2_aula5_atividade4':
            return {
                enunciado: 'DESENHE O SEU PLANE',
                audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-5/video-4/enunciado.ogg`,
            }

        case 'nao-alfabetizados_unit2_aula6_atividade4':
            return {
                enunciado: 'DESENHE VOCÊ DENTRO DE UM MEIO DE TRANSPORTATION',
                audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-6/video-4/enunciado.ogg`,
            }

        case 'nao-alfabetizados_unit2_aula7_atividade2':
            return {
                rodadas: [
                    {
                        enunciado: 'DESENHE AS PARTES QUE FALTAM NO CAR PARA ELE PODER ANDAR',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-7/video-2/enunciado_car.ogg`,
                        imagem: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-7/video-2/imagem_car.png`
                    },
                    {
                        enunciado: 'DESENHE AS PARTES QUE FALTAM NO TAXI PARA ELE PODER ANDAR',
                        audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-7/video-2/enunciado_taxi.ogg`,
                        imagem: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-7/video-2/imagem_taxi.png`
                    }
                ]
            }

        case 'nao-alfabetizados_unit2_aula7_atividade4':
            return {
                enunciado: 'DESENHE O VOLANTE QUE VOCÊ FEZ PARA O SEU CAR',
                audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-7/video-4/enunciado.ogg`
            }

        case 'nao-alfabetizados_unit2_aula8_atividade4':
            return {
                enunciado: 'DESENHE A SUA BIKE! SE VOCÊ NÃO TEM UMA, DESENHE A BIKE QUE VOCÊ GOSTARIA DE TER!',
                audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-8/video-4/enunciado.ogg`
            }

        case 'nao-alfabetizados_unit2_aula9_atividade4':
            return {
                enunciado: 'DESENHE VOCÊ E SEU FRIEND ANDANDO EM ALGUM MEIO DE TRANSPORTATION QUE NÓS APRENDEMOS E MANDE PARA ELE',
                audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-9/video-4/enunciado.ogg`
            }

        case 'nao-alfabetizados_unit2_aula10_atividade4':
            return {
                enunciado: 'DESENHE UM MEIO DE TRANSPORTATION REAL',
                audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-10/video-4/enunciado.ogg`
            }

        case 'alfabetizados_unit2_aula2_atividade4':
            return {
                enunciado: 'DESENHE ALGO QUE VOCÊ LOVE MUITO AO LADO DE ALGO QUE UM FAMILIAR LOVE MUITO TAMBÉM',
                audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-2/aula-2/video-4/enunciado.mp3`
            }

        case 'alfabetizados_unit2_aula3_atividade4':
            return {
                enunciado: 'DESENHE A RECEITA QUE VOCÊ FEZ COM O STRAWBERRY!',
                audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-2/aula-3/video-4/enunciado.mp3`
            }

        case 'alfabetizados_unit2_aula4_atividade4':
            return {
                enunciado: 'DESENHE UM MONTE DE COISA RED SE VOCÊ ESCOLHEU A APPLE OU DE COISAS GREEN SE VOCÊ ESCOLHEU A PEAR',
                audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-2/aula-4/video-4/enunciado.mp3`
            }

        case 'alfabetizados_unit2_aula6_atividade4':
            return {
                enunciado: `DESENHE UMA FRUIT QUE VOCÊ LIKE, UMA FRUIT QUE VOCÊ LOVE E UMA FRUIT QUE VOCÊ DON'T LIKE, UMA DO LADO DA OUTRA!`,
                audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-2/aula-6/video-4/enunciado.mp3`
            }

        case 'alfabetizados_unit2_aula7_atividade4':
            return {
                enunciado: 'DESENHE ALGO QUE VOCÊ COSTUMA HATE E HOJE VOCÊ LIKE:',
                audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-2/aula-7/video-4/enunciado.mp3`
            }

        case 'alfabetizados_unit2_aula8_atividade4':
            return {
                enunciado: 'DESENHE A SUA GUACAMOLE!',
                audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-2/aula-8/video-4/enunciado.mp3`
            }

        case 'alfabetizados_unit2_aula9_atividade4':
            return {
                enunciado: 'DESENHE UMA FRUIT QUE ESTÁ RIPE AO LADO DE UMA FRUIT QUE NÃO ESTÁ RIPE!',
                audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-2/aula-9/video-4/enunciado.mp3`
            }

        case 'alfabetizados_unit2_aula10_atividade1':
            return {
                enunciado: 'DESENHE A SUA VITAMIN E AS FRUITS QUE VOCÊ USOU!',
                audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-2/aula-10/video-4/enunciado.mp3`
            }

        case 'alfabetizados_unit3_aula3_atividade4':
            return {
                enunciado: 'DESENHE A SUA JELLYFISH NA GARRAFA!',
                audioEnunciado: `${serverHost()}/storage/ij-atividades/alfabetizados/unidade-3/aula-3/video-4/enunciado.ogg`
            }

        case 'nao-alfabetizados_unit2_aula3_atividade2':
            return {
                enunciado: 'VOCÊ JÁ ANDOU DE BUS? PINTE ESTE BUS DA COR PARA DEIXÁ-LO IGUALZINHO AO ÚLTIMO QUE VOCÊ VIU!',
                audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-3/video-2/enunciado.ogg`,
                imagem: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-3/video-2/imagem.jpg`
            }

        case 'nao-alfabetizados_unit2_aula5_atividade2':
            return {
                enunciado: 'OPS, FALTA ALGUMA COISA PARA O PLANE PODER VOAR! O QUE SERÁ QUE É? DESENHE!',
                audioEnunciado: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-5/video-2/enunciado.ogg`,
                imagem: `${serverHost()}/storage/ij-atividades/nao-alfabetizados/unidade-2/aula-5/video-2/imagem.png`
            }

        default: return {}
    }
}

export default configDesenho;