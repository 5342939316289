import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
// import api from "../services/api";
import ijws from "../services/ijws";
import "../styles/out.css";
import "./styesLogin.css";

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [solicitando, setSolicitando] = useState(false);

  const solicitarRecuperacao = (e) => {
    e.preventDefault();

    if (solicitando) return;

    if (email === "") {
      setError("Informe seu email para continuar!");
    } else {
      setSolicitando(true);
      setError("");
      setSuccess("");

      ijws
        .post("usuarios/solicitarRedefinicaoSenha", {
          email,
        })
        .then((res) => {
          if (res.email) {
            setSuccess("Um link de redefinição será enviado para o seu email.");
            setEmail("");
          } else if (res.status === 401) {
            setError(
              "Não foi possível localizar uma conta com o email informado."
            );
          } else {
            setError(
              "Não foi possível solicitar a redefinição de senha. Verifique o email informado."
            );
          }
        })
        .catch((e) => {
          console.log("Erro:", e);
        });
    }
  };

  return (
    <>
      {window.innerWidth > 800 ? (
        <div className="pagina-login">
          <div className="container-login">
            <img
              className="logo-login"
              src={require("../assets/imagens/logo.png")}
              alt="Logo da Inglês Juntos"
            />
            <p>Informe o email utilizado em seu cadastro</p>
            <form className="form-login" onSubmit={solicitarRecuperacao}>
              <input
                type="text"
                placeholder="Email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <input type="submit" className="btn-padrao" value="Enviar" />
            </form>
            {error && <h6 style={{ color: "red" }}>{error}</h6>}
            {success && <h6 style={{ color: "green" }}>{success}</h6>}
            <div className="footer-nav">
              <Link className="btn-link ali-left" to="/">
                Voltar
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ padding: 10 }}>
          <div id="logo" style={{ textAlign: "center", paddingTop: "30%" }}>
            <img
              style={{ width: 300 }}
              alt="Logo da inglês juntos"
              src={require("../assets/imagens/logo.png")}
            />
            <p>Informe o email utilizado em seu cadastro</p>
          </div>
          <form className="login" onSubmit={solicitarRecuperacao}>
            <input
              type="text"
              placeholder="Email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input type="submit" className="btn-entrar" value="Enviar" />
          </form>
          {error && <h6 style={{ color: "red" }}>{error}</h6>}
          {success && <h6 style={{ color: "green" }}>{success}</h6>}

          <div
            style={{
              textAlign: "center",
              paddingTop: 10,
            }}
          >
            <Link
              style={{
                textDecoration: "none",
                fontSize: "1em",
                fontWeight: 500,
                color: "#008fee",
              }}
              // className="btn-link ali-left"
              to="/"
            >
              Voltar
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default withRouter(SignIn);
