/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { click, win } from '../libs/soundEffects';
import {
  BoxConfirmation,
  BoxImageBG,
  BoxTopo,
  BtnConcluir,
  Button,
  Correta,
  ItemBox1,
  ItemBox2,
  Text,
  TextoConfirmacao,
  TextoDica,
  TextoInput,
  TextoInput2,
  Tl8Box2,
} from './styles/stylesEscrever';

const Escrever = ({ config }) => {
  const qtdRodadas = config.rodadas ? config.rodadas.length : 0;

  const [respostaOk, setRespostaOk] = useState(undefined);
  const [resposta, setResposta] = useState('');
  const [rodadaAtual, setRodadaAtual] = useState(undefined);
  const [numRodadaAtual, setNumRodadaAtual] = useState(0);
  const [respostaQuase, setRespostaQuase] = useState(undefined);

  useEffect(() => {
    iniciarRodada(1);
  }, []);

  const iniciarRodada = (numero) => {
    const rodada =
      config.rodadas && config.rodadas.length > 0
        ? config.rodadas[numero - 1]
        : config;

    console.log(rodadaAtual);

    if (!rodada) return;

    if (rodada.audioEnunciado) {
      const audio = new Audio(rodada.audioEnunciado);

      audio.play();
    }

    setNumRodadaAtual(numero);
    setRodadaAtual(rodada);
    setResposta('');
    setRespostaOk(false);
    setRespostaQuase(false);
  };

  const avancar = () => {
    click();

    if (numRodadaAtual === qtdRodadas || qtdRodadas === 0) {
      return config.avancar ? config.avancar() : alert('Fim!');
    } else {
      iniciarRodada(numRodadaAtual + 1);
    }
  };

  const preencher = (resposta) => {
    setResposta(resposta);

    if (resposta.toLowerCase() === rodadaAtual.resposta) {
      win();
      setRespostaOk(true);
    }

    if (rodadaAtual.variacao) {
      let alternativas = rodadaAtual.variacoes.filter((i) => i === resposta);

      if (alternativas.length > 0) {
        win();
        setRespostaOk(true);
        setRespostaQuase(true);
      }
    }
  };

  if (!rodadaAtual) return null;

  return (
    <>
      <BoxTopo>
        <ItemBox1>
          <Text>{rodadaAtual.enunciado}</Text>
        </ItemBox1>
        <ItemBox2>
          <Button onClick={() => iniciarRodada(1)}>
            <i className="fas fa-undo-alt"></i> REPETIR
          </Button>
        </ItemBox2>
      </BoxTopo>

      {!rodadaAtual.variacao ? (
        <>
          {respostaOk ? (
            <Tl8Box2>
              <BoxConfirmation>
                <Text>PARABÉNS</Text>
                <Text>VOCÊ ACERTOU!</Text>
                <BtnConcluir onClick={avancar}>
                  CONTINUAR <i className="fas fa-chevron-right"></i>{' '}
                </BtnConcluir>
              </BoxConfirmation>
            </Tl8Box2>
          ) : (
            <Tl8Box2>
              <BoxImageBG
                style={{
                  backgroundImage: `url(${rodadaAtual.imagem})`,
                  width: rodadaAtual.imagemLargura
                    ? rodadaAtual.imagemLargura
                    : 524,
                  height: rodadaAtual.imagemAltura
                    ? rodadaAtual.imagemAltura
                    : 524,
                }}
              ></BoxImageBG>
            </Tl8Box2>
          )}
        </>
      ) : (
        <>
          {respostaOk ? (
            <>
              {respostaQuase ? (
                <Tl8Box2>
                  <BoxConfirmation>
                    <Text>
                      QUASE LÁ! VOCÊ QUIS DIZER
                      <Correta>{rodadaAtual.correta}</Correta> CERTO?
                    </Text>
                    <BtnConcluir onClick={avancar}>
                      CONTINUAR <i className="fas fa-chevron-right"></i>{' '}
                    </BtnConcluir>
                  </BoxConfirmation>
                </Tl8Box2>
              ) : (
                <Tl8Box2>
                  <BoxConfirmation>
                    <Text>PARABÉNS</Text>
                    <Text>
                      VOCÊ ACERTOU!
                      <Correta>{rodadaAtual.correta}</Correta>
                    </Text>
                    <BtnConcluir onClick={avancar}>
                      CONTINUAR <i className="fas fa-chevron-right"></i>{' '}
                    </BtnConcluir>
                  </BoxConfirmation>
                </Tl8Box2>
              )}
            </>
          ) : (
            <Tl8Box2>
              <BoxImageBG
                style={{
                  backgroundImage: `url(${rodadaAtual.imagem})`,
                  width: rodadaAtual.imagemLargura
                    ? rodadaAtual.imagemLargura
                    : 524,
                  height: rodadaAtual.imagemAltura
                    ? rodadaAtual.imagemAltura
                    : 524,
                }}
              ></BoxImageBG>
            </Tl8Box2>
          )}
          {!respostaOk && (
            <TextoDica>
              <TextoInput2>
                <p>{rodadaAtual.dica}</p>
              </TextoInput2>
            </TextoDica>
          )}
        </>
      )}
      <TextoConfirmacao>
        <TextoInput>
          <input
            disabled={respostaOk}
            placeholder={rodadaAtual.rotulo}
            type="text"
            value={resposta}
            onChange={(e) => preencher(e.target.value)}
          />
        </TextoInput>
      </TextoConfirmacao>
    </>
  );
};

export default Escrever;
