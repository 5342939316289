import React, { useState, useEffect, useRef} from 'react';

let fim = false;

const Gif = ({config}) => {
    const vidRef = useRef(null);
    const qtdRodadas = config.rodadas ? config.rodadas.length : 0;

    const [rodadaAtual, setRodadaAtual] = useState(undefined);
    const [numRodadaAtual, setNumRodadaAtual] = useState(0);
    const [respostaOk, setRespostaOk] = useState(undefined);
    const [gif, setGif] = useState(true);

    useEffect(() => {
        if(config.audioInicio){
            const audio = new Audio(config.audioInicio);

            audio.play();        
        }

        iniciarRodada(1);
    }, []);

    const iniciarRodada = (numero) => {
        const rodada = config.rodadas[numero - 1];

        if(!rodada) return;

        if(rodada.audioEnunciado){
            const audio = new Audio(rodada.audioEnunciado);

            audio.play();        
        }

        setNumRodadaAtual(numero);
        setRodadaAtual(rodada);
        setRespostaOk(false);
      
    }

    const avancar = () => {
        if(numRodadaAtual === qtdRodadas){
            setRespostaOk(true);
        } else {
            iniciarRodada(numRodadaAtual + 1);
        }
    }

    const encerrar = () => 
    {
      fim = true;
      config.avancar ? config.avancar() : alert('acabou!');
    }

    const concluir = () => {
      setRespostaOk(true);
    }

    if(!rodadaAtual) return null;

    function handleGif() {
      if(gif) {
        vidRef.current.pause();
        setGif(false)
      } else {
        vidRef.current.play();
        setGif(true)
      }
    }

    return(
        <div className="content">
            <div className="container">
                <div className="box-topo">
                    <div className="item-box-topo-1">
                        <p className="p-text">{rodadaAtual.enunciado}</p>
                    </div>
                    <div className="item-box-topo-2">
                        <a className="btn-repetir" onClick={() => iniciarRodada(1)}><i className="fas fa-undo-alt"></i> REPETIR</a>
                    </div>
                </div>

                {!respostaOk &&
                  <>
                    <div onClick={handleGif} style={{alignItems: 'center', display: 'flex', justifyContent: 'center'}}>
                      <video 
                        autoPlay={true} 
                        loop={true} 
                        src={rodadaAtual.imagem}
                        style={{width: 650}}
                        muted
                        ref={vidRef}
                      />
                    </div>

                    <div className="box-botao-centro" style={{background: '#75dfeb', borderRadius: 15, padding: 15}}>
                      <a className="btn-concluir" onClick={concluir}><i className="fas fa-check"></i> CONCLUIR</a>
                    </div>
                  </>
                }
                
                {respostaOk &&
                  <div className="tl8-box-2">
                    <div className="tl7-box-confirmacao">
                      <p className="p-text">PARABÉNS</p>
                      <p className="p-text">VOCÊ CONSEGUIU!</p>
                      <a className="btn-concluir" onClick={encerrar}>CONTINUAR <i className="fas fa-chevron-right"></i> </a>
                    </div>
                  </div>
                }
            </div>
        </div>
    ) 
}

export default Gif;


